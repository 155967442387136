"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getUserRegisterData = exports.getStudentMapData = exports.getStudentData = exports.getRopeBarData = exports.getProductData = exports.getPlatformData = exports.getOrderSuccessData = exports.getOrderPieData = exports.getOrderData = exports.getOperateLogs = exports.getOnlineData = exports.getNoticeHistory = void 0;
var _request = require("@/utils/request");
//=============================================================商户端=======================================================
var getProductData = function getProductData() {
  return (0, _request.fetch)("/seller/dashboard/productData");
};
exports.getProductData = getProductData;
var getOrderData = function getOrderData() {
  return (0, _request.fetch)("/seller/dashboard/orderData");
};

//=============================================================平台端=======================================================
/**
 * 获取通知历史记录
 */
exports.getOrderData = getOrderData;
var getNoticeHistory = function getNoticeHistory() {
  return (0, _request.fetch)("/platform/dashboard/getNoticeHistory");
};
exports.getNoticeHistory = getNoticeHistory;
var getStudentMapData = function getStudentMapData() {
  return (0, _request.fetch)("/platform/dashboard/getStudentMapData");
};
/**
 * 获取跳绳人数柱形图数据
 */
exports.getStudentMapData = getStudentMapData;
var getRopeBarData = function getRopeBarData() {
  return (0, _request.fetch)("/platform/dashboard/getRopeBarData");
};
/**
 * 获取最近十条操作记录
 */
exports.getRopeBarData = getRopeBarData;
var getOperateLogs = function getOperateLogs() {
  return (0, _request.fetch)("/platform/dashboard/getOperateLogs");
};
/**
 * 获取平台数据
 */
exports.getOperateLogs = getOperateLogs;
var getPlatformData = function getPlatformData() {
  return (0, _request.fetch)("/platform/dashboard/getPlatformData");
};
/**
 * 获取在线系统用户数据
 */
exports.getPlatformData = getPlatformData;
var getOnlineData = function getOnlineData() {
  return (0, _request.fetch)("/platform/dashboard/getOnlineData");
};
/**
 * 获取学生饼图数据
 */
exports.getOnlineData = getOnlineData;
var getStudentData = function getStudentData() {
  return (0, _request.fetch)("/platform/dashboard/getStudentData");
};
/**
 * 获取最近一周用户注册数据
 */
exports.getStudentData = getStudentData;
var getUserRegisterData = function getUserRegisterData() {
  return (0, _request.fetch)("/platform/dashboard/getUserRegisterData");
};
/**
 * 获取商城订单饼图数据
 */
exports.getUserRegisterData = getUserRegisterData;
var getOrderPieData = function getOrderPieData() {
  return (0, _request.fetch)("/platform/dashboard/getOrderPieData");
};
/**
 * 获取近半年商城交易成功订单数据
 */
exports.getOrderPieData = getOrderPieData;
var getOrderSuccessData = function getOrderSuccessData() {
  return (0, _request.fetch)("/platform/dashboard/getOrderSuccessData");
};
exports.getOrderSuccessData = getOrderSuccessData;