var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "sendCode" },
    [
      _c(
        "el-form",
        {
          ref: "elForm",
          staticClass: "body",
          attrs: { model: _vm.ruleForm, rules: _vm.rules },
        },
        [
          _c(
            "el-form-item",
            { attrs: { prop: _vm.isCheckPhone ? "phone" : null } },
            [
              _c("el-input", {
                class: _vm.myClass,
                attrs: {
                  disabled: _vm.isDisabled,
                  "prefix-icon": "el-icon-phone",
                  placeholder: "请输入手机号",
                },
                model: {
                  value: _vm.isHidePhone
                    ? _vm.hideCenterPhone(_vm.ruleForm.phone)
                    : _vm.ruleForm.phone,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.isHidePhone
                        ? _vm.hideCenterPhone(_vm.ruleForm.phone)
                        : _vm.ruleForm,
                      "phone",
                      $$v
                    )
                  },
                  expression:
                    "isHidePhone?hideCenterPhone(ruleForm.phone):ruleForm.phone",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("el-form-item", { attrs: { prop: "code" } }, [
            _c(
              "div",
              { staticClass: "code" },
              [
                _c("el-input", {
                  class: _vm.myClass,
                  attrs: {
                    "prefix-icon": "el-icon-tickets",
                    placeholder: "请输入验证码",
                  },
                  model: {
                    value: _vm.ruleForm.code,
                    callback: function ($$v) {
                      _vm.$set(_vm.ruleForm, "code", $$v)
                    },
                    expression: "ruleForm.code",
                  },
                }),
                _vm._v(" "),
                _c(
                  "el-button",
                  {
                    staticClass: "send-btn",
                    style: { color: _vm.fontColor },
                    attrs: { disabled: _vm.disabled, type: "text" },
                    on: { click: _vm.sendCode },
                  },
                  [_vm._v("\n          " + _vm._s(_vm.btnText) + "\n        ")]
                ),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _vm.isShowSub
            ? _c(
                "div",
                { staticClass: "form-button-container" },
                [
                  _c(
                    "el-button",
                    {
                      staticStyle: { width: "80%" },
                      attrs: { type: "primary" },
                      on: { click: _vm.submit },
                    },
                    [_vm._v(_vm._s(_vm.submitText))]
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }