"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _withdrawLog = require("./withdraw-log");
var _globalDialog = require("@/mixins/global-dialog");
var _memberApi = require("@/api/member-api");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  mixins: [_withdrawLog.withdrawLog, _globalDialog.updateDialog],
  methods: {
    //执行修改操作
    doUpdate: function doUpdate() {
      return (0, _memberApi.withdrawLogReview)({
        id: this.entity.id,
        reviewStatus: this.entity.reviewStatus,
        cancelReason: this.entity.cancelReason
      });
    }
  }
};
exports.default = _default;