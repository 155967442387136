"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var getters = {
  sidebar: function sidebar(state) {
    return state.app.sidebar;
  },
  visitedViews: function visitedViews(state) {
    return state.tagsView.visitedViews;
  },
  cachedViews: function cachedViews(state) {
    return state.tagsView.cachedViews;
  },
  device: function device(state) {
    return state.app.device;
  },
  rowData: function rowData(state) {
    return state.app.selectedRowData;
  },
  websocket: function websocket(state) {
    return state.ws.websocket;
  }
};
var _default = getters;
exports.default = _default;