"use strict";

var _interopRequireDefault = require("D:/shenbimaliang1/sbml-backend/backend-page/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.split");
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _orderApi = require("@/api/order-api");
var _globalDialog = require("@/mixins/global-dialog");
var _permission = require("@/mixins/permission");
var _orderSlave = require("./order-slave");
var _OrderSlaveAdd = _interopRequireDefault(require("./OrderSlaveAdd"));
var _OrderSlaveShip = _interopRequireDefault(require("./OrderSlaveShip.vue"));
var _OrderSlaveDetail = _interopRequireDefault(require("./OrderSlaveDetail"));
var _OrderSlaveRefund = _interopRequireDefault(require("@/views/order/orderSlave/OrderSlaveRefund.vue"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'OrderSlavePage',
  components: {
    OrderSlaveRefund: _OrderSlaveRefund.default,
    Pagination: _Pagination.default,
    OrderSlaveAdd: _OrderSlaveAdd.default,
    OrderSlaveShip: _OrderSlaveShip.default,
    OrderSlaveDetail: _OrderSlaveDetail.default
  },
  mixins: [_orderSlave.orderSlave, _globalDialog.pageDialog, _permission.permission],
  props: {
    obj: {
      type: Object,
      default: null
    },
    isShow: {
      type: Boolean,
      default: true
    },
    isRefund: {
      type: Boolean,
      default: false
    }
  },
  created: function created() {
    if (this.isNotEmpty(this.obj)) {
      this.pageParam.orderNo = this.obj.orderNo;
    }
    this.pageParam.isRefund = this.isRefund;
  },
  methods: {
    //导出
    exportExcel: function exportExcel() {
      (0, _orderApi.orderSlaveExport)(this.pageParam);
    },
    //调用接口查询
    doSearch: function doSearch() {
      return (0, _orderApi.orderSlavePage)(this.pageParam);
    },
    /**
     * 执行批量删除操作
     * @param {Object} idList id集合
     */
    doBatchDelete: function doBatchDelete(idList) {
      return batchDelete(idList);
    },
    /**
     * 打开子订单详情窗口
     */
    openDetailDialog: function openDetailDialog() {
      var _this = this;
      var selectData = this.getSelect();
      if (this.isEmpty(selectData)) {
        return;
      }
      this.showDetailDialog = true;
      this.$nextTick(function () {
        if (_this.isNotEmpty(selectData.addressInfo) && _this.isNotUndefined(selectData.addressInfo)) {
          var split = selectData.addressInfo.split(" | ");
          selectData.addressPhone = split[1];
          selectData.addressName = split[0];
          selectData.address = split[2];
        }
        // 把选中行的数据拷贝一份传递给修改对话框
        selectData = _this.transTimeDia(selectData);
        _this.$refs.detailDialog.setEntity(_this.objectCopy(selectData));
      });
    },
    /**
     * 打开子订单发货窗口
     */
    openShipDialog: function openShipDialog() {
      var _this2 = this;
      var selectData = this.getSelect();
      if (this.isEmpty(selectData)) {
        return;
      }
      this.showShipDialog = true;
      this.$nextTick(function () {
        if (_this2.isNotEmpty(selectData.addressInfo) && _this2.isNotUndefined(selectData.addressInfo)) {
          var split = selectData.addressInfo.split(" | ");
          selectData.addressPhone = split[1];
          selectData.addressName = split[0];
          selectData.address = split[2];
        }
        // 把选中行的数据拷贝一份传递给修改对话框
        selectData = _this2.transTimeDia(selectData);
        _this2.$refs.shipDialog.setEntity(_this2.objectCopy(selectData));
      });
    },
    /**
     * 打开子订单退款窗口
     */
    openRefundDialog: function openRefundDialog() {
      var _this3 = this;
      var selectData = this.getSelect();
      if (this.isEmpty(selectData)) {
        return;
      }
      if (selectData.refundStatus === 0) {
        this.errorMessage("该笔订单未发起退款！");
        return;
      }
      if (selectData.refundStatus !== 1) {
        this.errorMessage("该笔订单退款已处理！");
        return;
      }
      this.showRefundDialog = true;
      this.$nextTick(function () {
        // 把选中行的数据拷贝一份传递给修改对话框
        selectData = _this3.transTimeDia(selectData);
        _this3.$refs.refundDialog.setEntity(_this3.objectCopy(selectData));
      });
    }
  }
};
exports.default = _default;