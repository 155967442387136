"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _adUser = require("./ad-user");
var _globalDialog = require("@/mixins/global-dialog");
var _adMasterApi = require("@/api/ad-master-api");
var _print = require("vue-print-nb/lib/print.common");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  mixins: [_adUser.adUser, _globalDialog.updateDialog],
  methods: {
    //执行修改操作
    doUpdate: function doUpdate() {
      if (this.entity.reviewStatus < 2) {
        this.errorMessage("请选择审核通过或不通过");
        return;
      }
      if (this.entity.reviewStatus === 3 && this.isEmpty(this.entity.backReason)) {
        this.errorMessage("审核不通过需填写驳回理由");
        return;
      }
      return (0, _adMasterApi.adUserReview)({
        id: this.entity.id,
        reviewStatus: this.entity.reviewStatus,
        backReason: this.entity.backReason
      });
    }
  }
};
exports.default = _default;