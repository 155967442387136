exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".notice{margin-right:10px;padding:0 20px;width:60px;cursor:pointer}.el-badge__content.is-fixed{top:15px !important}.el-popover.notice-wrapper{border-radius:10px}.el-popover.notice-wrapper .notice-tab .el-menu-item,.el-popover.notice-wrapper .el-submenu__title{height:35px;line-height:35px}.el-popover.notice-wrapper .el-menu-item{width:110px;-webkit-box-pack:center;-ms-flex-pack:center;justify-content:center;text-align:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center}.el-popover.notice-wrapper .scroll-wrapper{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-direction:column;flex-direction:column;max-height:400px;overflow:scroll}.el-popover.notice-wrapper .scroll-wrapper .scroll-item{height:70px;padding:10px;cursor:pointer;border-bottom:1px solid #cccccc}.el-popover.notice-wrapper .scroll-wrapper .scroll-item .content{overflow:hidden}.el-popover.notice-wrapper .scroll-wrapper .scroll-item .other{display:-webkit-box;display:-ms-flexbox;display:flex;-ms-flex-pack:distribute;justify-content:space-around;font-size:12px}.el-popover.notice-wrapper .scroll-wrapper .scroll-item .other .datetime{-webkit-box-flex:1;-ms-flex:1;flex:1;color:#b7b6b6}.el-popover.notice-wrapper .scroll-wrapper .scroll-item .other .unread{color:#f50d0d}.el-popover.notice-wrapper .scroll-wrapper .scroll-item:hover{background-color:#d6e4f5}.el-popover.notice-wrapper .notice-bottom{display:-webkit-box;display:-ms-flexbox;display:flex;-ms-flex-pack:distribute;justify-content:space-around}.el-popover.notice-wrapper .notice-bottom .el-button{padding:0}.el-popover.notice-wrapper .tip{text-align:center;color:#a2a0a0;font-size:12px}::-webkit-scrollbar{width:5px;height:10px}::-webkit-scrollbar-thumb{background-color:#dbd9d9;border-radius:3px}\n", ""]);

// exports
exports.locals = {
	"menuText": "#bfcbd9",
	"menuActiveText": "#409EFF",
	"subMenuActiveText": "#f4f4f5",
	"menuBg": "#304156",
	"menuHover": "#263445",
	"subMenuBg": "#1f2d3d",
	"subMenuHover": "#001528",
	"platform_btn": "linear-gradient(233deg, #119bf6 40%, #a5c7f3 100%)",
	"menuSellerText": "#fff3f9",
	"menuActiveSellerText": "#FF652F",
	"subMenuActiveSellerText": "#FF652F",
	"menuSellerBg": "#FFAE26",
	"menuSellerHover": "#c78920",
	"subMenuSellerBg": "#fda615",
	"subMenuSellerHover": "#cb8511",
	"seller_btn": "linear-gradient(233deg, #FFAE26 40%, #FF652F 100%)",
	"sideBarWidth": "210px"
};