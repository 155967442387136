"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.express = void 0;
var express = {
  data: function data() {
    return {
      //是否展示详情窗口
      showDetailDialog: false,
      backUrl: '/order/express/page',
      entity: {},
      rules: {
        phone: [{
          required: true,
          message: '请输入联系方式'
        }, {
          max: 20,
          message: '联系方式长度在20个字符内'
        }],
        name: [{
          required: true,
          message: '请输入名称'
        }, {
          max: 20,
          message: '名称长度在20个字符内'
        }],
        logo: [{
          required: true,
          message: '请输入LOGO'
        }, {
          max: 256,
          message: 'LOGO长度在256个字符内'
        }],
        code: [{
          required: true,
          message: '请输入编码'
        }, {
          max: 50,
          message: '编码长度在50个字符内'
        }]
      }
    };
  }
};
exports.express = express;