var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-upload",
    {
      ref: "upload",
      attrs: {
        drag: "",
        action: "#",
        accept: _vm.accept,
        "show-file-list": true,
        "before-upload": _vm.beforeUpload,
        "http-request": _vm.handleUpload,
        multiple: false,
      },
    },
    [
      _c("i", { staticClass: "el-icon-upload" }),
      _vm._v(" "),
      _c("div", { staticClass: "el-upload__text" }, [
        _vm._v("将文件拖到此处，或"),
        _c("em", [_vm._v("点击上传")]),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "el-upload__tip", attrs: { slot: "tip" }, slot: "tip" },
        [_vm._v("只能上传" + _vm._s(_vm.accept) + "文件")]
      ),
      _vm._v(" "),
      _c("el-progress", { attrs: { percentage: _vm.progressPercent } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }