var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "新增交作业记录",
        visible: _vm.dialogVisible,
        "close-on-click-modal": false,
        center: "",
        width: _vm.dialogWidth,
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
        close: _vm.closeDialog,
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "elForm",
          staticClass: "dialog-form",
          attrs: {
            model: _vm.entity,
            rules: _vm.rules,
            inline: true,
            "label-width": _vm.getLabelWidth(),
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "教师ID", prop: "teacherUserId" } },
            [
              _c("el-input", {
                directives: [
                  {
                    name: "number-input",
                    rawName: "v-number-input",
                    value: { type: "integer" },
                    expression: "{ type: 'integer' }",
                  },
                ],
                attrs: { clearable: "" },
                model: {
                  value: _vm.entity.teacherUserId,
                  callback: function ($$v) {
                    _vm.$set(_vm.entity, "teacherUserId", $$v)
                  },
                  expression: "entity.teacherUserId",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "作业ID", prop: "jobId" } },
            [
              _c("el-input", {
                directives: [
                  {
                    name: "number-input",
                    rawName: "v-number-input",
                    value: { type: "integer" },
                    expression: "{ type: 'integer' }",
                  },
                ],
                attrs: { clearable: "" },
                model: {
                  value: _vm.entity.jobId,
                  callback: function ($$v) {
                    _vm.$set(_vm.entity, "jobId", $$v)
                  },
                  expression: "entity.jobId",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "跳绳记录ID", prop: "ropeId" } },
            [
              _c("el-input", {
                directives: [
                  {
                    name: "number-input",
                    rawName: "v-number-input",
                    value: { type: "integer" },
                    expression: "{ type: 'integer' }",
                  },
                ],
                attrs: { clearable: "" },
                model: {
                  value: _vm.entity.ropeId,
                  callback: function ($$v) {
                    _vm.$set(_vm.entity, "ropeId", $$v)
                  },
                  expression: "entity.ropeId",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "是否已提交", prop: "hadPost" } },
            [
              _c(
                "el-select",
                {
                  model: {
                    value: _vm.entity.hadPost,
                    callback: function ($$v) {
                      _vm.$set(_vm.entity, "hadPost", $$v)
                    },
                    expression: "entity.hadPost",
                  },
                },
                _vm._l(_vm.hadPostMap, function (value, key) {
                  return _c("el-option", {
                    key: key,
                    attrs: { label: value, value: key },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "学生姓名", prop: "name" } },
            [
              _c("el-input", {
                attrs: { clearable: "" },
                model: {
                  value: _vm.entity.name,
                  callback: function ($$v) {
                    _vm.$set(_vm.entity, "name", $$v)
                  },
                  expression: "entity.name",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "作业提交时间", prop: "postTime" } },
            [
              _c("el-date-picker", {
                attrs: {
                  "value-format": "yyyy-MM-dd HH:mm:ss",
                  type: "datetime",
                  placeholder: "请选择作业提交时间",
                },
                model: {
                  value: _vm.entity.postTime,
                  callback: function ($$v) {
                    _vm.$set(_vm.entity, "postTime", $$v)
                  },
                  expression: "entity.postTime",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "数量", prop: "num" } },
            [
              _c("el-input", {
                directives: [
                  {
                    name: "number-input",
                    rawName: "v-number-input",
                    value: { type: "integer" },
                    expression: "{ type: 'integer' }",
                  },
                ],
                attrs: { clearable: "" },
                model: {
                  value: _vm.entity.num,
                  callback: function ($$v) {
                    _vm.$set(_vm.entity, "num", $$v)
                  },
                  expression: "entity.num",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "秒数", prop: "seconds" } },
            [
              _c("el-input", {
                directives: [
                  {
                    name: "number-input",
                    rawName: "v-number-input",
                    value: { type: "integer" },
                    expression: "{ type: 'integer' }",
                  },
                ],
                attrs: { clearable: "" },
                model: {
                  value: _vm.entity.seconds,
                  callback: function ($$v) {
                    _vm.$set(_vm.entity, "seconds", $$v)
                  },
                  expression: "entity.seconds",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "是否达标", prop: "isPass" } },
            [
              _c(
                "el-select",
                {
                  model: {
                    value: _vm.entity.isPass,
                    callback: function ($$v) {
                      _vm.$set(_vm.entity, "isPass", $$v)
                    },
                    expression: "entity.isPass",
                  },
                },
                _vm._l(_vm.isPassMap, function (value, key) {
                  return _c("el-option", {
                    key: key,
                    attrs: { label: value, value: key },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "用户ID", prop: "userId" } },
            [
              _c("el-input", {
                directives: [
                  {
                    name: "number-input",
                    rawName: "v-number-input",
                    value: { type: "integer" },
                    expression: "{ type: 'integer' }",
                  },
                ],
                attrs: { clearable: "" },
                model: {
                  value: _vm.entity.userId,
                  callback: function ($$v) {
                    _vm.$set(_vm.entity, "userId", $$v)
                  },
                  expression: "entity.userId",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "UUID", prop: "uuid" } },
            [
              _c("el-input", {
                attrs: { clearable: "" },
                model: {
                  value: _vm.entity.uuid,
                  callback: function ($$v) {
                    _vm.$set(_vm.entity, "uuid", $$v)
                  },
                  expression: "entity.uuid",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "年级ID", prop: "gradeId" } },
            [
              _c("el-input", {
                directives: [
                  {
                    name: "number-input",
                    rawName: "v-number-input",
                    value: { type: "integer" },
                    expression: "{ type: 'integer' }",
                  },
                ],
                attrs: { clearable: "" },
                model: {
                  value: _vm.entity.gradeId,
                  callback: function ($$v) {
                    _vm.$set(_vm.entity, "gradeId", $$v)
                  },
                  expression: "entity.gradeId",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "学校ID", prop: "schoolId" } },
            [
              _c("el-input", {
                directives: [
                  {
                    name: "number-input",
                    rawName: "v-number-input",
                    value: { type: "integer" },
                    expression: "{ type: 'integer' }",
                  },
                ],
                attrs: { clearable: "" },
                model: {
                  value: _vm.entity.schoolId,
                  callback: function ($$v) {
                    _vm.$set(_vm.entity, "schoolId", $$v)
                  },
                  expression: "entity.schoolId",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "班级", prop: "className" } },
            [
              _c("el-input", {
                attrs: { clearable: "" },
                model: {
                  value: _vm.entity.className,
                  callback: function ($$v) {
                    _vm.$set(_vm.entity, "className", $$v)
                  },
                  expression: "entity.className",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "记录号", prop: "mongoId" } },
            [
              _c("el-input", {
                attrs: { clearable: "" },
                model: {
                  value: _vm.entity.mongoId,
                  callback: function ($$v) {
                    _vm.$set(_vm.entity, "mongoId", $$v)
                  },
                  expression: "entity.mongoId",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "form-button-container" },
        [
          _c(
            "el-button",
            {
              attrs: { type: "danger", icon: "el-icon-back" },
              on: {
                click: function ($event) {
                  _vm.dialogVisible = false
                },
              },
            },
            [_vm._v("返回")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              directives: [
                { name: "preventReClick", rawName: "v-preventReClick" },
              ],
              staticClass: "form-button-submit",
              attrs: { type: "primary", icon: "el-icon-check" },
              on: { click: _vm.submitForm },
            },
            [_vm._v("提交")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }