var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "navbar" },
        [
          _c("hamburger", {
            staticClass: "hamburger-container",
            attrs: { "is-active": _vm.sidebar.opened },
            on: { toggleClick: _vm.toggleSideBar },
          }),
          _vm._v(" "),
          _c("breadcrumb", {
            staticClass: "breadcrumb-container",
            attrs: { id: "breadcrumb-container" },
          }),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "right-menu" },
            [
              _vm.isSellerServer()
                ? _c("notice", { staticClass: "notice" })
                : _vm._e(),
              _vm._v(" "),
              _c("full-screen", { staticClass: "full-screen-wrapper" }),
              _vm._v(" "),
              _c(
                "el-dropdown",
                { staticClass: "avatar-container" },
                [
                  _c("div", { staticClass: "avatar-wrapper" }, [
                    _c("a", [_vm._v(_vm._s(_vm.systemUser.name))]),
                    _vm._v(" "),
                    _c("i", { staticClass: "el-icon-caret-bottom" }),
                  ]),
                  _vm._v(" "),
                  _c(
                    "el-dropdown-menu",
                    {
                      staticClass: "user-dropdown",
                      attrs: { slot: "dropdown" },
                      slot: "dropdown",
                    },
                    [
                      _c(
                        "router-link",
                        { attrs: { to: "/" } },
                        [_c("el-dropdown-item", [_vm._v("首页")])],
                        1
                      ),
                      _vm._v(" "),
                      !_vm.isSellerServer()
                        ? _c(
                            "el-dropdown-item",
                            {
                              nativeOn: {
                                click: function ($event) {
                                  return _vm.showDialog2($event)
                                },
                              },
                            },
                            [_vm._v("修改昵称")]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "el-dropdown-item",
                        {
                          nativeOn: {
                            click: function ($event) {
                              return _vm.showDialog($event)
                            },
                          },
                        },
                        [_vm._v("修改密码")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-dropdown-item",
                        {
                          attrs: { divided: "" },
                          nativeOn: {
                            click: function ($event) {
                              return _vm.logout($event)
                            },
                          },
                        },
                        [_vm._v("退出登录")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        [_vm.isPc() ? _c("tags-view", { ref: "tagsView" }) : _vm._e()],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "修改昵称",
            visible: _vm.dialogFormVisible2,
            "close-on-click-modal": false,
            center: "",
            width: "30%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogFormVisible2 = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "elForm2",
              staticClass: "dialog-form",
              attrs: { model: _vm.model, rules: _vm.rules, inline: true },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "昵称", prop: "tempName" } },
                [
                  _c("el-input", {
                    attrs: { autocomplete: "off" },
                    model: {
                      value: _vm.model.tempName,
                      callback: function ($$v) {
                        _vm.$set(_vm.model, "tempName", $$v)
                      },
                      expression: "model.tempName",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              staticClass: "submit-btn",
              style: {
                backgroundImage: _vm.isSellerServer()
                  ? _vm.variables.seller_btn
                  : _vm.variables.platform_btn,
              },
              on: { click: _vm.submitForm2 },
            },
            [_vm._v("确 定\n    ")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "修改密码",
            visible: _vm.dialogFormVisible,
            "close-on-click-modal": false,
            center: "",
            width: "30%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogFormVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "elForm",
              staticClass: "dialog-form",
              attrs: { model: _vm.systemUser, rules: _vm.rules, inline: true },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "原始密码", prop: "oldPassword" } },
                [
                  _c("el-input", {
                    attrs: { autocomplete: "off" },
                    model: {
                      value: _vm.systemUser.oldPassword,
                      callback: function ($$v) {
                        _vm.$set(_vm.systemUser, "oldPassword", $$v)
                      },
                      expression: "systemUser.oldPassword",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "新密码", prop: "password" } },
                [
                  _c("el-input", {
                    attrs: { autocomplete: "off" },
                    model: {
                      value: _vm.systemUser.password,
                      callback: function ($$v) {
                        _vm.$set(_vm.systemUser, "password", $$v)
                      },
                      expression: "systemUser.password",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "新密码确认", prop: "confirmPassword" } },
                [
                  _c("el-input", {
                    attrs: { autocomplete: "off" },
                    model: {
                      value: _vm.systemUser.confirmPassword,
                      callback: function ($$v) {
                        _vm.$set(_vm.systemUser, "confirmPassword", $$v)
                      },
                      expression: "systemUser.confirmPassword",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              staticClass: "submit-btn",
              style: {
                backgroundImage: _vm.isSellerServer()
                  ? _vm.variables.seller_btn
                  : _vm.variables.platform_btn,
              },
              on: { click: _vm.submitForm },
            },
            [_vm._v("\n      确 定\n    ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }