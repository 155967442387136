"use strict";

var _interopRequireDefault = require("D:/shenbimaliang1/sbml-backend/backend-page/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _operationApi = require("@/api/operation-api");
var _globalDialog = require("@/mixins/global-dialog");
var _permission = require("@/mixins/permission");
var _version = require("./version");
var _VersionAdd = _interopRequireDefault(require("./VersionAdd"));
var _VersionUpdate = _interopRequireDefault(require("./VersionUpdate"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'VersionPage',
  components: {
    Pagination: _Pagination.default,
    VersionAdd: _VersionAdd.default,
    VersionUpdate: _VersionUpdate.default
  },
  mixins: [_version.version, _globalDialog.pageDialog, _permission.permission],
  methods: {
    //调用接口查询
    doSearch: function doSearch() {
      return (0, _operationApi.versionPage)(this.pageParam);
    },
    /**
     * 执行批量删除操作
     * @param {Object} idList id集合
     */
    doBatchDelete: function doBatchDelete(idList) {
      return (0, _operationApi.versionDelete)(idList);
    }
  }
};
exports.default = _default;