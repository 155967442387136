"use strict";

var _interopRequireDefault = require("D:/shenbimaliang1/sbml-backend/backend-page/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.number.constructor");
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _schoolApi = require("@/api/school-api");
var _globalDialog = require("@/mixins/global-dialog");
var _permission = require("@/mixins/permission");
var _grade = require("./grade");
var _GradeAdd = _interopRequireDefault(require("./GradeAdd"));
var _GradeUpdate = _interopRequireDefault(require("./GradeUpdate"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'GradePage',
  components: {
    Pagination: _Pagination.default,
    GradeAdd: _GradeAdd.default,
    GradeUpdate: _GradeUpdate.default
  },
  mixins: [_grade.grade, _globalDialog.pageDialog, _permission.permission],
  props: {
    schoolId: {
      type: Number
    },
    isShow: {
      type: Boolean,
      default: true
    }
  },
  created: function created() {
    if (this.isNotEmpty(this.schoolId)) {
      this.pageParam.schoolId = this.schoolId;
    }
  },
  methods: {
    //导出
    exportExcel: function exportExcel() {
      (0, _schoolApi.gradeExport)(this.pageParam);
    },
    //调用接口查询
    doSearch: function doSearch() {
      return (0, _schoolApi.gradePage)(this.pageParam);
    },
    /**
     * 执行批量删除操作
     * @param {Object} idList id集合
     */
    doBatchDelete: function doBatchDelete(idList) {
      return (0, _schoolApi.gradeDelete)(idList);
    }
  }
};
exports.default = _default;