"use strict";

module.exports = {
  title: '神比孖良后台系统',
  /**
   * 是否展示设置
   */
  showSettings: false,
  /**
   * @type {boolean} true | false
   * @description Whether fix the header
   */
  fixedHeader: true,
  /**
   * @type {boolean} true | false
   * @description Whether show the logo in sidebar
   */
  sidebarLogo: true
};