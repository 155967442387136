"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _user = require("./user");
var _globalDialog = require("@/mixins/global-dialog");
var _memberApi = require("@/api/member-api");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  mixins: [_user.user, _globalDialog.updateDialog],
  methods: {
    //执行修改操作
    doUpdate: function doUpdate() {
      if (this.entity.rechargeMoney === 0) {
        this.errorMessage('充值金额不能为0');
        return;
      }
      return (0, _memberApi.userRecharge)(this.entity);
    }
  }
};
exports.default = _default;