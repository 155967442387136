var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      staticClass: "seller",
      attrs: {
        title: _vm.title,
        visible: _vm.dialogVisible,
        "close-on-click-modal": false,
        center: "",
        "append-to-body": "",
        width: "40%",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
        close: function ($event) {
          return _vm.closeDialog()
        },
      },
    },
    [_c("div", { domProps: { innerHTML: _vm._s(_vm.content) } })]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }