"use strict";

var _interopRequireDefault = require("D:/shenbimaliang1/sbml-backend/backend-page/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _interopRequireWildcard2 = _interopRequireDefault(require("D:/shenbimaliang1/sbml-backend/backend-page/node_modules/@babel/runtime/helpers/interopRequireWildcard.js"));
/**
 * 商户平台管理路由配置
 */
var sellerRouter = [{
  path: 'seller/page',
  name: 'SellerPage',
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/views/seller/seller/SellerPage'));
    });
  },
  meta: {
    title: '商户列表'
  }
}, {
  path: 'sellerMoneyBalanceLog/page',
  name: 'sellerMoneyBalanceLog',
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/views/seller/sellerMoneyBalanceLog/SellerMoneyBalanceLogPage'));
    });
  },
  meta: {
    title: '商户余额明细列表'
  }
}, {
  path: 'sellerReviewLog/page',
  name: 'SellerReviewLogPage',
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/views/seller/sellerReviewLog/SellerReviewLogPage'));
    });
  },
  meta: {
    title: '商户审核记录表列表'
  }
}, {
  path: 'sellerWithdrawLog/page',
  name: 'SellerWithdrawLogPage',
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/views/seller/sellerWithdrawLog/SellerWithdrawLogPage'));
    });
  },
  meta: {
    title: '商户提现管理列表'
  }
}];
var _default = sellerRouter;
exports.default = _default;