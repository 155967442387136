"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
var _default2 = {
  name: "Clock",
  props: {
    cId: {
      type: String,
      default: function _default() {
        return this.guid();
      }
    }
  },
  data: function data() {
    return {};
  },
  mounted: function mounted() {
    try {
      var clockEChart = this.$echarts5.init(document.getElementById("clockEcharts" + this.cId));
      var option = {
        series: [{
          name: 'hour',
          type: 'gauge',
          startAngle: 90,
          endAngle: -270,
          min: 0,
          max: 12,
          splitNumber: 12,
          clockwise: true,
          radius: '60%',
          axisLine: {
            // 坐标轴线
            lineStyle: {
              // 属性lineStyle控制线条样式
              color: [[1, '#d73d32']],
              width: 0
            }
          },
          axisLabel: {
            //是否展示数字
            show: 0,
            fontSize: 0
            // formatter: function (value) {
            //   if (value === 0) {
            //     return '';
            //   }
            //   return value + '';
            // }
          },

          splitLine: {
            // 分隔线
            show: false
          },
          axisTick: {
            // 坐标轴小标记
            show: false
          },
          pointer: {
            // 分隔线
            shadowColor: '#fff',
            //默认透明
            shadowBlur: 10,
            length: '50%',
            width: 3,
            color: '#ffffff',
            itemStyle: {
              color: '#d73d32'
            }
          },
          detail: {
            show: false
          },
          title: {
            offsetCenter: [0, '30%']
          },
          data: [{
            value: 0
          }]
        }, {
          name: 'minute',
          type: 'gauge',
          startAngle: 90,
          endAngle: -270,
          min: 0,
          max: 60,
          clockwise: true,
          axisLine: {
            // 坐标轴线
            lineStyle: {
              // 属性lineStyle控制线条样式
              color: [[1, '#f4b400']],
              width: 0
            }
          },
          axisLabel: {
            show: false
          },
          axisTick: {
            // 坐标轴小标记
            show: false
          },
          splitLine: {
            // 分隔线
            show: false
          },
          pointer: {
            // 分隔线
            shadowColor: '#fff',
            //默认透明
            shadowBlur: 10,
            length: '60%',
            width: 2,
            itemStyle: {
              color: '#f4b400'
            }
          },
          detail: {
            show: false
          },
          title: {
            offsetCenter: ['0%', '-40%']
          },
          data: [{
            value: 0
          }]
        }, {
          name: 'second',
          type: 'gauge',
          startAngle: 90,
          endAngle: -270,
          min: 0,
          max: 60,
          animationEasingUpdate: 'bounceOut',
          clockwise: true,
          axisLine: {
            // 坐标轴线
            lineStyle: {
              // 属性lineStyle控制线条样式
              color: [[1, '#1e90ff']],
              width: 3,
              shadowColor: '#fff',
              //默认透明
              shadowBlur: 10
            }
          },
          axisLabel: {
            show: 0,
            // 属性lineStyle控制线条样式
            fontWeight: 'bolder',
            color: '#fff',
            shadowColor: '#fff',
            //默认透明
            shadowBlur: 10
          },
          axisTick: {
            // 坐标轴小标记
            length: 1,
            // 属性length控制线长
            lineStyle: {
              // 属性lineStyle控制线条样式
              color: 'auto',
              shadowColor: '#fff',
              //默认透明
              shadowBlur: 5
            }
          },
          splitLine: {
            // 分隔线
            length: 4,
            // 属性length控制线长
            lineStyle: {
              // 属性lineStyle（详见lineStyle）控制线条样式
              width: 3,
              color: '#fff',
              shadowColor: '#fff',
              //默认透明
              shadowBlur: 10
            }
          },
          pointer: {
            // 分隔线
            shadowColor: '#fff',
            //默认透明
            shadowBlur: 10,
            length: '80%',
            width: 1,
            itemStyle: {
              color: '#1e90ff'
            }
          },
          detail: {
            show: false
          },
          title: {
            axisLabel: {
              // 其余属性默认使用全局文本样式，详见TEXTSTYLE
              fontWeight: 'bolder',
              fontSize: 20,
              fontStyle: 'italic',
              color: '#000000',
              shadowColor: '#bebebe',
              //默认透明
              shadowBlur: 10
            }
          },
          data: [{
            value: 0
          }]
        }]
      };
      setInterval(function () {
        var date = new Date();
        var second = date.getSeconds();
        var minute = date.getMinutes() + second / 60;
        var hour = date.getHours() % 12 + minute / 60;
        option.animationDurationUpdate = 300;
        clockEChart.setOption({
          series: [{
            name: 'hour',
            animation: hour !== 0,
            data: [{
              value: hour
            }]
          }, {
            name: 'minute',
            animation: minute !== 0,
            data: [{
              value: minute
            }]
          }, {
            animation: second !== 0,
            name: 'second',
            data: [{
              value: second
            }]
          }]
        });
      }, 1000);
      clockEChart.setOption(option);
      //随着屏幕大小调节图表
      window.addEventListener("resize", function () {
        clockEChart.resize();
      });
    } catch (e) {}
  }
};
exports.default = _default2;