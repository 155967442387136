"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.adBanner = void 0;
var adBanner = {
  data: function data() {
    return {
      //是否展示详情窗口
      showDetailDialog: false,
      backUrl: '/advertise/adBanner/page',
      entity: {},
      rules: {
        image: [{
          required: true,
          message: '请上传闪屏广告位'
        }],
        link: [{
          required: true,
          message: '请输入跳转地址'
        }, {
          max: 256,
          message: '跳转地址长度在256个字符内'
        }],
        name: [{
          required: true,
          message: '请输入名称'
        }, {
          max: 50,
          message: '名称长度在50个字符内'
        }],
        sort: [{
          required: true,
          message: '请输入排序'
        }],
        columnImage: [{
          required: true,
          message: '请上传运动广告位'
        }],
        columnAdImgSecond: [{
          required: true,
          message: '请上传数据上传广告位'
        }]
      }
    };
  }
};
exports.adBanner = adBanner;