var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "修改年级",
        visible: _vm.dialogVisible,
        "close-on-click-modal": false,
        center: "",
        width: "40%",
        "append-to-body": "",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
        close: _vm.closeDialog,
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "elForm",
          staticClass: "dialog-form",
          attrs: {
            model: _vm.entity,
            rules: _vm.rules,
            inline: true,
            "label-width": _vm.getLabelWidth(),
            "append-to-body": "",
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "班级", prop: "name" } },
            [
              _c(
                "el-select",
                {
                  attrs: {
                    multiple: "",
                    "allow-create": "",
                    filterable: "",
                    "default-first-option": "",
                    placeholder: "请输入班级，按回车可添加",
                  },
                  model: {
                    value: _vm.entity.classList,
                    callback: function ($$v) {
                      _vm.$set(_vm.entity, "classList", $$v)
                    },
                    expression: "entity.classList",
                  },
                },
                _vm._l(_vm.classOptions, function (item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: { label: item.name, value: item.id },
                  })
                }),
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "form-button-container" },
        [
          _c(
            "el-button",
            {
              attrs: { type: "danger", icon: "el-icon-back" },
              on: {
                click: function ($event) {
                  _vm.dialogVisible = false
                },
              },
            },
            [_vm._v("返回")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              staticClass: "form-button-submit",
              attrs: { type: "primary", icon: "el-icon-check" },
              on: { click: _vm.submitForm },
            },
            [_vm._v("提交")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }