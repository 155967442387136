var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "uploadWrapper" },
    [
      _c(
        "vuedraggable",
        {
          staticClass: "vue-draggable",
          class: { single: _vm.isSingle, maxHidden: _vm.isMaxHidden },
          attrs: { tag: "ul", draggable: ".draggable-item" },
          on: { start: _vm.onDragStart, end: _vm.onDragEnd },
          model: {
            value: _vm.imgList,
            callback: function ($$v) {
              _vm.imgList = $$v
            },
            expression: "imgList",
          },
        },
        [
          _vm._l(_vm.imgList, function (item, index) {
            return _c(
              "li",
              {
                key: item + index,
                staticClass: "draggable-item",
                style: { width: _vm.width + "px", height: _vm.height + "px" },
              },
              [
                _c("el-image", {
                  attrs: {
                    src: _vm.getFullImg(item),
                    "preview-src-list": [_vm.getFullImg(item)],
                  },
                }),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: !_vm.isDisabled,
                        expression: "!isDisabled",
                      },
                    ],
                    staticClass: "shadow",
                    on: {
                      click: function ($event) {
                        return _vm.onRemoveHandler(index)
                      },
                    },
                  },
                  [_c("i", { staticClass: "el-icon-delete" })]
                ),
              ],
              1
            )
          }),
          _vm._v(" "),
          _c(
            "el-upload",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.isDisabled,
                  expression: "!isDisabled",
                },
              ],
              ref: "uploadRef",
              staticClass: "uploadBox",
              style: { width: _vm.width + "px", height: _vm.height + "px" },
              attrs: {
                slot: "footer",
                action: this.uploadImage,
                headers: _vm.headers,
                accept: ".jpg,.jpeg,.png,.gif,.webp,.jfif",
                "show-file-list": false,
                multiple: !_vm.isSingle,
                limit: _vm.limit,
                "before-upload": _vm.beforeUpload,
                "on-success": _vm.onSuccessUpload,
                "on-exceed": _vm.onExceed,
              },
              slot: "footer",
            },
            [
              _c("i", { staticClass: "el-icon-plus uploadIcon" }, [
                _c(
                  "span",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.isUploading,
                        expression: "isUploading",
                      },
                    ],
                    staticClass: "uploading",
                  },
                  [_vm._v("正在上传...")]
                ),
                _vm._v(" "),
                !_vm.isUploading &&
                _vm.limit &&
                _vm.limit !== 99 &&
                !_vm.isSingle
                  ? _c("span", { staticClass: "limitTxt" }, [
                      _vm._v("最多" + _vm._s(_vm.limit) + "张"),
                    ])
                  : _vm._e(),
              ]),
            ]
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }