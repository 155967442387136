import { render, staticRenderFns } from "./TeacherRaceAdd.vue?vue&type=template&id=fb5847de&"
import script from "./TeacherRaceAdd.vue?vue&type=script&lang=js&"
export * from "./TeacherRaceAdd.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\shenbimaliang1\\sbml-backend\\backend-page\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('fb5847de')) {
      api.createRecord('fb5847de', component.options)
    } else {
      api.reload('fb5847de', component.options)
    }
    module.hot.accept("./TeacherRaceAdd.vue?vue&type=template&id=fb5847de&", function () {
      api.rerender('fb5847de', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/teacher/teacherRace/TeacherRaceAdd.vue"
export default component.exports