"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.rating = void 0;
var rating = {
  data: function data() {
    return {
      //是否展示详情窗口
      showDetailDialog: false,
      //是否展示禁用评论窗口
      showDisabledDialog: false,
      backUrl: '/afterSales/rating/page',
      entity: {},
      //评价类型
      ratingTypeMap: {
        1: '好评',
        2: '中评',
        3: '差评',
        4: '有图'
      },
      //是否禁用枚举
      isDisabledMap: {
        0: '否',
        1: '是'
      },
      //是否禁用标签颜色枚举
      isDisabledColorMap: {
        0: 'success',
        1: 'danger'
      },
      //是否匿名枚举
      isAnonymousMap: {
        0: '否',
        1: '是'
      },
      //是否匿名标签颜色枚举
      isAnonymousColorMap: {
        0: 'success',
        1: 'danger'
      },
      rules: {
        sku: [{
          required: true,
          message: '请输入SKU'
        }, {
          max: 255,
          message: 'SKU长度在255个字符内'
        }],
        uid: [{
          required: true,
          message: '请输入用户ID'
        }],
        content: [{
          required: true,
          message: '请输入内容'
        }, {
          max: 1024,
          message: '内容长度在1024个字符内'
        }],
        images: [{
          required: true,
          message: '请输入图片'
        }, {
          max: 1024,
          message: '图片长度在1024个字符内'
        }],
        productId: [{
          required: true,
          message: '请输入商品ID'
        }],
        rating: [{
          required: true,
          message: '请输入评价星级'
        }],
        isAnonymous: [{
          required: true,
          message: '请输入是否匿名'
        }],
        userLevel: [{
          required: true,
          message: '请输入用户等级'
        }],
        userHeadImg: [{
          required: true,
          message: '请输入用户头像'
        }, {
          max: 255,
          message: '用户头像长度在255个字符内'
        }],
        userNickName: [{
          required: true,
          message: '请输入用户昵称'
        }, {
          max: 255,
          message: '用户昵称长度在255个字符内'
        }],
        reply: [{
          required: true,
          message: '请输入回复内容',
          trigger: 'blur'
        }, {
          max: 1024,
          message: '回复内容长度在1024个字符内'
        }],
        isDisabled: [{
          required: true,
          message: '请选择是否禁用',
          trigger: 'blur'
        }]
      }
    };
  }
};
exports.rating = rating;