"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.function.name");
var _permission = require("@/mixins/permission");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  mixins: [_permission.permission],
  name: "",
  data: function data() {
    return {
      uploadFileName: "",
      isLoading: false
    };
  },
  methods: {
    submitUpload: function submitUpload() {
      this.$refs.upload.submit();
      this.$emit("refreshTab");
      if (!this.uploadFileName) {
        return this.$message.warning("请先上传表格文件");
      } else {
        this.isLoading = true;
      }
    },
    beforeAvatarUpload: function beforeAvatarUpload(file) {
      this.uploadFileName = file.name;
    },
    handleAvatarSuccess: function handleAvatarSuccess(res) {
      this.isLoading = false;
      this.$message.error(res.message);
      this.uploadFileName = "";
      this.$refs.upload.clearFiles();
    },
    handleAvatarError: function handleAvatarError(res) {
      console.log(res);
    }
  },
  mounted: function mounted() {}
};
exports.default = _default;