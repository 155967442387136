var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _vm.showUseInstruction
        ? _c("el-card", [
            _c("div", { attrs: { slot: "header" }, slot: "header" }, [
              _c(
                "div",
                {
                  staticStyle: { "text-align": "center", "font-size": "14px" },
                },
                [_c("span", [_vm._v("使用说明")])]
              ),
            ]),
            _vm._v(" "),
            _c("div", [
              _c("span", [
                _vm._v(
                  "\n        项目的包:生成的java代码文件要放的地方和java文件的包名    表前缀:生成代码文件命名的时候要忽略的表前缀    模块名:生成的文件要放在哪一个模块下面\n      "
                ),
              ]),
              _vm._v(" "),
              _c("span", [
                _vm._v(
                  "\n            页面文件保存:生产的.vue等页面文件要保存的位置    父级菜单:生成的菜单sql文件中的上级菜单    页面类型:6个字段以内是弹出模态框，以外是跳转新页面\n      "
                ),
              ]),
              _vm._v(" "),
              _c("span", [
                _vm._v("    是否覆盖:这次生成的文件是否覆盖上一次生成的文件"),
              ]),
            ]),
            _vm._v(" "),
            _c("div", [
              _c("span", [
                _vm._v(
                  "\n        table:是否在列表页的el-table中展示    页面操作:是否在新增或者修改页面里面出现    查询类型:serviceImpl中queryWrapper的查询方式\n      "
                ),
              ]),
              _vm._v(" "),
              _c("span", [
                _vm._v(
                  "    搜索表单:是否在列表页面搜索    输入框类型:这个字段在页面中展示的类型"
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("div", [
              _c("span", [
                _vm._v(
                  '\n        注意事项: 表命名最好是加上前缀，比如bus_,每个字段都加上注释，表也需要加上注释，如果字段是枚举类型值，字段请用char 2个字节，列的注释需满足的格式为\n        列说明{空格}列的key-列的value{空格}，比如"性别 1-男 2-女 3-人妖"，可以有多个空格\n      '
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("div", [
              _c("span", [
                _vm._v(
                  "操作步骤: 1修改/api/codeGenerator/getGeneratorEntity接口返回的配置数据     "
                ),
              ]),
              _vm._v(" "),
              _c("span", [
                _vm._v(
                  "2生成文件后把菜单的SQL语句拿去执行，把生成的页面文件拷贝到前端项目相应的目录，仔细看下other.txt这个文件的内容     "
                ),
              ]),
              _vm._v(" "),
              _c("span", [
                _vm._v(
                  "3给超级管理员赋予新增菜单的权限，完了重启后端项目，前端项目修改路由文件和api接口文件，然后就可以正常访问了    "
                ),
              ]),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "el-card",
        {
          staticClass: "operate-page-card",
          staticStyle: { "margin-top": "10px" },
        },
        [
          _c("div", { attrs: { slot: "header" }, slot: "header" }, [
            _c(
              "div",
              { staticStyle: { "text-align": "center", "font-size": "14px" } },
              [_c("span", [_vm._v("基本信息")])]
            ),
          ]),
          _vm._v(" "),
          _c(
            "el-form",
            {
              ref: "elForm",
              attrs: {
                model: _vm.entity,
                rules: _vm.rules,
                inline: true,
                "label-width": "130px",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "数据库账号", prop: "userName" } },
                [
                  _c("el-input", {
                    staticClass: "form-input",
                    attrs: { clearable: "" },
                    model: {
                      value: _vm.entity.userName,
                      callback: function ($$v) {
                        _vm.$set(_vm.entity, "userName", $$v)
                      },
                      expression: "entity.userName",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "数据库名称", prop: "dbName" } },
                [
                  _c("el-input", {
                    staticClass: "form-input",
                    attrs: { clearable: "" },
                    model: {
                      value: _vm.entity.dbName,
                      callback: function ($$v) {
                        _vm.$set(_vm.entity, "dbName", $$v)
                      },
                      expression: "entity.dbName",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "数据库密码", prop: "password" } },
                [
                  _c("el-input", {
                    staticClass: "form-input",
                    attrs: { clearable: "" },
                    model: {
                      value: _vm.entity.password,
                      callback: function ($$v) {
                        _vm.$set(_vm.entity, "password", $$v)
                      },
                      expression: "entity.password",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "数据库url", prop: "url" } },
                [
                  _c("el-input", {
                    staticClass: "form-input",
                    attrs: { clearable: "" },
                    model: {
                      value: _vm.entity.url,
                      callback: function ($$v) {
                        _vm.$set(_vm.entity, "url", $$v)
                      },
                      expression: "entity.url",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "作者", prop: "author" } },
                [
                  _c("el-input", {
                    staticClass: "form-input",
                    attrs: { clearable: "" },
                    model: {
                      value: _vm.entity.author,
                      callback: function ($$v) {
                        _vm.$set(_vm.entity, "author", $$v)
                      },
                      expression: "entity.author",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "项目的包", prop: "projectPackage" } },
                [
                  _c("el-input", {
                    staticClass: "form-input",
                    attrs: { disabled: "" },
                    model: {
                      value: _vm.entity.projectPackage,
                      callback: function ($$v) {
                        _vm.$set(_vm.entity, "projectPackage", $$v)
                      },
                      expression: "entity.projectPackage",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "项目地址", prop: "projectPath" } },
                [
                  _c("el-input", {
                    staticClass: "form-input",
                    attrs: { clearable: "" },
                    model: {
                      value: _vm.entity.projectPath,
                      callback: function ($$v) {
                        _vm.$set(_vm.entity, "projectPath", $$v)
                      },
                      expression: "entity.projectPath",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "表前缀", prop: "ignoreTablePrefix" } },
                [
                  _c("el-input", {
                    staticClass: "form-input",
                    attrs: {
                      placeholder: "需要忽略的表前缀,如bus_user表的前缀bus_",
                      clearable: "",
                    },
                    model: {
                      value: _vm.entity.ignoreTablePrefix,
                      callback: function ($$v) {
                        _vm.$set(_vm.entity, "ignoreTablePrefix", $$v)
                      },
                      expression: "entity.ignoreTablePrefix",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "服务名", prop: "serviceName" } },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "form-input",
                      attrs: { placeholder: "请选择服务名" },
                      model: {
                        value: _vm.entity.serviceName,
                        callback: function ($$v) {
                          _vm.$set(_vm.entity, "serviceName", $$v)
                        },
                        expression: "entity.serviceName",
                      },
                    },
                    _vm._l(_vm.entity.serviceMap, function (key, value) {
                      return _c("el-option", {
                        key: value,
                        attrs: { label: value, value: value },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "请求前缀路径", prop: "urlName" } },
                [
                  _c("el-input", {
                    staticClass: "form-input",
                    attrs: { disabled: "" },
                    model: {
                      value: _vm.entity.urlName,
                      callback: function ($$v) {
                        _vm.$set(_vm.entity, "urlName", $$v)
                      },
                      expression: "entity.urlName",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "模块名", prop: "moduleName" } },
                [
                  _c("el-input", {
                    staticClass: "form-input",
                    attrs: { clearable: "" },
                    model: {
                      value: _vm.entity.moduleName,
                      callback: function ($$v) {
                        _vm.$set(_vm.entity, "moduleName", $$v)
                      },
                      expression: "entity.moduleName",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "页面文件保存", prop: "pageFileSavaPath" } },
                [
                  _c("el-input", {
                    staticClass: "form-input",
                    attrs: { clearable: "" },
                    model: {
                      value: _vm.entity.pageFileSavaPath,
                      callback: function ($$v) {
                        _vm.$set(_vm.entity, "pageFileSavaPath", $$v)
                      },
                      expression: "entity.pageFileSavaPath",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "父级菜单" } },
                [
                  _c("tree-select", {
                    ref: "treeSelect",
                    staticClass: "form-input",
                    on: {
                      setSelectedValue: function ($event) {
                        return _vm.setSelectedValue($event)
                      },
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "页面类型", prop: "pageType" } },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "form-input",
                      model: {
                        value: _vm.entity.pageType,
                        callback: function ($$v) {
                          _vm.$set(_vm.entity, "pageType", $$v)
                        },
                        expression: "entity.pageType",
                      },
                    },
                    [
                      _c("el-option", {
                        attrs: { label: "系统匹配", value: "1" },
                      }),
                      _vm._v(" "),
                      _c("el-option", {
                        attrs: { label: "弹出模态框(推荐)", value: "2" },
                      }),
                      _vm._v(" "),
                      _c("el-option", {
                        attrs: { label: "跳转新页面", value: "3" },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "是否覆盖", prop: "whetherToCover" } },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "form-input",
                      model: {
                        value: _vm.entity.whetherToCover,
                        callback: function ($$v) {
                          _vm.$set(_vm.entity, "whetherToCover", $$v)
                        },
                        expression: "entity.whetherToCover",
                      },
                    },
                    [
                      _c("el-option", { attrs: { label: "是", value: true } }),
                      _vm._v(" "),
                      _c("el-option", { attrs: { label: "否", value: false } }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "数据库表", prop: "tableName" } },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "form-input",
                      attrs: { filterable: "", placeholder: "搜索一下试试" },
                      on: { change: _vm.databaseTableRowSelected },
                      model: {
                        value: _vm.entity.tableName,
                        callback: function ($$v) {
                          _vm.$set(_vm.entity, "tableName", $$v)
                        },
                        expression: "entity.tableName",
                      },
                    },
                    _vm._l(
                      _vm.databaseTableItems,
                      function (databaseTable, index) {
                        return _c("el-option", {
                          key: index,
                          attrs: {
                            label:
                              databaseTable.tableName +
                              "--" +
                              databaseTable.tableComment,
                            value: databaseTable.tableName,
                          },
                        })
                      }
                    ),
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: " " } },
                    [
                      _c(
                        "el-button",
                        {
                          staticClass: "form-input",
                          attrs: { type: "primary", icon: "el-icon-s-home" },
                          on: { click: _vm.submit },
                        },
                        [_vm._v("自动生成")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-card",
        { staticStyle: { "margin-top": "10px" } },
        [
          _c("div", { attrs: { slot: "header" }, slot: "header" }, [
            _c(
              "div",
              { staticStyle: { "text-align": "center", "font-size": "14px" } },
              [_c("span", [_vm._v("代码模板")])]
            ),
          ]),
          _vm._v(" "),
          _c(
            "el-checkbox",
            {
              attrs: { indeterminate: _vm.isIndeterminate },
              on: { change: _vm.handleCheckAllChange },
              model: {
                value: _vm.checkAll,
                callback: function ($$v) {
                  _vm.checkAll = $$v
                },
                expression: "checkAll",
              },
            },
            [_vm._v("全选")]
          ),
          _vm._v(" "),
          _c("div", { staticStyle: { margin: "15px 0" } }),
          _vm._v(" "),
          _c(
            "el-checkbox-group",
            {
              on: { change: _vm.handleCheckedCodeTemplateChange },
              model: {
                value: _vm.entity.codeTemplateSelected,
                callback: function ($$v) {
                  _vm.$set(_vm.entity, "codeTemplateSelected", $$v)
                },
                expression: "entity.codeTemplateSelected",
              },
            },
            _vm._l(_vm.codeTemplateArray, function (codeTemplate) {
              return _c(
                "el-checkbox",
                { key: codeTemplate, attrs: { label: codeTemplate } },
                [_vm._v(_vm._s(codeTemplate) + "\n      ")]
              )
            }),
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-card",
        { staticStyle: { "margin-top": "10px" } },
        [
          _c("div", { attrs: { slot: "header" }, slot: "header" }, [
            _c(
              "div",
              { staticStyle: { "text-align": "center", "font-size": "14px" } },
              [_c("span", [_vm._v("数据库表字段")])]
            ),
          ]),
          _vm._v(" "),
          _c(
            "el-table",
            {
              ref: "elTable",
              attrs: {
                data: _vm.entity.tableInfoList,
                "element-loading-text": "正在查询中...",
                border: "",
                fit: "",
                "highlight-current-row": "",
              },
            },
            [
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "字段注释",
                  "min-width": "200",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          "\n          " +
                            _vm._s(
                              scope.row.columnComment + scope.row.columnKey
                            ) +
                            "\n        "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { align: "center", label: "字段名", prop: "columnName" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { align: "center", label: "数据类型", prop: "dataType" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { align: "center", label: "列表&搜索" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("el-switch", {
                          model: {
                            value: scope.row.listField,
                            callback: function ($$v) {
                              _vm.$set(scope.row, "listField", $$v)
                            },
                            expression: "scope.row.listField",
                          },
                        }),
                        _vm._v(" "),
                        _c("el-switch", {
                          model: {
                            value: scope.row.pageQuery,
                            callback: function ($$v) {
                              _vm.$set(scope.row, "pageQuery", $$v)
                            },
                            expression: "scope.row.pageQuery",
                          },
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { align: "center", label: "页面操作" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("el-switch", {
                          model: {
                            value: scope.row.pageField,
                            callback: function ($$v) {
                              _vm.$set(scope.row, "pageField", $$v)
                            },
                            expression: "scope.row.pageField",
                          },
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { align: "center", label: "排序&统计" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("el-switch", {
                          model: {
                            value: scope.row.isSort,
                            callback: function ($$v) {
                              _vm.$set(scope.row, "isSort", $$v)
                            },
                            expression: "scope.row.isSort",
                          },
                        }),
                        _vm._v(" "),
                        _c("el-switch", {
                          model: {
                            value: scope.row.isSummaryColumn,
                            callback: function ($$v) {
                              _vm.$set(scope.row, "isSummaryColumn", $$v)
                            },
                            expression: "scope.row.isSummaryColumn",
                          },
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { align: "center", label: "查询类型" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-select",
                          {
                            model: {
                              value: scope.row.queryType,
                              callback: function ($$v) {
                                _vm.$set(scope.row, "queryType", $$v)
                              },
                              expression: "scope.row.queryType",
                            },
                          },
                          [
                            _c("el-option", {
                              attrs: { label: "无", value: "0" },
                            }),
                            _vm._v(" "),
                            _c("el-option", {
                              attrs: { label: "eq", value: "1" },
                            }),
                            _vm._v(" "),
                            _c("el-option", {
                              attrs: { label: "like", value: "2" },
                            }),
                            _vm._v(" "),
                            _c("el-option", {
                              attrs: { label: "时间范围", value: "3" },
                            }),
                            _vm._v(" "),
                            _c("el-option", {
                              attrs: { label: "in", value: "4" },
                            }),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { align: "center", label: "输入框类型" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-select",
                          {
                            model: {
                              value: scope.row.inputType,
                              callback: function ($$v) {
                                _vm.$set(scope.row, "inputType", $$v)
                              },
                              expression: "scope.row.inputType",
                            },
                          },
                          [
                            _c("el-option", {
                              attrs: { label: "输入框", value: "1" },
                            }),
                            _vm._v(" "),
                            _c("el-option", {
                              attrs: { label: "下拉选择框", value: "2" },
                            }),
                            _vm._v(" "),
                            _c("el-option", {
                              attrs: { label: "日期选择框", value: "3" },
                            }),
                            _vm._v(" "),
                            _c("el-option", {
                              attrs: { label: "日期时间选择框", value: "4" },
                            }),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "最大长度",
                  prop: "maxLength",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "校验类型",
                  prop: "validateType",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-select",
                          {
                            model: {
                              value: scope.row.validateType,
                              callback: function ($$v) {
                                _vm.$set(scope.row, "validateType", $$v)
                              },
                              expression: "scope.row.validateType",
                            },
                          },
                          [
                            _c("el-option", {
                              attrs: { label: "无校验", value: "noValidate" },
                            }),
                            _vm._v(" "),
                            _c("el-option", {
                              attrs: { label: "NotNull", value: "NotNull" },
                            }),
                            _vm._v(" "),
                            _c("el-option", {
                              attrs: { label: "NotBlank", value: "NotBlank" },
                            }),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }