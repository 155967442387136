var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "新增版本控制",
        visible: _vm.dialogVisible,
        "close-on-click-modal": false,
        center: "",
        width: "40%",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
        close: _vm.closeDialog,
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "elForm",
          staticClass: "dialog-form",
          attrs: {
            model: _vm.entity,
            rules: _vm.rules,
            inline: true,
            "label-width": _vm.getLabelWidth(),
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "平台", prop: "platform" } },
            [
              _c(
                "el-select",
                {
                  model: {
                    value: _vm.entity.platform,
                    callback: function ($$v) {
                      _vm.$set(_vm.entity, "platform", $$v)
                    },
                    expression: "entity.platform",
                  },
                },
                _vm._l(_vm.platformMap, function (value, key) {
                  return _c("el-option", {
                    key: key,
                    attrs: { label: value, value: key },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _vm.entity.platform === "android"
            ? _c(
                "el-form-item",
                { attrs: { label: "文件" } },
                [_c("drag-upload")],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "版本", prop: "v" } },
            [
              _c("el-input", {
                attrs: { clearable: "" },
                model: {
                  value: _vm.entity.v,
                  callback: function ($$v) {
                    _vm.$set(_vm.entity, "v", $$v)
                  },
                  expression: "entity.v",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "是否需要强制更新", prop: "editionForce" } },
            [
              _c(
                "el-select",
                {
                  model: {
                    value: _vm.entity.editionForce,
                    callback: function ($$v) {
                      _vm.$set(_vm.entity, "editionForce", $$v)
                    },
                    expression: "entity.editionForce",
                  },
                },
                _vm._l(_vm.editionForceMap, function (value, key) {
                  return _c("el-option", {
                    key: key,
                    attrs: { label: value, value: Number(key) },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "更新内容", prop: "content" } },
            [
              _c("rich-text", {
                model: {
                  value: _vm.entity.content,
                  callback: function ($$v) {
                    _vm.$set(_vm.entity, "content", $$v)
                  },
                  expression: "entity.content",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "form-button-container" },
        [
          _c(
            "el-button",
            {
              attrs: { type: "danger", icon: "el-icon-back" },
              on: {
                click: function ($event) {
                  _vm.dialogVisible = false
                },
              },
            },
            [_vm._v("返回")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              directives: [
                { name: "preventReClick", rawName: "v-preventReClick" },
              ],
              staticClass: "form-button-submit",
              attrs: { type: "primary", icon: "el-icon-check" },
              on: { click: _vm.submitForm },
            },
            [_vm._v("\n      提交\n    ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }