"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
var _default2 = {
  name: "ChinaMap",
  props: {
    mapData: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    mId: {
      type: String,
      default: function _default() {
        return this.guid();
      }
    }
  },
  watch: {
    mapData: function mapData() {
      this.mapFn();
    }
  },
  mounted: function mounted() {
    this.mapFn();
  },
  methods: {
    mapFn: function mapFn() {
      try {
        // 基于准备好的dom，初始化echarts实例
        var mapChart = this.$echarts.init(document.getElementById("mapChart" + this.mId));
        mapChart.setOption({
          backgroundColor: "transparent",
          //背景颜色
          //标题
          title: {
            text: "全国学生数据分布",
            subtext: "绑定绳子的学生",
            color: "#fff",
            // x: "center",
            left: 'right'
          },
          //是视觉映射组件，用于进行『视觉编码』，也就是将数据映射到视觉元素（视觉通道）。
          visualMap: {
            // 左下角定义 在选中范围中的视觉元素 渐变地区颜色
            type: "piecewise",
            // 类型为分段型
            left: '2px',
            top: '20px',
            calculable: true,
            //是否显示拖拽用的手柄（手柄能拖拽调整选中范围）。
            splitNumber: 6,
            seriesIndex: [0],
            itemWidth: 10,
            // 每个图元的宽度
            itemGap: 2,
            // 每两个图元之间的间隔距离，单位为px
            pieces: [
            // 自定义每一段的范围，以及每一段的文字
            {
              gte: 10000,
              label: "1万以上",
              color: "#f12424"
            },
            // 不指定 max，表示 max 为无限大（Infinity）。
            {
              gte: 1000,
              lte: 9999,
              label: "1千-9999位",
              color: "#f88722"
            }, {
              gte: 500,
              lte: 999,
              label: "500-999位",
              color: "#f8d513"
            }, {
              gte: 100,
              lte: 499,
              label: "100-499位",
              color: "#366dec"
            }, {
              gte: 1,
              lte: 99,
              label: "1-99位",
              color: "#19d3bd"
            }, {
              lte: 0,
              label: "无",
              color: "#e5e5ef"
            } // 不指定 min，表示 min 为无限大（-Infinity）。
            ],

            axisLabel: {
              color: "#737373"
            }
            // left: 'right',
            // min: 0,
            // max: 10000000,
            // inRange: {
            //   color: ['#313695', '#4575b4', '#74add1', '#abd9e9', '#e0f3f8', '#ffffbf', '#fee090', '#fdae61', '#f46d43', '#d73027', '#a50026']
            // },
            // text: ['High', 'Low'],           // 文本，默认为数值文本
            // calculable: true
          },

          // 提示框，鼠标移入
          tooltip: {
            show: true,
            //鼠标移入是否触发数据
            trigger: "item",
            //出发方式
            formatter: "{b}-学生数量：{c}"
          },
          //配置地图的数据，并且显示
          series: [{
            roam: true,
            //是否允许缩放
            name: "地图",
            type: "map",
            //地图种类
            map: "china",
            //地图类型。
            data: this.mapData,
            itemStyle: {
              // 默认地图区域样式
              normal: {
                // 文字
                label: {
                  show: true,
                  //默认是否显示省份名称
                  textStyle: {
                    //手机端修改文字大小
                    fontSize: this.isPc() ? '' : '12px'
                  }
                },
                areaColor: '#ddd',
                borderColor: "#4ECEE6",
                borderWidth: 1,
                shadowColor: "#2C99F6",
                shadowOffsetY: 0,
                shadowBlur: 15
              },
              // 鼠标悬浮地图区域样式
              emphasis: {
                label: {
                  show: true //选中状态是否显示省份名称
                },

                areaColor: "#1a86cc"
              }
            },
            zoom: 1,
            //放大比例
            label: {
              //图形上的文本标签，可用于说明图形的一些数据信息
              show: true
            }
          }]
        });
        window.addEventListener("resize", function () {
          // 自动渲染echarts
          mapChart.resize();
        });
      } catch (e) {}
    }
  }
};
exports.default = _default2;