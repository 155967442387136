var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.isSellerServer()
    ? _c(
        "div",
        { staticClass: "platform-login" },
        [
          _c(
            "el-form",
            {
              ref: "loginForm",
              staticClass: "platform-login-form",
              attrs: {
                model: _vm.user,
                autocomplete: "on",
                "label-position": "left",
              },
            },
            [
              _c("div", { staticClass: "platform-title" }, [
                _c("span", [_vm._v("神比孖良管理服务平台")]),
              ]),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { prop: "account" } },
                [
                  _c("el-input", {
                    ref: "account",
                    attrs: {
                      autocomplete: "on",
                      "prefix-icon": "el-icon-user",
                      placeholder: "手机号",
                    },
                    model: {
                      value: _vm.user.account,
                      callback: function ($$v) {
                        _vm.$set(_vm.user, "account", $$v)
                      },
                      expression: "user.account",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { prop: "password" } },
                [
                  _c("el-input", {
                    ref: "password",
                    attrs: {
                      "prefix-icon": "el-icon-lock",
                      autocomplete: "on",
                      "show-password": "",
                      placeholder: "请输入登录密码",
                    },
                    model: {
                      value: _vm.user.password,
                      callback: function ($$v) {
                        _vm.$set(_vm.user, "password", $$v)
                      },
                      expression: "user.password",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticStyle: { width: "60%", float: "left" } },
                [
                  _c("el-input", {
                    attrs: {
                      "prefix-icon": "el-icon-tickets",
                      placeholder: "4位图形验证码",
                      clearable: "",
                      name: "kaptchaCode",
                      type: "text",
                      tabindex: "1",
                      autocomplete: "on",
                    },
                    nativeOn: {
                      keyup: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        ) {
                          return null
                        }
                        return _vm.handleLogin($event)
                      },
                    },
                    model: {
                      value: _vm.user.kaptchaCode,
                      callback: function ($$v) {
                        _vm.$set(_vm.user, "kaptchaCode", $$v)
                      },
                      expression: "user.kaptchaCode",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticStyle: { width: "35%", float: "right" } },
                [
                  _c(
                    "a",
                    {
                      on: {
                        click: function ($event) {
                          return _vm.changeCode()
                        },
                      },
                    },
                    [
                      _c("img", {
                        staticStyle: {
                          height: "60px",
                          margin: "auto 0",
                          "max-width": "100%",
                        },
                        attrs: { src: _vm.imgUrl, alt: "#" },
                      }),
                    ]
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  staticClass: "platform-login-btn",
                  attrs: { loading: _vm.loading },
                  nativeOn: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.handleLogin($event)
                    },
                  },
                },
                [_vm._v("登录\n    ")]
              ),
            ],
            1
          ),
        ],
        1
      )
    : _c(
        "div",
        { staticClass: "seller-login" },
        [
          _c(
            "el-row",
            { attrs: { gutter: 24 } },
            [
              _c("el-col", { attrs: { md: 13, xl: 11, lg: 12 } }, [
                _c("div", { staticClass: "seller-left hidden-sm-and-down" }),
              ]),
              _vm._v(" "),
              _c("el-col", { attrs: { sm: 24, md: 11, xl: 13, lg: 12 } }, [
                _c("div", { staticClass: "seller-right" }, [
                  _c(
                    "div",
                    { staticClass: "seller-login-head" },
                    [
                      _c(
                        "el-link",
                        {
                          staticClass: "head-a",
                          attrs: {
                            href: "http://web.sbml.51uhj.com/sy",
                            underline: "",
                          },
                        },
                        [_vm._v("关于神比孖良")]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "seller-login-content" }, [
                    _c("div", { staticClass: "seller-login-title" }, [
                      _c(
                        "span",
                        { staticClass: "seller-login-title-welcome" },
                        [_vm._v("欢迎登录")]
                      ),
                      _c("span", { staticClass: "seller-login-title-intro" }, [
                        _vm._v("神比孖良商户系统"),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "seller-login-body" },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "seller-tab seller-login-blocks",
                            class:
                              _vm.loginActive === 0 ? "seller-tab-active" : "",
                          },
                          [_vm._v("密码登录")]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-form",
                          {
                            ref: "loginForm",
                            staticClass: "seller-login-form",
                            attrs: { model: _vm.user },
                          },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { prop: "account" } },
                              [
                                _c("el-input", {
                                  ref: "account",
                                  attrs: {
                                    autocomplete: "on",
                                    "prefix-icon": "el-icon-user",
                                    placeholder: "手机号",
                                  },
                                  model: {
                                    value: _vm.user.account,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.user, "account", $$v)
                                    },
                                    expression: "user.account",
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-form-item",
                              { attrs: { prop: "password" } },
                              [
                                _c("el-input", {
                                  ref: "password",
                                  attrs: {
                                    "prefix-icon": "el-icon-lock",
                                    autocomplete: "on",
                                    "show-password": "",
                                    placeholder: "请输入登录密码",
                                  },
                                  model: {
                                    value: _vm.user.password,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.user, "password", $$v)
                                    },
                                    expression: "user.password",
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-row",
                              {
                                staticStyle: { "max-height": "70px" },
                                attrs: { gutter: 24 },
                              },
                              [
                                _c(
                                  "el-col",
                                  { attrs: { span: 15 } },
                                  [
                                    _c(
                                      "el-form-item",
                                      { attrs: { prop: "kaptchaCode" } },
                                      [
                                        _c("el-input", {
                                          attrs: {
                                            "prefix-icon": "el-icon-tickets",
                                            placeholder: "4位图形验证码",
                                            clearable: "",
                                            name: "kaptchaCode",
                                            type: "text",
                                            tabindex: "1",
                                            autocomplete: "on",
                                          },
                                          nativeOn: {
                                            keyup: function ($event) {
                                              if (
                                                !$event.type.indexOf("key") &&
                                                _vm._k(
                                                  $event.keyCode,
                                                  "enter",
                                                  13,
                                                  $event.key,
                                                  "Enter"
                                                )
                                              ) {
                                                return null
                                              }
                                              return _vm.handleLogin($event)
                                            },
                                          },
                                          model: {
                                            value: _vm.user.kaptchaCode,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.user,
                                                "kaptchaCode",
                                                $$v
                                              )
                                            },
                                            expression: "user.kaptchaCode",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-col",
                                  { attrs: { span: 9 } },
                                  [
                                    _c("el-form-item", [
                                      _c(
                                        "a",
                                        {
                                          on: {
                                            click: function ($event) {
                                              return _vm.changeCode()
                                            },
                                          },
                                        },
                                        [
                                          _c("img", {
                                            staticStyle: {
                                              height: "60px",
                                              margin: "auto 0",
                                              "max-width": "100%",
                                            },
                                            attrs: {
                                              src: _vm.imgUrl,
                                              alt: "#",
                                            },
                                          }),
                                        ]
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "seller-body-bottom seller-login-blocks",
                              },
                              [
                                _c(
                                  "el-link",
                                  {
                                    on: {
                                      click: function ($event) {
                                        return _vm.forgetPassword()
                                      },
                                    },
                                  },
                                  [_vm._v("忘记密码？")]
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-button",
                              {
                                staticClass: "seller-login-btn",
                                attrs: { loading: _vm.loading },
                                nativeOn: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                    return _vm.handleLogin($event)
                                  },
                                },
                              },
                              [_vm._v("登录\n              ")]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "login-links" },
                              [
                                _c(
                                  "el-link",
                                  {
                                    staticClass:
                                      "links-item seller-login-blocks",
                                    on: { click: _vm.sellerSettleIn },
                                  },
                                  [_vm._v("商家入驻\n                ")]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                ]),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _vm.showSellerSettleInDialog
            ? _c("seller-settle-in", {
                ref: "sellerSettleInDialog",
                attrs: { "dialog-width": _vm.getDialogWidth() },
                on: {
                  close: function ($event) {
                    _vm.showSellerSettleInDialog = false
                  },
                },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.showSmsUpdatePasswordDialog
            ? _c("sms-update-password", {
                ref: "smsUpdatePasswordDialog",
                attrs: { "dialog-width": _vm.getDialogWidth() },
                on: {
                  close: function ($event) {
                    _vm.showSmsUpdatePasswordDialog = false
                  },
                },
              })
            : _vm._e(),
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }