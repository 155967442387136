"use strict";

var _interopRequireDefault = require("D:/shenbimaliang1/sbml-backend/backend-page/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _advertiseApi = require("@/api/advertise-api");
var _globalDialog = require("@/mixins/global-dialog");
var _permission = require("@/mixins/permission");
var _adTime = require("./ad-time");
var _AdTimeAdd = _interopRequireDefault(require("./AdTimeAdd"));
var _AdTimeUpdate = _interopRequireDefault(require("./AdTimeUpdate"));
var _AdTimeDetail = _interopRequireDefault(require("./AdTimeDetail"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'AdTimePage',
  components: {
    Pagination: _Pagination.default,
    AdTimeAdd: _AdTimeAdd.default,
    AdTimeUpdate: _AdTimeUpdate.default,
    AdTimeDetail: _AdTimeDetail.default
  },
  mixins: [_adTime.adTime, _globalDialog.pageDialog, _permission.permission],
  methods: {
    //导出
    exportExcel: function exportExcel() {
      if (this.isNotEmpty(this.pageParam.adTimeRange)) {
        var adTimeRange = this.pageParam.adTimeRange;
        var startTime = this.formatDate(adTimeRange[0], "yyyy-MM-dd HH:mm:ss");
        var endTime = this.formatDate(adTimeRange[1], "yyyy-MM-dd HH:mm:ss");
        this.pageParam.adTimeRange[0] = startTime;
        this.pageParam.adTimeRange[1] = endTime;
      }
      (0, _advertiseApi.adTimeExport)(this.pageParam);
    },
    //调用接口查询
    doSearch: function doSearch() {
      if (this.isNotEmpty(this.pageParam.adTimeRange)) {
        var adTimeRange = this.pageParam.adTimeRange;
        var startTime = this.formatDate(adTimeRange[0], "yyyy-MM-dd HH:mm:ss");
        var endTime = this.formatDate(adTimeRange[1], "yyyy-MM-dd HH:mm:ss");
        this.pageParam.adTimeRange[0] = startTime;
        this.pageParam.adTimeRange[1] = endTime;
      }
      return (0, _advertiseApi.adTimePage)(this.pageParam);
    },
    /**
     * 执行批量删除操作
     * @param {Object} idList id集合
     */
    doBatchDelete: function doBatchDelete(idList) {
      return (0, _advertiseApi.adTimeDelete)(idList);
    },
    /**
     * 打开广告时段详情窗口
     */
    openDetailDialog: function openDetailDialog() {
      var _this = this;
      var selectData = this.getSelect();
      if (this.isEmpty(selectData)) {
        return;
      }
      this.showDetailDialog = true;
      this.$nextTick(function () {
        // 把选中行的数据拷贝一份传递给修改对话框
        selectData = _this.transTimeDia(selectData);
        _this.$refs.detailDialog.setEntity(_this.objectCopy(selectData));
      });
    }
  }
};
exports.default = _default;