var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "老师申请详情",
        visible: _vm.dialogVisible,
        "close-on-click-modal": false,
        center: "",
        width: _vm.dialogWidth,
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
        close: _vm.closeDialog,
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "elForm",
          staticClass: "dialog-form",
          attrs: {
            model: _vm.entity,
            rules: _vm.rules,
            inline: true,
            "label-width": _vm.getLabelWidth(),
          },
        },
        [
          _c(
            "el-descriptions",
            {
              staticClass: "margin-top",
              attrs: {
                title: "基本信息",
                "label-style": { width: "120px" },
                column: 3,
                size: "medium",
                border: "",
              },
            },
            [
              _c(
                "el-descriptions-item",
                [
                  _c("template", { slot: "label" }, [
                    _c("i", { staticClass: "el-icon-postcard" }),
                    _vm._v("\n                    是否注销\n                "),
                  ]),
                  _vm._v(
                    "\n                " +
                      _vm._s(_vm.entity.isDel) +
                      "\n            "
                  ),
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "el-descriptions-item",
                [
                  _c("template", { slot: "label" }, [
                    _c("i", { staticClass: "el-icon-postcard" }),
                    _vm._v("\n                    用户ID\n                "),
                  ]),
                  _vm._v(
                    "\n                " +
                      _vm._s(_vm.entity.userId) +
                      "\n            "
                  ),
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "el-descriptions-item",
                [
                  _c("template", { slot: "label" }, [
                    _c("i", { staticClass: "el-icon-postcard" }),
                    _vm._v("\n                    审核状态\n                "),
                  ]),
                  _vm._v(
                    "\n                " +
                      _vm._s(_vm.entity.reviewStatus) +
                      "\n            "
                  ),
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "el-descriptions-item",
                [
                  _c("template", { slot: "label" }, [
                    _c("i", { staticClass: "el-icon-postcard" }),
                    _vm._v("\n                    驳回理由\n                "),
                  ]),
                  _vm._v(
                    "\n                " +
                      _vm._s(_vm.entity.backReason) +
                      "\n            "
                  ),
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "el-descriptions-item",
                [
                  _c("template", { slot: "label" }, [
                    _c("i", { staticClass: "el-icon-postcard" }),
                    _vm._v(
                      "\n                    审核的合伙人ID\n                "
                    ),
                  ]),
                  _vm._v(
                    "\n                " +
                      _vm._s(_vm.entity.proxyId) +
                      "\n            "
                  ),
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "el-descriptions-item",
                [
                  _c("template", { slot: "label" }, [
                    _c("i", { staticClass: "el-icon-postcard" }),
                    _vm._v("\n                    手机号\n                "),
                  ]),
                  _vm._v(
                    "\n                " +
                      _vm._s(_vm.entity.phone) +
                      "\n            "
                  ),
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "el-descriptions-item",
                [
                  _c("template", { slot: "label" }, [
                    _c("i", { staticClass: "el-icon-postcard" }),
                    _vm._v("\n                    代理区域\n                "),
                  ]),
                  _vm._v(
                    "\n                " +
                      _vm._s(_vm.entity.area) +
                      "\n            "
                  ),
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "el-descriptions-item",
                [
                  _c("template", { slot: "label" }, [
                    _c("i", { staticClass: "el-icon-postcard" }),
                    _vm._v("\n                    学校\n                "),
                  ]),
                  _vm._v(
                    "\n                " +
                      _vm._s(_vm.entity.school) +
                      "\n            "
                  ),
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "el-descriptions-item",
                [
                  _c("template", { slot: "label" }, [
                    _c("i", { staticClass: "el-icon-postcard" }),
                    _vm._v("\n                    姓名\n                "),
                  ]),
                  _vm._v(
                    "\n                " +
                      _vm._s(_vm.entity.name) +
                      "\n            "
                  ),
                ],
                2
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "form-button-container" },
        [
          _c(
            "el-button",
            {
              attrs: { type: "danger", icon: "el-icon-back" },
              on: {
                click: function ($event) {
                  _vm.dialogVisible = false
                },
              },
            },
            [_vm._v("返回")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }