"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.withdrawLogStatusEnums = exports.sellerWithdrawParams = exports.sellerWithdrawLogReview = exports.sellerWithdrawLogPage = exports.sellerWithdrawLogExport = exports.sellerWithdrawLogAdd = exports.sellerWithdrawLock = exports.sellerWithdrawCancel = exports.sellerWaitUpdate = exports.sellerUpdateRefundInfo = exports.sellerUpdateRangeTime = exports.sellerUpdateBank = exports.sellerUpdate = exports.sellerStatusEnums = exports.sellerReviewLogReview = exports.sellerReviewLogPage = exports.sellerReviewLogExport = exports.sellerReview = exports.sellerRecharge = exports.sellerQuery = exports.sellerPayTypeEnums = exports.sellerPage = exports.sellerMoneyTypeEnums = exports.sellerMoneyBalanceLogPage = exports.sellerMoneyBalanceLogExport = exports.sellerIsEnabled = exports.sellerInnerSendCode = exports.sellerExport = exports.sellerDelete = exports.sellerBalanceTypeEnums = exports.sellerAdd = void 0;
var _request = require("@/utils/request");
//=============================================================枚举=======================================================
var sellerStatusEnums = function sellerStatusEnums() {
  return (0, _request.post)('/seller/enums/seller/status');
};
exports.sellerStatusEnums = sellerStatusEnums;
var sellerPayTypeEnums = function sellerPayTypeEnums() {
  return (0, _request.post)('/seller/enums/seller/pay/type');
};
exports.sellerPayTypeEnums = sellerPayTypeEnums;
var sellerMoneyTypeEnums = function sellerMoneyTypeEnums() {
  return (0, _request.post)('/seller/enums/seller-money/type');
};
exports.sellerMoneyTypeEnums = sellerMoneyTypeEnums;
var sellerBalanceTypeEnums = function sellerBalanceTypeEnums() {
  return (0, _request.post)('/seller/enums/seller-balance/type');
};
exports.sellerBalanceTypeEnums = sellerBalanceTypeEnums;
var withdrawLogStatusEnums = function withdrawLogStatusEnums() {
  return (0, _request.post)('/seller/enums/withdraw/log/status', {}, {}, true, 'SellerWithdrawLogStatusEnums');
};

//=============================================================商户=======================================================
/**
 * 商户已登录发送验证码
 */
exports.withdrawLogStatusEnums = withdrawLogStatusEnums;
var sellerInnerSendCode = function sellerInnerSendCode(data) {
  return (0, _request.post)('/seller/seller/innerSendCode', data);
};
/**
 * 商户数据查询
 */
exports.sellerInnerSendCode = sellerInnerSendCode;
var sellerQuery = function sellerQuery(id) {
  return (0, _request.post)('/seller/seller/query?id=' + id);
};
/**
 * 商户新增
 */
exports.sellerQuery = sellerQuery;
var sellerAdd = function sellerAdd(data) {
  return (0, _request.post)('/seller/seller/add', data);
};
/**
 * 商户批量删除
 */
exports.sellerAdd = sellerAdd;
var sellerDelete = function sellerDelete(idList) {
  return (0, _request.post)('/seller/seller/delete?idList=' + idList);
};
/**
 * 商户修改
 */
exports.sellerDelete = sellerDelete;
var sellerUpdate = function sellerUpdate(data) {
  return (0, _request.post)('/seller/seller/update', data);
};
/**
 * 商户审核
 */
exports.sellerUpdate = sellerUpdate;
var sellerReview = function sellerReview(data) {
  return (0, _request.post)('/seller/seller/review', data);
};
/**
 * 商户审核
 */
exports.sellerReview = sellerReview;
var sellerRecharge = function sellerRecharge(data) {
  return (0, _request.post)('/seller/seller/recharge', data);
};
/**
 * 商户退款信息修改
 */
exports.sellerRecharge = sellerRecharge;
var sellerUpdateRefundInfo = function sellerUpdateRefundInfo(data) {
  return (0, _request.post)('/seller/seller/updateRefundInfo', data);
};
/**
 * 商户经营时段修改
 */
exports.sellerUpdateRefundInfo = sellerUpdateRefundInfo;
var sellerUpdateRangeTime = function sellerUpdateRangeTime(data) {
  return (0, _request.post)('/seller/seller/updateRangeTime', data);
};
/**
 * 修改商户状态
 */
exports.sellerUpdateRangeTime = sellerUpdateRangeTime;
var sellerIsEnabled = function sellerIsEnabled(data) {
  return (0, _request.post)('/seller/seller/disabled', data);
};
/**
 * 商户银行卡修改
 */
exports.sellerIsEnabled = sellerIsEnabled;
var sellerUpdateBank = function sellerUpdateBank(data) {
  return (0, _request.post)('/seller/seller/updateBankInfo', data);
};
/**
 * 审核被拒绝的商户重新提交申请
 */
exports.sellerUpdateBank = sellerUpdateBank;
var sellerWaitUpdate = function sellerWaitUpdate(data) {
  return (0, _request.post)('/seller/seller/waitUpdate', data);
};
/**
 * 商户列表
 */
exports.sellerWaitUpdate = sellerWaitUpdate;
var sellerPage = function sellerPage(data) {
  return (0, _request.post)('/seller/seller/page', data);
};
/**
 * 商户导出
 */
exports.sellerPage = sellerPage;
var sellerExport = function sellerExport(param, name) {
  return (0, _request.exportExcel)('/seller/seller/export', param, name);
};
//=============================================================商户余额明细=======================================================
/**
 * 商户余额明细列表
 */
exports.sellerExport = sellerExport;
var sellerMoneyBalanceLogPage = function sellerMoneyBalanceLogPage(data) {
  return (0, _request.post)('/seller/sellerMoneyBalanceLog/page', data);
};
/**
 * 商户余额明细导出
 */
exports.sellerMoneyBalanceLogPage = sellerMoneyBalanceLogPage;
var sellerMoneyBalanceLogExport = function sellerMoneyBalanceLogExport(param, name) {
  return (0, _request.exportExcel)('/seller/sellerMoneyBalanceLog/export', param, name);
};
//=============================================================商户审核记录表=======================================================
/**
 * 商户审核记录表审核
 */
exports.sellerMoneyBalanceLogExport = sellerMoneyBalanceLogExport;
var sellerReviewLogReview = function sellerReviewLogReview(data) {
  return (0, _request.post)('/seller/sellerReviewLog/review', data);
};
/**
 * 商户审核记录表列表
 */
exports.sellerReviewLogReview = sellerReviewLogReview;
var sellerReviewLogPage = function sellerReviewLogPage(data) {
  return (0, _request.post)('/seller/sellerReviewLog/page', data);
};
/**
 * 商户审核记录表导出
 */
exports.sellerReviewLogPage = sellerReviewLogPage;
var sellerReviewLogExport = function sellerReviewLogExport(param, name) {
  return (0, _request.exportExcel)('/seller/sellerReviewLog/export', param, name);
};
//=============================================================商户提现管理=======================================================
/**
 * 商户提现参数
 */
exports.sellerReviewLogExport = sellerReviewLogExport;
var sellerWithdrawParams = function sellerWithdrawParams() {
  return (0, _request.post)('/seller/sellerWithdrawLog/withdrawParams');
};
/**
 * 商户提现管理新增
 */
exports.sellerWithdrawParams = sellerWithdrawParams;
var sellerWithdrawLogAdd = function sellerWithdrawLogAdd(data) {
  return (0, _request.post)('/seller/sellerWithdrawLog/withdraw', data);
};
/**
 * 商户提现撤销
 */
exports.sellerWithdrawLogAdd = sellerWithdrawLogAdd;
var sellerWithdrawCancel = function sellerWithdrawCancel(data) {
  return (0, _request.post)('/seller/sellerWithdrawLog/cancel', data);
};
/**
 * 商户提现锁定
 */
exports.sellerWithdrawCancel = sellerWithdrawCancel;
var sellerWithdrawLock = function sellerWithdrawLock(idList) {
  return (0, _request.post)('/seller/sellerWithdrawLog/lock?idList=' + idList);
};
/**
 * 运营审核商户提现
 */
exports.sellerWithdrawLock = sellerWithdrawLock;
var sellerWithdrawLogReview = function sellerWithdrawLogReview(data) {
  return (0, _request.post)('/seller/sellerWithdrawLog/review', data);
};
/**
 * 商户提现管理列表
 */
exports.sellerWithdrawLogReview = sellerWithdrawLogReview;
var sellerWithdrawLogPage = function sellerWithdrawLogPage(data) {
  return (0, _request.post)('/seller/sellerWithdrawLog/page', data);
};
/**
 * 商户提现管理导出
 */
exports.sellerWithdrawLogPage = sellerWithdrawLogPage;
var sellerWithdrawLogExport = function sellerWithdrawLogExport(param, name) {
  return (0, _request.exportExcel)('/seller/sellerWithdrawLog/export', param, name);
};
exports.sellerWithdrawLogExport = sellerWithdrawLogExport;