"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _searchKeyword = require("./search-keyword");
var _globalDialog = require("@/mixins/global-dialog");
var _operationApi = require("@/api/operation-api");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  mixins: [_searchKeyword.searchKeyword, _globalDialog.addDialog],
  methods: {
    //执行添加操作
    doAdd: function doAdd() {
      return (0, _operationApi.searchKeywordAdd)(this.entity);
    }
  }
};
exports.default = _default;