var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        "close-on-click-modal": false,
        visible: _vm.dialogVisible,
        width: _vm.dialogWidth,
        center: "",
        title: "绳子会员订单详情",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
        close: _vm.closeDialog,
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "elForm",
          staticClass: "dialog-form",
          attrs: {
            inline: true,
            "label-width": _vm.getLabelWidth(),
            model: _vm.entity,
            rules: _vm.rules,
          },
        },
        [
          _c(
            "el-descriptions",
            {
              staticClass: "margin-top",
              attrs: {
                column: _vm.isPc() ? 3 : 1,
                "label-style": { width: "120px" },
                border: "",
                size: "medium",
                title: "基本信息",
              },
            },
            [
              _c(
                "el-descriptions-item",
                [
                  _c("template", { slot: "label" }, [
                    _c("i", { staticClass: "el-icon-postcard" }),
                    _vm._v("\n          用户ID\n        "),
                  ]),
                  _vm._v("\n        " + _vm._s(_vm.entity.userId) + "\n      "),
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "el-descriptions-item",
                [
                  _c("template", { slot: "label" }, [
                    _c("i", { staticClass: "el-icon-postcard" }),
                    _vm._v("\n          唯一编码\n        "),
                  ]),
                  _vm._v("\n        " + _vm._s(_vm.entity.uuid) + "\n      "),
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "el-descriptions-item",
                [
                  _c("template", { slot: "label" }, [
                    _c("i", { staticClass: "el-icon-postcard" }),
                    _vm._v("\n          上送订单号\n        "),
                  ]),
                  _vm._v(
                    "\n        " + _vm._s(_vm.entity.orderNo) + "\n      "
                  ),
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "el-descriptions-item",
                [
                  _c("template", { slot: "label" }, [
                    _c("i", { staticClass: "el-icon-postcard" }),
                    _vm._v("\n          第三方订单号\n        "),
                  ]),
                  _vm._v(
                    "\n        " + _vm._s(_vm.entity.thirdNo) + "\n      "
                  ),
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "el-descriptions-item",
                [
                  _c("template", { slot: "label" }, [
                    _c("i", { staticClass: "el-icon-postcard" }),
                    _vm._v("\n          押金\n        "),
                  ]),
                  _vm._v("\n        " + _vm._s(_vm.entity.money) + "\n      "),
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "el-descriptions-item",
                [
                  _c("template", { slot: "label" }, [
                    _c("i", { staticClass: "el-icon-postcard" }),
                    _vm._v("\n          支付方式\n        "),
                  ]),
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.payWayMap[_vm.entity.payWay]) +
                      "\n      "
                  ),
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "el-descriptions-item",
                [
                  _c("template", { slot: "label" }, [
                    _c("i", { staticClass: "el-icon-postcard" }),
                    _vm._v("\n          赠送的商品\n        "),
                  ]),
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.entity.ropeVipProductId) +
                      "\n      "
                  ),
                ],
                2
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-descriptions",
            {
              staticClass: "margin-top",
              attrs: {
                column: _vm.isPc() ? 3 : 1,
                "label-style": { width: "120px" },
                border: "",
                size: "medium",
                title: "奖励信息",
              },
            },
            [
              _c(
                "el-descriptions-item",
                [
                  _c(
                    "el-descriptions-item",
                    [
                      _c("template", { slot: "label" }, [
                        _c("i", { staticClass: "el-icon-postcard" }),
                        _vm._v("\n            邀请人ID\n          "),
                      ]),
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.entity.inviterId) +
                          "\n        "
                      ),
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _c("template", { slot: "label" }, [
                    _c("i", { staticClass: "el-icon-postcard" }),
                    _vm._v("\n          邀请人奖励\n        "),
                  ]),
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.entity.inviterAwardMoney) +
                      "\n      "
                  ),
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "el-descriptions-item",
                [
                  _c("template", { slot: "label" }, [
                    _c("i", { staticClass: "el-icon-postcard" }),
                    _vm._v("\n          代理ID\n        "),
                  ]),
                  _vm._v(
                    "\n        " + _vm._s(_vm.entity.proxyId) + "\n      "
                  ),
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "el-descriptions-item",
                [
                  _c("template", { slot: "label" }, [
                    _c("i", { staticClass: "el-icon-postcard" }),
                    _vm._v("\n          代理人奖励\n        "),
                  ]),
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.entity.proxyAwardMoney) +
                      "\n      "
                  ),
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "el-descriptions-item",
                [
                  _c("template", { slot: "label" }, [
                    _c("i", { staticClass: "el-icon-postcard" }),
                    _vm._v("\n          是否已经分润\n        "),
                  ]),
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.hadAwardMap[_vm.entity.hadAward]) +
                      "\n      "
                  ),
                ],
                2
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-descriptions",
            {
              staticClass: "margin-top",
              attrs: {
                column: _vm.isPc() ? 3 : 1,
                "label-style": { width: "120px" },
                border: "",
                size: "medium",
                title: "收货信息",
              },
            },
            [
              _c(
                "el-descriptions-item",
                [
                  _c("template", { slot: "label" }, [
                    _c("i", { staticClass: "el-icon-postcard" }),
                    _vm._v("\n          收货人姓名\n        "),
                  ]),
                  _vm._v(
                    "\n        " + _vm._s(_vm.entity.addressName) + "\n      "
                  ),
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "el-descriptions-item",
                [
                  _c("template", { slot: "label" }, [
                    _c("i", { staticClass: "el-icon-postcard" }),
                    _vm._v("\n          收货人手机号\n        "),
                  ]),
                  _vm._v(
                    "\n        " + _vm._s(_vm.entity.addressPhone) + "\n      "
                  ),
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "el-descriptions-item",
                [
                  _c("template", { slot: "label" }, [
                    _c("i", { staticClass: "el-icon-postcard" }),
                    _vm._v("\n          收货地址\n        "),
                  ]),
                  _vm._v(
                    "\n        " + _vm._s(_vm.entity.address) + "\n      "
                  ),
                ],
                2
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "form-button-container" },
        [
          _c(
            "el-button",
            {
              attrs: { icon: "el-icon-back", type: "danger" },
              on: {
                click: function ($event) {
                  _vm.dialogVisible = false
                },
              },
            },
            [_vm._v("返回")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }