var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "广告主详情",
        visible: _vm.dialogVisible,
        "close-on-click-modal": false,
        center: "",
        width: _vm.dialogWidth,
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
        close: _vm.closeDialog,
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "elForm",
          staticClass: "dialog-form",
          attrs: {
            model: _vm.entity,
            rules: _vm.rules,
            inline: true,
            "label-width": _vm.getLabelWidth(),
          },
        },
        [
          _c(
            "el-descriptions",
            {
              staticClass: "margin-top",
              attrs: {
                column: _vm.isPc() ? 3 : 1,
                "label-style": { width: "120px" },
                size: "medium",
                title: "基本信息",
                border: "",
              },
            },
            [
              _c(
                "el-descriptions-item",
                [
                  _c("template", { slot: "label" }, [
                    _c("i", { staticClass: "el-icon-postcard" }),
                    _vm._v("\n          头像\n        "),
                  ]),
                  _vm._v(" "),
                  _c("single-img-upload", {
                    attrs: { height: "80", isDisabled: "", width: "80" },
                    model: {
                      value: _vm.entity.headImg,
                      callback: function ($$v) {
                        _vm.$set(_vm.entity, "headImg", $$v)
                      },
                      expression: "entity.headImg",
                    },
                  }),
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "el-descriptions-item",
                [
                  _c("template", { slot: "label" }, [
                    _c("i", { staticClass: "el-icon-postcard" }),
                    _vm._v("\n          昵称\n        "),
                  ]),
                  _vm._v(
                    "\n        " + _vm._s(_vm.entity.nickName) + "\n      "
                  ),
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "el-descriptions-item",
                [
                  _c("template", { slot: "label" }, [
                    _c("i", { staticClass: "el-icon-postcard" }),
                    _vm._v("\n          手机号\n        "),
                  ]),
                  _vm._v("\n        " + _vm._s(_vm.entity.mobile) + "\n      "),
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "el-descriptions-item",
                [
                  _c("template", { slot: "label" }, [
                    _c("i", { staticClass: "el-icon-postcard" }),
                    _vm._v("\n          注册IP\n        "),
                  ]),
                  _vm._v(
                    "\n        " + _vm._s(_vm.entity.registerIp) + "\n      "
                  ),
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "el-descriptions-item",
                [
                  _c("template", { slot: "label" }, [
                    _c("i", { staticClass: "el-icon-postcard" }),
                    _vm._v("\n          状态\n        "),
                  ]),
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.statusMap[_vm.entity.status]) +
                      "\n      "
                  ),
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "el-descriptions-item",
                [
                  _c("template", { slot: "label" }, [
                    _c("i", { staticClass: "el-icon-postcard" }),
                    _vm._v("\n          微信unionId\n        "),
                  ]),
                  _vm._v(
                    "\n        " + _vm._s(_vm.entity.wxUnionId) + "\n      "
                  ),
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "el-descriptions-item",
                [
                  _c("template", { slot: "label" }, [
                    _c("i", { staticClass: "el-icon-postcard" }),
                    _vm._v("\n          小程序openId\n        "),
                  ]),
                  _vm._v(
                    "\n        " + _vm._s(_vm.entity.wxMiniOpenId) + "\n      "
                  ),
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "el-descriptions-item",
                [
                  _c("template", { slot: "label" }, [
                    _c("i", { staticClass: "el-icon-postcard" }),
                    _vm._v("\n          身份证头像面\n        "),
                  ]),
                  _vm._v(" "),
                  _c("single-img-upload", {
                    attrs: { height: "80", isDisabled: "", width: "80" },
                    model: {
                      value: _vm.entity.identityFront,
                      callback: function ($$v) {
                        _vm.$set(_vm.entity, "identityFront", $$v)
                      },
                      expression: "entity.identityFront",
                    },
                  }),
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "el-descriptions-item",
                [
                  _c("template", { slot: "label" }, [
                    _c("i", { staticClass: "el-icon-postcard" }),
                    _vm._v("\n          身份证国徽面\n        "),
                  ]),
                  _vm._v(" "),
                  _c("single-img-upload", {
                    attrs: { height: "80", isDisabled: "", width: "80" },
                    model: {
                      value: _vm.entity.identityBackend,
                      callback: function ($$v) {
                        _vm.$set(_vm.entity, "identityBackend", $$v)
                      },
                      expression: "entity.identityBackend",
                    },
                  }),
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "el-descriptions-item",
                [
                  _c("template", { slot: "label" }, [
                    _c("i", { staticClass: "el-icon-postcard" }),
                    _vm._v("\n          广告主状态\n        "),
                  ]),
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.checkStatusMap[_vm.entity.checkStatus]) +
                      "\n      "
                  ),
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "el-descriptions-item",
                [
                  _c("template", { slot: "label" }, [
                    _c("i", { staticClass: "el-icon-postcard" }),
                    _vm._v("\n          驳回理由\n        "),
                  ]),
                  _vm._v(
                    "\n        " + _vm._s(_vm.entity.backReason) + "\n      "
                  ),
                ],
                2
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-descriptions",
            {
              staticClass: "margin-top",
              attrs: {
                column: _vm.isPc() ? 3 : 1,
                "label-style": { width: "120px" },
                border: "",
                size: "medium",
                title: "资金信息",
              },
            },
            [
              _c(
                "el-descriptions-item",
                [
                  _c("template", { slot: "label" }, [
                    _c("i", { staticClass: "el-icon-postcard" }),
                    _vm._v("\n          广告卡\n        "),
                  ]),
                  _vm._v("\n        " + _vm._s(_vm.entity.adCard) + "\n      "),
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "el-descriptions-item",
                [
                  _c("template", { slot: "label" }, [
                    _c("i", { staticClass: "el-icon-postcard" }),
                    _vm._v("\n          现金\n        "),
                  ]),
                  _vm._v("\n        " + _vm._s(_vm.entity.cash) + "\n      "),
                ],
                2
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-descriptions",
            {
              staticClass: "margin-top",
              attrs: {
                column: _vm.isPc() ? 3 : 1,
                "label-style": { width: "120px" },
                border: "",
                size: "medium",
                title: "公司信息",
              },
            },
            [
              _c(
                "el-descriptions-item",
                [
                  _c("template", { slot: "label" }, [
                    _c("i", { staticClass: "el-icon-postcard" }),
                    _vm._v("\n          商标\n        "),
                  ]),
                  _vm._v(" "),
                  _c("single-img-upload", {
                    attrs: { height: "80", isDisabled: "", width: "80" },
                    model: {
                      value: _vm.entity.logo,
                      callback: function ($$v) {
                        _vm.$set(_vm.entity, "logo", $$v)
                      },
                      expression: "entity.logo",
                    },
                  }),
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "el-descriptions-item",
                [
                  _c("template", { slot: "label" }, [
                    _c("i", { staticClass: "el-icon-postcard" }),
                    _vm._v("\n          公司全称\n        "),
                  ]),
                  _vm._v(
                    "\n        " + _vm._s(_vm.entity.company) + "\n      "
                  ),
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "el-descriptions-item",
                [
                  _c("template", { slot: "label" }, [
                    _c("i", { staticClass: "el-icon-postcard" }),
                    _vm._v("\n          公司简称\n        "),
                  ]),
                  _vm._v(
                    "\n        " + _vm._s(_vm.entity.companyShort) + "\n      "
                  ),
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "el-descriptions-item",
                [
                  _c("template", { slot: "label" }, [
                    _c("i", { staticClass: "el-icon-postcard" }),
                    _vm._v("\n          联系电话\n        "),
                  ]),
                  _vm._v(
                    "\n        " + _vm._s(_vm.entity.companyPhone) + "\n      "
                  ),
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "el-descriptions-item",
                [
                  _c("template", { slot: "label" }, [
                    _c("i", { staticClass: "el-icon-postcard" }),
                    _vm._v("\n          营业执照\n        "),
                  ]),
                  _vm._v(" "),
                  _c("single-img-upload", {
                    attrs: { height: "80", isDisabled: "", width: "80" },
                    model: {
                      value: _vm.entity.licenseUrl,
                      callback: function ($$v) {
                        _vm.$set(_vm.entity, "licenseUrl", $$v)
                      },
                      expression: "entity.licenseUrl",
                    },
                  }),
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "el-descriptions-item",
                [
                  _c("template", { slot: "label" }, [
                    _c("i", { staticClass: "el-icon-postcard" }),
                    _vm._v("\n          经营范围\n        "),
                  ]),
                  _vm._v(
                    "\n        " + _vm._s(_vm.entity.cateName) + "\n      "
                  ),
                ],
                2
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "form-button-container" },
        [
          _c(
            "el-button",
            {
              attrs: { type: "danger", icon: "el-icon-back" },
              on: {
                click: function ($event) {
                  _vm.dialogVisible = false
                },
              },
            },
            [_vm._v("返回")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }