"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.teacherApply = void 0;
var teacherApply = {
  data: function data() {
    return {
      //是否展示详情窗口
      showDetailDialog: false,
      //是否注销枚举
      isDelMap: {
        0: '未注销',
        1: '已注销'
      },
      //是否注销标签颜色枚举
      isDelColorMap: {
        0: 'success',
        1: 'danger'
      },
      //审核状态枚举
      reviewStatusMap: {
        0: '待审核',
        1: '审核通过',
        2: '审核不通过'
      },
      //审核状态标签颜色枚举
      reviewStatusColorMap: {
        0: 'danger',
        1: 'success',
        2: 'danger',
        3: 'warning',
        4: 'info',
        5: 'primary'
      },
      backUrl: '/teacher/teacherApply/page',
      entity: {},
      rules: {
        isDel: [{
          required: true,
          message: '请输入是否注销'
        }],
        userId: [{
          required: true,
          message: '请输入用户ID'
        }],
        reviewStatus: [{
          required: true,
          message: '请输入审核状态'
        }],
        backReason: [{
          required: true,
          message: '请输入驳回理由'
        }, {
          max: 255,
          message: '驳回理由长度在255个字符内'
        }],
        proxyId: [{
          required: true,
          message: '请输入审核的合伙人ID'
        }],
        phone: [{
          required: true,
          message: '请输入手机号'
        }, {
          max: 20,
          message: '手机号长度在20个字符内'
        }],
        area: [{
          required: true,
          message: '请输入代理区域'
        }, {
          max: 255,
          message: '代理区域长度在255个字符内'
        }],
        school: [{
          required: true,
          message: '请输入学校'
        }, {
          max: 255,
          message: '学校长度在255个字符内'
        }],
        name: [{
          required: true,
          message: '请输入姓名'
        }, {
          max: 255,
          message: '姓名长度在255个字符内'
        }]
      }
    };
  }
};
exports.teacherApply = teacherApply;