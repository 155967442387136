"use strict";

var _interopRequireDefault = require("D:/shenbimaliang1/sbml-backend/backend-page/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _SingleImgUpload = _interopRequireDefault(require("@/components/Upload/SingleImgUpload"));
var _vueQuillEditor = require("vue-quill-editor");
require("quill/dist/quill.core.css");
require("quill/dist/quill.snow.css");
require("quill/dist/quill.bubble.css");
var _quillTitle = require("./quill-title.js");
//
//
//
//
//
//
//

var that = void 0;
var _default2 = {
  props: {
    // 图片数据(图片url组成的数组) 通过v-model传递
    value: {
      type: String,
      default: function _default() {
        return '';
      }
    },
    id: {
      type: String,
      default: function _default() {
        return 'hideImg';
      }
    }
  },
  components: {
    quillEditor: _vueQuillEditor.quillEditor,
    SingleImgUpload: _SingleImgUpload.default
  },
  data: function data() {
    return {
      content: '',
      quillEditorImgUrl: '',
      //图片上传成功返回的url
      quillEditorOption: {
        placeholder: '请在此输入内容',
        id: this.id,
        theme: 'snow',
        // or 'bubble'
        modules: {
          toolbar: {
            container: [['bold', 'italic', 'underline', 'strike'],
            // toggled buttons
            ['blockquote', 'code-block'], [{
              header: 1
            }, {
              header: 2
            }],
            // custom button values
            [{
              list: 'ordered'
            }, {
              list: 'bullet'
            }], [{
              script: 'sub'
            }, {
              script: 'super'
            }],
            // superscript/subscript
            [{
              indent: '-1'
            }, {
              indent: '+1'
            }],
            // outdent/indent
            [{
              direction: 'rtl'
            }],
            // text direction
            [{
              size: ['small', false, 'large', 'huge']
            }],
            // custom dropdown
            [{
              header: [1, 2, 3, 4, 5, 6, false]
            }], [{
              color: []
            }, {
              background: []
            }],
            // dropdown with defaults from theme
            [{
              font: []
            }], [{
              align: []
            }], ['link', 'image', 'video'], ['clean'] // remove formatting button
            ],

            // 工具栏
            handlers: {
              image: function image(value) {
                if (value) {
                  // 触发input框选择图片文件
                  var div = document.getElementById(this.quill.options.id);
                  var inputs = div.getElementsByTagName("input");
                  inputs[0].click();
                } else {
                  this.quill.format('image', false);
                }
              }
            }
          }
        }
      }
    };
  },
  watch: {
    value: {
      handler: function handler(val) {
        this.content = this.value;
      },
      deep: true
    },
    //监听图片上传成功的url变化，把这个url插入到富文本编辑器中
    quillEditorImgUrl: function quillEditorImgUrl(val) {
      if (this.isEmpty(val)) {
        return;
      }
      val = this.ossDomain + val;
      // 获取富文本组件实例
      var quill = this.$refs.myQuillEditor.quill;
      // 获取光标所在位置
      var length = quill.getSelection().index;
      // 插入图片  res.info为服务器返回的图片地址
      quill.insertEmbed(length, 'image', val);
      // 调整光标到最后
      quill.setSelection(length + 1);
    }
  },
  mounted: function mounted() {
    var _this = this;
    this.$nextTick(function () {
      if (_this.isNotUndefined(_this.$refs.myQuillEditor)) {
        //添加工具栏的文字提示
        (0, _quillTitle.addQuillTitle)();
      }
    });
  },
  methods: {
    onEditorChange: function onEditorChange(_ref) {
      var quill = _ref.quill,
        html = _ref.html,
        text = _ref.text;
      console.log('editor change!', quill, html, text);
      this.content = html;
      this.$emit('input', html);
    }
  }
};
exports.default = _default2;