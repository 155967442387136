"use strict";

var _interopRequireDefault = require("D:/shenbimaliang1/sbml-backend/backend-page/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _version = require("./version");
var _globalDialog = require("@/mixins/global-dialog");
var _operationApi = require("@/api/operation-api");
var _DragUpload = _interopRequireDefault(require("@/components/Upload/DragUpload.vue"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    DragUpload: _DragUpload.default
  },
  mixins: [_version.version, _globalDialog.addDialog],
  methods: {
    //执行添加操作
    doAdd: function doAdd() {
      return (0, _operationApi.versionAdd)(this.entity);
    }
  }
};
exports.default = _default;