"use strict";

var _interopRequireDefault = require("D:/shenbimaliang1/sbml-backend/backend-page/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _interopRequireWildcard2 = _interopRequireDefault(require("D:/shenbimaliang1/sbml-backend/backend-page/node_modules/@babel/runtime/helpers/interopRequireWildcard.js"));
/**
 * 会员管理路由配置
 */
var memberRouter = [{
  path: 'user/page',
  name: 'UserPage',
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/views/member/user/UserPage'));
    });
  },
  meta: {
    title: '用户列表'
  }
}, {
  path: 'favorite/page',
  name: 'FavoritePage',
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/views/member/favorite/FavoritePage'));
    });
  },
  meta: {
    title: '用户收藏列表'
  }
}, {
  path: 'address/page',
  name: 'AddressPage',
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/views/member/address/AddressPage'));
    });
  },
  meta: {
    title: '用户收货地址列表'
  }
}, {
  path: 'userMoneyPointsLog/page',
  name: 'UserMoneyPointsLogPage',
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/views/member/userMoneyPointsLog/UserMoneyPointsLogPage'));
    });
  },
  meta: {
    title: '用户资金明细-健康宝列表'
  }
}, {
  path: 'userMoneyBalanceLog/page',
  name: 'UserMoneyBalanceLogPage',
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/views/member/userMoneyBalanceLog/UserMoneyBalanceLogPage'));
    });
  },
  meta: {
    title: '用户资金明细-余额列表'
  }
}, {
  path: 'userMoneyAdLog/page',
  name: 'UserMoneyAdLogPage',
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/views/member/userMoneyAdLog/UserMoneyAdLogPage'));
    });
  },
  meta: {
    title: '用户资金明细-广告卡列表'
  }
}, {
  path: 'userBank/page',
  name: 'UserBankPage',
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/views/member/userBank/UserBankPage'));
    });
  },
  meta: {
    title: '用户银行卡表列表'
  }
}, {
  path: 'withdrawLog/page',
  name: 'WithdrawLogPage',
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/views/member/withdrawLog/WithdrawLogPage'));
    });
  },
  meta: {
    title: '用户提现列表'
  }
}];
var _default = memberRouter;
exports.default = _default;