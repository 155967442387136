"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = {
  name: 'tree-select',
  props: {
    // 配置项
    treeProps: {
      type: Object,
      default: function _default() {
        return {
          value: 'id',
          // ID字段名
          label: 'name',
          // 显示字段名
          children: 'children' // 子级字段名
        };
      }
    },

    // 可清空选项
    clearable: {
      type: Boolean,
      default: false
    },
    // 自动收起
    accordion: {
      type: Boolean,
      default: false
    }
  },
  data: function data() {
    return {
      treeData: [],
      //树形结构数据
      valueId: null,
      //当前选中节点的【表情】
      valueTitle: '',
      //当前选中节点的名称
      defaultExpandedKey: []
    };
  },
  methods: {
    /**初始化树形结构数据
     * @param {Object} treeData 树形结构数据
     * @param {Object} valueId 要选中的节点id
     */
    initTree: function initTree(treeData, valueId) {
      this.treeData = treeData;
      this.valueId = valueId;
      // //等页面渲染完了之后在去初始化值，否则会报错
      this.$nextTick(function () {
        this.initHandle();
      });
    },
    // 初始化值
    initHandle: function initHandle() {
      if (null != this.valueId) {
        this.$refs.selectTree.setCurrentKey(this.valueId); // 设置默认选中
        this.valueTitle = this.$refs.selectTree.getNode(this.valueId).data[this.treeProps.label]; // 初始化显示
        this.defaultExpandedKey = [this.valueId]; // 设置默认展开
      }

      this.initScroll();
    },
    // 初始化滚动条
    initScroll: function initScroll() {
      this.$nextTick(function () {
        var scrollWrap = document.querySelectorAll('.el-scrollbar .el-select-dropdown__wrap')[0];
        var scrollBar = document.querySelectorAll('.el-scrollbar .el-scrollbar__bar');
        scrollWrap.style.cssText = 'margin: 0px; max-height: none; overflow: hidden;';
        scrollBar.forEach(function (ele) {
          return ele.style.width = 0;
        });
      });
    },
    // 切换选项
    handleNodeClick: function handleNodeClick(node) {
      this.valueTitle = node[this.treeProps.label];
      this.valueId = node[this.treeProps.value];
      //向父组件传递当前选中的值
      this.$emit('setSelectedValue', this.valueId);
      this.defaultExpandedKey = [];
    },
    // 清除选中
    clearHandle: function clearHandle() {
      this.valueTitle = '';
      this.valueId = null;
      this.defaultExpandedKey = [];
      this.clearSelected();
      this.$emit('setSelectedValue', null);
    },
    // 清空选中样式
    clearSelected: function clearSelected() {
      var allNode = document.querySelectorAll('#tree-option .el-tree-node');
      allNode.forEach(function (element) {
        return element.classList.remove('is-current');
      });
    }
  }
};
exports.default = _default2;