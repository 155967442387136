"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.notice = void 0;
var notice = {
  data: function data() {
    return {
      //状态枚举
      statusMap: {
        0: '禁用',
        1: '启用'
      },
      //状态标签颜色枚举
      statusColorMap: {
        0: 'danger',
        1: 'success',
        2: 'danger',
        3: 'warning',
        4: 'info',
        5: 'primary'
      },
      //类型枚举
      typeMap: {
        0: '公告',
        1: '协议文章',
        2: '常见问题'
      },
      //类型标签颜色枚举
      typeColorMap: {
        0: 'danger',
        1: 'success',
        2: 'danger',
        3: 'warning',
        4: 'info',
        5: 'primary'
      },
      backUrl: '/operation/notice/page',
      entity: {},
      rules: {
        content: [{
          required: true,
          message: '请输入内容'
        }, {
          max: 2147483647,
          message: '内容长度在2147483647个字符内'
        }],
        image: [{
          required: false,
          message: '请输入图片'
        }, {
          max: 256,
          message: '图片长度在256个字符内'
        }],
        name: [{
          required: true,
          message: '请输入标题'
        }, {
          max: 50,
          message: '标题长度在50个字符内'
        }],
        status: [{
          required: true,
          message: '请输入状态'
        }],
        type: [{
          required: true,
          message: '请输入类型'
        }]
      }
    };
  }
};
exports.notice = notice;