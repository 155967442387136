"use strict";

var _interopRequireDefault = require("D:/shenbimaliang1/sbml-backend/backend-page/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _interopRequireWildcard2 = _interopRequireDefault(require("D:/shenbimaliang1/sbml-backend/backend-page/node_modules/@babel/runtime/helpers/interopRequireWildcard.js"));
/**
 * 运营管理路由配置
 */
var operationRouter = [{
  path: 'advert/page',
  name: 'AdvertPage',
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/views/operation/advert/AdvertPage'));
    });
  },
  meta: {
    title: '广告栏列表'
  }
}, {
  path: 'feedback/page',
  name: 'FeedbackPage',
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/views/operation/feedback/FeedbackPage'));
    });
  },
  meta: {
    title: '意见反馈列表'
  }
}, {
  path: 'notice/page',
  name: 'NoticePage',
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/views/operation/notice/NoticePage'));
    });
  },
  meta: {
    title: '通知列表'
  }
}, {
  path: 'version/page',
  name: 'VersionPage',
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/views/operation/version/VersionPage'));
    });
  },
  meta: {
    title: '版本控制列表'
  }
}, {
  path: 'searchKeyword/page',
  name: 'SearchKeywordPage',
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/views/operation/searchKeyword/SearchKeywordPage'));
    });
  },
  meta: {
    title: '热门搜索列表'
  }
}];
var _default = operationRouter;
exports.default = _default;