var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("el-card", { staticClass: "card" }, [
    _c("div", {
      ref: "clockEcharts",
      staticClass: "clock",
      attrs: { id: "clockEcharts" + _vm.cId },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }