"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.teacherRace = void 0;
var teacherRace = {
  data: function data() {
    return {
      //是否展示详情窗口
      showDetailDialog: false,
      //模式枚举
      modeMap: {
        0: '计数',
        1: '计时'
      },
      //模式标签颜色枚举
      modeColorMap: {
        0: 'danger',
        1: 'success',
        2: 'danger',
        3: 'warning',
        4: 'info',
        5: 'primary'
      },
      //是否删除枚举
      isDelMap: {
        0: '否',
        1: '是'
      },
      //是否删除标签颜色枚举
      isDelColorMap: {
        0: 'danger',
        1: 'success',
        2: 'danger',
        3: 'warning',
        4: 'info',
        5: 'primary'
      },
      backUrl: '/teacher/teacherRace/page',
      entity: {},
      rules: {
        teachers: [{
          required: true,
          message: '请输入教师们'
        }, {
          max: 1024,
          message: '教师们长度在1024个字符内'
        }],
        area: [{
          required: true,
          message: '请输入区域'
        }, {
          max: 255,
          message: '区域长度在255个字符内'
        }],
        name: [{
          required: true,
          message: '请输入赛事名称'
        }, {
          max: 255,
          message: '赛事名称长度在255个字符内'
        }],
        mode: [{
          required: true,
          message: '请输入模式'
        }],
        raceBeginTime: [{
          required: true,
          message: '请输入比赛开始时间'
        }],
        raceEndTime: [{
          required: true,
          message: '请输入比赛结束时间'
        }],
        raceLocation: [{
          required: true,
          message: '请输入比赛地点'
        }, {
          max: 255,
          message: '比赛地点长度在255个字符内'
        }],
        rule: [{
          required: true,
          message: '请输入规则和奖励'
        }, {
          max: 255,
          message: '规则和奖励长度在255个字符内'
        }],
        proxyUserId: [{
          required: true,
          message: '请输入合伙人ID'
        }],
        isDel: [{
          required: true,
          message: '请输入是否删除'
        }],
        seconds: [{
          required: true,
          message: '请输入计时的秒数'
        }],
        num: [{
          required: true,
          message: '请输入计数的数量'
        }]
      }
    };
  }
};
exports.teacherRace = teacherRace;