"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.adRating = void 0;
var adRating = {
  data: function data() {
    return {
      //是否展示详情窗口
      showDetailDialog: false,
      backUrl: '/advertise/adRating/page',
      entity: {},
      rules: {
        price: [{
          required: true,
          message: '请输入每秒单价'
        }],
        costTime: [{
          required: true,
          message: '请输入花费的秒数'
        }],
        timeId: [{
          required: true,
          message: '请输入时间段'
        }],
        adId: [{
          required: true,
          message: '请输入广告ID'
        }],
        nickName: [{
          required: true,
          message: '请输入下单人'
        }, {
          max: 255,
          message: '下单人长度在255个字符内'
        }],
        costAdCardNum: [{
          required: true,
          message: '请输入花费的广告卡'
        }],
        timeDesc: [{
          required: true,
          message: '请输入时段'
        }, {
          max: 255,
          message: '时段长度在255个字符内'
        }],
        area: [{
          required: true,
          message: '请输入城市'
        }, {
          max: 255,
          message: '城市长度在255个字符内'
        }],
        hash: [{
          required: true,
          message: '请输入上链hash'
        }, {
          max: 255,
          message: '上链hash长度在255个字符内'
        }],
        encodeKey: [{
          required: true,
          message: '请输入秘钥'
        }, {
          max: 255,
          message: '秘钥长度在255个字符内'
        }]
      }
    };
  }
};
exports.adRating = adRating;