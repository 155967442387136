"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var state = {
  websocket: null // WebSocket 实例
};

var mutations = {
  setWebSocket: function setWebSocket(state, websocket) {
    state.websocket = websocket;
  }
};
var actions = {
  connectSocket: function connectSocket(_ref, data) {
    var commit = _ref.commit;
    commit('setWebSocket', data);
  }
};
var _default = {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions
};
exports.default = _default;