"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _productCategory = require("./product-category");
var _globalDialog = require("@/mixins/global-dialog");
var _productApi = require("@/api/product-api");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  mixins: [_productCategory.productCategory, _globalDialog.addDialog],
  methods: {
    //执行添加操作
    doAdd: function doAdd() {
      if (this.entity.parentId === undefined || this.entity.parentId === null || this.entity.parentId === 'undefined') {
        this.entity.parentId = 0;
      }
      var result = (0, _productApi.productCategoryAdd)(this.entity);
      localStorage.removeItem('productCateGoryOptions');
      return result;
    }
  }
};
exports.default = _default;