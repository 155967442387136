var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-form",
        {
          staticClass: "search-form-container",
          attrs: { "label-width": "100px" },
        },
        [
          _c(
            "el-row",
            [
              _vm.isShow
                ? _c(
                    "el-col",
                    { attrs: { xs: 24, sm: 12, md: 10, lg: 6 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "学校" } },
                        [
                          _c("el-input", {
                            attrs: { clearable: "" },
                            nativeOn: {
                              keyup: function ($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                ) {
                                  return null
                                }
                                return _vm.pageSearch($event)
                              },
                            },
                            model: {
                              value: _vm.pageParam.schoolId,
                              callback: function ($$v) {
                                _vm.$set(_vm.pageParam, "schoolId", $$v)
                              },
                              expression: "pageParam.schoolId",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { xs: 24, sm: 12, md: 10, lg: 6 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "年级" } },
                    [
                      _c("el-input", {
                        attrs: { clearable: "" },
                        nativeOn: {
                          keyup: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                            return _vm.pageSearch($event)
                          },
                        },
                        model: {
                          value: _vm.pageParam.name,
                          callback: function ($$v) {
                            _vm.$set(_vm.pageParam, "name", $$v)
                          },
                          expression: "pageParam.name",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "page-button-container" },
        [
          _vm.isShow
            ? _c(
                "el-button",
                {
                  staticClass: "search-button",
                  attrs: {
                    type: "info",
                    size: _vm.getButtonSize(),
                    icon: "el-icon-refresh",
                  },
                  on: { click: _vm.reset },
                },
                [_vm._v("重置\n      ")]
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "el-button",
            {
              staticClass: "search-button",
              attrs: {
                type: "primary",
                size: _vm.getButtonSize(),
                icon: "el-icon-check",
              },
              on: { click: _vm.pageSearch },
            },
            [_vm._v("\n        搜索\n      ")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.pageLoading,
              expression: "pageLoading",
            },
          ],
          ref: "elTable",
          attrs: {
            data: _vm.pageResult.records,
            "element-loading-text": "正在查询中...",
            border: "",
            fit: "",
            "highlight-current-row": "",
          },
          on: {
            "sort-change": _vm.sortChange,
            "row-click": _vm.handleRowClick,
            "selection-change": _vm.handleSelectionChange,
          },
        },
        [
          _c("el-table-column", { attrs: { type: "selection", width: "40" } }),
          _vm._v(" "),
          _vm.isShow
            ? _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "学校所在区域",
                  prop: "schoolArea",
                  "show-overflow-tooltip": true,
                },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.isShow
            ? _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "学校名称",
                  prop: "schoolName",
                  "show-overflow-tooltip": true,
                },
              })
            : _vm._e(),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "年级",
              prop: "name",
              "show-overflow-tooltip": true,
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "班级数",
              "show-overflow-tooltip": true,
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_vm._v(_vm._s(scope.row.classList.length) + "个班")]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("pagination", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.pageResult.total > 0,
            expression: "pageResult.total > 0",
          },
        ],
        attrs: {
          total: _vm.pageResult.total,
          layout: _vm.getLayout(),
          page: _vm.pageParam.pageNumber,
          limit: _vm.pageParam.pageSize,
        },
        on: {
          "update:page": function ($event) {
            return _vm.$set(_vm.pageParam, "pageNumber", $event)
          },
          "update:limit": function ($event) {
            return _vm.$set(_vm.pageParam, "pageSize", $event)
          },
          pagination: _vm.pageSearch,
        },
      }),
      _vm._v(" "),
      _vm.showAddDialog
        ? _c("grade-add", {
            ref: "addDialog",
            attrs: { "dialog-width": _vm.getDialogWidth() },
            on: { close: _vm.closeAddDialog, refreshTable: _vm.refreshTable },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.showUpdateDialog
        ? _c("grade-update", {
            ref: "updateDialog",
            attrs: { "dialog-width": _vm.getDialogWidth() },
            on: {
              close: _vm.closeUpdateDialog,
              refreshTable: _vm.refreshTable,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }