"use strict";

var _interopRequireDefault = require("D:/shenbimaliang1/sbml-backend/backend-page/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _operationApi = require("@/api/operation-api");
var _globalDialog = require("@/mixins/global-dialog");
var _permission = require("@/mixins/permission");
var _notice = require("./notice");
var _NoticeAdd = _interopRequireDefault(require("./NoticeAdd"));
var _NoticeUpdate = _interopRequireDefault(require("./NoticeUpdate"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'NoticePage',
  components: {
    Pagination: _Pagination.default,
    NoticeAdd: _NoticeAdd.default,
    NoticeUpdate: _NoticeUpdate.default
  },
  mixins: [_notice.notice, _globalDialog.pageDialog, _permission.permission],
  methods: {
    //导出
    exportExcel: function exportExcel() {
      (0, _operationApi.noticeExport)(this.pageParam);
    },
    //调用接口查询
    doSearch: function doSearch() {
      return (0, _operationApi.noticePage)(this.pageParam);
    },
    /**
     * 执行批量删除操作
     * @param {Object} idList id集合
     */
    doBatchDelete: function doBatchDelete(idList) {
      return (0, _operationApi.noticeDelete)(idList);
    }
  }
};
exports.default = _default;