exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".shadow{position:absolute;top:0;right:0;background-color:rgba(0,0,0,0.5);opacity:0;color:#fff;font-size:20px;line-height:20px;padding:2px;cursor:pointer}.shadow-search{position:absolute;bottom:0;right:0;background-color:rgba(0,0,0,0.5);opacity:0;color:#fff;font-size:20px;line-height:20px;padding:2px;cursor:pointer}.avatar-uploader:hover .shadow,.avatar-uploader:hover .shadow-search{opacity:1}.show-bigImg{width:350px;height:350px;background:#fff;border:1px solid #ccc;position:absolute;left:-135px;top:-355px;border-radius:10px;-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none}.show-bigImg .el-image{width:inherit;height:inherit}.show-bigImg img{border-radius:10px}.avatar-uploader .el-upload{border:1px solid #ddd;border-radius:6px;cursor:pointer;position:relative;overflow:hidden}.uploadIcon{width:100%;height:100%;position:relative;display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:center;-ms-flex-pack:center;justify-content:center;border:1px dashed #c0ccda;background-color:#fbfdff;border-radius:6px;font-size:20px;color:#999}\n", ""]);

// exports
exports.locals = {
	"menuText": "#bfcbd9",
	"menuActiveText": "#409EFF",
	"subMenuActiveText": "#f4f4f5",
	"menuBg": "#304156",
	"menuHover": "#263445",
	"subMenuBg": "#1f2d3d",
	"subMenuHover": "#001528",
	"platform_btn": "linear-gradient(233deg, #119bf6 40%, #a5c7f3 100%)",
	"menuSellerText": "#fff3f9",
	"menuActiveSellerText": "#FF652F",
	"subMenuActiveSellerText": "#FF652F",
	"menuSellerBg": "#FFAE26",
	"menuSellerHover": "#c78920",
	"subMenuSellerBg": "#fda615",
	"subMenuSellerHover": "#cb8511",
	"seller_btn": "linear-gradient(233deg, #FFAE26 40%, #FF652F 100%)",
	"sideBarWidth": "210px"
};