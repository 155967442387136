"use strict";

var _interopRequireDefault = require("D:/shenbimaliang1/sbml-backend/backend-page/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _order = require("./order");
var _OrderSlavePage = _interopRequireDefault(require("@/views/order/orderSlave/OrderSlavePage.vue"));
//
//
//
//
//
//
//
var _default = {
  components: {
    OrderSlavePage: _OrderSlavePage.default
  },
  mixins: [_order.order],
  data: function data() {
    return {
      dialogVisible: false,
      //传给子单窗口的母单
      obj: null,
      //退款订单状态
      isRefund: false
    };
  },
  methods: {
    //执行修改操作
    init: function init(obj) {
      var status = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
      this.obj = obj;
      this.isRefund = status;
      this.dialogVisible = true;
    }
  }
};
exports.default = _default;