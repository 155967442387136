"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.schoolUpdate = exports.schoolPage = exports.schoolExport = exports.schoolDelete = exports.schoolAdd = exports.gradeUpdate = exports.gradePage = exports.gradeExport = exports.gradeDelete = exports.gradeAdd = void 0;
var _request = require("@/utils/request");
//=============================================================学校=======================================================
/**
 * 学校新增
 */
var schoolAdd = function schoolAdd(data) {
  return (0, _request.post)('/school/school/add', data);
};
/**
 * 学校批量删除
 */
exports.schoolAdd = schoolAdd;
var schoolDelete = function schoolDelete(idList) {
  return (0, _request.post)('/school/school/delete?idList=' + idList);
};
/**
 * 学校修改
 */
exports.schoolDelete = schoolDelete;
var schoolUpdate = function schoolUpdate(data) {
  return (0, _request.post)('/school/school/update', data);
};
/**
 * 学校列表
 */
exports.schoolUpdate = schoolUpdate;
var schoolPage = function schoolPage(data) {
  return (0, _request.post)('/school/school/page', data);
};
/**
 * 学校导出
 */
exports.schoolPage = schoolPage;
var schoolExport = function schoolExport(param, name) {
  return (0, _request.exportExcel)('/school/school/export', param, name);
};
//=============================================================年级=======================================================
/**
 * 年级新增
 */
exports.schoolExport = schoolExport;
var gradeAdd = function gradeAdd(data) {
  return (0, _request.post)('/school/grade/add', data);
};
/**
 * 年级批量删除
 */
exports.gradeAdd = gradeAdd;
var gradeDelete = function gradeDelete(idList) {
  return (0, _request.post)('/school/grade/delete?idList=' + idList);
};
/**
 * 年级修改
 */
exports.gradeDelete = gradeDelete;
var gradeUpdate = function gradeUpdate(data) {
  return (0, _request.post)('/school/grade/update', data);
};
/**
 * 年级列表
 */
exports.gradeUpdate = gradeUpdate;
var gradePage = function gradePage(data) {
  return (0, _request.post)('/school/grade/page', data);
};
/**
 * 年级导出
 */
exports.gradePage = gradePage;
var gradeExport = function gradeExport(param, name) {
  return (0, _request.exportExcel)('/school/grade/export', param, name);
};
exports.gradeExport = gradeExport;