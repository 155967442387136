"use strict";

var _interopRequireDefault = require("D:/shenbimaliang1/sbml-backend/backend-page/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _permission = require("@/mixins/permission");
var _PlatformHome = _interopRequireDefault(require("@/views/system/home/PlatformHome.vue"));
var _SellerHome = _interopRequireDefault(require("@/views/system/home/SellerHome.vue"));
var _Feedback = _interopRequireDefault(require("@/views/system/home/Feedback.vue"));
//
//
//
//
//
//
//
//
var _default = {
  name: 'Dashboard',
  components: {
    Feedback: _Feedback.default,
    SellerHome: _SellerHome.default,
    PlatformHome: _PlatformHome.default
  },
  mixins: [_permission.permission]
};
exports.default = _default;