"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.adBuySlaveOrder = void 0;
var adBuySlaveOrder = {
  data: function data() {
    return {
      //是否展示详情窗口
      showDetailDialog: false,
      //挂单角色枚举
      saleRoleMap: {
        0: "广告主",
        1: "合伙人"
      },
      //挂单角色标签颜色枚举
      saleRoleColorMap: {
        0: 'danger',
        1: 'success',
        2: 'danger',
        3: 'warning',
        4: 'info',
        5: 'primary'
      },
      backUrl: '/advertise/adBuySlaveOrder/page',
      entity: {},
      rules: {
        price: [{
          required: true,
          message: '请输入单价'
        }],
        number: [{
          required: true,
          message: '请输入数量'
        }],
        saleId: [{
          required: true,
          message: '请输入挂单ID'
        }],
        money: [{
          required: true,
          message: '请输入金额'
        }],
        masterNo: [{
          required: true,
          message: '请输入母单编号'
        }, {
          max: 255,
          message: '母单编号长度在255个字符内'
        }],
        saleUserId: [{
          required: true,
          message: '请输入挂单用户'
        }],
        saleRole: [{
          required: true,
          message: '请输入挂单角色'
        }]
      }
    };
  }
};
exports.adBuySlaveOrder = adBuySlaveOrder;