"use strict";

var _interopRequireDefault = require("D:/shenbimaliang1/sbml-backend/backend-page/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _advertiseApi = require("@/api/advertise-api");
var _globalDialog = require("@/mixins/global-dialog");
var _permission = require("@/mixins/permission");
var _adBanner = require("./ad-banner");
var _AdBannerAdd = _interopRequireDefault(require("./AdBannerAdd"));
var _AdBannerUpdate = _interopRequireDefault(require("./AdBannerUpdate"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'AdBannerPage',
  components: {
    Pagination: _Pagination.default,
    AdBannerAdd: _AdBannerAdd.default,
    AdBannerUpdate: _AdBannerUpdate.default
  },
  mixins: [_adBanner.adBanner, _globalDialog.pageDialog, _permission.permission],
  methods: {
    //导出
    exportExcel: function exportExcel() {
      (0, _advertiseApi.adBannerExport)(this.pageParam);
    },
    //调用接口查询
    doSearch: function doSearch() {
      return (0, _advertiseApi.adBannerPage)(this.pageParam);
    },
    /**
     * 执行批量删除操作
     * @param {Object} idList id集合
     */
    doBatchDelete: function doBatchDelete(idList) {
      return (0, _advertiseApi.adBannerDelete)(idList);
    }
  }
};
exports.default = _default;