"use strict";

var _interopRequireDefault = require("D:/shenbimaliang1/sbml-backend/backend-page/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _memberApi = require("@/api/member-api");
var _globalDialog = require("@/mixins/global-dialog");
var _permission = require("@/mixins/permission");
var _userMoneyAdLog = require("./user-money-ad-log");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'UserMoneyAdLogPage',
  components: {
    Pagination: _Pagination.default
  },
  mixins: [_userMoneyAdLog.userMoneyAdLog, _globalDialog.pageDialog, _permission.permission],
  methods: {
    //导出
    exportExcel: function exportExcel() {
      (0, _memberApi.userMoneyAdLogExport)(this.pageParam);
    },
    //调用接口查询
    doSearch: function doSearch() {
      return (0, _memberApi.userMoneyAdLogPage)(this.pageParam);
    }
  }
};
exports.default = _default;