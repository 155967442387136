"use strict";

var _interopRequireDefault = require("D:/shenbimaliang1/sbml-backend/backend-page/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _variables2 = _interopRequireDefault(require("@/styles/variables.scss"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'SidebarLogo',
  props: {
    collapse: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    title: function title() {
      return this.isSellerServer() ? '神比孖良商户后台' : '神比孖良平台系统';
    },
    logo: function logo() {
      return 'https://sbml.oss-cn-beijing.aliyuncs.com/static/logo.png';
      // return 'https://wpimg.wallstcn.com/69a1c46c-eb1c-4b46-8bd4-e9e686ef5251.png';
    },
    variables: function variables() {
      return _variables2.default;
    }
  }
};
exports.default = _default;