"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _adTime = require("./ad-time");
var _globalDialog = require("@/mixins/global-dialog");
var _advertiseApi = require("@/api/advertise-api");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  mixins: [_adTime.adTime, _globalDialog.addDialog],
  methods: {
    //执行添加操作
    doAdd: function doAdd() {
      var value = this.entity.value;
      this.entity.startTime = this.formatDate(value[0], "HH:mm");
      this.entity.endTime = this.formatDate(value[1], "HH:mm");
      return (0, _advertiseApi.adTimeAdd)(this.entity);
    }
  }
};
exports.default = _default;