"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ad = void 0;
var ad = {
  data: function data() {
    return {
      //是否展示详情窗口
      showDetailDialog: false,
      //审核状态枚举
      reviewStatusMap: {
        1: '审核通过',
        3: '审核不通过'
      },
      //状态枚举
      statusMap: {
        0: '待审核',
        1: '投放中',
        2: '投放结束',
        3: '审核不通过'
      },
      //状态标签颜色枚举
      statusColorMap: {
        0: 'danger',
        1: 'success',
        2: 'danger',
        3: 'warning',
        4: 'info',
        5: 'primary'
      },
      backUrl: '/advertise/ad-operation/page',
      entity: {},
      rules: {
        name: [{
          required: false,
          message: '请输入名称'
        }, {
          max: 255,
          message: '名称长度在255个字符内'
        }],
        link: [{
          required: false,
          message: '请输入链接'
        }, {
          max: 255,
          message: '链接长度在255个字符内'
        }],
        brief: [{
          required: false,
          message: '请输入描述'
        }, {
          max: 255,
          message: '描述长度在255个字符内'
        }],
        adImg: [{
          required: false,
          message: '请输入横版图片素材'
        }, {
          max: 255,
          message: '横版图片素材长度在255个字符内'
        }],
        seeTimes: [{
          required: false,
          message: '请输入曝光量'
        }],
        clickTimes: [{
          required: false,
          message: '请输入点击量'
        }],
        status: [{
          required: false,
          message: '请输入状态'
        }],
        userId: [{
          required: false,
          message: '请输入用户ID'
        }],
        adCardNum: [{
          required: false,
          message: '请输入广告卡'
        }],
        costAdCardNum: [{
          required: false,
          message: '请输入花费的广告卡'
        }],
        overTime: [{
          required: false,
          message: '请输入撤销时间'
        }],
        reviewStatus: [{
          required: true,
          message: '请选择审核状态'
        }],
        backReason: [{
          required: true,
          message: '请输入不通过理由'
        }, {
          max: 255,
          message: '不通过理由长度在255个字符内'
        }],
        costTime: [{
          required: false,
          message: '请输入花费时长'
        }],
        columnAdImg: [{
          required: false,
          message: '请输入竖版图片素材'
        }, {
          max: 255,
          message: '竖版图片素材长度在255个字符内'
        }],
        portraitId: [{
          required: false,
          message: '请输入肖像权ID'
        }],
        columnAdImgSecond: [{
          required: false,
          message: '请输入竖版图片素材2'
        }, {
          max: 255,
          message: '竖版图片素材2长度在255个字符内'
        }]
      }
    };
  }
};
exports.ad = ad;