"use strict";

var _interopRequireDefault = require("D:/shenbimaliang1/sbml-backend/backend-page/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _checkinApi = require("@/api/checkin-api");
var _globalDialog = require("@/mixins/global-dialog");
var _permission = require("@/mixins/permission");
var _checkinAward = require("./checkin-award");
var _CheckinAwardAdd = _interopRequireDefault(require("./CheckinAwardAdd"));
var _CheckinAwardUpdate = _interopRequireDefault(require("./CheckinAwardUpdate"));
var _CheckinAwardDetail = _interopRequireDefault(require("./CheckinAwardDetail"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'CheckinAwardPage',
  components: {
    Pagination: _Pagination.default,
    CheckinAwardAdd: _CheckinAwardAdd.default,
    CheckinAwardUpdate: _CheckinAwardUpdate.default,
    CheckinAwardDetail: _CheckinAwardDetail.default
  },
  mixins: [_checkinAward.checkinAward, _globalDialog.pageDialog, _permission.permission],
  methods: {
    //导出
    exportExcel: function exportExcel() {
      (0, _checkinApi.checkinAwardExport)(this.pageParam);
    },
    //调用接口查询
    doSearch: function doSearch() {
      return (0, _checkinApi.checkinAwardPage)(this.pageParam);
    },
    /**
     * 执行批量删除操作
     * @param {Object} idList id集合
     */
    doBatchDelete: function doBatchDelete(idList) {
      return (0, _checkinApi.checkinAwardDelete)(idList);
    },
    /**
     * 打开签到奖励详情窗口
     */
    openDetailDialog: function openDetailDialog() {
      var _this = this;
      var selectData = this.getSelect();
      if (this.isEmpty(selectData)) {
        return;
      }
      this.showDetailDialog = true;
      this.$nextTick(function () {
        // 把选中行的数据拷贝一份传递给修改对话框
        selectData = _this.transTimeDia(selectData);
        _this.$refs.detailDialog.setEntity(_this.objectCopy(selectData));
      });
    }
  }
};
exports.default = _default;