var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-form",
        {
          staticClass: "search-form-container",
          attrs: { "label-width": "100px" },
        },
        [
          _c(
            "el-row",
            [
              _c(
                "el-col",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.isShow,
                      expression: "isShow",
                    },
                  ],
                  attrs: { xs: 24, sm: 12, md: 8, lg: 6 },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "商品名称" } },
                    [
                      _c("el-input", {
                        attrs: { clearable: "" },
                        nativeOn: {
                          keyup: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                            return _vm.pageSearch($event)
                          },
                        },
                        model: {
                          value: _vm.pageParam.productTitle,
                          callback: function ($$v) {
                            _vm.$set(_vm.pageParam, "productTitle", $$v)
                          },
                          expression: "pageParam.productTitle",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "page-button-container" },
        [
          _vm.isShow
            ? _c(
                "el-button",
                {
                  staticClass: "search-button",
                  attrs: {
                    type: "info",
                    size: _vm.getButtonSize(),
                    icon: "el-icon-refresh",
                  },
                  on: { click: _vm.reset },
                },
                [_vm._v("重置\n    ")]
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "el-button",
            {
              staticClass: "search-button",
              attrs: {
                type: "primary",
                size: _vm.getButtonSize(),
                icon: "el-icon-check",
              },
              on: { click: _vm.pageSearch },
            },
            [_vm._v("\n      搜索\n    ")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.pageLoading,
              expression: "pageLoading",
            },
          ],
          ref: "elTable",
          attrs: {
            data: _vm.pageResult.records,
            "element-loading-text": "正在查询中...",
            border: "",
            fit: "",
            "highlight-current-row": "",
          },
          on: {
            "sort-change": _vm.sortChange,
            "row-click": _vm.handleRowClick,
            "selection-change": _vm.handleSelectionChange,
          },
        },
        [
          _c("el-table-column", { attrs: { type: "selection", width: "40" } }),
          _vm._v(" "),
          _vm.isShow
            ? _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "商品ID",
                  prop: "productId",
                  "show-overflow-tooltip": true,
                },
              })
            : _vm._e(),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              "min-width": "150",
              label: "商品名称",
              prop: "productTitle",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", { staticStyle: { "font-size": "10px" } }, [
                      _vm._v(_vm._s(scope.row.productTitle)),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "图片",
              prop: "image",
              "show-overflow-tooltip": true,
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("table-img", { attrs: { imgUrl: scope.row.image } }),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              "min-width": "120",
              label: "规格",
              prop: "name",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return _vm._l(
                    _vm.isNotEmpty(scope.row.name)
                      ? scope.row.name.split(" ")
                      : [],
                    function (item, key) {
                      return _c(
                        "el-tag",
                        {
                          key: key,
                          staticStyle: { "margin-left": "5px" },
                          attrs: { type: "primary" },
                        },
                        [
                          _vm._v(
                            _vm._s(scope.row.title.split(" ")[key]) +
                              "：" +
                              _vm._s(item) +
                              "\n        "
                          ),
                        ]
                      )
                    }
                  )
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              "min-width": "100",
              label: "售价/市场价",
              prop: "marketPrice",
              "show-overflow-tooltip": true,
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.price !== scope.row.marketPrice
                      ? _c("div", [
                          _c(
                            "span",
                            {
                              staticStyle: {
                                "font-size": "14px",
                                color: "#f50d0d",
                              },
                            },
                            [_vm._v("￥" + _vm._s(scope.row.price))]
                          ),
                          _vm._v("/"),
                          _c(
                            "span",
                            {
                              staticStyle: {
                                "font-size": "12px",
                                "text-decoration": "line-through",
                              },
                            },
                            [_vm._v("￥" + _vm._s(scope.row.marketPrice))]
                          ),
                        ])
                      : _c("div", [
                          _c(
                            "span",
                            {
                              staticStyle: {
                                "font-size": "14px",
                                color: "#f50d0d",
                              },
                            },
                            [_vm._v("￥" + _vm._s(scope.row.price))]
                          ),
                        ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              "min-width": "80",
              label: "成本价",
              prop: "basePrice",
              "show-overflow-tooltip": true,
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "span",
                      {
                        staticStyle: { "font-size": "14px", color: "#6cce2e" },
                      },
                      [_vm._v("￥" + _vm._s(scope.row.basePrice))]
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "库存",
              prop: "stock",
              "show-overflow-tooltip": true,
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("pagination", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.pageResult.total > 0,
            expression: "pageResult.total > 0",
          },
        ],
        attrs: {
          total: _vm.pageResult.total,
          layout: _vm.getLayout(),
          page: _vm.pageParam.pageNumber,
          limit: _vm.pageParam.pageSize,
        },
        on: {
          "update:page": function ($event) {
            return _vm.$set(_vm.pageParam, "pageNumber", $event)
          },
          "update:limit": function ($event) {
            return _vm.$set(_vm.pageParam, "pageSize", $event)
          },
          pagination: _vm.pageSearch,
        },
      }),
      _vm._v(" "),
      _vm.showAddDialog
        ? _c("sku-add", {
            attrs: { "dialog-width": _vm.getDialogWidth() },
            on: { close: _vm.closeAddDialog, refreshTable: _vm.refreshTable },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.showUpdateDialog
        ? _c("sku-update", {
            ref: "updateDialog",
            attrs: { "dialog-width": _vm.getDialogWidth() },
            on: {
              close: _vm.closeUpdateDialog,
              refreshTable: _vm.refreshTable,
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.showDetailDialog
        ? _c("sku-detail", {
            ref: "detailDialog",
            attrs: { "dialog-width": _vm.getDialogWidth() },
            on: {
              close: function ($event) {
                _vm.showDetailDialog = false
              },
              refreshTable: _vm.refreshTable,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }