"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _teacherRace = require("./teacher-race");
var _globalDialog = require("@/mixins/global-dialog");
var _teacherApi = require("@/api/teacher-api");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  mixins: [_teacherRace.teacherRace, _globalDialog.addDialog],
  methods: {
    //执行添加操作
    doAdd: function doAdd() {
      return (0, _teacherApi.teacherRaceAdd)(this.entity);
    }
  }
};
exports.default = _default;