"use strict";

var _interopRequireDefault = require("D:/shenbimaliang1/sbml-backend/backend-page/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _adMasterApi = require("@/api/ad-master-api");
var _globalDialog = require("@/mixins/global-dialog");
var _permission = require("@/mixins/permission");
var _adUser = require("./ad-user");
var _AdUserReview = _interopRequireDefault(require("./AdUserReview.vue"));
var _AdUserDetail = _interopRequireDefault(require("./AdUserDetail"));
var _AdUserRecharge = _interopRequireDefault(require("@/views/ad-master/adUser/AdUserRecharge.vue"));
var _UserRecharge = _interopRequireDefault(require("@/views/member/user/UserRecharge.vue"));
var _AdUserReview2 = _interopRequireDefault(require("@/views/ad-master/adUser/AdUserReview.vue"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'AdUserPage',
  components: {
    AdUserReview: _AdUserReview2.default,
    UserRecharge: _UserRecharge.default,
    AdUserRecharge: _AdUserRecharge.default,
    Pagination: _Pagination.default,
    AdUserUpdate: _AdUserReview.default,
    AdUserDetail: _AdUserDetail.default
  },
  mixins: [_adUser.adUser, _globalDialog.pageDialog, _permission.permission],
  methods: {
    //导出
    exportExcel: function exportExcel() {
      (0, _adMasterApi.adUserExport)(this.pageParam);
    },
    //调用接口查询
    doSearch: function doSearch() {
      return (0, _adMasterApi.adUserPage)(this.pageParam);
    },
    /**
     * 执行批量删除操作
     * @param {Object} idList id集合
     */
    doBatchDelete: function doBatchDelete(idList) {
      return (0, _adMasterApi.adUserDelete)(idList);
    },
    /**
     * 打开广告主详情窗口
     */
    openDetailDialog: function openDetailDialog() {
      var _this = this;
      var selectData = this.getSelect();
      if (this.isEmpty(selectData)) {
        return;
      }
      this.showDetailDialog = true;
      this.$nextTick(function () {
        // 把选中行的数据拷贝一份传递给修改对话框
        selectData = _this.transTimeDia(selectData);
        _this.$refs.detailDialog.setEntity(_this.objectCopy(selectData));
      });
    },
    /**
     * 打开充值窗口
     */
    openRechargeDialog: function openRechargeDialog() {
      var _this2 = this;
      var selectData = this.getSelect();
      if (this.isEmpty(selectData)) {
        return;
      }
      this.showRechargeDialog = true;
      this.$nextTick(function () {
        // 把选中行的数据拷贝一份传递给修改对话框
        selectData = _this2.transTimeDia(selectData);
        _this2.$refs.rechargeDialog.setEntity(_this2.objectCopy(selectData));
      });
    },
    /**
     * 打开审核窗口
     */
    openReviewDialog: function openReviewDialog() {
      var _this3 = this;
      var selectData = this.getSelect();
      if (this.isEmpty(selectData)) {
        return;
      }
      this.showReviewDialog = true;
      selectData.backReason = null;
      this.$nextTick(function () {
        // 把选中行的数据拷贝一份传递给修改对话框
        selectData = _this3.transTimeDia(selectData);
        _this3.$refs.reviewDialog.setEntity(_this3.objectCopy(selectData));
      });
    }
  }
};
exports.default = _default;