"use strict";

var _interopRequireDefault = require("D:/shenbimaliang1/sbml-backend/backend-page/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _interopRequireWildcard2 = _interopRequireDefault(require("D:/shenbimaliang1/sbml-backend/backend-page/node_modules/@babel/runtime/helpers/interopRequireWildcard.js"));
/**
 * 校园管理路由配置
 */
var schoolRouter = [{
  path: 'school/page',
  name: 'SchoolPage',
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/views/school/school/SchoolPage'));
    });
  },
  meta: {
    title: '学校列表'
  }
}, {
  path: 'grade/page',
  name: 'GradePage',
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/views/school/grade/GradePage'));
    });
  },
  meta: {
    title: '年级列表'
  }
}];
var _default = schoolRouter;
exports.default = _default;