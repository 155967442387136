var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "跳绳记录详情",
        visible: _vm.dialogVisible,
        "close-on-click-modal": false,
        center: "",
        width: "50%",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
        close: _vm.closeDialog,
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "elForm",
          staticClass: "dialog-form",
          attrs: {
            model: _vm.entity,
            rules: _vm.rules,
            inline: true,
            "label-width": _vm.getLabelWidth(),
          },
        },
        [
          _c(
            "el-descriptions",
            {
              staticClass: "margin-top",
              attrs: {
                title: "学校信息",
                "label-style": { width: "120px" },
                column: _vm.isPc() ? 2 : 1,
                size: "medium",
                border: "",
              },
            },
            [
              _c(
                "el-descriptions-item",
                [
                  _c("template", { slot: "label" }, [
                    _c("i", { staticClass: "el-icon-school" }),
                    _vm._v("\n          学校地址\n        "),
                  ]),
                  _vm._v(
                    "\n        " +
                      _vm._s(
                        _vm.entity.province +
                          _vm.entity.city +
                          _vm.entity.district +
                          _vm.entity.school
                      ) +
                      "\n      "
                  ),
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "el-descriptions-item",
                [
                  _c("template", { slot: "label" }, [
                    _c("i", { staticClass: "el-icon-school" }),
                    _vm._v("\n          班级\n        "),
                  ]),
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.entity.grade + _vm.entity.classes) +
                      "\n      "
                  ),
                ],
                2
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-descriptions",
            {
              staticClass: "margin-top",
              attrs: {
                title: "跳绳数据",
                "label-style": { width: "120px" },
                column: _vm.isPc() ? 4 : 1,
                size: "medium",
                border: "",
              },
            },
            [
              _c(
                "el-descriptions-item",
                [
                  _c("template", { slot: "label" }, [
                    _c("i", { staticClass: "el-icon-postcard" }),
                    _vm._v("\n          类型\n        "),
                  ]),
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.typeMap[_vm.entity.type]) +
                      "\n      "
                  ),
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "el-descriptions-item",
                [
                  _c("template", { slot: "label" }, [
                    _c("i", { staticClass: "el-icon-postcard" }),
                    _vm._v("\n          秒数\n        "),
                  ]),
                  _vm._v(
                    "\n        " + _vm._s(_vm.entity.seconds) + "秒\n      "
                  ),
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "el-descriptions-item",
                [
                  _c("template", { slot: "label" }, [
                    _c("i", { staticClass: "el-icon-postcard" }),
                    _vm._v("\n          个数\n        "),
                  ]),
                  _vm._v(
                    "\n        " + _vm._s(_vm.entity.total) + "个\n      "
                  ),
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "el-descriptions-item",
                [
                  _c("template", { slot: "label" }, [
                    _c("i", { staticClass: "el-icon-postcard" }),
                    _vm._v("\n          速率\n        "),
                  ]),
                  _vm._v(
                    "\n        " + _vm._s(_vm.entity.rate) + "个/秒\n      "
                  ),
                ],
                2
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "form-button-container" },
        [
          _c(
            "el-button",
            {
              attrs: { type: "danger", icon: "el-icon-back" },
              on: {
                click: function ($event) {
                  _vm.dialogVisible = false
                },
              },
            },
            [_vm._v("返回")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }