var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "fullScreen" }, [
    _c("i", {
      staticClass: "el-icon-full-screen",
      on: { click: _vm.changeStatus },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }