"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.regionDataPlus = exports.regionData = exports.provinceAndCityDataPlus = exports.provinceAndCityData = exports.TextToCode = exports.CodeToText = void 0;
var _chinaAreaData = require("./china-area-data");
var _lodashEs = require("lodash-es");
// code转汉字大对象
var CodeToText = {};
// 汉字转code大对象
exports.CodeToText = CodeToText;
var TextToCode = {};
exports.TextToCode = TextToCode;
var provinceObject = _chinaAreaData.REGION_DATA['86']; // 省份对象
var regionData = [];
exports.regionData = regionData;
var provinceAndCityData = [];
exports.provinceAndCityData = provinceAndCityData;
CodeToText[''] = '全部';

// 计算省
for (var prop in provinceObject) {
  regionData.push({
    value: prop,
    // 省份code值
    label: provinceObject[prop] // 省份汉字
  });

  CodeToText[prop] = provinceObject[prop];
  TextToCode[provinceObject[prop]] = {
    code: prop
  };
  TextToCode[provinceObject[prop]]['全部'] = {
    code: ''
  };
}

// 计算市
for (var i = 0, len = regionData.length; i < len; i++) {
  var provinceCode = regionData[i].value;
  var provinceText = regionData[i].label;
  var provinceChildren = [];
  for (var _prop in _chinaAreaData.REGION_DATA[provinceCode]) {
    provinceChildren.push({
      value: _prop,
      label: _chinaAreaData.REGION_DATA[provinceCode][_prop]
    });
    CodeToText[_prop] = _chinaAreaData.REGION_DATA[provinceCode][_prop];
    TextToCode[provinceText][_chinaAreaData.REGION_DATA[provinceCode][_prop]] = {
      code: _prop
    };
    TextToCode[provinceText][_chinaAreaData.REGION_DATA[provinceCode][_prop]]['全部'] = {
      code: ''
    };
  }
  if (provinceChildren.length) {
    regionData[i].children = provinceChildren;
  }
}
exports.provinceAndCityData = provinceAndCityData = (0, _lodashEs.cloneDeep)(regionData);

// 计算区
for (var _i = 0, _len = regionData.length; _i < _len; _i++) {
  var province = regionData[_i].children;
  var _provinceText = regionData[_i].label;
  if (province) {
    for (var j = 0, _len2 = province.length; j < _len2; j++) {
      var cityCode = province[j].value;
      var cityText = province[j].label;
      var cityChildren = [];
      for (var _prop2 in _chinaAreaData.REGION_DATA[cityCode]) {
        cityChildren.push({
          value: _prop2,
          label: _chinaAreaData.REGION_DATA[cityCode][_prop2]
        });
        CodeToText[_prop2] = _chinaAreaData.REGION_DATA[cityCode][_prop2];
        TextToCode[_provinceText][cityText][_chinaAreaData.REGION_DATA[cityCode][_prop2]] = {
          code: _prop2
        };
      }
      if (cityChildren.length) {
        province[j].children = cityChildren;
      }
    }
  }
}

// 添加“全部”选项
var provinceAndCityDataPlus = (0, _lodashEs.cloneDeep)(provinceAndCityData);
exports.provinceAndCityDataPlus = provinceAndCityDataPlus;
provinceAndCityDataPlus.unshift({
  value: '',
  label: '全部'
});
for (var _i2 = 0, _len3 = provinceAndCityDataPlus.length; _i2 < _len3; _i2++) {
  var _province = provinceAndCityDataPlus[_i2].children;
  if (_province && _province.length) {
    _province.unshift({
      value: '',
      label: '全部'
    });
    for (var _j = 0, _len4 = _province.length; _j < _len4; _j++) {
      var city = _province[_j].children;
      if (city && city.length) {
        city.unshift({
          value: '',
          label: '全部'
        });
      }
    }
  }
}
var regionDataPlus = (0, _lodashEs.cloneDeep)(regionData);
exports.regionDataPlus = regionDataPlus;
regionDataPlus.unshift({
  value: '',
  label: '全部'
});
for (var _i3 = 0, _len5 = regionDataPlus.length; _i3 < _len5; _i3++) {
  var _province2 = regionDataPlus[_i3].children;
  if (_province2 && _province2.length) {
    _province2.unshift({
      value: '',
      label: '全部'
    });
    for (var _j2 = 0, _len6 = _province2.length; _j2 < _len6; _j2++) {
      var _city = _province2[_j2].children;
      if (_city && _city.length) {
        _city.unshift({
          value: '',
          label: '全部'
        });
      }
    }
  }
}