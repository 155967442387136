import { render, staticRenderFns } from "./TeacherApplyDetail.vue?vue&type=template&id=15ad44ae&scoped=true&"
import script from "./TeacherApplyDetail.vue?vue&type=script&lang=js&"
export * from "./TeacherApplyDetail.vue?vue&type=script&lang=js&"
import style0 from "./TeacherApplyDetail.vue?vue&type=style&index=0&id=15ad44ae&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "15ad44ae",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\shenbimaliang1\\sbml-backend\\backend-page\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('15ad44ae')) {
      api.createRecord('15ad44ae', component.options)
    } else {
      api.reload('15ad44ae', component.options)
    }
    module.hot.accept("./TeacherApplyDetail.vue?vue&type=template&id=15ad44ae&scoped=true&", function () {
      api.rerender('15ad44ae', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/teacher/teacherApply/TeacherApplyDetail.vue"
export default component.exports