"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.operateLog = void 0;
var operateLog = {
  data: function data() {
    return {
      backUrl: '/system/operateLog/page',
      entity: {},
      rules: {
        ip: [{
          required: true,
          message: '请输入ip地址'
        }, {
          max: 30,
          message: 'ip地址长度在30个字符内'
        }],
        userId: [{
          required: true,
          message: '请输入用户ID'
        }],
        className: [{
          required: true,
          message: '请输入类名'
        }, {
          max: 100,
          message: '类名长度在100个字符内'
        }],
        methodName: [{
          required: true,
          message: '请输入方法名'
        }, {
          max: 100,
          message: '方法名长度在100个字符内'
        }],
        requestParam: [{
          required: true,
          message: '请输入请求入参'
        }, {
          max: 65535,
          message: '请求入参长度在65535个字符内'
        }],
        returnCode: [{
          required: true,
          message: '请输入返回码'
        }, {
          max: 20,
          message: '返回码长度在20个字符内'
        }],
        returnMessage: [{
          required: true,
          message: '请输入返回消息提示'
        }, {
          max: 100,
          message: '返回消息提示长度在100个字符内'
        }],
        operateTime: [{
          required: true,
          message: '请输入操作时间'
        }],
        requestUrl: [{
          required: true,
          message: '请输入请求url'
        }, {
          max: 100,
          message: '请求url长度在100个字符内'
        }],
        methodDesc: [{
          required: true,
          message: '请输入执行的方法描述'
        }, {
          max: 50,
          message: '执行的方法描述长度在50个字符内'
        }],
        moduleName: [{
          required: true,
          message: '请输入执行的模块名称'
        }, {
          max: 50,
          message: '执行的模块名称长度在50个字符内'
        }],
        serviceName: [{
          required: true,
          message: '请输入服务名称'
        }, {
          max: 20,
          message: '服务名称长度在20个字符内'
        }]
      }
    };
  }
};
exports.operateLog = operateLog;