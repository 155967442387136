"use strict";

var _interopRequireDefault = require("D:/shenbimaliang1/sbml-backend/backend-page/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _interopRequireWildcard2 = _interopRequireDefault(require("D:/shenbimaliang1/sbml-backend/backend-page/node_modules/@babel/runtime/helpers/interopRequireWildcard.js"));
/**
 * 广告主管理路由配置
 */
var adMasterRouter = [{
  path: 'adUser/page',
  name: 'AdUserPage',
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/views/ad-master/adUser/AdUserPage'));
    });
  },
  meta: {
    title: '广告主列表'
  }
}, {
  path: 'adUserBank/page',
  name: 'AdUserBankPage',
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/views/ad-master/adUserBank/AdUserBankPage'));
    });
  },
  meta: {
    title: '广告主银行卡列表'
  }
}, {
  path: 'adUserMoneyAdLog/page',
  name: 'AdUserMoneyAdLogPage',
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/views/ad-master/adUserMoneyAdLog/AdUserMoneyAdLogPage'));
    });
  },
  meta: {
    title: '广告主资金明细-广告卡列表'
  }
}, {
  path: 'adUserMoneyBalanceLog/page',
  name: 'AdUserMoneyBalanceLogPage',
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/views/ad-master/adUserMoneyBalanceLog/AdUserMoneyBalanceLogPage'));
    });
  },
  meta: {
    title: '广告主资金明细-余额列表'
  }
}, {
  path: 'adWithdrawLog/page',
  name: 'AdWithdrawLogPage',
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/views/ad-master/adWithdrawLog/AdWithdrawLogPage'));
    });
  },
  meta: {
    title: '广告主提现列表'
  }
}];
var _default = adMasterRouter;
exports.default = _default;