var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "学校年级列表",
        visible: _vm.dialogVisible,
        "close-on-click-modal": false,
        center: "",
        width: "60%",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
        close: function ($event) {
          _vm.dialogVisible = false
        },
      },
    },
    [
      _vm.dialogVisible
        ? _c("grade-page", {
            attrs: { schoolId: _vm.schoolId, "is-show": false },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }