"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.version = void 0;
var version = {
  data: function data() {
    return {
      //是否展示详情窗口
      showDetailDialog: false,
      //平台枚举
      platformMap: {
        'android': '安卓',
        'ios': '苹果'
      },
      //平台标签颜色枚举
      platformColorMap: {
        0: 'danger',
        1: 'success',
        2: 'danger',
        3: 'warning',
        4: 'info',
        5: 'primary'
      },
      //是否强制更新枚举
      editionForceMap: {
        0: '否',
        1: '是'
      },
      //是否强制更新颜色枚举
      editionForceColorMap: {
        0: 'danger',
        1: 'success',
        2: 'danger',
        3: 'warning',
        4: 'info',
        5: 'primary'
      },
      backUrl: '/operation/version/page',
      entity: {},
      rules: {
        platform: [{
          required: true,
          message: '请输入平台'
        }, {
          max: 255,
          message: '平台长度在255个字符内'
        }],
        v: [{
          required: true,
          message: '请输入版本'
        }, {
          max: 50,
          message: '版本长度在50个字符内'
        }],
        content: [{
          required: true,
          message: '请输入更新内容'
        }, {
          max: 255,
          message: '更新内容长度在255个字符内'
        }],
        editionForce: [{
          required: true,
          message: '请输入是否需要强制更新'
        }]
      }
    };
  }
};
exports.version = version;