"use strict";

var _interopRequireDefault = require("D:/shenbimaliang1/sbml-backend/backend-page/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.REGION_DATA = void 0;
var _slicedToArray2 = _interopRequireDefault(require("D:/shenbimaliang1/sbml-backend/backend-page/node_modules/@babel/runtime/helpers/slicedToArray.js"));
var _toConsumableArray2 = _interopRequireDefault(require("D:/shenbimaliang1/sbml-backend/backend-page/node_modules/@babel/runtime/helpers/toConsumableArray.js"));
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.string.iterator");
require("core-js/modules/es6.map");
var _areadata = _interopRequireDefault(require("@/mixins/areadata"));
var REGION_DATA = {};
exports.REGION_DATA = REGION_DATA;
var map = new Map();
var map1 = new Map();
_areadata.default.forEach(function (item) {
  //获取 省的value - label
  map1.set(item.value, item.label);

  //用于收集 每个省里面的市
  var map2 = new Map();
  //遍历每个省中的市
  item.children.forEach(function (item2) {
    //将每个省中的市 收集到map
    map2.set(item2.value, item2.label);

    //用于收集每个市中的区
    var map3 = new Map();

    //遍历每个市中的区
    item2.children.forEach(function (item3) {
      //将每个市中的区收集到map
      map3.set(item3.value, item3.label);
    });

    //市code - 区[{...}] 将收集到的 每个市所对应的区，封装到主map key为 对应的市的value
    // map2.set(item2.value, map3);
    map.set(item2.value, (0, _toConsumableArray2.default)(map3.entries()).reduce(function (obj, _ref) {
      var _ref2 = (0, _slicedToArray2.default)(_ref, 2),
        key = _ref2[0],
        value = _ref2[1];
      return obj[key] = value, obj;
    }, {}));
    // map2.set(item2.value, JSON.stringify([...map3]));
  });

  //省code - 市[{...}] //将收集到的 每个省所对应的市，封装到主map key为 对应的省的value
  map.set(item.value, (0, _toConsumableArray2.default)(map2.entries()).reduce(function (obj, _ref3) {
    var _ref4 = (0, _slicedToArray2.default)(_ref3, 2),
      key = _ref4[0],
      value = _ref4[1];
    return obj[key] = value, obj;
  }, {}));
  // map.set(item.value, JSON.stringify([...map2]));
});
// map.set('86', JSON.stringify([...map1]));
map.set('86', (0, _toConsumableArray2.default)(map1.entries()).reduce(function (obj, _ref5) {
  var _ref6 = (0, _slicedToArray2.default)(_ref5, 2),
    key = _ref6[0],
    value = _ref6[1];
  return obj[key] = value, obj;
}, {}));
exports.REGION_DATA = REGION_DATA = (0, _toConsumableArray2.default)(map.entries()).reduce(function (obj, _ref7) {
  var _ref8 = (0, _slicedToArray2.default)(_ref7, 2),
    key = _ref8[0],
    value = _ref8[1];
  return obj[key] = value, obj;
}, {});
// REGION_DATA=JSON.stringify(REGION_DATA)