var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isNotEmpty(_vm.imgUrl)
    ? _c(
        "el-popover",
        {
          attrs: {
            placement: "right",
            trigger: "click",
            width: _vm.isPc() ? "400" : "100%",
          },
        },
        [
          _c("el-image", {
            staticStyle: { "max-height": "100px", "max-width": "100px" },
            attrs: { slot: "reference", src: _vm.getFullImg(_vm.imgUrl) },
            slot: "reference",
          }),
          _vm._v(" "),
          _c("el-image", {
            staticStyle: {
              width: "400px",
              "max-width": "100%",
              margin: "auto",
            },
            attrs: { src: _vm.getFullImg(_vm.imgUrl) },
          }),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }