"use strict";

var _interopRequireDefault = require("D:/shenbimaliang1/sbml-backend/backend-page/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.split");
require("core-js/modules/es6.number.constructor");
var _vuedraggable = _interopRequireDefault(require("vuedraggable"));
var _lrz = _interopRequireDefault(require("lrz"));
var _validImgUpload = require("./validImgUpload.js");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// 前端图片压缩插件
var _default2 = {
  name: 'MultiImgUpload',
  components: {
    vuedraggable: _vuedraggable.default
  },
  props: {
    //
    isDisabled: {
      type: Boolean,
      default: false
    },
    // 图片数据(图片url组成的数组) 通过v-model传递
    value: {
      type: String,
      default: function _default() {
        return '';
      }
    },
    // 限制上传的图片数量
    limit: {
      type: Number,
      default: 99
    },
    // 限制上传图片的文件大小(kb)
    size: {
      type: Number,
      default: 1024 * 10
    },
    // 是否是单图上传(单图上传就是已传图片和上传按钮重叠)
    isSingle: {
      type: Boolean,
      default: false
    },
    // 是否使用图片压缩
    useCompress: {
      type: Boolean,
      default: false
    },
    // 图片显示的宽度(px)
    width: {
      type: Number,
      default: 100
    },
    // 图片显示的高度(px)
    height: {
      type: Number,
      default: 100
    }
  },
  data: function data() {
    return {
      headers: {},
      isUploading: false,
      // 正在上传状态
      isFirstMount: true // 控制防止重复回显
    };
  },

  computed: {
    // 图片数组数据
    imgList: {
      get: function get() {
        if (this.isEmpty(this.value)) {
          return [];
        }
        return this.value.split(',');
      },
      set: function set(val) {
        if (val.length < this.imgList.length) {
          // 判断是删除图片时同步el-upload数据
          this.syncElUpload(val);
        }
        // 同步v-model
        this.$emit('input', val.length === 0 ? '' : val.join(','));
      }
    },
    // 控制达到最大限制时隐藏上传按钮
    isMaxHidden: function isMaxHidden() {
      return this.imgList.length >= this.limit;
    }
  },
  watch: {
    value: {
      handler: function handler(val) {
        if (this.isFirstMount && this.isNotEmpty(this.value)) {
          this.syncElUpload();
        }
      },
      deep: true
    }
  },
  mounted: function mounted() {
    if (this.isNotEmpty(this.value)) {
      this.syncElUpload();
    }
  },
  methods: {
    // 同步el-upload数据
    syncElUpload: function syncElUpload(val) {
      var imgList = val || this.imgList;
      this.$refs.uploadRef.uploadFiles = imgList.map(function (v, i) {
        return {
          name: 'pic' + i,
          url: v,
          status: 'success',
          uid: (0, _validImgUpload.createUniqueString)()
        };
      });
      this.isFirstMount = false;
    },
    // 上传图片之前
    beforeUpload: function beforeUpload(file) {
      var _this = this;
      this.isFirstMount = false;
      if (this.useCompress) {
        // 图片压缩
        return new Promise(function (resolve, reject) {
          (0, _lrz.default)(file, {
            width: 1920
          }).then(function (rst) {
            file = rst.file;
          }).always(function () {
            if ((0, _validImgUpload.validImgUpload)(file, _this.size)) {
              _this.isUploading = true;
              resolve();
            } else {
              reject(new Error());
            }
          });
        });
      } else {
        if ((0, _validImgUpload.validImgUpload)(file, this.size)) {
          this.isUploading = true;
          return true;
        } else {
          return false;
        }
      }
    },
    // 上传完单张图片,每上传完一张图片都会调该方法
    onSuccessUpload: function onSuccessUpload(res, file, fileList) {
      if (res.data[0]) {
        if (this.imgList.length < this.limit) {
          this.imgList.push(res.data[0]);
          //重新赋值，目的是调用computer里面的set方法
          this.imgList = this.imgList;
        }
      } else {
        this.syncElUpload();
        this.$message({
          type: 'error',
          message: res.msg
        });
      }
      this.isUploading = false;
    },
    // 移除单张图片
    onRemoveHandler: function onRemoveHandler(index) {
      var _this2 = this;
      this.$confirm('确定删除该图片?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        _this2.imgList = _this2.imgList.filter(function (v, i) {
          return i !== index;
        });
      }).catch(function () {});
    },
    // 超限
    onExceed: function onExceed() {
      this.$refs.uploadRef.abort(); // 取消剩余接口请求
      this.syncElUpload();
      this.$message({
        type: 'warning',
        message: "\u56FE\u7247\u8D85\u9650\uFF0C\u6700\u591A\u53EF\u4E0A\u4F20".concat(this.limit, "\u5F20\u56FE\u7247")
      });
    },
    onDragStart: function onDragStart(e) {
      e.target.classList.add('hideShadow');
    },
    onDragEnd: function onDragEnd(e) {
      e.target.classList.remove('hideShadow');
    }
  }
};
exports.default = _default2;