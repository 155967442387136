"use strict";

var _interopRequireDefault = require("D:/shenbimaliang1/sbml-backend/backend-page/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es7.string.pad-start");
require("core-js/modules/es6.regexp.to-string");
require("core-js/modules/es6.array.find");
require("core-js/modules/es6.regexp.split");
require("core-js/modules/es6.number.constructor");
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.regexp.constructor");
require("core-js/modules/es6.reflect.own-keys");
var _toConsumableArray2 = _interopRequireDefault(require("D:/shenbimaliang1/sbml-backend/backend-page/node_modules/@babel/runtime/helpers/toConsumableArray.js"));
var _typeof2 = _interopRequireDefault(require("D:/shenbimaliang1/sbml-backend/backend-page/node_modules/@babel/runtime/helpers/typeof.js"));
var _objectSpread2 = _interopRequireDefault(require("D:/shenbimaliang1/sbml-backend/backend-page/node_modules/@babel/runtime/helpers/objectSpread2.js"));
require("core-js/modules/es6.regexp.replace");
var _elementUi = require("element-ui");
var _jsCookie = _interopRequireDefault(require("js-cookie"));
var _fileSaver = _interopRequireDefault(require("file-saver"));
var _xlsx = _interopRequireDefault(require("xlsx"));
// 引入导出Excel表格依赖
var _default = {
  install: function install(Vue) {
    /**
     * 文件上传接口
     */
    Vue.prototype.uploadImage = process.env.VUE_APP_BASE_API + '/system/file/uploadImage';
    Vue.prototype.uploadApp = '/system/file/uploadApp';
    /**
     * 文件预导入
     */
    Vue.prototype.uploadImportUrl = '/backend/user/wallet/readExcel';
    /**
     * oss域名
     */
    Vue.prototype.ossDomain = 'https://sbml.oss-cn-beijing.aliyuncs.com';
    /**
     * 接口返回成功的返回码
     */
    Vue.prototype.successCode = 200;
    /**
     * 接口返回异常码
     */
    Vue.prototype.exceptionCode = 404;
    /**
     * form表单参数校验失败的返回码
     */
    Vue.prototype.validateFailCode = 500;
    /**
     * form表单参数校验成功的返回码
     */
    Vue.prototype.validateSuccessCode = 200;
    /**
     * 判断接口返回是否返回成功
     */
    Vue.prototype.isSuccess = function (result) {
      // 如果接口返回失败就弹出提示并返回false
      if (result.code !== Vue.prototype.successCode) {
        (0, _elementUi.Message)({
          message: result.message,
          type: 'error',
          duration: 3000,
          showClose: true
        });
        return false;
      } else {
        return true;
      }
    };
    // 根据接口返回的code来弹出提示信息,并返回true或false
    Vue.prototype.resultMessage = function (result) {
      var type = 'success';
      var resultCode = true;
      var duration = 3000; // 成功提示3s,失败提示3s
      if (result.code !== Vue.prototype.successCode) {
        type = 'error';
        resultCode = false;
        duration = 3000;
      }
      // 接口返回异常码就不要在弹出提示，因为已经在全局请求拦截器中弹出了
      if (result.code !== Vue.prototype.exceptionCode) {
        (0, _elementUi.Message)({
          message: result.message,
          type: type,
          duration: duration,
          showClose: true
        });
      }
      return resultCode;
    };
    // 根据接口返回的code来弹出提示信息,并返回true或false
    Vue.prototype.resultNotify = function (result) {
      var type = 'success';
      var resultCode = true;
      var duration = 3000; // 成功提示3s,失败提示3s
      if (result.code !== Vue.prototype.successCode) {
        type = 'error';
        resultCode = false;
        duration = 3000;
      }
      // 接口返回异常码就不要在弹出提示，因为已经在全局请求拦截器中弹出了
      if (result.code !== Vue.prototype.exceptionCode) {
        (0, _elementUi.Notification)({
          message: result.message,
          type: type,
          duration: duration,
          showClose: true
        });
      }
      return resultCode;
    };
    Vue.prototype.errorMessage = function (message) {
      !Vue.prototype.isSellerServer() ? (0, _elementUi.Message)({
        message: message,
        type: 'error',
        duration: 3000,
        showClose: true
      }) : (0, _elementUi.Notification)({
        type: 'error',
        title: '错误',
        message: message,
        duration: 3000
      });
    };
    Vue.prototype.errorNotify = function (message) {
      Vue.prototype.isSellerServer() ? (0, _elementUi.Notification)({
        type: 'error',
        title: '错误',
        message: message,
        duration: 3000
      }) : (0, _elementUi.Message)({
        message: message,
        type: 'error',
        duration: 3000,
        showClose: true
      });
    };
    /**
     * 成功的消息提示
     */
    Vue.prototype.successMessage = function (message) {
      !Vue.prototype.isSellerServer() ? (0, _elementUi.Message)({
        message: message,
        type: 'success',
        duration: 3000,
        showClose: true
      }) : (0, _elementUi.Notification)({
        type: 'success',
        title: '成功',
        message: message,
        duration: 3000
      });
      ;
    };
    Vue.prototype.successNotify = function (message) {
      Vue.prototype.isSellerServer() ? (0, _elementUi.Notification)({
        type: 'success',
        title: '成功',
        message: message,
        duration: 3000
      }) : (0, _elementUi.Message)({
        message: message,
        type: 'success',
        duration: 3000,
        showClose: true
      });
    };
    Vue.prototype.warningNotify = function (message) {
      (0, _elementUi.Notification)({
        type: 'warning',
        message: message,
        duration: 3000
      });
    };
    /**
     * 判断是否为空
     */
    Vue.prototype.isEmpty = function (val) {
      if (typeof val === 'string') {
        val = val.replace(/(^\s*)|(\s*$)/g, ''); // 去掉两边的空格
      }

      if (typeof val === 'boolean') {
        return false;
      }
      if (typeof val === 'number') {
        return false;
      }
      if (val instanceof Array) {
        if (val.length === 0) return true;
      } else if (val instanceof Object) {
        if (JSON.stringify(val) === '{}') return true;
      } else {
        return val === 'null' || val == null || val === 'undefined' || val === undefined || val === '';
      }
      return false;
    };
    /**
     * 判断是否不为空
     */
    Vue.prototype.isNotEmpty = function (val) {
      return !Vue.prototype.isEmpty(val);
    };
    /**
     * 判断变量是否未定义,true 未定义
     */
    Vue.prototype.isUndefined = function (obj) {
      if (typeof obj === 'undefined') {
        return true;
      } else {
        return false;
      }
    };
    // 获取图片全路径
    Vue.prototype.getFullImg = function (imgUrl) {
      if (imgUrl.substring(0, 4) === 'http') {
        return imgUrl;
      } else {
        return Vue.prototype.ossDomain + imgUrl;
      }
    };
    // 导出Excel
    Vue.prototype.exportExcel = function (obj) {
      var fileName = mThis._route.meta.title;
      /* 从表生成工作簿对象 */ // 如果数据中带有‘100%’等数据，不想被处理为小数，加{raw:true}即可
      var wb = _xlsx.default.utils.table_to_book(document.querySelector('#out-table'), {
        raw: true
      });
      /* 获取二进制字符串作为输出 */
      var wbout = _xlsx.default.write(wb, {
        bookType: 'xlsx',
        bookSST: true,
        type: 'array'
      });
      try {
        _fileSaver.default.saveAs(
        // Blob 对象表示一个不可变、原始数据的类文件对象。
        // Blob 表示的不一定是JavaScript原生格式的数据。
        // File 接口基于Blob，继承了 blob 的功能并将其扩展使其支持用户系统上的文件。
        // 返回一个新创建的 Blob 对象，其内容由参数中给定的数组串联组成。
        new Blob([wbout], {
          type: 'application/octet-stream'
        }), // 设置导出文件名称
        "".concat(fileName, ".xlsx"));
      } catch (e) {
        if (typeof console !== 'undefined') console.log(e, wbout);
      }
      return wbout;
    };

    /**
     * 判断变量是否未定义 true 已定义
     */
    Vue.prototype.isNotUndefined = function (obj) {
      return !Vue.prototype.isUndefined(obj);
    };
    // 电话号码
    Vue.prototype.isPhone = function (obj) {
      return /^(13[0-9]{9})|(18[0-9]{9})|(14[0-9]{9})|(17[0-9]{9})|(15[0-9]{9})$/.test(obj);
    };
    /* 把扁平化的数据转化成树形数据 */
    Vue.prototype.convertTreeData = function (obj, returnLevel) {
      if (Vue.prototype.isUndefined(obj)) {
        return null;
      }
      return obj.filter(function (father) {
        // 循环所有项，并添加children属性
        var branchArr = obj.filter(function (child) {
          return father.id === child.parentId;
        }); // 返回每一项的子级数组
        branchArr.length > 0 ? father.children = branchArr : ''; // 给父级添加一个children属性，并赋值
        return father.parentId === returnLevel; // returnLevel 要返回的层级
      });
    };
    /**
     * true 是pc设备
     */
    Vue.prototype.isPc = function () {
      var isPc = _jsCookie.default.get('isPc');
      if (Vue.prototype.isNotUndefined(isPc)) {
        return isPc;
      }
      var userAgentInfo = navigator.userAgent;
      var Agents = ['Android', 'iPhone', 'SymbianOS', 'Windows Phone', 'iPad', 'iPod'];
      var flag = true;
      for (var v = 0; v < Agents.length; v++) {
        if (userAgentInfo.indexOf(Agents[v]) > 0) {
          flag = false;
          break;
        }
      }
      _jsCookie.default.get('isPc', flag);
      return flag;
    };
    /**
     * true 非pc设备
     */
    Vue.prototype.isNotPc = function () {
      return !Vue.prototype.isPc();
    };
    /**
     * 获取form表单中输入框的标签宽度
     */
    Vue.prototype.getLabelWidth = function () {
      if (Vue.prototype.isPc()) {
        return '145px';
      } else {
        return '68px';
      }
    };
    /**
     * 获取form表单中输入框的标签大型宽度
     */
    Vue.prototype.getBigLabelWidth = function () {
      if (Vue.prototype.isPc()) {
        return '150px';
      } else {
        return '';
      }
    };
    /**
     * pc端一行显示4个输入表单  非pc端一行只显示一个输入表单
     */
    Vue.prototype.getColSpan = function () {
      if (Vue.prototype.isPc()) {
        return 6;
      } else {
        return 24;
      }
    };
    /**
     * pc端一行显示4个输入表单  非pc端一行只显示一个输入表单
     */
    Vue.prototype.getTimeColSpan = function () {
      if (Vue.prototype.isPc()) {
        return 6;
      } else {
        return 24;
      }
    };
    /**
     * 获取table的分页组件的页面显示数
     */
    Vue.prototype.getPaperCount = function () {
      if (Vue.prototype.isPc()) {
        return 9;
      } else {
        return 3;
      }
    };
    /**
     * 获取table的分页组件要显示的图标之类的东西
     */
    Vue.prototype.getLayout = function () {
      if (Vue.prototype.isPc()) {
        return 'total, sizes, prev, pager, next, jumper';
      } else {
        return 'total, prev, pager, next';
      }
    };
    /**
     * 获取button的大小
     */
    Vue.prototype.getButtonSize = function () {
      if (Vue.prototype.isPc()) {
        return 'small';
      } else {
        return 'medium';
      }
    };
    /**
     * 根据屏幕的宽度获取打开的模态框的宽度
     */
    Vue.prototype.getDialogWidth = function () {
      var clientWidth = document.documentElement.clientWidth;
      if (Vue.prototype.isPc()) {
        // 屏幕可视区域的宽度
        if (clientWidth <= 500) {
          return '95%';
        }
        if (clientWidth > 500 && clientWidth <= 1600) {
          return '63%';
        }
        if (clientWidth > 1600) {
          return '60%';
        }
      } else {
        return '99%';
      }
    };
    /**
     * 根据屏幕的宽度获取打开的模态框的小型宽度
     */
    Vue.prototype.getSmallDialogWidth = function () {
      var clientWidth = document.documentElement.clientWidth;
      // 屏幕可视区域的宽度
      if (clientWidth <= 500) {
        return '95%';
      }
      if (clientWidth > 500 && clientWidth <= 1600) {
        return '40%';
      }
      if (clientWidth > 1600) {
        return '30%';
      }
    };
    /**
     * 对象深拷贝 利用ES6对象扩展运算符（…）
     */
    Vue.prototype.objectCopy = function (originObject) {
      return (0, _objectSpread2.default)({}, originObject);
    };
    /**
     * 数组的深度克隆
     */
    Vue.prototype.deepClone = function (obj) {
      var isObject = function isObject(args) {
        return ((0, _typeof2.default)(args) === 'object' || typeof args === 'function') && (0, _typeof2.default)(args) !== null;
      };
      if (!isObject) throw new Error('Not Reference Types');
      var newObj = Array.isArray(obj) ? (0, _toConsumableArray2.default)(obj) : (0, _objectSpread2.default)({}, obj);
      Reflect.ownKeys(newObj).map(function (key) {
        newObj[key] = isObject(obj[key]) ? Vue.prototype.deepClone(obj[key]) : obj[key];
      });
      return newObj;
    };
    // 对Date的扩展，将 Date 转化为指定格式的String
    // 月(M)、日(d)、小时(h)、分(m)、秒(s)、季度(q) 可以用 1-2 个占位符，
    // 年(y)可以用 1-4 个占位符，毫秒(S)只能用 1 个占位符(是 1-3 位的数字)
    // 例子：
    // (new Date()).Format("yyyy-MM-dd HH:mm:ss.S") ==> 2006-07-02 08:09:04.423
    // (new Date()).Format("yyyy-M-d H:m:s.S")      ==> 2006-7-2 8:9:4.18
    Vue.prototype.formatDate = function (date, fmt) {
      var o = {
        'M+': date.getMonth() + 1,
        // 月份
        'd+': date.getDate(),
        // 日
        'H+': date.getHours(),
        // 小时
        'm+': date.getMinutes(),
        // 分
        's+': date.getSeconds(),
        // 秒
        'q+': Math.floor((date.getMonth() + 3) / 3),
        // 季度
        S: date.getMilliseconds() // 毫秒
      };

      if (/(y+)/.test(fmt)) fmt = fmt.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length));
      for (var k in o) {
        if (new RegExp('(' + k + ')').test(fmt)) {
          fmt = fmt.replace(RegExp.$1, RegExp.$1.length === 1 ? o[k] : ('00' + o[k]).substr(('' + o[k]).length));
        }
      }
      return fmt;
    };
    /**
     * 获取统计列
     * param 列表数据参数
     * summaryColumns 要统计的列
     */
    Vue.prototype.summaryColumn = function (param, summaryColumns) {
      var columns = param.columns,
        data = param.data;
      var sums = [];
      columns.forEach(function (column, index) {
        if (index === 0) {
          sums[index] = '合计';
          return;
        }
        sums[index] = '';
        var _loop = function _loop(i) {
          if (column.property === summaryColumns[i]) {
            var values = data.map(function (item) {
              return Number(item[summaryColumns[i]]);
            });
            sums[index] = values.reduce(function (prev, curr) {
              var value = Number(curr);
              if (!isNaN(value)) {
                return prev + curr;
              } else {
                return prev;
              }
            }, 0);
            sums[index] = parseFloat(Number(sums[index]).toFixed(2));
          }
        };
        for (var i = 0; i < summaryColumns.length; i++) {
          _loop(i);
        }
      });
      return sums;
    };

    /**
     * 默认保留两位小数（整数不补零）
     * number 保留的小数位
     */
    Vue.prototype.keepDecimal = function (origin, number) {
      if (typeof number === 'undefined') {
        return parseFloat(origin.toFixed(2));
      } else {
        return parseFloat(origin.toFixed(number));
      }
    };

    /**
     * js根据后缀判断文件文件类型
     */
    Vue.prototype.getFileType = function (fileName) {
      // 后缀获取
      var suffix = '';
      // 获取类型结果
      var result = '';
      try {
        var fileArr = fileName.split('.');
        suffix = fileArr[fileArr.length - 1];
      } catch (err) {
        suffix = '';
      }
      // fileName无后缀返回 false
      if (!suffix) {
        return false;
      }
      suffix = suffix.toLocaleLowerCase();
      // 图片格式
      var imgList = ['png', 'jpg', 'jpeg', 'bmp', 'gif'];
      // 进行图片匹配
      result = imgList.find(function (item) {
        return item === suffix;
      });
      if (result) {
        return 'image';
      }
      // 匹配txt
      var txtList = ['txt'];
      result = txtList.find(function (item) {
        return item === suffix;
      });
      if (result) {
        return 'txt';
      }
      // 匹配 excel
      var excelList = ['xls', 'xlsx'];
      result = excelList.find(function (item) {
        return item === suffix;
      });
      if (result) {
        return 'excel';
      }
      // 匹配 word
      var wordList = ['doc', 'docx'];
      result = wordList.find(function (item) {
        return item === suffix;
      });
      if (result) {
        return 'word';
      }
      // 匹配 pdf
      var pdfList = ['pdf'];
      result = pdfList.find(function (item) {
        return item === suffix;
      });
      if (result) {
        return 'pdf';
      }
      // 匹配 ppt
      var pptList = ['ppt', 'pptx'];
      result = pptList.find(function (item) {
        return item === suffix;
      });
      if (result) {
        return 'ppt';
      }
      // 匹配 视频
      var videoList = ['mp4', 'm2v', 'mkv', 'rmvb', 'wmv', 'avi', 'flv', 'mov', 'm4v'];
      result = videoList.find(function (item) {
        return item === suffix;
      });
      if (result) {
        return 'video';
      }
      // 匹配 音频
      var radioList = ['mp3', 'wav', 'wmv'];
      result = radioList.find(function (item) {
        return item === suffix;
      });
      if (result) {
        return 'radio';
      }
      // 其他 文件类型
      return 'other';
    };
    /**
     * 只隐藏姓名中间的字
     * @param name
     * @returns 加密的昵称 张*三
     */
    Vue.prototype.hideCenterName = function (name) {
      if (Vue.prototype.isEmpty(name)) {
        return name;
      }
      var newStr;
      if (name.length === 2) {
        newStr = name.substring(0, 1) + '*';
      } else if (name.length > 2) {
        var char = '';
        for (var i = 0, len = name.length - 2; i < len; i++) {
          char += '*';
        }
        newStr = name.substring(0, 1) + char + name.substr(-1, 1);
      } else {
        newStr = name;
      }
      return newStr;
    };
    /**
     * 隐藏手机号中间4位数字
     * @param phone
     * @returns 加密的手机号 181****2688
     */
    Vue.prototype.hideCenterPhone = function (phone) {
      if (Vue.prototype.isEmpty(phone)) {
        return phone;
      }
      return phone.replace(/(\d{3})\d*(\d{4})/, "$1****$2");
    };

    /**
     * 隐藏身份证中间8位数
     * @param idCard
     * @returns 加密的身份证号 530026******2101
     */
    Vue.prototype.hideCenterIdCard = function (idCard) {
      if (Vue.prototype.isEmpty(idCard)) {
        return idCard;
      }
      return idCard.replace(/(.{6}).*(.{4})/, "$1********$2");
    };

    /**
     * 防止el-button重复点击
     */
    Vue.directive('preventReClick', {
      inserted: function inserted(el, binding) {
        el.addEventListener('click', function () {
          console.log("listen....preventReClick");
          if (!el.disabled) {
            el.disabled = true;
            setTimeout(function () {
              el.disabled = false;
            }, binding.value || 2000);
          }
        });
      }
    });
    Vue.prototype.picUrl = function (url) {
      return Vue.prototype.ossDomain + url;
    };

    /**
     * 显示两位小数，不足补零
     *
     * @param num 需要处理的数字
     */
    Vue.prototype.formatTwo = function (num) {
      //1. 可能是字符串，转换为浮点数
      //2. 乘以100 小数点向右移动两位
      //3. Math.round 进行四舍五入
      //4. 除以100 小数点向左移动两位 实现保留小数点后两位
      var value = Math.round(parseFloat(num) * 100) / 100;
      // 去掉小数点 存为数组
      var arrayNum = value.toString().split(".");
      //只有一位（整数）
      if (arrayNum.length === 1) {
        return value + ".00";
      }
      if (arrayNum.length > 1) {
        //小数点右侧 如果小于两位 则补一个0
        if (arrayNum[1].length < 2) {
          return value.toString() + "0";
        }
        return value;
      }
    };
    /**
     * 判断当前服务是否是商户
     */
    Vue.prototype.isSellerServer = function () {
      var domain = window.location.href;
      return domain.indexOf("//service.") === -1;
    };

    /**
     * 数字转换，将大额数字转换为万、亿、万亿等
     * @param value 数字值
     */
    Vue.prototype.bigNumberTransform = function (value) {
      if (typeof value == 'string') {
        value = Number(value);
      }
      var unit = '';
      var k = 10000;
      var sizes = ['', '万', '亿', '万亿'];
      var i;
      if (value >= k) {
        i = Math.floor(Math.log(value) / Math.log(k));
        value = (value / Math.pow(k, i)).toFixed(2);
        unit = sizes[i];
      }
      return value + unit;
    };
    /**
     * 生成uuid
     *
     * @returns {string}
     */
    Vue.prototype.guid = function () {
      return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = Math.random() * 16 | 0,
          v = c === 'x' ? r : r & 0x3 | 0x8;
        return v.toString(16);
      });
    };
    /**
     * file转image
     * @param file
     * @returns {Promise<unknown>}
     */
    Vue.prototype.readImg = function (file) {
      return new Promise(function (resolve, reject) {
        var img = new Image();
        var reader = new FileReader();
        reader.onload = function (e) {
          img.src = e.target.result;
        };
        reader.onerror = function (e) {
          reject(e);
        };
        reader.readAsDataURL(file);
        img.onload = function () {
          resolve(img);
        };
        img.onerror = function (e) {
          reject(e);
        };
      });
    };
    /**
     * 解析成yyyy-MM-dd
     * @param date
     * @returns {`${number}-${string}-${string}`}
     */
    Vue.prototype.formatToYYYYMMDD = function (date) {
      var year = date.getFullYear();
      var month = String(date.getMonth() + 1).padStart(2, '0');
      var day = String(date.getDate()).padStart(2, '0');
      return "".concat(year, "-").concat(month, "-").concat(day);
    };
    /**
     * 根据时间串解析
     * @param dateTimeString
     * @param format
     * @returns {Date}
     */
    Vue.prototype.parseDateTime = function (dateTimeString, format) {
      var parts = dateTimeString.split(/[ :\-]/); // 分隔符可以是空格、冒号或短横线
      var formatParts = format.split(/[ :\-]/);
      var parsedDate = new Date();
      for (var i = 0; i < formatParts.length; i++) {
        var formatPart = formatParts[i];
        var value = parseInt(parts[i], 10);
        if (formatPart === "yyyy") {
          parsedDate.setFullYear(value);
        } else if (formatPart === "MM") {
          parsedDate.setMonth(value - 1);
        } else if (formatPart === "dd") {
          parsedDate.setDate(value);
        } else if (formatPart === "HH") {
          parsedDate.setHours(value);
        } else if (formatPart === "mm") {
          parsedDate.setMinutes(value);
        } else if (formatPart === "ss") {
          parsedDate.setSeconds(value);
        }
      }
      return parsedDate;
    };
    /**
     * 获取指定年月的天数
     *
     * @param year
     * @param month
     * @returns {number}
     */
    Vue.prototype.getDaysInMonth = function (year, month) {
      return new Date(year, month, 0).getDate();
    };
  }
};
exports.default = _default;