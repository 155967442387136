"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.teacherJobSlave = void 0;
var teacherJobSlave = {
  data: function data() {
    return {
      //是否展示详情窗口
      showDetailDialog: false,
      //是否已提交枚举
      hadPostMap: {
        0: '否',
        1: '是'
      },
      //是否已提交标签颜色枚举
      hadPostColorMap: {
        0: 'danger',
        1: 'success',
        2: 'danger',
        3: 'warning',
        4: 'info',
        5: 'primary'
      },
      //是否达标枚举
      isPassMap: {
        0: '否',
        1: '是'
      },
      //是否达标标签颜色枚举
      isPassColorMap: {
        0: 'danger',
        1: 'success',
        2: 'danger',
        3: 'warning',
        4: 'info',
        5: 'primary'
      },
      backUrl: '/teacher/teacherJobSlave/page',
      entity: {},
      rules: {
        teacherUserId: [{
          required: true,
          message: '请输入教师ID'
        }],
        jobId: [{
          required: true,
          message: '请输入作业ID'
        }],
        ropeId: [{
          required: true,
          message: '请输入跳绳记录ID'
        }],
        hadPost: [{
          required: true,
          message: '请输入是否已提交'
        }],
        name: [{
          required: true,
          message: '请输入学生姓名'
        }, {
          max: 255,
          message: '学生姓名长度在255个字符内'
        }],
        postTime: [{
          required: true,
          message: '请输入作业提交时间'
        }],
        num: [{
          required: true,
          message: '请输入数量'
        }],
        seconds: [{
          required: true,
          message: '请输入秒数'
        }],
        isPass: [{
          required: true,
          message: '请输入是否达标'
        }],
        userId: [{
          required: true,
          message: '请输入用户ID'
        }],
        uuid: [{
          required: true,
          message: '请输入UUID'
        }, {
          max: 255,
          message: 'UUID长度在255个字符内'
        }],
        gradeId: [{
          required: true,
          message: '请输入年级ID'
        }],
        schoolId: [{
          required: true,
          message: '请输入学校ID'
        }],
        className: [{
          required: true,
          message: '请输入班级'
        }, {
          max: 255,
          message: '班级长度在255个字符内'
        }],
        mongoId: [{
          required: true,
          message: '请输入记录号'
        }, {
          max: 50,
          message: '记录号长度在50个字符内'
        }]
      }
    };
  }
};
exports.teacherJobSlave = teacherJobSlave;