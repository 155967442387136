"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
var _default2 = {
  name: "Pie",
  props: {
    title: {
      type: String
    },
    pId: {
      type: String,
      default: function _default() {
        return this.guid();
      }
    },
    //分类
    cate: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    //数据
    data: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    oLabel: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    data: function data() {
      this.pieInit();
    }
  },
  mounted: function mounted() {
    this.pieInit();
  },
  methods: {
    pieInit: function pieInit() {
      try {
        var pieEChart = this.$echarts5.init(document.getElementById('pieEChart' + this.pId));
        var option = {
          title: {
            text: this.title,
            left: 'center'
          },
          tooltip: {
            trigger: 'item',
            formatter: '{a} <br/>{b}: {c} ({d}%)'
          },
          legend: {
            orient: 'vertical',
            left: 5,
            data: this.cate
          },
          series: [{
            name: '类型',
            type: 'pie',
            radius: ['50%', '70%'],
            avoidLabelOverlap: false,
            label: {
              show: this.oLabel
            },
            labelLine: {
              show: this.oLabel
            },
            emphasis: {
              label: {
                show: true,
                fontSize: '16',
                fontWeight: 'bold'
              },
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            },
            data: this.data
          }]
        };
        pieEChart.setOption(option);
        //随着屏幕大小调节图表
        window.addEventListener("resize", function () {
          pieEChart.resize();
        });
      } catch (e) {}
    }
  }
};
exports.default = _default2;