"use strict";

var _interopRequireDefault = require("D:/shenbimaliang1/sbml-backend/backend-page/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _school = require("./school");
var _GradePage = _interopRequireDefault(require("@/views/school/grade/GradePage.vue"));
var _OrderSlavePage = _interopRequireDefault(require("@/views/order/orderSlave/OrderSlavePage.vue"));
//
//
//
//
//
//
//
var _default = {
  components: {
    OrderSlavePage: _OrderSlavePage.default,
    GradePage: _GradePage.default
  },
  mixins: [_school.school],
  data: function data() {
    return {
      dialogVisible: false,
      //传给年纪窗口的学校ID
      schoolId: null
    };
  },
  methods: {
    //执行修改操作
    init: function init(schoolId) {
      this.schoolId = schoolId;
      this.dialogVisible = true;
    }
  }
};
exports.default = _default;