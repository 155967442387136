"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ratingUpdate = exports.ratingPage = exports.ratingExport = exports.ratingDisabled = void 0;
var _request = require("@/utils/request");
//=============================================================评价=======================================================
/**
 * 评价修改
 */
var ratingUpdate = function ratingUpdate(data) {
  return (0, _request.post)('/afterSales/rating/update', data);
};
/**
 * 评价列表
 */
exports.ratingUpdate = ratingUpdate;
var ratingPage = function ratingPage(data) {
  return (0, _request.post)('/afterSales/rating/page', data);
};
/**
 * 是否禁用评论
 */
exports.ratingPage = ratingPage;
var ratingDisabled = function ratingDisabled(data) {
  return (0, _request.post)('/afterSales/rating/disabled', data);
};
/**
 * 评价导出
 */
exports.ratingDisabled = ratingDisabled;
var ratingExport = function ratingExport(param, name) {
  return (0, _request.exportExcel)('/afterSales/rating/export', param, name);
};
exports.ratingExport = ratingExport;