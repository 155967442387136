"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ropeLog = void 0;
var ropeLog = {
  data: function data() {
    return {
      //是否展示详情窗口
      showDetailDialog: false,
      //类型枚举
      typeMap: {
        0: '计数',
        1: '计时',
        2: '自由'
      },
      //类型标签颜色枚举
      typeColorMap: {
        0: 'warning',
        1: 'success',
        2: 'primary'
      },
      backUrl: '/sport/ropeLog/page',
      entity: {},
      rules: {
        uuid: [{
          required: true,
          message: '请输入跳绳UUID'
        }, {
          max: 64,
          message: '跳绳UUID长度在64个字符内'
        }],
        seconds: [{
          required: true,
          message: '请输入秒数'
        }],
        total: [{
          required: true,
          message: '请输入个数'
        }],
        rate: [{
          required: true,
          message: '请输入速率'
        }],
        type: [{
          required: true,
          message: '请输入类型'
        }],
        district: [{
          required: true,
          message: '请输入地区'
        }, {
          max: 10,
          message: '地区长度在10个字符内'
        }],
        city: [{
          required: true,
          message: '请输入城市'
        }, {
          max: 10,
          message: '城市长度在10个字符内'
        }],
        province: [{
          required: true,
          message: '请输入省份'
        }, {
          max: 10,
          message: '省份长度在10个字符内'
        }],
        school: [{
          required: true,
          message: '请输入学校'
        }, {
          max: 255,
          message: '学校长度在255个字符内'
        }],
        grade: [{
          required: true,
          message: '请输入年级'
        }, {
          max: 255,
          message: '年级长度在255个字符内'
        }],
        classes: [{
          required: true,
          message: '请输入班级'
        }, {
          max: 50,
          message: '班级长度在50个字符内'
        }],
        userId: [{
          required: true,
          message: '请输入用户ID'
        }],
        ropeId: [{
          required: true,
          message: '请输入跳绳订单ID'
        }]
      }
    };
  }
};
exports.ropeLog = ropeLog;