"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.adPortrait = void 0;
var adPortrait = {
  data: function data() {
    return {
      //是否展示详情窗口
      showDetailDialog: false,
      backUrl: '/advertise/adPortrait/page',
      entity: {},
      rules: {
        userId: [{
          required: true,
          message: '请输入广告主ID'
        }],
        name: [{
          required: true,
          message: '请输入名称'
        }, {
          max: 255,
          message: '名称长度在255个字符内'
        }],
        beginTime: [{
          required: true,
          message: '请输入开始时间'
        }],
        endTime: [{
          required: true,
          message: '请输入结束时间'
        }],
        portraitUrl: [{
          required: true,
          message: '请输入文件'
        }, {
          max: 255,
          message: '文件长度在255个字符内'
        }]
      }
    };
  }
};
exports.adPortrait = adPortrait;