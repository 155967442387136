"use strict";

var _interopRequireDefault = require("D:/shenbimaliang1/sbml-backend/backend-page/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _sportApi = require("@/api/sport-api");
var _globalDialog = require("@/mixins/global-dialog");
var _permission = require("@/mixins/permission");
var _ropeLog = require("./rope-log");
var _RopeLogAdd = _interopRequireDefault(require("./RopeLogAdd"));
var _RopeLogUpdate = _interopRequireDefault(require("./RopeLogUpdate"));
var _RopeLogDetail = _interopRequireDefault(require("./RopeLogDetail"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'RopeLogPage',
  components: {
    Pagination: _Pagination.default,
    RopeLogAdd: _RopeLogAdd.default,
    RopeLogUpdate: _RopeLogUpdate.default,
    RopeLogDetail: _RopeLogDetail.default
  },
  mixins: [_ropeLog.ropeLog, _globalDialog.pageDialog, _permission.permission],
  methods: {
    //导出
    exportExcel: function exportExcel() {
      (0, _sportApi.ropeLogExport)(this.pageParam);
    },
    //调用接口查询
    doSearch: function doSearch() {
      return (0, _sportApi.ropeLogPage)(this.pageParam);
    },
    /**
     * 执行批量删除操作
     * @param {Object} idList id集合
     */
    doBatchDelete: function doBatchDelete(idList) {
      return (0, _sportApi.ropeLogDelete)(idList);
    },
    /**
     * 打开跳绳记录详情窗口
     */
    openDetailDialog: function openDetailDialog() {
      var _this = this;
      var selectData = this.getSelect();
      if (this.isEmpty(selectData)) {
        return;
      }
      this.showDetailDialog = true;
      this.$nextTick(function () {
        // 把选中行的数据拷贝一份传递给修改对话框
        selectData = _this.transTimeDia(selectData);
        _this.$refs.detailDialog.setEntity(_this.objectCopy(selectData));
      });
    }
  }
};
exports.default = _default;