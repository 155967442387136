"use strict";

var _interopRequireDefault = require("D:/shenbimaliang1/sbml-backend/backend-page/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.exportExcel = exportExcel;
exports.fetch = fetch;
exports.post = post;
exports.upload = upload;
require("core-js/modules/es6.regexp.replace");
var _axios = _interopRequireDefault(require("axios"));
var _vue = _interopRequireDefault(require("vue"));
var _router = _interopRequireDefault(require("../router"));
var _jsCookie = _interopRequireDefault(require("js-cookie"));
var _cache = require("@/utils/cache");
var showMsg = true;
var domain = window.location.href;

// create an axios instance
var service = _axios.default.create({
  baseURL: process.env.VUE_APP_BASE_API,
  // url = base url + request url
  withCredentials: true,
  // send cookies when cross-domain requests
  crossDomain: true,
  timeout: 10000 // request timeout
});
// http request 拦截器
service.interceptors.request.use(function (config) {
  var systemUser = _jsCookie.default.get('systemUser');
  if (_vue.default.prototype.isNotUndefined(systemUser)) {
    // 判断是否存在token，如果存在的话，则每个http header都加上token
    config.headers.userId = JSON.parse(systemUser).id; //请求头加上token
  }

  var kToken = (0, _cache.getItemCache)("kToken");
  if (_vue.default.prototype.isNotUndefined(kToken) && _vue.default.prototype.isNotEmpty(kToken)) {
    config.headers['kaptcha-token'] = kToken; //请求头加上验证码临时token
  }

  var jwtToken = (0, _cache.getItemCache)("AccessToken");
  if (_vue.default.prototype.isNotUndefined(jwtToken) && _vue.default.prototype.isNotEmpty(jwtToken)) {
    config.headers['jwt-token'] = jwtToken; //请求头加上登录token
  }

  return config;
}, function (err) {
  return Promise.reject(err);
});
// response interceptor
service.interceptors.response.use(
/**
 * Determine the request status by custom code
 * Here is just an example
 * You can also judge the status by HTTP Status Code
 */
function (response) {
  var kToken = response.headers['kaptcha-token'];
  if (_vue.default.prototype.isNotUndefined(kToken) && _vue.default.prototype.isNotEmpty(kToken)) {
    (0, _cache.setItemCache)("kToken", kToken, 1000 * 60 * 10);
  }
  var jwtToken = response.headers['authorization'];
  if (_vue.default.prototype.isNotUndefined(jwtToken) && _vue.default.prototype.isNotEmpty(jwtToken)) {
    // console.log("返回access token...")
    var cacheJwtToken = (0, _cache.getItemCache)("AccessToken");
    // if (Vue.prototype.isNotUndefined(cacheJwtToken) && Vue.prototype.isNotEmpty(cacheJwtToken)) {
    //   if (cacheJwtToken !== jwtToken) {
    //     console.log("access token刷新了", cacheJwtToken, "\n", jwtToken)
    //   }
    // }
    //缓存10分钟
    (0, _cache.setItemCache)("AccessToken", jwtToken, 1000 * 60 * 60 * 24);
  }
  var isSeller = domain.indexOf("//service.") === -1;
  var result = response.data;
  // if the custom code is not 200, it is judged as an error.
  if (result.code !== _vue.default.prototype.successCode) {
    //Vue.prototype.errorMessage(result.message);
    //未登录或者登录超时 / 强制下线
    if (result.code === 401 || result.code === 402) {
      if (showMsg) {
        isSeller ? _vue.default.prototype.errorNotify(result.message) : _vue.default.prototype.errorMessage(result.message);
        showMsg = false;
        setTimeout(function () {
          showMsg = true;
        }, 3000);
      }
      (0, _cache.removeItemCache)("AccessToken");
      //退出登录时断开socket
      _vue.default.prototype.websocket.disconnectSocket();
      _router.default.push('/login');
    }
    //系统返回异常码
    if (result.code === _vue.default.prototype.exceptionCode) {
      if (showMsg) {
        isSeller ? _vue.default.prototype.errorNotify(result.message) : _vue.default.prototype.errorMessage(result.message);
        showMsg = false;
        setTimeout(function () {
          showMsg = true;
        }, 3000);
      }
    }
    //跳转到首页
    if (result.code === 403) {
      if (showMsg) {
        isSeller ? _vue.default.prototype.errorNotify(result.message + '，即将在3秒后跳转到首页...') : _vue.default.prototype.errorMessage(result.message);
      }
      showMsg = false;
      setTimeout(function () {
        showMsg = true;
        _router.default.push('/dashboard');
      }, 3000);
    }
  }
  return result;
}, function (error) {
  console.log(error);
  var isSeller = domain.indexOf("//service.") === -1;
  if (showMsg) {
    console.log('系统error' + error); // for debug
    isSeller ? _vue.default.prototype.errorNotify(error.message) : _vue.default.prototype.errorMessage(error.message);
    return Promise.reject(error);
  }
});

/**
 * 封装get方法
 * @param url
 * @param params
 * @returns {Promise}
 */
function fetch(url) {
  var params = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  return new Promise(function (resolve, reject) {
    service.get(url, {
      params: params
    }).then(function (response) {
      resolve(response.data);
    }).catch(function (err) {
      reject(err);
    });
  });
}

/**
 * 封装post请求
 * @param url
 * @param data
 * @param requestConfig
 * @returns {Promise}
 */
function post(url) {
  var data = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  var requestConfig = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
  return new Promise(function (resolve, reject) {
    service.post(url, data, requestConfig).then(function (response) {
      resolve(response);
    }, function (err) {
      reject(err);
    });
  });
}
function upload(url) {
  var params = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  var requestConfig = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
  return new Promise(function (resolve, reject) {
    service.post(url, params, requestConfig).then(function (response) {
      resolve(response);
    }, function (err) {
      reject(err);
    });
  });
}

/**
 * 封装Excel导出方法
 * @param url
 * @param data
 * @param name
 * @author Salmon
 */
function exportExcel(url) {
  var data = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  var name = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : '';
  var headers = {};
  var jwtToken = (0, _cache.getItemCache)("AccessToken");
  if (_vue.default.prototype.isNotUndefined(jwtToken) && _vue.default.prototype.isNotEmpty(jwtToken)) {
    headers['jwt-token'] = jwtToken; //请求头加上登录token
  }

  (0, _axios.default)({
    method: "POST",
    responseType: 'blob',
    // 重点是把请求的默认格式responseType:'json'改成responseType: 'blob'。
    url: process.env.VUE_APP_BASE_API + url,
    data: data,
    headers: headers
  }).then(function (res) {
    var link = document.createElement('a'); // 首先创建一个a标签毕竟下载是要通过a标签来下载的。
    var blob = new Blob([res.data], {
      type: "application/vnd.ms-excel"
    }); // 第一个参数是后台返回的文件流变量，第二个参数是要转换的类型，由type的值来决定。
    console.log(blob);
    link.href = URL.createObjectURL(blob); // 用URL.createObjectURL方法来创建一个URL对象并赋值给a标签的heft属性。
    if (name === '') {
      name = getFileNameFromResponse(res); //获取后端返回的文件名
    }

    console.log(name);
    link.download = name; // 设置文件名字。
    link.click(); // 触发点击事件，开始下载。
  });
}

/**
 * 根据响应头获取文件名
 *
 * @param response
 * @returns {null|string}
 */
function getFileNameFromResponse(response) {
  var disposition = response.headers['content-disposition'];
  var regex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
  var matches = regex.exec(disposition);
  if (matches != null && matches[1]) {
    return decodeURIComponent(matches[1].replace(/['"]/g, ''));
  }
  return null;
}