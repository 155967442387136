"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _proxyRopeStatistics = require("./proxy-rope-statistics");
var _globalDialog = require("@/mixins/global-dialog");
var _partnerApi = require("@/api/partner-api");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  mixins: [_proxyRopeStatistics.proxyRopeStatistics, _globalDialog.addDialog],
  methods: {
    //执行添加操作
    doAdd: function doAdd() {
      return (0, _partnerApi.proxyRopeStatisticsAdd)(this.entity);
    }
  }
};
exports.default = _default;