exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".feedback-btn{position:fixed;right:30px;bottom:100px}/*!*修改最外层div背景色*!*/.el-popover.feedback-popover{background-color:#f8f5f5}.el-popover.feedback-popover .feedback-title{font-weight:bold;font-size:14px}.el-popover.feedback-popover .feedback-type{display:inline-block}.el-popover.feedback-popover .feedback-type .feedback-type-btn{width:85px;height:30px;line-height:30px;border:1px solid #E1E1E1;border-radius:15px;color:#969696;padding:0 10px}.el-popover.feedback-popover .feedback-type .feedback-type-btn-pl-active{background:#409EFF;color:#fff}.el-popover.feedback-popover .feedback-type .feedback-type-btn-se-active{background:#FF652F;color:#fff}.el-popover.feedback-popover .submit-btn{border-radius:19px;height:45px;font-size:16px;color:#fbfdff;width:330px;margin-bottom:30px}.feedback-popover .el-popover__title{text-align:center;font-weight:bold}\n", ""]);

// exports
exports.locals = {
	"menuText": "#bfcbd9",
	"menuActiveText": "#409EFF",
	"subMenuActiveText": "#f4f4f5",
	"menuBg": "#304156",
	"menuHover": "#263445",
	"subMenuBg": "#1f2d3d",
	"subMenuHover": "#001528",
	"platform_btn": "linear-gradient(233deg, #119bf6 40%, #a5c7f3 100%)",
	"menuSellerText": "#fff3f9",
	"menuActiveSellerText": "#FF652F",
	"subMenuActiveSellerText": "#FF652F",
	"menuSellerBg": "#FFAE26",
	"menuSellerHover": "#c78920",
	"subMenuSellerBg": "#fda615",
	"subMenuSellerHover": "#cb8511",
	"seller_btn": "linear-gradient(233deg, #FFAE26 40%, #FF652F 100%)",
	"sideBarWidth": "210px"
};