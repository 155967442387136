"use strict";

var _interopRequireDefault = require("D:/shenbimaliang1/sbml-backend/backend-page/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _advertiseApi = require("@/api/advertise-api");
var _globalDialog = require("@/mixins/global-dialog");
var _permission = require("@/mixins/permission");
var _ad = require("./ad");
var _AdAdd = _interopRequireDefault(require("./AdAdd"));
var _AdDetail = _interopRequireDefault(require("./AdDetail"));
var _AdReview = _interopRequireDefault(require("@/views/advertise/ad-operation/AdReview.vue"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'AdPage',
  components: {
    AdReview: _AdReview.default,
    Pagination: _Pagination.default,
    AdAdd: _AdAdd.default,
    AdDetail: _AdDetail.default
  },
  mixins: [_ad.ad, _globalDialog.pageDialog, _permission.permission],
  methods: {
    //导出
    exportExcel: function exportExcel() {
      (0, _advertiseApi.adExport)(this.pageParam);
    },
    //调用接口查询
    doSearch: function doSearch() {
      return (0, _advertiseApi.adPage)(this.pageParam);
    },
    /**
     * 执行批量删除操作
     * @param {Object} idList id集合
     */
    doBatchDelete: function doBatchDelete(idList) {
      return (0, _advertiseApi.adDelete)(idList);
    },
    /**
     * 打开广告详情窗口
     */
    openDetailDialog: function openDetailDialog() {
      var _this = this;
      var selectData = this.getSelect();
      if (this.isEmpty(selectData)) {
        return;
      }
      this.showDetailDialog = true;
      this.$nextTick(function () {
        // 把选中行的数据拷贝一份传递给修改对话框
        selectData = _this.transTimeDia(selectData);
        _this.$refs.detailDialog.setEntity(_this.objectCopy(selectData));
      });
    },
    /**
     * 打开审核窗口
     */
    openReviewDialog: function openReviewDialog() {
      var _this2 = this;
      var selectData = this.getSelect();
      if (this.isEmpty(selectData)) {
        return;
      }
      // if (selectData.status !== 0) {
      //   this.errorMessage("该广告已审核")
      //   return;
      // }
      this.showReviewDialog = true;
      this.$nextTick(function () {
        // 把选中行的数据拷贝一份传递给修改对话框
        selectData = _this2.transTimeDia(selectData);
        _this2.$refs.reviewDialog.setEntity(_this2.objectCopy(selectData));
      });
    }
  }
};
exports.default = _default;