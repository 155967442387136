"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.withdrawLogStatusEnums = exports.withdrawLogReview = exports.withdrawLogPage = exports.withdrawLogLock = exports.withdrawLogExport = exports.userReviewDel = exports.userRecharge = exports.userPage = exports.userMoneyPointsLogPage = exports.userMoneyPointsLogExport = exports.userMoneyBalanceLogPage = exports.userMoneyBalanceLogExport = exports.userMoneyAdLogPage = exports.userMoneyAdLogExport = exports.userExport = exports.userBankPage = exports.userBankExport = exports.updateStatus = exports.pointsTypeEnums = exports.moneyEnums = exports.levelEnums = exports.favoritePage = exports.balanceTypeEnums = exports.addressPage = exports.addressExport = exports.adTypeEnums = void 0;
var _request = require("@/utils/request");
//=============================================================枚举=======================================================
var levelEnums = function levelEnums() {
  return (0, _request.post)('/member/enums/level');
};
exports.levelEnums = levelEnums;
var moneyEnums = function moneyEnums() {
  return (0, _request.post)('/member/enums/money');
};
exports.moneyEnums = moneyEnums;
var pointsTypeEnums = function pointsTypeEnums() {
  return (0, _request.post)('/member/enums/pointsType');
};
exports.pointsTypeEnums = pointsTypeEnums;
var balanceTypeEnums = function balanceTypeEnums() {
  return (0, _request.post)('/member/enums/balanceType');
};
exports.balanceTypeEnums = balanceTypeEnums;
var adTypeEnums = function adTypeEnums() {
  return (0, _request.post)('/member/enums/adType');
};
exports.adTypeEnums = adTypeEnums;
var withdrawLogStatusEnums = function withdrawLogStatusEnums() {
  return (0, _request.post)('/member/enums/withdraw/log/status');
};
//=============================================================用户=======================================================
/**
 * 用户充值
 */
exports.withdrawLogStatusEnums = withdrawLogStatusEnums;
var userRecharge = function userRecharge(data) {
  return (0, _request.post)('/member/user/recharge', data);
};
/**
 * 用户注销审核
 */
exports.userRecharge = userRecharge;
var userReviewDel = function userReviewDel(data) {
  return (0, _request.post)('/member/user/reviewDel', data);
};
/**
 * 用户列表
 */
exports.userReviewDel = userReviewDel;
var userPage = function userPage(data) {
  return (0, _request.post)('/member/user/page', data);
};
/**
 * 修改系统用户状态
 */
exports.userPage = userPage;
var updateStatus = function updateStatus(data) {
  return (0, _request.post)('/member/user/status', data);
};
/**
 * 用户导出
 */
exports.updateStatus = updateStatus;
var userExport = function userExport(param, name) {
  return (0, _request.exportExcel)('/member/user/export', param, name);
};
//=============================================================收藏=======================================================
/**
 * 用户收藏列表
 */
exports.userExport = userExport;
var favoritePage = function favoritePage(data) {
  return (0, _request.post)('/member/favorite/page', data);
};
//=============================================================收货地址=======================================================
/**
 * 用户收货地址列表
 */
exports.favoritePage = favoritePage;
var addressPage = function addressPage(data) {
  return (0, _request.post)('/member/address/page', data);
};
/**
 * 用户收货地址导出
 */
exports.addressPage = addressPage;
var addressExport = function addressExport(param, name) {
  return (0, _request.exportExcel)('/member/address/export', param, name);
};
//=============================================================用户资金明细-余额=======================================================
/**
 * 用户资金明细-余额列表
 */
exports.addressExport = addressExport;
var userMoneyBalanceLogPage = function userMoneyBalanceLogPage(data) {
  return (0, _request.post)('/member/userMoneyBalanceLog/page', data);
};
/**
 * 用户资金明细-余额导出
 */
exports.userMoneyBalanceLogPage = userMoneyBalanceLogPage;
var userMoneyBalanceLogExport = function userMoneyBalanceLogExport(param, name) {
  return (0, _request.exportExcel)('/member/userMoneyBalanceLog/export', param, name);
};
//=============================================================用户资金明细-余额=======================================================
/**
 * 用户资金明细-健康宝列表
 */
exports.userMoneyBalanceLogExport = userMoneyBalanceLogExport;
var userMoneyPointsLogPage = function userMoneyPointsLogPage(data) {
  return (0, _request.post)('/member/userMoneyPointsLog/page', data);
};
/**
 * 用户资金明细-健康宝导出
 */
exports.userMoneyPointsLogPage = userMoneyPointsLogPage;
var userMoneyPointsLogExport = function userMoneyPointsLogExport(param, name) {
  return (0, _request.exportExcel)('/member/userMoneyPointsLog/export', param, name);
};
//=============================================================用户资金明细-广告卡=======================================================
/**
 * 用户资金明细-广告卡列表
 */
exports.userMoneyPointsLogExport = userMoneyPointsLogExport;
var userMoneyAdLogPage = function userMoneyAdLogPage(data) {
  return (0, _request.post)('/member/userMoneyAdLog/page', data);
};
/**
 * 用户资金明细-广告卡导出
 */
exports.userMoneyAdLogPage = userMoneyAdLogPage;
var userMoneyAdLogExport = function userMoneyAdLogExport(param, name) {
  return (0, _request.exportExcel)('/member/userMoneyAdLog/export', param, name);
};
//=============================================================用户银行卡表=======================================================
/**
 * 用户银行卡表列表
 */
exports.userMoneyAdLogExport = userMoneyAdLogExport;
var userBankPage = function userBankPage(data) {
  return (0, _request.post)('/member/userBank/page', data);
};
/**
 * 用户银行卡表导出
 */
exports.userBankPage = userBankPage;
var userBankExport = function userBankExport(param, name) {
  return (0, _request.exportExcel)('/member/userBank/export', param, name);
};
//=============================================================提现=======================================================
/**
 * 提现批量锁定
 */
exports.userBankExport = userBankExport;
var withdrawLogLock = function withdrawLogLock(idList) {
  return (0, _request.post)('/member/withdrawLog/lock?idList=' + idList);
};
/**
 * 提现审核
 */
exports.withdrawLogLock = withdrawLogLock;
var withdrawLogReview = function withdrawLogReview(data) {
  return (0, _request.post)('/member/withdrawLog/review', data);
};
/**
 * 提现列表
 */
exports.withdrawLogReview = withdrawLogReview;
var withdrawLogPage = function withdrawLogPage(data) {
  return (0, _request.post)('/member/withdrawLog/page', data);
};
/**
 * 提现导出
 */
exports.withdrawLogPage = withdrawLogPage;
var withdrawLogExport = function withdrawLogExport(param, name) {
  return (0, _request.exportExcel)('/member/withdrawLog/export', param, name);
};
exports.withdrawLogExport = withdrawLogExport;