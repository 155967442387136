"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.userBank = void 0;
var userBank = {
  data: function data() {
    return {
      //是否展示详情窗口
      showDetailDialog: false,
      backUrl: '/member/userBank/page',
      entity: {},
      rules: {
        name: [{
          required: true,
          message: '请输入银行'
        }, {
          max: 20,
          message: '银行长度在20个字符内'
        }],
        branch: [{
          required: true,
          message: '请输入支行'
        }, {
          max: 255,
          message: '支行长度在255个字符内'
        }],
        number: [{
          required: true,
          message: '请输入卡号'
        }, {
          max: 255,
          message: '卡号长度在255个字符内'
        }],
        userName: [{
          required: true,
          message: '请输入开户姓名'
        }, {
          max: 20,
          message: '开户姓名长度在20个字符内'
        }],
        userId: [{
          required: true,
          message: '请输入用户ID'
        }]
      }
    };
  }
};
exports.userBank = userBank;