"use strict";

var _interopRequireWildcard = require("D:/shenbimaliang1/sbml-backend/backend-page/node_modules/@babel/runtime/helpers/interopRequireWildcard.js");
var _interopRequireDefault = require("D:/shenbimaliang1/sbml-backend/backend-page/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
require("D:\\shenbimaliang1\\sbml-backend\\backend-page\\node_modules\\core-js\\modules\\es6.array.iterator.js");
require("D:\\shenbimaliang1\\sbml-backend\\backend-page\\node_modules\\core-js\\modules\\es6.promise.js");
require("D:\\shenbimaliang1\\sbml-backend\\backend-page\\node_modules\\core-js\\modules\\es6.object.assign.js");
require("D:\\shenbimaliang1\\sbml-backend\\backend-page\\node_modules\\core-js\\modules\\es7.promise.finally.js");
var _vue = _interopRequireDefault(require("vue"));
require("normalize.css/normalize.css");
var _elementUi = _interopRequireDefault(require("element-ui"));
require("element-ui/lib/theme-chalk/index.css");
require("element-ui/lib/theme-chalk/display.css");
require("@/styles/index.scss");
var _App = _interopRequireDefault(require("./App"));
var _store = _interopRequireDefault(require("./store"));
var _router = _interopRequireDefault(require("./router"));
require("@/icons");
var echarts5 = _interopRequireWildcard(require("echarts5"));
var echarts4 = _interopRequireWildcard(require("echarts"));
require("echarts/map/js/china.js");
var _vuePrintNb = _interopRequireDefault(require("vue-print-nb"));
var _globalPlugin = _interopRequireDefault(require("./utils/global-plugin"));
var _numberInput = require("./directive/number-input");
var _websocket = _interopRequireDefault(require("@/utils/websocket"));
var _SingleImgUpload = _interopRequireDefault(require("@/components/Upload/SingleImgUpload"));
var _MultiImgUpload = _interopRequireDefault(require("@/components/Upload/MultiImgUpload"));
var _UploadFile = _interopRequireDefault(require("@/components/Upload/UploadFile"));
var _index3 = _interopRequireDefault(require("@/components/TableImg/index"));
var _RichText = _interopRequireDefault(require("@/components/RichText/RichText"));
// A modern alternative to CSS resets

// global css

// icon
// 引入echarts以及中国地图china.js 这里多版本共存
//将websocket挂载全局
_vue.default.prototype.$echarts5 = echarts5;
_vue.default.prototype.$echarts = echarts4;
// 如果想要中文版 element-ui，按如下方式声明
_vue.default.use(_elementUi.default);
_vue.default.use(_vuePrintNb.default); //注册

_vue.default.use(_globalPlugin.default);
_vue.default.config.productionTip = false;

/**
 * 输入框输入时的数字校验指令
 */
_vue.default.directive('number-input', _numberInput.numberInput);
_vue.default.prototype.websocket = _websocket.default;
_vue.default.component('SingleImgUpload', _SingleImgUpload.default);
_vue.default.component('MultiImgUpload', _MultiImgUpload.default);
_vue.default.component('UploadFile', _UploadFile.default);
_vue.default.component('TableImg', _index3.default);
_vue.default.component('RichText', _RichText.default);
new _vue.default({
  el: '#app',
  router: _router.default,
  store: _store.default,
  created: function created() {
    window.mThis = this;
  },
  render: function render(h) {
    return h(_App.default);
  }
});