"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.adTime = void 0;
var adTime = {
  data: function data() {
    return {
      //是否展示详情窗口
      showDetailDialog: false,
      backUrl: '/advertise/adTime/page',
      entity: {},
      rules: {
        beginSeconds: [{
          required: true,
          message: '请输入开始时间（距离0点的秒数）'
        }],
        endSeconds: [{
          required: true,
          message: '请输入结束时间（距离0点的秒数）'
        }],
        timeStr: [{
          required: true,
          message: '请输入时间段'
        }, {
          max: 255,
          message: '时间段长度在255个字符内'
        }],
        baseMoney: [{
          required: true,
          message: '请输入最低的费用/秒'
        }],
        isDel: [{
          required: true,
          message: '请输入是否删除'
        }],
        value: [{
          required: true,
          message: '请选择时间段'
        }]
      }
    };
  }
};
exports.adTime = adTime;