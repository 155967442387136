var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.item.hidden
    ? _c(
        "div",
        { staticClass: "menu-wrapper" },
        [
          _vm.isEmpty(_vm.item.children)
            ? [
                _c(
                  "app-link",
                  { attrs: { to: _vm.item.url } },
                  [
                    _c(
                      "el-menu-item",
                      {
                        staticClass: "submenu-title-noDropdown",
                        attrs: {
                          index: _vm.isNotEmpty(_vm.item.url)
                            ? _vm.item.url
                            : _vm.item.name,
                        },
                      },
                      [
                        _c("item", {
                          attrs: { icon: _vm.item.icon, title: _vm.item.name },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            : _c(
                "el-submenu",
                {
                  ref: "subMenu",
                  attrs: {
                    index: _vm.isNotEmpty(_vm.item.url)
                      ? _vm.item.url
                      : _vm.item.name,
                    "popper-append-to-body": "",
                  },
                },
                [
                  _c(
                    "template",
                    { slot: "title" },
                    [
                      _c("item", {
                        attrs: { icon: _vm.item.icon, title: _vm.item.name },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm._l(_vm.item.children, function (child) {
                    return _c("sidebar-item", {
                      key: child.id,
                      staticClass: "nest-menu",
                      attrs: {
                        "is-nest": true,
                        item: child,
                        "base-path": child.url,
                      },
                    })
                  }),
                ],
                2
              ),
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }