var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "修改作业",
        visible: _vm.dialogVisible,
        "close-on-click-modal": false,
        center: "",
        width: _vm.dialogWidth,
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
        close: _vm.closeDialog,
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "elForm",
          staticClass: "dialog-form",
          attrs: {
            model: _vm.entity,
            rules: _vm.rules,
            inline: true,
            "label-width": _vm.getLabelWidth(),
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "教师ID", prop: "teacherUserId" } },
            [
              _c("el-input", {
                directives: [
                  {
                    name: "number-input",
                    rawName: "v-number-input",
                    value: { type: "integer" },
                    expression: "{ type: 'integer' }",
                  },
                ],
                attrs: { clearable: "" },
                model: {
                  value: _vm.entity.teacherUserId,
                  callback: function ($$v) {
                    _vm.$set(_vm.entity, "teacherUserId", $$v)
                  },
                  expression: "entity.teacherUserId",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "作业名称", prop: "name" } },
            [
              _c("el-input", {
                attrs: { clearable: "" },
                model: {
                  value: _vm.entity.name,
                  callback: function ($$v) {
                    _vm.$set(_vm.entity, "name", $$v)
                  },
                  expression: "entity.name",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "模式", prop: "mode" } },
            [
              _c(
                "el-select",
                {
                  model: {
                    value: _vm.entity.mode,
                    callback: function ($$v) {
                      _vm.$set(_vm.entity, "mode", $$v)
                    },
                    expression: "entity.mode",
                  },
                },
                _vm._l(_vm.modeMap, function (value, key) {
                  return _c("el-option", {
                    key: key,
                    attrs: { label: value, value: Number(key) },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "截止时间", prop: "overTime" } },
            [
              _c("el-input", {
                attrs: { clearable: "" },
                model: {
                  value: _vm.entity.overTime,
                  callback: function ($$v) {
                    _vm.$set(_vm.entity, "overTime", $$v)
                  },
                  expression: "entity.overTime",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "是否删除", prop: "isDel" } },
            [
              _c(
                "el-select",
                {
                  model: {
                    value: _vm.entity.isDel,
                    callback: function ($$v) {
                      _vm.$set(_vm.entity, "isDel", $$v)
                    },
                    expression: "entity.isDel",
                  },
                },
                _vm._l(_vm.isDelMap, function (value, key) {
                  return _c("el-option", {
                    key: key,
                    attrs: { label: value, value: Number(key) },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "计时的秒数", prop: "seconds" } },
            [
              _c("el-input", {
                directives: [
                  {
                    name: "number-input",
                    rawName: "v-number-input",
                    value: { type: "integer" },
                    expression: "{ type: 'integer' }",
                  },
                ],
                attrs: { clearable: "" },
                model: {
                  value: _vm.entity.seconds,
                  callback: function ($$v) {
                    _vm.$set(_vm.entity, "seconds", $$v)
                  },
                  expression: "entity.seconds",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "计数的数量", prop: "num" } },
            [
              _c("el-input", {
                directives: [
                  {
                    name: "number-input",
                    rawName: "v-number-input",
                    value: { type: "integer" },
                    expression: "{ type: 'integer' }",
                  },
                ],
                attrs: { clearable: "" },
                model: {
                  value: _vm.entity.num,
                  callback: function ($$v) {
                    _vm.$set(_vm.entity, "num", $$v)
                  },
                  expression: "entity.num",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "跳绳要求", prop: "conditionRule" } },
            [
              _c("el-input", {
                attrs: { clearable: "" },
                model: {
                  value: _vm.entity.conditionRule,
                  callback: function ($$v) {
                    _vm.$set(_vm.entity, "conditionRule", $$v)
                  },
                  expression: "entity.conditionRule",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "班级数组", prop: "classData" } },
            [
              _c("el-input", {
                attrs: { clearable: "" },
                model: {
                  value: _vm.entity.classData,
                  callback: function ($$v) {
                    _vm.$set(_vm.entity, "classData", $$v)
                  },
                  expression: "entity.classData",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "form-button-container" },
        [
          _c(
            "el-button",
            {
              attrs: { type: "danger", icon: "el-icon-back" },
              on: {
                click: function ($event) {
                  _vm.dialogVisible = false
                },
              },
            },
            [_vm._v("返回")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              directives: [
                { name: "preventReClick", rawName: "v-preventReClick" },
              ],
              staticClass: "form-button-submit",
              attrs: { type: "primary", icon: "el-icon-check" },
              on: { click: _vm.submitForm },
            },
            [_vm._v("提交")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }