var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-form",
        {
          staticClass: "search-form-container",
          attrs: { "label-width": "100px" },
        },
        [
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { xs: 24, sm: 12, md: 8, lg: 6 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "名称" } },
                    [
                      _c("el-input", {
                        attrs: { clearable: "" },
                        nativeOn: {
                          keyup: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                            return _vm.pageSearch($event)
                          },
                        },
                        model: {
                          value: _vm.pageParam.name,
                          callback: function ($$v) {
                            _vm.$set(_vm.pageParam, "name", $$v)
                          },
                          expression: "pageParam.name",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { xs: 24, sm: 12, md: 8, lg: 6 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "状态" } },
                    [
                      _c(
                        "el-select",
                        {
                          model: {
                            value: _vm.pageParam.status,
                            callback: function ($$v) {
                              _vm.$set(_vm.pageParam, "status", $$v)
                            },
                            expression: "pageParam.status",
                          },
                        },
                        _vm._l(_vm.statusMap, function (value, key) {
                          return _c("el-option", {
                            key: key,
                            attrs: { label: value, value: key },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "page-button-container" },
        [
          _vm.hasPermission("product:productCategory:add")
            ? _c(
                "el-button",
                {
                  attrs: {
                    type: "primary",
                    size: _vm.getButtonSize(),
                    icon: "el-icon-plus",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.showAdd({}, 1)
                    },
                  },
                },
                [_vm._v("新增一级分类\n      ")]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.hasPermission("product:productCategory:update")
            ? _c(
                "el-button",
                {
                  attrs: {
                    type: "warning",
                    size: _vm.getButtonSize(),
                    icon: "el-icon-edit",
                  },
                  on: { click: _vm.openUpdateDialog },
                },
                [_vm._v("修改\n      ")]
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "el-button",
            {
              staticClass: "search-button",
              attrs: {
                type: "info",
                size: _vm.getButtonSize(),
                icon: "el-icon-refresh",
              },
              on: { click: _vm.reset },
            },
            [_vm._v("重置\n      ")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              staticClass: "search-button",
              attrs: {
                type: "primary",
                size: _vm.getButtonSize(),
                icon: "el-icon-check",
              },
              on: { click: _vm.pageSearch },
            },
            [_vm._v("\n        搜索\n      ")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.pageLoading,
              expression: "pageLoading",
            },
          ],
          ref: "elTable",
          attrs: {
            "row-key": "id",
            data: _vm.pageResult.records,
            "element-loading-text": "正在查询中...",
            border: "",
            fit: "",
            "highlight-current-row": "",
          },
          on: {
            "sort-change": _vm.sortChange,
            "row-click": _vm.handleRowClick,
            "selection-change": _vm.handleSelectionChange,
          },
        },
        [
          _c("el-table-column", { attrs: { type: "selection", width: "40" } }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "分类名称",
              prop: "name",
              "show-overflow-tooltip": true,
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "图片",
              prop: "image",
              "show-overflow-tooltip": true,
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("table-img", { attrs: { imgUrl: scope.row.image } }),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "状态",
              prop: "status",
              "show-overflow-tooltip": true,
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "el-tag",
                      {
                        attrs: {
                          type: _vm.statusColorMap[scope.row.status],
                          size: "small",
                        },
                      },
                      [_vm._v(_vm._s(_vm.statusMap[scope.row.status]))]
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "排序",
              prop: "sort",
              "show-overflow-tooltip": true,
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "创建时间",
              prop: "createTime",
              "show-overflow-tooltip": true,
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "更新时间",
              prop: "updateTime",
              "show-overflow-tooltip": true,
            },
          }),
          _vm._v(" "),
          _vm.hasPermission("product:productCategory:add")
            ? _c("el-table-column", {
                attrs: {
                  fixed: "right",
                  align: "center",
                  label: "操作",
                  width: "130",
                  "show-overflow-tooltip": true,
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          scope.row.level === 1
                            ? _c(
                                "el-button",
                                {
                                  attrs: { size: "small", type: "primary" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.showAdd(scope.row, 2)
                                    },
                                  },
                                },
                                [_vm._v("添加二级分类\n          ")]
                              )
                            : _vm._e(),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  2294627402
                ),
              })
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c("pagination", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.pageResult.total > 0,
            expression: "pageResult.total > 0",
          },
        ],
        attrs: {
          total: _vm.pageResult.total,
          layout: _vm.getLayout(),
          page: _vm.pageParam.pageNumber,
          limit: _vm.pageParam.pageSize,
        },
        on: {
          "update:page": function ($event) {
            return _vm.$set(_vm.pageParam, "pageNumber", $event)
          },
          "update:limit": function ($event) {
            return _vm.$set(_vm.pageParam, "pageSize", $event)
          },
          pagination: _vm.pageSearch,
        },
      }),
      _vm._v(" "),
      _vm.showAddDialog
        ? _c("productCategory-add", {
            ref: "addDialog",
            attrs: { "dialog-width": _vm.getDialogWidth() },
            on: { close: _vm.closeAddDialog, refreshTable: _vm.refreshTable },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.showUpdateDialog
        ? _c("productCategory-update", {
            ref: "updateDialog",
            attrs: { "dialog-width": _vm.getDialogWidth() },
            on: {
              close: _vm.closeUpdateDialog,
              refreshTable: _vm.refreshTable,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }