"use strict";

var _interopRequireDefault = require("D:/shenbimaliang1/sbml-backend/backend-page/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _schoolApi = require("@/api/school-api");
var _globalDialog = require("@/mixins/global-dialog");
var _permission = require("@/mixins/permission");
var _school = require("./school");
var _SchoolAdd = _interopRequireDefault(require("./SchoolAdd"));
var _SchoolUpdate = _interopRequireDefault(require("./SchoolUpdate"));
var _SchoolGrade = _interopRequireDefault(require("@/views/school/school/SchoolGrade.vue"));
var _index = _interopRequireDefault(require("@/components/MineCascader/index.vue"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'SchoolPage',
  components: {
    MineCascader: _index.default,
    SchoolGrade: _SchoolGrade.default,
    Pagination: _Pagination.default,
    SchoolAdd: _SchoolAdd.default,
    SchoolUpdate: _SchoolUpdate.default
  },
  mixins: [_school.school, _globalDialog.pageDialog, _permission.permission],
  methods: {
    //导出
    exportExcel: function exportExcel() {
      (0, _schoolApi.schoolExport)(this.pageParam);
    },
    //调用接口查询
    doSearch: function doSearch() {
      return (0, _schoolApi.schoolPage)(this.pageParam);
    },
    /**
     * 执行批量删除操作
     * @param {Object} idList id集合
     */
    doBatchDelete: function doBatchDelete(idList) {
      return (0, _schoolApi.schoolDelete)(idList);
    },
    /**
     * 打开年级分页窗口
     */
    openGradeDialog: function openGradeDialog(selectData) {
      var _this = this;
      this.showGradeDialog = true;
      this.$nextTick(function () {
        _this.$refs.gradeDialog.init(selectData.id);
      });
    }
  }
};
exports.default = _default;