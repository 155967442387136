"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.teacherJob = void 0;
var teacherJob = {
  data: function data() {
    return {
      //是否展示详情窗口
      showDetailDialog: false,
      //模式枚举
      modeMap: {
        0: '计数',
        1: '计时'
      },
      //模式标签颜色枚举
      modeColorMap: {
        0: 'danger',
        1: 'success',
        2: 'danger',
        3: 'warning',
        4: 'info',
        5: 'primary'
      },
      //是否删除枚举
      isDelMap: {
        0: '否',
        1: '是'
      },
      //是否删除标签颜色枚举
      isDelColorMap: {
        0: 'danger',
        1: 'success',
        2: 'danger',
        3: 'warning',
        4: 'info',
        5: 'primary'
      },
      backUrl: '/teacher/teacherJob/page',
      entity: {},
      rules: {
        teacherUserId: [{
          required: true,
          message: '请输入教师ID'
        }],
        name: [{
          required: true,
          message: '请输入作业名称'
        }, {
          max: 255,
          message: '作业名称长度在255个字符内'
        }],
        mode: [{
          required: true,
          message: '请输入模式'
        }],
        overTime: [{
          required: true,
          message: '请输入截止时间'
        }],
        isDel: [{
          required: true,
          message: '请输入是否删除'
        }],
        seconds: [{
          required: true,
          message: '请输入计时的秒数'
        }],
        num: [{
          required: true,
          message: '请输入计数的数量'
        }],
        conditionRule: [{
          required: true,
          message: '请输入跳绳要求'
        }, {
          max: 1024,
          message: '跳绳要求长度在1024个字符内'
        }],
        classData: [{
          required: true,
          message: '请输入班级数组'
        }, {
          max: 1024,
          message: '班级数组长度在1024个字符内'
        }]
      }
    };
  }
};
exports.teacherJob = teacherJob;