"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _searchKeyword = require("./search-keyword");
var _globalDialog = require("@/mixins/global-dialog");
var _operationApi = require("@/api/operation-api");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  mixins: [_searchKeyword.searchKeyword, _globalDialog.updateDialog],
  methods: {
    //执行修改操作
    doUpdate: function doUpdate() {
      return (0, _operationApi.searchKeywordUpdate)(this.entity);
    }
  }
};
exports.default = _default;