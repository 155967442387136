var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "用户详情",
        visible: _vm.dialogVisible,
        "close-on-click-modal": false,
        center: "",
        width: _vm.dialogWidth,
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
        close: _vm.closeDialog,
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "elForm",
          staticClass: "dialog-form",
          attrs: {
            model: _vm.entity,
            rules: _vm.rules,
            inline: true,
            "label-width": _vm.getLabelWidth(),
          },
        },
        [
          _c(
            "el-descriptions",
            {
              staticClass: "margin-top",
              attrs: {
                "label-style": { width: "120px" },
                title: "基本信息",
                column: _vm.isPc() ? 3 : 1,
                size: "medium",
                border: "",
              },
            },
            [
              _c(
                "el-descriptions-item",
                { attrs: { "label-style": "width:120px;max-width:10%;" } },
                [
                  _c("template", { slot: "label" }, [
                    _c("i", { staticClass: "el-icon-picture" }),
                    _vm._v("\n          头像\n        "),
                  ]),
                  _vm._v(" "),
                  _c("single-img-upload", {
                    attrs: { height: "100", width: "100", isDisabled: "" },
                    model: {
                      value: _vm.entity.headImg,
                      callback: function ($$v) {
                        _vm.$set(_vm.entity, "headImg", $$v)
                      },
                      expression: "entity.headImg",
                    },
                  }),
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "el-descriptions-item",
                [
                  _c("template", { slot: "label" }, [
                    _c("i", { staticClass: "el-icon-user" }),
                    _vm._v("\n          昵称\n        "),
                  ]),
                  _vm._v(
                    "\n        " + _vm._s(_vm.entity.nickName) + "\n      "
                  ),
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "el-descriptions-item",
                [
                  _c("template", { slot: "label" }, [
                    _c("i", { staticClass: "el-icon-mobile" }),
                    _vm._v("\n          手机号\n        "),
                  ]),
                  _vm._v("\n        " + _vm._s(_vm.entity.mobile) + "\n      "),
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "el-descriptions-item",
                [
                  _c("template", { slot: "label" }, [
                    _c("i", { staticClass: "el-icon-postcard" }),
                    _vm._v("\n          角色\n        "),
                  ]),
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.levelMap[_vm.entity.level]) +
                      "\n      "
                  ),
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "el-descriptions-item",
                [
                  _c("template", { slot: "label" }, [
                    _c("i", { staticClass: "el-icon-postcard" }),
                    _vm._v("\n          邀请码\n        "),
                  ]),
                  _vm._v(
                    "\n        " + _vm._s(_vm.entity.inviteCode) + "\n      "
                  ),
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "el-descriptions-item",
                [
                  _c("template", { slot: "label" }, [
                    _c("i", { staticClass: "el-icon-postcard" }),
                    _vm._v("\n          邀请人\n        "),
                  ]),
                  _vm._v(
                    "\n        " + _vm._s(_vm.entity.inviterId) + "\n      "
                  ),
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "el-descriptions-item",
                [
                  _c("template", { slot: "label" }, [
                    _c("i", { staticClass: "el-icon-postcard" }),
                    _vm._v("\n          直推vip绳子数量\n        "),
                  ]),
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.entity.inviteVipRopeNum) +
                      "\n      "
                  ),
                ],
                2
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-descriptions",
            {
              staticClass: "margin-top",
              attrs: {
                title: "资金信息",
                "label-style": { width: "120px" },
                column: _vm.isPc() ? 3 : 1,
                size: "medium",
                border: "",
              },
            },
            [
              _c(
                "el-descriptions-item",
                [
                  _c("template", { slot: "label" }, [
                    _c("i", { staticClass: "el-icon-money" }),
                    _vm._v("\n          余额\n        "),
                  ]),
                  _vm._v("\n        " + _vm._s(_vm.entity.cash) + "\n      "),
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "el-descriptions-item",
                [
                  _c("template", { slot: "label" }, [
                    _c("i", { staticClass: "el-icon-money" }),
                    _vm._v("\n          健康宝\n        "),
                  ]),
                  _vm._v("\n        " + _vm._s(_vm.entity.points) + "\n      "),
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "el-descriptions-item",
                [
                  _c("template", { slot: "label" }, [
                    _c("i", { staticClass: "el-icon-money" }),
                    _vm._v("\n          广告卡\n        "),
                  ]),
                  _vm._v("\n        " + _vm._s(_vm.entity.adCard) + "\n      "),
                ],
                2
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-descriptions",
            {
              staticClass: "margin-top",
              attrs: {
                title: "状态信息",
                "label-style": { width: "120px" },
                column: _vm.isPc() ? 3 : 1,
                size: "medium",
                border: "",
              },
            },
            [
              _c(
                "el-descriptions-item",
                [
                  _c("template", { slot: "label" }, [
                    _c("i", { staticClass: "el-icon-postcard" }),
                    _vm._v("\n          注册IP\n        "),
                  ]),
                  _vm._v(
                    "\n        " + _vm._s(_vm.entity.registerIp) + "\n      "
                  ),
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "el-descriptions-item",
                [
                  _c("template", { slot: "label" }, [
                    _c("i", { staticClass: "el-icon-s-tools" }),
                    _vm._v("\n          状态\n        "),
                  ]),
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.statusMap[_vm.entity.status]) +
                      "\n      "
                  ),
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "el-descriptions-item",
                [
                  _c("template", { slot: "label" }, [
                    _c("i", { staticClass: "el-icon-s-tools" }),
                    _vm._v("\n          是否注销\n        "),
                  ]),
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.isDelMap[_vm.entity.isDel]) +
                      "\n      "
                  ),
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "el-descriptions-item",
                [
                  _c("template", { slot: "label" }, [
                    _c("i", { staticClass: "el-icon-time" }),
                    _vm._v("\n          注销发起时间\n        "),
                  ]),
                  _vm._v(
                    "\n        " + _vm._s(_vm.entity.delPostTime) + "\n      "
                  ),
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "el-descriptions-item",
                [
                  _c("template", { slot: "label" }, [
                    _c("i", { staticClass: "el-icon-time" }),
                    _vm._v("\n          注销通过时间\n        "),
                  ]),
                  _vm._v(
                    "\n        " + _vm._s(_vm.entity.delTime) + "\n      "
                  ),
                ],
                2
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-descriptions",
            {
              staticClass: "margin-top",
              attrs: {
                title: "账号信息",
                "label-style": { width: "120px" },
                column: _vm.isPc() ? 3 : 1,
                size: "medium",
                border: "",
              },
            },
            [
              _c(
                "el-descriptions-item",
                { staticStyle: { "max-width": "33%" } },
                [
                  _c("template", { slot: "label" }, [
                    _c("i", { staticClass: "el-icon-postcard" }),
                    _vm._v("\n          微信unionId\n        "),
                  ]),
                  _vm._v(
                    "\n        " + _vm._s(_vm.entity.wxUnionId) + "\n      "
                  ),
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "el-descriptions-item",
                { staticStyle: { "max-width": "33%" } },
                [
                  _c("template", { slot: "label" }, [
                    _c("i", { staticClass: "el-icon-postcard" }),
                    _vm._v("\n          app openId\n        "),
                  ]),
                  _vm._v(
                    "\n        " + _vm._s(_vm.entity.wxOpenId) + "\n      "
                  ),
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "el-descriptions-item",
                { staticStyle: { "max-width": "33%" } },
                [
                  _c("template", { slot: "label" }, [
                    _c("i", { staticClass: "el-icon-postcard" }),
                    _vm._v("\n          小程序openId\n        "),
                  ]),
                  _vm._v(
                    "\n        " + _vm._s(_vm.entity.wxMiniOpenId) + "\n      "
                  ),
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "el-descriptions-item",
                [
                  _c("template", { slot: "label" }, [
                    _c("i", { staticClass: "el-icon-postcard" }),
                    _vm._v("\n          苹果用户标识\n        "),
                  ]),
                  _vm._v(
                    "\n        " + _vm._s(_vm.entity.appleId) + "\n      "
                  ),
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "el-descriptions-item",
                [
                  _c("template", { slot: "label" }, [
                    _c("i", { staticClass: "el-icon-postcard" }),
                    _vm._v("\n          微信公众号 open id\n        "),
                  ]),
                  _vm._v(
                    "\n        " + _vm._s(_vm.entity.wxMpOpenId) + "\n      "
                  ),
                ],
                2
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "form-button-container" },
        [
          _c(
            "el-button",
            {
              attrs: { type: "danger", icon: "el-icon-back" },
              on: {
                click: function ($event) {
                  _vm.dialogVisible = false
                },
              },
            },
            [_vm._v("返回")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }