var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "notice" },
    [
      _c(
        "el-badge",
        {
          staticClass: "item",
          attrs: { max: 99 },
          model: {
            value: _vm.unReadCount,
            callback: function ($$v) {
              _vm.unReadCount = $$v
            },
            expression: "unReadCount",
          },
        },
        [
          _c(
            "el-popover",
            {
              attrs: {
                placement: "bottom",
                "popper-class": "notice-wrapper",
                trigger: "click",
                width: "360",
              },
              model: {
                value: _vm.showHistory,
                callback: function ($$v) {
                  _vm.showHistory = $$v
                },
                expression: "showHistory",
              },
            },
            [
              _c(
                "el-menu",
                {
                  staticClass: "notice-tab",
                  attrs: {
                    "default-active": _vm.tabIndex + "",
                    "active-text-color": "#40aaf7",
                    mode: "horizontal",
                  },
                  on: { select: _vm.changeTab },
                },
                [
                  _c("el-menu-item", { attrs: { index: "0" } }, [
                    _vm._v("公告(" + _vm._s(_vm.noticeData[0].length) + ")"),
                  ]),
                  _vm._v(" "),
                  _c("el-menu-item", { attrs: { index: "1" } }, [
                    _vm._v("发货(" + _vm._s(_vm.noticeData[1].length) + ")"),
                  ]),
                  _vm._v(" "),
                  _c("el-menu-item", { attrs: { index: "2" } }, [
                    _vm._v("退款(" + _vm._s(_vm.noticeData[2].length) + ")"),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "scroll-wrapper" },
                [
                  _vm._l(_vm.messages, function (item, index) {
                    return _c(
                      "div",
                      {
                        key: index,
                        staticClass: "scroll-item",
                        on: {
                          click: function ($event) {
                            return _vm.clickNotice(_vm.currentIndex, item)
                          },
                        },
                      },
                      [
                        _c("div", { staticClass: "content" }, [
                          _vm._v(_vm._s(item.data.content)),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "other" }, [
                          _c("div", { staticClass: "datetime" }, [
                            _vm._v(_vm._s(item.data.date)),
                          ]),
                          _vm._v(" "),
                          _c("div", { class: { unread: !item.isRead } }, [
                            _vm._v(_vm._s(item.isRead ? "已读" : "未读")),
                          ]),
                        ]),
                      ]
                    )
                  }),
                  _vm._v(" "),
                  _vm.messages.length < 1
                    ? _c(
                        "div",
                        {
                          staticStyle: {
                            "text-align": "center",
                            padding: "10px 0",
                            "margin-top": "10px",
                          },
                        },
                        [_vm._v("\n          当前暂无新的消息~~\n        ")]
                      )
                    : _vm._e(),
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "notice-bottom" },
                [
                  _c(
                    "el-button",
                    { attrs: { type: "text" }, on: { click: _vm.allRead } },
                    [_vm._v("全部已读")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    { attrs: { type: "text" }, on: { click: _vm.allClear } },
                    [_vm._v("全部清空")]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "tip" }, [
                _vm._v("\n        ps:消息最多保留30天\n      "),
              ]),
              _vm._v(" "),
              _c("i", {
                staticClass: "el-icon-message-solid",
                style: { color: _vm.haveNewMsg ? "red" : "#676666" },
                attrs: { slot: "reference" },
                slot: "reference",
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }