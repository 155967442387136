"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.teacherClass = void 0;
var teacherClass = {
  data: function data() {
    return {
      //是否展示详情窗口
      showDetailDialog: false,
      backUrl: '/teacher/teacherClass/page',
      entity: {},
      rules: {
        userId: [{
          required: true,
          message: '请输入用户ID'
        }],
        applyId: [{
          required: true,
          message: '请输入申请ID'
        }],
        gradeName: [{
          required: true,
          message: '请输入年级'
        }, {
          max: 255,
          message: '年级长度在255个字符内'
        }],
        className: [{
          required: true,
          message: '请输入班级'
        }, {
          max: 255,
          message: '班级长度在255个字符内'
        }]
      }
    };
  }
};
exports.teacherClass = teacherClass;