"use strict";

var _interopRequireDefault = require("D:/shenbimaliang1/sbml-backend/backend-page/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _sportApi = require("@/api/sport-api");
var _globalDialog = require("@/mixins/global-dialog");
var _permission = require("@/mixins/permission");
var _ropeDeviceData = require("./rope-device-data");
var _RopeDeviceDataAdd = _interopRequireDefault(require("./RopeDeviceDataAdd"));
var _RopeDeviceDataUpdate = _interopRequireDefault(require("./RopeDeviceDataUpdate"));
var _RopeDeviceDataDetail = _interopRequireDefault(require("./RopeDeviceDataDetail"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'RopeDeviceDataPage',
  components: {
    Pagination: _Pagination.default,
    RopeDeviceDataAdd: _RopeDeviceDataAdd.default,
    RopeDeviceDataUpdate: _RopeDeviceDataUpdate.default,
    RopeDeviceDataDetail: _RopeDeviceDataDetail.default
  },
  mixins: [_ropeDeviceData.ropeDeviceData, _globalDialog.pageDialog, _permission.permission],
  methods: {
    //导出
    exportExcel: function exportExcel() {
      (0, _sportApi.ropeDeviceDataExport)(this.pageParam);
    },
    //调用接口查询
    doSearch: function doSearch() {
      return (0, _sportApi.ropeDeviceDataPage)(this.pageParam);
    },
    /**
     * 执行批量删除操作
     * @param {Object} idList id集合
     */
    doBatchDelete: function doBatchDelete(idList) {
      return (0, _sportApi.ropeDeviceDataDelete)(idList);
    },
    /**
     * 打开绳子信息详情窗口
     */
    openDetailDialog: function openDetailDialog() {
      var _this = this;
      var selectData = this.getSelect();
      if (this.isEmpty(selectData)) {
        return;
      }
      this.showDetailDialog = true;
      this.$nextTick(function () {
        // 把选中行的数据拷贝一份传递给修改对话框
        selectData = _this.transTimeDia(selectData);
        _this.$refs.detailDialog.setEntity(_this.objectCopy(selectData));
      });
    }
  }
};
exports.default = _default;