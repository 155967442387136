var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-form",
        {
          staticClass: "search-form-container",
          attrs: { "label-width": "100px" },
        },
        [
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { xs: 24, sm: 12, md: 8, lg: 6 } },
                [
                  _c("el-form-item", { attrs: { label: "创建时间" } }, [
                    _c(
                      "div",
                      { staticStyle: { width: "100%" } },
                      [
                        _c("el-date-picker", {
                          staticStyle: { width: "100%" },
                          attrs: {
                            "value-format": "yyyy-MM-dd",
                            type: "daterange",
                            align: "right",
                            "unlink-panels": "",
                            "picker-options": _vm.pickerOptions,
                            "range-separator": "至",
                            "start-placeholder": "开始日期",
                            "end-placeholder": "结束日期",
                          },
                          model: {
                            value: _vm.pageParam.createTimeRange,
                            callback: function ($$v) {
                              _vm.$set(_vm.pageParam, "createTimeRange", $$v)
                            },
                            expression: "pageParam.createTimeRange",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { xs: 24, sm: 12, md: 8, lg: 6 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "状态" } },
                    [
                      _c(
                        "el-select",
                        {
                          model: {
                            value: _vm.pageParam.status,
                            callback: function ($$v) {
                              _vm.$set(_vm.pageParam, "status", $$v)
                            },
                            expression: "pageParam.status",
                          },
                        },
                        _vm._l(_vm.statusMap, function (value, key) {
                          return _c("el-option", {
                            key: key,
                            attrs: { label: value, value: key },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { xs: 24, sm: 12, md: 8, lg: 6 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "类型" } },
                    [
                      _c(
                        "el-select",
                        {
                          model: {
                            value: _vm.pageParam.type,
                            callback: function ($$v) {
                              _vm.$set(_vm.pageParam, "type", $$v)
                            },
                            expression: "pageParam.type",
                          },
                        },
                        _vm._l(_vm.typeMap, function (value, key) {
                          return _c("el-option", {
                            key: key,
                            attrs: { label: value, value: key },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "page-button-container" },
        [
          _vm.hasPermission("operation:notice:add")
            ? _c(
                "el-button",
                {
                  attrs: {
                    type: "primary",
                    size: _vm.getButtonSize(),
                    icon: "el-icon-plus",
                  },
                  on: { click: _vm.openAddDialog },
                },
                [_vm._v("新增\n      ")]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.hasPermission("operation:notice:update")
            ? _c(
                "el-button",
                {
                  attrs: {
                    type: "warning",
                    size: _vm.getButtonSize(),
                    icon: "el-icon-edit",
                  },
                  on: { click: _vm.openUpdateDialog },
                },
                [_vm._v("修改\n      ")]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.hasPermission("operation:notice:delete")
            ? _c(
                "el-button",
                {
                  attrs: {
                    type: "danger",
                    size: _vm.getButtonSize(),
                    icon: "el-icon-close",
                  },
                  on: { click: _vm.batchDelete },
                },
                [_vm._v("删除\n      ")]
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "el-button",
            {
              staticClass: "search-button",
              attrs: {
                type: "info",
                size: _vm.getButtonSize(),
                icon: "el-icon-refresh",
              },
              on: { click: _vm.reset },
            },
            [_vm._v("重置\n      ")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              staticClass: "search-button",
              attrs: {
                type: "primary",
                size: _vm.getButtonSize(),
                icon: "el-icon-check",
              },
              on: { click: _vm.pageSearch },
            },
            [_vm._v("\n        搜索\n      ")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.pageLoading,
              expression: "pageLoading",
            },
          ],
          ref: "elTable",
          attrs: {
            data: _vm.pageResult.records,
            "element-loading-text": "正在查询中...",
            border: "",
            fit: "",
            "highlight-current-row": "",
          },
          on: {
            "sort-change": _vm.sortChange,
            "row-click": _vm.handleRowClick,
            "selection-change": _vm.handleSelectionChange,
          },
        },
        [
          _c("el-table-column", { attrs: { type: "selection", width: "40" } }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "ID",
              prop: "id",
              "show-overflow-tooltip": true,
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "标题",
              prop: "name",
              "show-overflow-tooltip": true,
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "图片",
              prop: "image",
              "show-overflow-tooltip": true,
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("table-img", { attrs: { imgUrl: scope.row.image } }),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "状态",
              prop: "status",
              "show-overflow-tooltip": true,
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "el-tag",
                      {
                        attrs: {
                          type: _vm.statusColorMap[scope.row.status],
                          size: "small",
                        },
                      },
                      [_vm._v(_vm._s(_vm.statusMap[scope.row.status]))]
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "类型",
              prop: "type",
              "show-overflow-tooltip": true,
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "el-tag",
                      {
                        attrs: {
                          type: _vm.typeColorMap[scope.row.type],
                          size: "small",
                        },
                      },
                      [_vm._v(_vm._s(_vm.typeMap[scope.row.type]))]
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              width: "180",
              label: "创建时间",
              prop: "createTime",
              "show-overflow-tooltip": true,
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              width: "180",
              label: "更新时间",
              prop: "updateTime",
              "show-overflow-tooltip": true,
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("pagination", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.pageResult.total > 0,
            expression: "pageResult.total > 0",
          },
        ],
        attrs: {
          total: _vm.pageResult.total,
          layout: _vm.getLayout(),
          page: _vm.pageParam.pageNumber,
          limit: _vm.pageParam.pageSize,
        },
        on: {
          "update:page": function ($event) {
            return _vm.$set(_vm.pageParam, "pageNumber", $event)
          },
          "update:limit": function ($event) {
            return _vm.$set(_vm.pageParam, "pageSize", $event)
          },
          pagination: _vm.pageSearch,
        },
      }),
      _vm._v(" "),
      _vm.showAddDialog
        ? _c("notice-add", {
            attrs: { "dialog-width": _vm.getDialogWidth() },
            on: { close: _vm.closeAddDialog, refreshTable: _vm.refreshTable },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.showUpdateDialog
        ? _c("notice-update", {
            ref: "updateDialog",
            attrs: { "dialog-width": _vm.getDialogWidth() },
            on: {
              close: _vm.closeUpdateDialog,
              refreshTable: _vm.refreshTable,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }