"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
var _default2 = {
  name: "Bar",
  props: {
    bId: {
      type: String,
      default: function _default() {
        return this.guid();
      }
    },
    data: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    title: {
      type: String
    }
  },
  watch: {
    data: function data() {
      this.getBarData();
    }
  },
  methods: {
    /**
     * 加载柱形图数据
     */
    getBarData: function getBarData() {
      try {
        var barChart = this.$echarts5.init(document.getElementById('barChart' + this.bId));
        var option = {
          title: {
            text: this.title,
            left: 'center'
          },
          legend: {
            left: 10,
            top: '20px'
          },
          tooltip: {},
          dataset: {
            source: this.data
          },
          toolbox: {
            feature: {
              saveAsImage: {}
            }
          },
          xAxis: {
            type: 'category'
          },
          yAxis: {},
          // Declare several bar series, each will be mapped
          // to a column of dataset.source by default.
          series: [{
            type: 'bar'
          }, {
            type: 'bar'
          }]
        };
        barChart.setOption(option);
        //随着屏幕大小调节图表
        window.addEventListener("resize", function () {
          barChart.resize();
        });
      } catch (e) {}
    }
  }
};
exports.default = _default2;