var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-upload",
    {
      ref: "upload",
      staticClass: "upload-demo",
      attrs: {
        action: _vm.uploadImportUrl,
        "show-file-list": false,
        "before-upload": _vm.beforeAvatarUpload,
        "on-success": _vm.handleAvatarSuccess,
        "on-error": _vm.handleAvatarError,
        accept: ".xlsx",
        "auto-upload": false,
        multiple: false,
        limit: 1,
      },
    },
    [
      _vm.hasPermission("user:wallet:readExcel")
        ? _c(
            "el-button",
            {
              attrs: {
                slot: "trigger",
                type: "primary",
                icon: "el-icon-upload",
              },
              slot: "trigger",
            },
            [_vm._v("预导入")]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.hasPermission("user:wallet:readExcel")
        ? _c(
            "el-button",
            {
              attrs: {
                type: "primary",
                icon: "el-icon-check",
                loading: _vm.isLoading,
              },
              on: { click: _vm.submitUpload },
            },
            [_vm._v("提交上传")]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }