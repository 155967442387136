"use strict";

var _interopRequireDefault = require("D:/shenbimaliang1/sbml-backend/backend-page/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _seller = require("./seller");
var _globalDialog = require("@/mixins/global-dialog");
var _sellerApi = require("@/api/seller-api");
var _index = _interopRequireDefault(require("@/components/MineCascader/index.vue"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    MineCascader: _index.default
  },
  mixins: [_seller.seller, _globalDialog.updateDialog],
  methods: {
    //执行修改操作
    doUpdate: function doUpdate() {
      return (0, _sellerApi.sellerUpdate)(this.entity);
    }
  }
};
exports.default = _default;