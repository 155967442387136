var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-select",
    {
      attrs: { value: _vm.valueTitle, clearable: _vm.clearable },
      on: { clear: _vm.clearHandle },
    },
    [
      _c(
        "el-option",
        { attrs: { value: _vm.valueTitle, label: _vm.valueTitle } },
        [
          _c("el-tree", {
            ref: "selectTree",
            attrs: {
              id: "tree-option",
              accordion: _vm.accordion,
              data: _vm.treeData,
              props: _vm.treeProps,
              "node-key": _vm.treeProps.value,
              "default-expanded-keys": _vm.defaultExpandedKey,
            },
            on: { "node-click": _vm.handleNodeClick },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }