var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-form",
        {
          staticClass: "search-form-container",
          attrs: { "label-width": "100px" },
        },
        [
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { xs: 24, sm: 12, md: 8, lg: 6 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "名称" } },
                    [
                      _c("el-input", {
                        attrs: { clearable: "" },
                        nativeOn: {
                          keyup: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                            return _vm.pageSearch($event)
                          },
                        },
                        model: {
                          value: _vm.pageParam.title,
                          callback: function ($$v) {
                            _vm.$set(_vm.pageParam, "title", $$v)
                          },
                          expression: "pageParam.title",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { xs: 24, sm: 12, md: 8, lg: 6 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "是否在售" } },
                    [
                      _c(
                        "el-select",
                        {
                          model: {
                            value: _vm.pageParam.isSales,
                            callback: function ($$v) {
                              _vm.$set(_vm.pageParam, "isSales", $$v)
                            },
                            expression: "pageParam.isSales",
                          },
                        },
                        _vm._l(_vm.isSalesMap, function (value, key) {
                          return _c("el-option", {
                            key: key,
                            attrs: { label: value, value: key },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { xs: 24, sm: 12, md: 8, lg: 6 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "状态" } },
                    [
                      _c(
                        "el-select",
                        {
                          model: {
                            value: _vm.pageParam.status,
                            callback: function ($$v) {
                              _vm.$set(_vm.pageParam, "status", $$v)
                            },
                            expression: "pageParam.status",
                          },
                        },
                        _vm._l(_vm.statusMap, function (value, key) {
                          return _c("el-option", {
                            key: key,
                            attrs: { label: value, value: key },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { xs: 24, sm: 12, md: 8, lg: 6 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "专区" } },
                    [
                      _c(
                        "el-select",
                        {
                          model: {
                            value: _vm.pageParam.area,
                            callback: function ($$v) {
                              _vm.$set(_vm.pageParam, "area", $$v)
                            },
                            expression: "pageParam.area",
                          },
                        },
                        _vm._l(_vm.areaMap, function (value, key) {
                          return _c("el-option", {
                            key: key,
                            attrs: { label: value, value: key },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { xs: 24, sm: 12, md: 8, lg: 6 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "是否热门" } },
                    [
                      _c(
                        "el-select",
                        {
                          model: {
                            value: _vm.pageParam.isHot,
                            callback: function ($$v) {
                              _vm.$set(_vm.pageParam, "isHot", $$v)
                            },
                            expression: "pageParam.isHot",
                          },
                        },
                        _vm._l(_vm.isHotMap, function (value, key) {
                          return _c("el-option", {
                            key: key,
                            attrs: { label: value, value: key },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              !_vm.isSeller()
                ? _c(
                    "el-col",
                    { attrs: { xs: 24, sm: 12, md: 8, lg: 6 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "商家ID" } },
                        [
                          _c("el-input", {
                            attrs: { clearable: "" },
                            nativeOn: {
                              keyup: function ($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                ) {
                                  return null
                                }
                                return _vm.pageSearch($event)
                              },
                            },
                            model: {
                              value: _vm.pageParam.sellerId,
                              callback: function ($$v) {
                                _vm.$set(_vm.pageParam, "sellerId", $$v)
                              },
                              expression: "pageParam.sellerId",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              !_vm.isSeller()
                ? _c(
                    "el-col",
                    { attrs: { xs: 24, sm: 12, md: 8, lg: 6 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "商家名称" } },
                        [
                          _c("el-input", {
                            attrs: { clearable: "" },
                            nativeOn: {
                              keyup: function ($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                ) {
                                  return null
                                }
                                return _vm.pageSearch($event)
                              },
                            },
                            model: {
                              value: _vm.pageParam.sellerName,
                              callback: function ($$v) {
                                _vm.$set(_vm.pageParam, "sellerName", $$v)
                              },
                              expression: "pageParam.sellerName",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { xs: 24, sm: 12, md: 8, lg: 6 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "商品分类" } },
                    [
                      _c("el-cascader", {
                        attrs: {
                          options: _vm.cateGoryOptions,
                          filterable: true,
                          clearable: "",
                          props: _vm.categoryProps,
                        },
                        model: {
                          value: _vm.pageParam.categoryIdList,
                          callback: function ($$v) {
                            _vm.$set(_vm.pageParam, "categoryIdList", $$v)
                          },
                          expression: "pageParam.categoryIdList",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "page-button-container" },
        [
          _c(
            "el-button",
            {
              attrs: {
                type: "success",
                size: _vm.getButtonSize(),
                icon: "el-icon-info",
              },
              on: { click: _vm.openDetailDialog },
            },
            [_vm._v("详情\n    ")]
          ),
          _vm._v(" "),
          _vm.hasPermission("product:product:add")
            ? _c(
                "el-button",
                {
                  attrs: {
                    type: "primary",
                    size: _vm.getButtonSize(),
                    icon: "el-icon-plus",
                  },
                  on: { click: _vm.openAddDialog },
                },
                [_vm._v("发布\n    ")]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.hasPermission("product:product:update")
            ? _c(
                "el-button",
                {
                  attrs: {
                    type: "warning",
                    size: _vm.getButtonSize(),
                    icon: "el-icon-edit",
                  },
                  on: { click: _vm.openUpdateDialog },
                },
                [_vm._v("修改\n    ")]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.hasPermission("product:product:shelf")
            ? _c(
                "el-button",
                {
                  attrs: {
                    type: "primary",
                    size: _vm.getButtonSize(),
                    icon: "el-icon-top",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.doShelf(1)
                    },
                  },
                },
                [_vm._v("商品上架\n    ")]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.hasPermission("product:product:shelf")
            ? _c(
                "el-button",
                {
                  attrs: {
                    type: "warning",
                    size: _vm.getButtonSize(),
                    icon: "el-icon-bottom",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.doShelf(0)
                    },
                  },
                },
                [_vm._v("商品下架\n    ")]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.hasPermission("product:product:review")
            ? _c(
                "el-button",
                {
                  attrs: {
                    type: "danger",
                    size: _vm.getButtonSize(),
                    icon: "el-icon-document",
                  },
                  on: { click: _vm.openReviewDialog },
                },
                [_vm._v("商品审核\n    ")]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.hasPermission("product:product:delete")
            ? _c(
                "el-button",
                {
                  attrs: {
                    type: "danger",
                    size: _vm.getButtonSize(),
                    icon: "el-icon-close",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.batchDelete({}, "商品")
                    },
                  },
                },
                [_vm._v("删除\n    ")]
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "el-button",
            {
              staticClass: "search-button",
              attrs: {
                type: "info",
                size: _vm.getButtonSize(),
                icon: "el-icon-refresh",
              },
              on: { click: _vm.reset },
            },
            [_vm._v("重置\n    ")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              staticClass: "search-button",
              attrs: {
                type: "primary",
                size: _vm.getButtonSize(),
                icon: "el-icon-check",
              },
              on: { click: _vm.pageSearch },
            },
            [_vm._v("\n      搜索\n    ")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.pageLoading,
              expression: "pageLoading",
            },
          ],
          ref: "elTable",
          attrs: {
            data: _vm.pageResult.records,
            "element-loading-text": "正在查询中...",
            border: "",
            fit: "",
            "highlight-current-row": "",
          },
          on: {
            "sort-change": _vm.sortChange,
            "row-click": _vm.handleRowClick,
            "selection-change": _vm.handleSelectionChange,
          },
        },
        [
          _c("el-table-column", { attrs: { type: "selection", width: "40" } }),
          _vm._v(" "),
          !_vm.isSeller()
            ? _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "ID",
                  prop: "id",
                  "show-overflow-tooltip": true,
                },
              })
            : _vm._e(),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              "min-width": "134",
              label: "名称",
              prop: "title",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", { staticStyle: { "font-size": "10px" } }, [
                      _vm._v(_vm._s(scope.row.title)),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "主图",
              prop: "thumb",
              "show-overflow-tooltip": true,
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("table-img", { attrs: { imgUrl: scope.row.thumb } }),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          !_vm.isSeller()
            ? _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "商家ID",
                  prop: "sellerId",
                  "show-overflow-tooltip": true,
                },
              })
            : _vm._e(),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              width: "150",
              label: "商家名称",
              prop: "sellerName",
              "show-overflow-tooltip": true,
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              "min-width": "100",
              label: "售价/市场价",
              "show-overflow-tooltip": true,
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.price !== scope.row.marketPrice
                      ? _c("div", [
                          _c(
                            "span",
                            {
                              staticStyle: {
                                "font-size": "14px",
                                color: "#f50d0d",
                              },
                            },
                            [_vm._v("￥" + _vm._s(scope.row.price))]
                          ),
                          _vm._v("/"),
                          _c(
                            "span",
                            {
                              staticStyle: {
                                "font-size": "12px",
                                "text-decoration": "line-through",
                              },
                            },
                            [_vm._v("￥" + _vm._s(scope.row.marketPrice))]
                          ),
                        ])
                      : _c("div", [
                          _c(
                            "span",
                            {
                              staticStyle: {
                                "font-size": "14px",
                                color: "#f50d0d",
                              },
                            },
                            [_vm._v("￥" + _vm._s(scope.row.price))]
                          ),
                        ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              "min-width": "80",
              label: "结算价",
              prop: "basePrice",
              "show-overflow-tooltip": true,
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "span",
                      {
                        staticStyle: { "font-size": "14px", color: "#6cce2e" },
                      },
                      [_vm._v("￥" + _vm._s(scope.row.basePrice))]
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "预估库存",
              prop: "stock",
              "show-overflow-tooltip": true,
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.stock < 100
                      ? _c(
                          "div",
                          [
                            _c(
                              "el-popover",
                              {
                                attrs: {
                                  trigger: "hover",
                                  content: "库存不足100件！请及时补充~",
                                },
                              },
                              [
                                _c(
                                  "el-badge",
                                  {
                                    staticStyle: {
                                      "margin-top": "10px",
                                      "margin-right": "10px",
                                    },
                                    attrs: { slot: "reference", "is-dot": "" },
                                    slot: "reference",
                                  },
                                  [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(scope.row.stock) +
                                        "\n            "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _c("div", [
                          _vm._v(
                            "\n          " +
                              _vm._s(scope.row.stock) +
                              "\n        "
                          ),
                        ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "销量",
              prop: "sales",
              "show-overflow-tooltip": true,
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              "min-width": "130",
              label: "分类",
              prop: "firstCateName",
              "show-overflow-tooltip": true,
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", { staticStyle: { "font-size": "12px" } }, [
                      _vm._v(
                        _vm._s(scope.row.firstCateName) +
                          "/" +
                          _vm._s(scope.row.cateName)
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              width: "200",
              align: "center",
              label: "综合评分",
              prop: "rating",
              "show-overflow-tooltip": true,
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.rating !== 0
                      ? _c("el-rate", {
                          attrs: {
                            disabled: "",
                            "show-score": "",
                            "text-color": "#ff9900",
                            "score-template": "{value}",
                          },
                          model: {
                            value: scope.row.rating,
                            callback: function ($$v) {
                              _vm.$set(scope.row, "rating", $$v)
                            },
                            expression: "scope.row.rating",
                          },
                        })
                      : _c("span", [_vm._v("暂无评价")]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "状态",
              prop: "status",
              "show-overflow-tooltip": true,
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "el-tag",
                      {
                        attrs: {
                          type: _vm.statusColorMap[scope.row.status],
                          size: "small",
                        },
                      },
                      [_vm._v(_vm._s(_vm.statusMap[scope.row.status]))]
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              width: "100",
              label: "专区",
              prop: "area",
              "show-overflow-tooltip": true,
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "el-tag",
                      {
                        attrs: {
                          type: _vm.areaColorMap[scope.row.area],
                          size: "small",
                        },
                      },
                      [_vm._v(_vm._s(_vm.areaMap[scope.row.area]))]
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              width: "150",
              align: "center",
              label: "是否热门",
              prop: "isHot",
              "show-overflow-tooltip": true,
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("el-switch", {
                      attrs: {
                        disabled: _vm.isSeller(),
                        "active-text": "是",
                        "active-value": 1,
                        "inactive-value": 0,
                        "inactive-color": "#FB3858",
                        "inactive-text": "否",
                      },
                      on: {
                        change: function ($event) {
                          return _vm.changeIsHot(scope.row)
                        },
                      },
                      model: {
                        value: scope.row.isHot,
                        callback: function ($$v) {
                          _vm.$set(scope.row, "isHot", $$v)
                        },
                        expression: "scope.row.isHot",
                      },
                    }),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "是否在售",
              prop: "isSales",
              "show-overflow-tooltip": true,
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "el-tag",
                      {
                        attrs: {
                          type: _vm.isSalesColorMap[scope.row.isSales],
                          size: "small",
                        },
                      },
                      [_vm._v(_vm._s(_vm.isSalesMap[scope.row.isSales]))]
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _vm.isPc()
            ? _c("el-table-column", {
                attrs: {
                  align: "center",
                  width: "180",
                  label: "发布时间",
                  prop: "createTime",
                  "show-overflow-tooltip": true,
                },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.isPc()
            ? _c("el-table-column", {
                attrs: {
                  align: "center",
                  width: "180",
                  label: "修改时间",
                  prop: "updateTime",
                  "show-overflow-tooltip": true,
                },
              })
            : _vm._e(),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              fixed: "right",
              align: "center",
              label: "操作",
              width: "110",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          display: "flex",
                          "flex-direction": "column",
                          "align-items": "center",
                          "justify-content": "center",
                          "text-align": "center",
                        },
                      },
                      [
                        scope.row.model === 1
                          ? _c(
                              "el-button",
                              {
                                attrs: { type: "text", size: "small" },
                                on: {
                                  click: function ($event) {
                                    return _vm.openSkuDialog(scope.row)
                                  },
                                },
                              },
                              [_vm._v("\n            商品规格\n          ")]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text", size: "small" },
                            on: {
                              click: function ($event) {
                                return _vm.openRatingDialog(scope.row)
                              },
                            },
                          },
                          [_vm._v("评价列表")]
                        ),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("pagination", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.pageResult.total > 0,
            expression: "pageResult.total > 0",
          },
        ],
        attrs: {
          total: _vm.pageResult.total,
          layout: _vm.getLayout(),
          page: _vm.pageParam.pageNumber,
          limit: _vm.pageParam.pageSize,
        },
        on: {
          "update:page": function ($event) {
            return _vm.$set(_vm.pageParam, "pageNumber", $event)
          },
          "update:limit": function ($event) {
            return _vm.$set(_vm.pageParam, "pageSize", $event)
          },
          pagination: _vm.pageSearch,
        },
      }),
      _vm._v(" "),
      _vm.showAddDialog
        ? _c("product-add", {
            attrs: { "dialog-width": _vm.getDialogWidth() },
            on: { close: _vm.closeAddDialog, refreshTable: _vm.refreshTable },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.showUpdateDialog
        ? _c("product-update", {
            ref: "updateDialog",
            attrs: { "dialog-width": _vm.getDialogWidth() },
            on: {
              close: _vm.closeUpdateDialog,
              refreshTable: _vm.refreshTable,
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.showDetailDialog
        ? _c("product-detail", {
            ref: "detailDialog",
            attrs: { "dialog-width": _vm.getDialogWidth() },
            on: {
              close: function ($event) {
                _vm.showDetailDialog = false
              },
              refreshTable: _vm.refreshTable,
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.showReviewDialog
        ? _c("product-review", {
            ref: "reviewDialog",
            attrs: { "dialog-width": _vm.getDialogWidth() },
            on: {
              close: function ($event) {
                _vm.showReviewDialog = false
              },
              refreshTable: _vm.refreshTable,
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.showSkuDialog
        ? _c("product-sku", {
            ref: "skuDialog",
            attrs: { "dialog-width": _vm.getDialogWidth() },
            on: {
              close: function ($event) {
                _vm.showSkuDialog = false
              },
              refreshTable: _vm.refreshTable,
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.showRatingDialog
        ? _c("product-rating", {
            ref: "ratingDialog",
            attrs: { "dialog-width": _vm.getDialogWidth() },
            on: {
              close: function ($event) {
                _vm.showRatingDialog = false
              },
              refreshTable: _vm.refreshTable,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }