"use strict";

var _interopRequireDefault = require("D:/shenbimaliang1/sbml-backend/backend-page/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _systemApi = require("@/api/system-api");
var _globalPage = require("@/mixins/global-page");
var _permission = require("@/mixins/permission");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'RolePage',
  components: {
    Pagination: _Pagination.default
  },
  data: function data() {
    return {
      updatePageUrl: '/system/role/update' //修改页面地址
    };
  },

  mixins: [_globalPage.page, _permission.permission],
  methods: {
    //新增
    jumpAddPage: function jumpAddPage() {
      this.$router.push({
        path: '/system/role/add'
      });
    },
    //查询
    doSearch: function doSearch() {
      return (0, _systemApi.rolePage)(this.pageParam);
    },
    /**
     * 执行批量删除操作
     * @param {Object} idList id集合
     */
    doBatchDelete: function doBatchDelete(idList) {
      return (0, _systemApi.roleDelete)(idList);
    }
  }
};
exports.default = _default;