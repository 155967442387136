"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.teacherRaceLog = void 0;
var teacherRaceLog = {
  data: function data() {
    return {
      //是否展示详情窗口
      showDetailDialog: false,
      backUrl: '/teacher/teacherRaceLog/page',
      entity: {},
      rules: {
        raceId: [{
          required: true,
          message: '请输入比赛ID'
        }],
        ropeId: [{
          required: true,
          message: '请输入跳绳UUID'
        }],
        ropeDataName: [{
          required: true,
          message: '请输入跳绳名称'
        }, {
          max: 255,
          message: '跳绳名称长度在255个字符内'
        }],
        ropeDataImg: [{
          required: true,
          message: '请输入跳绳头像'
        }, {
          max: 255,
          message: '跳绳头像长度在255个字符内'
        }],
        schoolName: [{
          required: true,
          message: '请输入学校'
        }, {
          max: 255,
          message: '学校长度在255个字符内'
        }],
        gradeName: [{
          required: true,
          message: '请输入年级'
        }, {
          max: 255,
          message: '年级长度在255个字符内'
        }],
        className: [{
          required: true,
          message: '请输入班级'
        }, {
          max: 255,
          message: '班级长度在255个字符内'
        }],
        note: [{
          required: true,
          message: '请输入备注'
        }, {
          max: 255,
          message: '备注长度在255个字符内'
        }],
        data: [{
          required: true,
          message: '请输入数量（个）或者时长（秒）'
        }],
        teacherUserId: [{
          required: true,
          message: '请输入教师ID'
        }]
      }
    };
  }
};
exports.teacherRaceLog = teacherRaceLog;