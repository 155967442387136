"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.number.constructor");
//
//
//
//
//
//
var _default = {
  name: "SwaggerUI",
  data: function data() {
    return {
      url: '/swagger-ui/index.html'
    };
  },
  created: function created() {
    this.url = "http://localhost:10006/backend/admin" + this.url;
  },
  mounted: function mounted() {
    /**
     * iframe-宽高自适应显示
     */
    this.changeIframe();
  },
  methods: {
    changeIframe: function changeIframe() {
      var deviceWidth = document.body.clientWidth;
      var deviceHeight = document.body.clientHeight;
      this.$refs.sw.style.position = 'absolute';
      this.$refs.sw.style.width = Number(deviceWidth) - Number(deviceWidth) * 0.13 + 'px'; //数字是页面布局宽度差值
      this.$refs.sw.style.height = Number(deviceHeight) + 'px'; //数字是页面布局高度差
    }
  }
};
exports.default = _default;