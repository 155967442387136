"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.regexp.split");
require("core-js/modules/es6.number.constructor");
var _area = require("@/mixins/area");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//三级数据 regionData    二级数据 provinceAndCityData
var _default2 = {
  name: "mine-cascader",
  props: {
    value: {
      type: String,
      default: function _default() {
        return null;
      }
    },
    myClass: {
      type: String,
      default: function _default() {
        return null;
      }
    },
    placeholder: {
      type: String,
      default: function _default() {
        return "省/市/地区";
      }
    },
    level: {
      type: Number,
      default: function _default() {
        return 3;
      }
    },
    filterItem: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data: function data() {
    return {
      dropDownVisible: false,
      selectValue: null,
      arr: [],
      //省数据
      provinceData: _area.regionData,
      //市数据
      cityData: [],
      //区数据
      areaData: [],
      defaultTabs: ['请选择省', '请选择市', '请选择区'],
      tabs: ['请选择省'],
      tabLabel: '请选择省',
      isFirst: true
    };
  },
  watch: {
    value: {
      handler: function handler(val) {
        this.selectValue = this.value;
      },
      deep: true
    },
    selectValue: {
      handler: function handler(val) {
        this.$emit("input", this.selectValue);
      }
    },
    provinceData: {
      handler: function handler(val) {
        if (this.filterItem.length > 0) {}
      }
    }
  },
  created: function created() {
    var _this = this;
    //等待数据加载完再调用初始化，不然value可能为null
    setTimeout(function () {
      _this.init();
    }, 100);
  },
  methods: {
    /**
     *  回显数据
     */
    init: function init() {
      var _this2 = this;
      if (this.isNotEmpty(this.value)) {
        this.tabs = this.value.split(" ");
        _area.regionData.forEach(function (item) {
          if (item.label === _this2.tabs[0]) {
            _this2.tabLabel = item.label;
            _this2.cityData = item.children;
            if (_this2.tabs.length > 1) {
              item.children.forEach(function (item2) {
                if (item2.label === _this2.tabs[1]) {
                  _this2.tabLabel = item2.label;
                  _this2.areaData = item2.children;
                  if (_this2.level === 3 && _this2.tabs.length > 2) {
                    item2.children.forEach(function (item3) {
                      if (item3.label === _this2.tabs[2]) {
                        _this2.tabLabel = item3.label;
                      }
                    });
                  }
                }
              });
            }
          }
        });
      }
      this.selectValue = this.value;
    },
    /**
     * 改变当前选项卡区域
     * @param tabIndex
     * @param index
     * @param label
     */
    changeArea: function changeArea(tabIndex, index, label) {
      this.tabs[tabIndex] = label;
      if (tabIndex === 0) {
        this.tabs = this.tabs.slice(0, 1);
        this.selectValue = this.tabs.join(" ");
        this.tabs.push(this.defaultTabs[1]);
        this.cityData = this.provinceData[index].children;
        this.tabLabel = this.defaultTabs[1];
      }
      if (tabIndex === 1) {
        this.tabs = this.tabs.slice(0, 2);
        this.selectValue = this.tabs.join(" ");
        if (this.level === 3) {
          this.tabs.push(this.defaultTabs[2]);
          this.areaData = this.cityData[index].children;
          this.tabLabel = this.defaultTabs[2];
        } else {
          this.dropDownVisible = false;
        }
      }
      if (this.level === 3 && tabIndex === 2) {
        this.tabLabel = label;
        this.selectValue = this.tabs.join(" ");
        this.dropDownVisible = false;
      }
    }
  }
};
exports.default = _default2;