"use strict";

var _interopRequireDefault = require("D:/shenbimaliang1/sbml-backend/backend-page/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _interopRequireWildcard2 = _interopRequireDefault(require("D:/shenbimaliang1/sbml-backend/backend-page/node_modules/@babel/runtime/helpers/interopRequireWildcard.js"));
/**
 * 合伙人管理路由配置
 */
var partnerRouter = [{
  path: 'proxy/page',
  name: 'ProxyPage',
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/views/partner/proxy/ProxyPage'));
    });
  },
  meta: {
    title: '代理列表'
  }
}, {
  path: 'proxyRopeStatistics/page',
  name: 'ProxyRopeStatisticsPage',
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/views/partner/proxyRopeStatistics/ProxyRopeStatisticsPage'));
    });
  },
  meta: {
    title: '代理跳绳数据统计列表'
  }
}];
var _default = partnerRouter;
exports.default = _default;