"use strict";

var _interopRequireDefault = require("D:/shenbimaliang1/sbml-backend/backend-page/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _interopRequireWildcard2 = _interopRequireDefault(require("D:/shenbimaliang1/sbml-backend/backend-page/node_modules/@babel/runtime/helpers/interopRequireWildcard.js"));
/**
 * 系统管理路由配置
 */
var systemRouter = [{
  path: 'menu/page',
  name: 'MenuPage',
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/views/system/menu/MenuPage'));
    });
  },
  meta: {
    title: '菜单列表'
  }
}, {
  path: 'menu/add',
  name: 'MenuAdd',
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/views/system/menu/MenuAdd'));
    });
  },
  meta: {
    title: '新增菜单'
  }
}, {
  path: 'menu/update',
  name: 'MenuUpdate',
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/views/system/menu/MenuUpdate'));
    });
  },
  meta: {
    title: '修改菜单'
  }
}, {
  path: 'role/page',
  name: 'RolePage',
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/views/system/role/RolePage'));
    });
  },
  meta: {
    title: '角色列表'
  }
}, {
  path: 'role/add',
  name: 'RoleAdd',
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/views/system/role/RoleAdd'));
    });
  },
  meta: {
    title: '新增角色'
  }
}, {
  path: 'role/update',
  name: 'RoleUpdate',
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/views/system/role/RoleUpdate'));
    });
  },
  meta: {
    title: '修改角色'
  }
}, {
  path: 'user/page',
  name: 'UserPage',
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/views/system/user/UserPage'));
    });
  },
  meta: {
    title: '系统用户列表'
  }
}, {
  path: 'user/add',
  name: 'UserAdd',
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/views/system/user/UserAdd'));
    });
  },
  meta: {
    title: '新增系统用户'
  }
}, {
  path: 'user/update',
  name: 'UserUpdate',
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/views/system/user/UserUpdate'));
    });
  },
  meta: {
    title: '修改系统用户'
  }
}, {
  path: 'operateLog/page',
  name: 'OperateLogPage',
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/views/system/operateLog/OperateLogPage'));
    });
  },
  meta: {
    title: '操作日志列表'
  }
}];
var _default = systemRouter;
exports.default = _default;