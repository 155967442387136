"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.adTimeUpdate = exports.adTimePage = exports.adTimeExport = exports.adTimeDelete = exports.adTimeAdd = exports.adSaleOrderUpdate = exports.adSaleOrderPage = exports.adSaleOrderExport = exports.adSaleOrderDelete = exports.adSaleOrderAdd = exports.adReview = exports.adRatingUpdate = exports.adRatingPage = exports.adRatingExport = exports.adRatingDelete = exports.adRatingAdd = exports.adPortraitUpdate = exports.adPortraitPage = exports.adPortraitExport = exports.adPortraitDelete = exports.adPortraitAdd = exports.adPage = exports.adGaveOrderUpdate = exports.adGaveOrderPage = exports.adGaveOrderExport = exports.adGaveOrderDelete = exports.adGaveOrderAdd = exports.adExport = exports.adDurationLogUpdate = exports.adDurationLogPage = exports.adDurationLogExport = exports.adDurationLogDelete = exports.adDurationLogAdd = exports.adDelete = exports.adBuySlaveOrderUpdate = exports.adBuySlaveOrderPage = exports.adBuySlaveOrderExport = exports.adBuySlaveOrderDelete = exports.adBuySlaveOrderAdd = exports.adBuyOrderUpdate = exports.adBuyOrderStatusEnums = exports.adBuyOrderPayTypeEnums = exports.adBuyOrderPage = exports.adBuyOrderExport = exports.adBuyOrderDelete = exports.adBuyOrderAdd = exports.adBannerUpdate = exports.adBannerPage = exports.adBannerExport = exports.adBannerDelete = exports.adBannerAdd = exports.adAdd = void 0;
var _request = require("@/utils/request");
//=============================================================枚举=======================================================
var adBuyOrderPayTypeEnums = function adBuyOrderPayTypeEnums() {
  return (0, _request.post)('/advertise/enums/pay/type');
};
exports.adBuyOrderPayTypeEnums = adBuyOrderPayTypeEnums;
var adBuyOrderStatusEnums = function adBuyOrderStatusEnums() {
  return (0, _request.post)('/advertise/enums/buy-order/type');
};
//=============================================================广告=======================================================
/**
 * 广告新增
 */
exports.adBuyOrderStatusEnums = adBuyOrderStatusEnums;
var adAdd = function adAdd(data) {
  return (0, _request.post)('/advertise/ad-operation/add', data);
};
/**
 * 广告批量删除
 */
exports.adAdd = adAdd;
var adDelete = function adDelete(idList) {
  return (0, _request.post)('/advertise/ad-operation/delete?idList=' + idList);
};
/**
 * 广告审核
 */
exports.adDelete = adDelete;
var adReview = function adReview(data) {
  return (0, _request.post)('/advertise/ad-operation/review', data);
};
/**
 * 广告列表
 */
exports.adReview = adReview;
var adPage = function adPage(data) {
  return (0, _request.post)('/advertise/ad-operation/page', data);
};
/**
 * 广告导出
 */
exports.adPage = adPage;
var adExport = function adExport(param, name) {
  return (0, _request.exportExcel)('/advertise/ad-operation/export', param, name);
};
//=============================================================广告-平台=======================================================
/**
 * 广告-平台新增
 */
exports.adExport = adExport;
var adBannerAdd = function adBannerAdd(data) {
  return (0, _request.post)('/advertise/adBanner/add', data);
};
/**
 * 广告-平台批量删除
 */
exports.adBannerAdd = adBannerAdd;
var adBannerDelete = function adBannerDelete(idList) {
  return (0, _request.post)('/advertise/adBanner/delete?idList=' + idList);
};
/**
 * 广告-平台修改
 */
exports.adBannerDelete = adBannerDelete;
var adBannerUpdate = function adBannerUpdate(data) {
  return (0, _request.post)('/advertise/adBanner/update', data);
};
/**
 * 广告-平台列表
 */
exports.adBannerUpdate = adBannerUpdate;
var adBannerPage = function adBannerPage(data) {
  return (0, _request.post)('/advertise/adBanner/page', data);
};
/**
 * 广告-平台导出
 */
exports.adBannerPage = adBannerPage;
var adBannerExport = function adBannerExport(param, name) {
  return (0, _request.exportExcel)('/advertise/adBanner/export', param, name);
};
//=============================================================广告购买订单=======================================================
/**
 * 广告购买订单新增
 */
exports.adBannerExport = adBannerExport;
var adBuyOrderAdd = function adBuyOrderAdd(data) {
  return (0, _request.post)('/advertise/adBuyOrder/add', data);
};
/**
 * 广告购买订单批量删除
 */
exports.adBuyOrderAdd = adBuyOrderAdd;
var adBuyOrderDelete = function adBuyOrderDelete(idList) {
  return (0, _request.post)('/advertise/adBuyOrder/delete?idList=' + idList);
};
/**
 * 广告购买订单修改
 */
exports.adBuyOrderDelete = adBuyOrderDelete;
var adBuyOrderUpdate = function adBuyOrderUpdate(data) {
  return (0, _request.post)('/advertise/adBuyOrder/update', data);
};
/**
 * 广告购买订单列表
 */
exports.adBuyOrderUpdate = adBuyOrderUpdate;
var adBuyOrderPage = function adBuyOrderPage(data) {
  return (0, _request.post)('/advertise/adBuyOrder/page', data);
};
/**
 * 广告购买订单导出
 */
exports.adBuyOrderPage = adBuyOrderPage;
var adBuyOrderExport = function adBuyOrderExport(param, name) {
  return (0, _request.exportExcel)('/advertise/adBuyOrder/export', param, name);
};
//=============================================================广告购买订单-子单=======================================================
/**
 * 广告购买订单-子单新增
 */
exports.adBuyOrderExport = adBuyOrderExport;
var adBuySlaveOrderAdd = function adBuySlaveOrderAdd(data) {
  return (0, _request.post)('/advertise/adBuySlaveOrder/add', data);
};
/**
 * 广告购买订单-子单批量删除
 */
exports.adBuySlaveOrderAdd = adBuySlaveOrderAdd;
var adBuySlaveOrderDelete = function adBuySlaveOrderDelete(idList) {
  return (0, _request.post)('/advertise/adBuySlaveOrder/delete?idList=' + idList);
};
/**
 * 广告购买订单-子单修改
 */
exports.adBuySlaveOrderDelete = adBuySlaveOrderDelete;
var adBuySlaveOrderUpdate = function adBuySlaveOrderUpdate(data) {
  return (0, _request.post)('/advertise/adBuySlaveOrder/update', data);
};
/**
 * 广告购买订单-子单列表
 */
exports.adBuySlaveOrderUpdate = adBuySlaveOrderUpdate;
var adBuySlaveOrderPage = function adBuySlaveOrderPage(data) {
  return (0, _request.post)('/advertise/adBuySlaveOrder/page', data);
};
/**
 * 广告购买订单-子单导出
 */
exports.adBuySlaveOrderPage = adBuySlaveOrderPage;
var adBuySlaveOrderExport = function adBuySlaveOrderExport(param, name) {
  return (0, _request.exportExcel)('/advertise/adBuySlaveOrder/export', param, name);
};
//=============================================================广告时段=======================================================
/**
 * 广告时段新增
 */
exports.adBuySlaveOrderExport = adBuySlaveOrderExport;
var adTimeAdd = function adTimeAdd(data) {
  return (0, _request.post)('/advertise/adTime/add', data);
};
/**
 * 广告时段批量删除
 */
exports.adTimeAdd = adTimeAdd;
var adTimeDelete = function adTimeDelete(idList) {
  return (0, _request.post)('/advertise/adTime/delete?idList=' + idList);
};
/**
 * 广告时段修改
 */
exports.adTimeDelete = adTimeDelete;
var adTimeUpdate = function adTimeUpdate(data) {
  return (0, _request.post)('/advertise/adTime/update', data);
};
/**
 * 广告时段列表
 */
exports.adTimeUpdate = adTimeUpdate;
var adTimePage = function adTimePage(data) {
  return (0, _request.post)('/advertise/adTime/page', data);
};
/**
 * 广告时段导出
 */
exports.adTimePage = adTimePage;
var adTimeExport = function adTimeExport(param, name) {
  return (0, _request.exportExcel)('/advertise/adTime/export', param, name);
};
//=============================================================广告卡转赠订单=======================================================
/**
 * 广告卡转赠订单新增
 */
exports.adTimeExport = adTimeExport;
var adGaveOrderAdd = function adGaveOrderAdd(data) {
  return (0, _request.post)('/advertise/adGaveOrder/add', data);
};
/**
 * 广告卡转赠订单批量删除
 */
exports.adGaveOrderAdd = adGaveOrderAdd;
var adGaveOrderDelete = function adGaveOrderDelete(idList) {
  return (0, _request.post)('/advertise/adGaveOrder/delete?idList=' + idList);
};
/**
 * 广告卡转赠订单修改
 */
exports.adGaveOrderDelete = adGaveOrderDelete;
var adGaveOrderUpdate = function adGaveOrderUpdate(data) {
  return (0, _request.post)('/advertise/adGaveOrder/update', data);
};
/**
 * 广告卡转赠订单列表
 */
exports.adGaveOrderUpdate = adGaveOrderUpdate;
var adGaveOrderPage = function adGaveOrderPage(data) {
  return (0, _request.post)('/advertise/adGaveOrder/page', data);
};
/**
 * 广告卡转赠订单导出
 */
exports.adGaveOrderPage = adGaveOrderPage;
var adGaveOrderExport = function adGaveOrderExport(param, name) {
  return (0, _request.exportExcel)('/advertise/adGaveOrder/export', param, name);
};
//=============================================================广告时长记录=======================================================
/**
 * 广告时长记录新增
 */
exports.adGaveOrderExport = adGaveOrderExport;
var adDurationLogAdd = function adDurationLogAdd(data) {
  return (0, _request.post)('/advertise/adDurationLog/add', data);
};
/**
 * 广告时长记录批量删除
 */
exports.adDurationLogAdd = adDurationLogAdd;
var adDurationLogDelete = function adDurationLogDelete(idList) {
  return (0, _request.post)('/advertise/adDurationLog/delete?idList=' + idList);
};
/**
 * 广告时长记录修改
 */
exports.adDurationLogDelete = adDurationLogDelete;
var adDurationLogUpdate = function adDurationLogUpdate(data) {
  return (0, _request.post)('/advertise/adDurationLog/update', data);
};
/**
 * 广告时长记录列表
 */
exports.adDurationLogUpdate = adDurationLogUpdate;
var adDurationLogPage = function adDurationLogPage(data) {
  return (0, _request.post)('/advertise/adDurationLog/page', data);
};
/**
 * 广告时长记录导出
 */
exports.adDurationLogPage = adDurationLogPage;
var adDurationLogExport = function adDurationLogExport(param, name) {
  return (0, _request.exportExcel)('/advertise/adDurationLog/export', param, name);
};
//=============================================================时段竞价=======================================================
/**
 * 时段竞价新增
 */
exports.adDurationLogExport = adDurationLogExport;
var adRatingAdd = function adRatingAdd(data) {
  return (0, _request.post)('/advertise/adRating/add', data);
};
/**
 * 时段竞价批量删除
 */
exports.adRatingAdd = adRatingAdd;
var adRatingDelete = function adRatingDelete(idList) {
  return (0, _request.post)('/advertise/adRating/delete?idList=' + idList);
};
/**
 * 时段竞价修改
 */
exports.adRatingDelete = adRatingDelete;
var adRatingUpdate = function adRatingUpdate(data) {
  return (0, _request.post)('/advertise/adRating/update', data);
};
/**
 * 时段竞价列表
 */
exports.adRatingUpdate = adRatingUpdate;
var adRatingPage = function adRatingPage(data) {
  return (0, _request.post)('/advertise/adRating/page', data);
};
/**
 * 时段竞价导出
 */
exports.adRatingPage = adRatingPage;
var adRatingExport = function adRatingExport(param, name) {
  return (0, _request.exportExcel)('/advertise/adRating/export', param, name);
};
//=============================================================肖像=======================================================
/**
 * 肖像新增
 */
exports.adRatingExport = adRatingExport;
var adPortraitAdd = function adPortraitAdd(data) {
  return (0, _request.post)('/advertise/adPortrait/add', data);
};
/**
 * 肖像批量删除
 */
exports.adPortraitAdd = adPortraitAdd;
var adPortraitDelete = function adPortraitDelete(idList) {
  return (0, _request.post)('/advertise/adPortrait/delete?idList=' + idList);
};
/**
 * 肖像修改
 */
exports.adPortraitDelete = adPortraitDelete;
var adPortraitUpdate = function adPortraitUpdate(data) {
  return (0, _request.post)('/advertise/adPortrait/update', data);
};
/**
 * 肖像列表
 */
exports.adPortraitUpdate = adPortraitUpdate;
var adPortraitPage = function adPortraitPage(data) {
  return (0, _request.post)('/advertise/adPortrait/page', data);
};
/**
 * 肖像导出
 */
exports.adPortraitPage = adPortraitPage;
var adPortraitExport = function adPortraitExport(param, name) {
  return (0, _request.exportExcel)('/advertise/adPortrait/export', param, name);
};
//=============================================================广告出售订单=======================================================
/**
 * 广告出售订单新增
 */
exports.adPortraitExport = adPortraitExport;
var adSaleOrderAdd = function adSaleOrderAdd(data) {
  return (0, _request.post)('/advertise/adSaleOrder/add', data);
};
/**
 * 广告出售订单批量删除
 */
exports.adSaleOrderAdd = adSaleOrderAdd;
var adSaleOrderDelete = function adSaleOrderDelete(idList) {
  return (0, _request.post)('/advertise/adSaleOrder/delete?idList=' + idList);
};
/**
 * 广告出售订单修改
 */
exports.adSaleOrderDelete = adSaleOrderDelete;
var adSaleOrderUpdate = function adSaleOrderUpdate(data) {
  return (0, _request.post)('/advertise/adSaleOrder/update', data);
};
/**
 * 广告出售订单列表
 */
exports.adSaleOrderUpdate = adSaleOrderUpdate;
var adSaleOrderPage = function adSaleOrderPage(data) {
  return (0, _request.post)('/advertise/adSaleOrder/page', data);
};
/**
 * 广告出售订单导出
 */
exports.adSaleOrderPage = adSaleOrderPage;
var adSaleOrderExport = function adSaleOrderExport(param, name) {
  return (0, _request.exportExcel)('/advertise/adSaleOrder/export', param, name);
};
exports.adSaleOrderExport = adSaleOrderExport;