"use strict";

var _interopRequireDefault = require("D:/shenbimaliang1/sbml-backend/backend-page/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _vue = _interopRequireDefault(require("vue"));
var _cache = require("@/utils/cache");
var _jsCookie = _interopRequireDefault(require("js-cookie"));
var _store = _interopRequireDefault(require("@/store"));
var _default = {
  cacheVal: {
    state: false
  },
  get ws() {
    return _store.default.state.ws.websocket;
  },
  // 连接socket
  connectSocket: function connectSocket() {
    var userId = this.getUserId();
    if (userId == null) {
      return;
    }
    var url = "".concat(process.env.VUE_APP_SOCKET_PRE, ":").concat(location.host).concat(process.env.VUE_APP_BASE_API, "/websocket?userId=").concat(userId);
    var newWs = new WebSocket(url);
    // 存储 WebSocket 实例到 Vuex store
    _store.default.dispatch('ws/connectSocket', newWs);
    //设置当前是否重连
    if (newWs.readyState !== WebSocket.OPEN) {
      this.cacheVal.state = false;
    } else {
      this.cacheVal.state = true;
      console.log("socket connect success ", url);
      //注册关闭监听
      this.ws.onclose = function (e) {
        console.log("socket on close", e);
      };
      //错误监听
      this.ws.onerror = function (e) {
        console.log("socket error", e);
      };
    }
    (0, _cache.setItemCache)("reconnect_ws", JSON.stringify(this.cacheVal), 24 * 60 * 60);
  },
  // 发送消息
  sendMsg: function sendMsg(msg, type) {
    var _this = this;
    console.log(msg, type);
    if (this.ws != null && this.ws.readyState === WebSocket.OPEN) {
      console.log("发送消息", this.ws);
      this.ws.send(JSON.stringify({
        uuid: _vue.default.prototype.guid().replaceAll("-", ""),
        data: msg,
        type: type,
        timestamp: Date.now()
      }));
    } else {
      if (this.ws == null) {
        var cache = (0, _cache.getItemCache)("reconnect_ws");
        if (_vue.default.prototype.isNotUndefined(cache) && _vue.default.prototype.isNotEmpty(cache)) {
          var cacheVal = JSON.parse(cache);
          if (cacheVal.state) {
            var userId = this.getUserId();
            if (userId == null) {
              return;
            }
            var url = "".concat(process.env.VUE_APP_SOCKET_PRE, ":").concat(location.host).concat(process.env.VUE_APP_BASE_API, "/websocket?userId=").concat(userId);
            var newWs = new WebSocket(url);
            // 存储 WebSocket 实例到 Vuex store
            _store.default.dispatch('ws/connectSocket', newWs);
            console.log("重新发送...", url, this.ws.readyState);
            setTimeout(function () {
              _this.sendMsg(msg);
            }, 1000);
          }
        }
      }
    }
  },
  getUserId: function getUserId() {
    var systemUser = _jsCookie.default.get('systemUser');
    if (_vue.default.prototype.isUndefined(systemUser)) {
      // 判断是否存在token，如果存在的话，则每个http header都加上token
      return null;
    }
    return JSON.parse(systemUser).id; //请求头加上token
  },
  // 断开socket
  disconnectSocket: function disconnectSocket() {
    (0, _cache.removeItemCache)("reconnect_ws");
    if (this.ws != null) {
      this.ws.close();
      _store.default.dispatch('ws/connectSocket', null);
    }
  }
};
exports.default = _default;