import { render, staticRenderFns } from "./Login.vue?vue&type=template&id=5950ab5d&scoped=true&"
import script from "./Login.vue?vue&type=script&lang=js&"
export * from "./Login.vue?vue&type=script&lang=js&"
import style0 from "./Login.vue?vue&type=style&index=0&id=5950ab5d&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5950ab5d",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\shenbimaliang1\\sbml-backend\\backend-page\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('5950ab5d')) {
      api.createRecord('5950ab5d', component.options)
    } else {
      api.reload('5950ab5d', component.options)
    }
    module.hot.accept("./Login.vue?vue&type=template&id=5950ab5d&scoped=true&", function () {
      api.rerender('5950ab5d', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/system/login/Login.vue"
export default component.exports