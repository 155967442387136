"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _role = require("./role");
var _globalPage = require("@/mixins/global-page");
var _systemApi = require("@/api/system-api");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  mixins: [_role.role, _globalPage.add],
  methods: {
    //执行添加操作
    doAdd: function doAdd() {
      var checked = this.$refs.tree.getCheckedKeys();
      if (checked.length === 0) {
        return {
          message: '请选择权限'
        };
      }
      this.entity.menuIdList = checked;
      return (0, _systemApi.roleAdd)(this.entity);
    }
  }
};
exports.default = _default;