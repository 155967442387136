var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-form",
        {
          staticClass: "search-form-container",
          attrs: { "label-width": "100px" },
        },
        [
          _c(
            "el-row",
            [
              _c(
                "el-col",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.isShow,
                      expression: "isShow",
                    },
                  ],
                  attrs: { xs: 24, sm: 12, md: 8, lg: 6 },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "母订单编号" } },
                    [
                      _c("el-input", {
                        attrs: { clearable: "" },
                        nativeOn: {
                          keyup: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                            return _vm.pageSearch($event)
                          },
                        },
                        model: {
                          value: _vm.pageParam.orderNo,
                          callback: function ($$v) {
                            _vm.$set(_vm.pageParam, "orderNo", $$v)
                          },
                          expression: "pageParam.orderNo",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { xs: 24, sm: 12, md: 8, lg: 6 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "商品名称" } },
                    [
                      _c("el-input", {
                        attrs: { clearable: "" },
                        nativeOn: {
                          keyup: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                            return _vm.pageSearch($event)
                          },
                        },
                        model: {
                          value: _vm.pageParam.productName,
                          callback: function ($$v) {
                            _vm.$set(_vm.pageParam, "productName", $$v)
                          },
                          expression: "pageParam.productName",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { xs: 24, sm: 12, md: 8, lg: 6 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "物流公司" } },
                    [
                      _c(
                        "el-select",
                        {
                          model: {
                            value: _vm.pageParam.shipCompany,
                            callback: function ($$v) {
                              _vm.$set(_vm.pageParam, "shipCompany", $$v)
                            },
                            expression: "pageParam.shipCompany",
                          },
                        },
                        _vm._l(_vm.expressOptions, function (value, key) {
                          return _c("el-option", {
                            key: key,
                            attrs: { label: value.name, value: value.name },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { xs: 24, sm: 12, md: 8, lg: 6 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "物流单号" } },
                    [
                      _c("el-input", {
                        attrs: { clearable: "" },
                        nativeOn: {
                          keyup: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                            return _vm.pageSearch($event)
                          },
                        },
                        model: {
                          value: _vm.pageParam.shipNo,
                          callback: function ($$v) {
                            _vm.$set(_vm.pageParam, "shipNo", $$v)
                          },
                          expression: "pageParam.shipNo",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { xs: 24, sm: 12, md: 8, lg: 6 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "商品ID" } },
                    [
                      _c("el-input", {
                        attrs: { clearable: "" },
                        nativeOn: {
                          keyup: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                            return _vm.pageSearch($event)
                          },
                        },
                        model: {
                          value: _vm.pageParam.productId,
                          callback: function ($$v) {
                            _vm.$set(_vm.pageParam, "productId", $$v)
                          },
                          expression: "pageParam.productId",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { xs: 24, sm: 12, md: 8, lg: 6 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "退货状态" } },
                    [
                      _c(
                        "el-select",
                        {
                          model: {
                            value: _vm.pageParam.refundStatus,
                            callback: function ($$v) {
                              _vm.$set(_vm.pageParam, "refundStatus", $$v)
                            },
                            expression: "pageParam.refundStatus",
                          },
                        },
                        _vm._l(_vm.refundStatusMap, function (value, key) {
                          return _c("el-option", {
                            key: key,
                            attrs: { label: value, value: key },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { xs: 24, sm: 12, md: 8, lg: 6 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "退款理由" } },
                    [
                      _c("el-input", {
                        attrs: { clearable: "" },
                        nativeOn: {
                          keyup: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                            return _vm.pageSearch($event)
                          },
                        },
                        model: {
                          value: _vm.pageParam.refundReason,
                          callback: function ($$v) {
                            _vm.$set(_vm.pageParam, "refundReason", $$v)
                          },
                          expression: "pageParam.refundReason",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { xs: 24, sm: 12, md: 8, lg: 6 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "退款类型" } },
                    [
                      _c(
                        "el-select",
                        {
                          model: {
                            value: _vm.pageParam.refundType,
                            callback: function ($$v) {
                              _vm.$set(_vm.pageParam, "refundType", $$v)
                            },
                            expression: "pageParam.refundType",
                          },
                        },
                        _vm._l(_vm.refundTypeMap, function (value, key) {
                          return _c("el-option", {
                            key: key,
                            attrs: { label: value, value: key },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { xs: 24, sm: 12, md: 8, lg: 6 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "退款物流公司" } },
                    [
                      _c(
                        "el-select",
                        {
                          model: {
                            value: _vm.pageParam.refundShipCompany,
                            callback: function ($$v) {
                              _vm.$set(_vm.pageParam, "refundShipCompany", $$v)
                            },
                            expression: "pageParam.refundShipCompany",
                          },
                        },
                        _vm._l(_vm.expressOptions, function (value, key) {
                          return _c("el-option", {
                            key: key,
                            attrs: { label: value.name, value: value.name },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { xs: 24, sm: 12, md: 8, lg: 6 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "退款物流单号" } },
                    [
                      _c("el-input", {
                        attrs: { clearable: "" },
                        nativeOn: {
                          keyup: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                            return _vm.pageSearch($event)
                          },
                        },
                        model: {
                          value: _vm.pageParam.refundShipOrderNo,
                          callback: function ($$v) {
                            _vm.$set(_vm.pageParam, "refundShipOrderNo", $$v)
                          },
                          expression: "pageParam.refundShipOrderNo",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { xs: 24, sm: 12, md: 8, lg: 6 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "退款单号" } },
                    [
                      _c("el-input", {
                        attrs: { clearable: "" },
                        nativeOn: {
                          keyup: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                            return _vm.pageSearch($event)
                          },
                        },
                        model: {
                          value: _vm.pageParam.refundNo,
                          callback: function ($$v) {
                            _vm.$set(_vm.pageParam, "refundNo", $$v)
                          },
                          expression: "pageParam.refundNo",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { lg: 6, md: 8, sm: 12, xs: 24 } },
                [
                  _c("el-form-item", { attrs: { label: "下单时间" } }, [
                    _c(
                      "div",
                      { staticStyle: { width: "100%" } },
                      [
                        _c("el-date-picker", {
                          staticStyle: { width: "100%" },
                          attrs: {
                            "value-format": "yyyy-MM-dd",
                            type: "daterange",
                            align: "right",
                            "unlink-panels": "",
                            "picker-options": _vm.pickerOptions,
                            "range-separator": "至",
                            "start-placeholder": "开始日期",
                            "end-placeholder": "结束日期",
                          },
                          model: {
                            value: _vm.pageParam.createTimeRange,
                            callback: function ($$v) {
                              _vm.$set(_vm.pageParam, "createTimeRange", $$v)
                            },
                            expression: "pageParam.createTimeRange",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "page-button-container" },
        [
          _c(
            "el-button",
            {
              attrs: {
                size: _vm.getButtonSize(),
                icon: "el-icon-info",
                type: "success",
              },
              on: { click: _vm.openDetailDialog },
            },
            [_vm._v("详情")]
          ),
          _vm._v(" "),
          _vm.hasPermission("order:orderSlave:ship") && !_vm.isRefund
            ? _c(
                "el-button",
                {
                  attrs: {
                    type: "warning",
                    size: _vm.getButtonSize(),
                    icon: "el-icon-s-promotion",
                  },
                  on: { click: _vm.openShipDialog },
                },
                [_vm._v("订单发货\n    ")]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.hasPermission("order:orderSlave:refund")
            ? _c(
                "el-button",
                {
                  attrs: {
                    type: "danger",
                    size: _vm.getButtonSize(),
                    icon: "el-icon-document-remove",
                  },
                  on: { click: _vm.openRefundDialog },
                },
                [_vm._v("订单退款\n    ")]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.isShow
            ? _c(
                "el-button",
                {
                  staticClass: "search-button",
                  attrs: {
                    type: "info",
                    size: _vm.getButtonSize(),
                    icon: "el-icon-refresh",
                  },
                  on: { click: _vm.reset },
                },
                [_vm._v("重置\n    ")]
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "el-button",
            {
              staticClass: "search-button",
              attrs: {
                type: "primary",
                size: _vm.getButtonSize(),
                icon: "el-icon-check",
              },
              on: { click: _vm.pageSearch },
            },
            [_vm._v("\n      搜索\n    ")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.pageLoading,
              expression: "pageLoading",
            },
          ],
          ref: "elTable",
          attrs: {
            data: _vm.pageResult.records,
            "element-loading-text": "正在查询中...",
            border: "",
            fit: "",
            "highlight-current-row": "",
          },
          on: {
            "sort-change": _vm.sortChange,
            "row-click": _vm.handleRowClick,
            "selection-change": _vm.handleSelectionChange,
          },
        },
        [
          _c("el-table-column", { attrs: { type: "selection", width: "40" } }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              width: "180",
              label: "下单时间",
              prop: "createTime",
              "show-overflow-tooltip": true,
            },
          }),
          _vm._v(" "),
          !_vm.isSeller()
            ? _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "ID",
                  prop: "id",
                  "show-overflow-tooltip": true,
                },
              })
            : _vm._e(),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              width: "180",
              align: "center",
              label: "母订单编号",
              prop: "orderNo",
              "show-overflow-tooltip": true,
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              width: "120",
              align: "center",
              label: "商品图片",
              prop: "productImg",
              "show-overflow-tooltip": true,
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("table-img", {
                      attrs: { imgUrl: scope.row.productImg },
                    }),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          !_vm.isSeller()
            ? _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "商品ID",
                  prop: "productId",
                  "show-overflow-tooltip": true,
                },
              })
            : _vm._e(),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              width: "150",
              align: "center",
              label: "商品名称",
              prop: "productName",
              "show-overflow-tooltip": true,
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              width: "180",
              align: "center",
              label: "规格",
              prop: "productSpec",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return _vm._l(
                    _vm.isNotEmpty(scope.row.productSpec)
                      ? scope.row.productSpec.split(" ")
                      : [],
                    function (item, key) {
                      return _c(
                        "el-tag",
                        {
                          key: key,
                          staticStyle: { "margin-left": "5px" },
                          attrs: { type: "primary" },
                        },
                        [_vm._v(_vm._s(item) + "\n        ")]
                      )
                    }
                  )
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              "show-overflow-tooltip": true,
              align: "center",
              label: "专区",
              prop: "area",
              width: "100",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "el-tag",
                      {
                        attrs: {
                          type: _vm.areaColorMap[scope.row.area],
                          size: "small",
                        },
                      },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.areaMap[scope.row.area]) +
                            "\n        "
                        ),
                      ]
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "单价",
              prop: "price",
              "show-overflow-tooltip": true,
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "成本价",
              prop: "basePrice",
              "show-overflow-tooltip": true,
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "数量",
              prop: "number",
              "show-overflow-tooltip": true,
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              width: "100",
              label: "物流公司",
              prop: "shipCompany",
              "show-overflow-tooltip": true,
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              width: "150",
              label: "物流单号",
              prop: "shipNo",
              "show-overflow-tooltip": true,
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              width: "180",
              label: "发货时间",
              prop: "shipTime",
              "show-overflow-tooltip": true,
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              width: "100",
              label: "退货状态",
              prop: "refundStatus",
              "show-overflow-tooltip": true,
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "el-tag",
                      {
                        attrs: {
                          type: _vm.refundStatusColorMap[
                            scope.row.refundStatus
                          ],
                          size: "small",
                        },
                      },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(
                              _vm.refundStatusMap[scope.row.refundStatus]
                            ) +
                            "\n        "
                        ),
                      ]
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              width: "150",
              label: "退款理由",
              prop: "refundReason",
              "show-overflow-tooltip": true,
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              width: "100",
              label: "退款类型",
              prop: "refundType",
              "show-overflow-tooltip": true,
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "el-tag",
                      {
                        attrs: {
                          type: _vm.refundTypeColorMap[scope.row.refundType],
                          size: "small",
                        },
                      },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.refundTypeMap[scope.row.refundType]) +
                            "\n        "
                        ),
                      ]
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              width: "100",
              label: "退款物流公司",
              prop: "refundShipCompany",
              "show-overflow-tooltip": true,
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              width: "150",
              label: "退款物流单号",
              prop: "refundShipOrderNo",
              "show-overflow-tooltip": true,
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              width: "180",
              label: "订单更新时间",
              prop: "updateTime",
              "show-overflow-tooltip": true,
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("pagination", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.pageResult.total > 0,
            expression: "pageResult.total > 0",
          },
        ],
        attrs: {
          total: _vm.pageResult.total,
          layout: _vm.getLayout(),
          page: _vm.pageParam.pageNumber,
          limit: _vm.pageParam.pageSize,
        },
        on: {
          "update:page": function ($event) {
            return _vm.$set(_vm.pageParam, "pageNumber", $event)
          },
          "update:limit": function ($event) {
            return _vm.$set(_vm.pageParam, "pageSize", $event)
          },
          pagination: _vm.pageSearch,
        },
      }),
      _vm._v(" "),
      _vm.showAddDialog
        ? _c("orderSlave-add", {
            attrs: { "dialog-width": _vm.getDialogWidth() },
            on: { close: _vm.closeAddDialog, refreshTable: _vm.refreshTable },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.showShipDialog
        ? _c("order-slave-ship", {
            ref: "shipDialog",
            attrs: { "dialog-width": _vm.getDialogWidth() },
            on: {
              close: function ($event) {
                _vm.showShipDialog = false
              },
              refreshTable: _vm.refreshTable,
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.showDetailDialog
        ? _c("order-slave-detail", {
            ref: "detailDialog",
            attrs: { "dialog-width": _vm.getDialogWidth() },
            on: {
              close: function ($event) {
                _vm.showDetailDialog = false
              },
              refreshTable: _vm.refreshTable,
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.showRefundDialog
        ? _c("order-slave-refund", {
            ref: "refundDialog",
            attrs: { "dialog-width": _vm.getDialogWidth() },
            on: {
              close: function ($event) {
                _vm.showRefundDialog = false
              },
              refreshTable: _vm.refreshTable,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }