"use strict";

var _interopRequireDefault = require("D:/shenbimaliang1/sbml-backend/backend-page/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _proxy = require("./proxy");
var _globalDialog = require("@/mixins/global-dialog");
var _partnerApi = require("@/api/partner-api");
var _index = _interopRequireDefault(require("@/components/MineCascader/index.vue"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    MineCascader: _index.default
  },
  mixins: [_proxy.proxy, _globalDialog.updateDialog],
  methods: {
    //执行修改操作
    doUpdate: function doUpdate() {
      if (this.entity.reviewStatus === 0) {
        this.errorMessage("请选择审核通过或不通过");
        return;
      }
      if (this.entity.reviewStatus === 2 && this.isEmpty(this.entity.backReason)) {
        this.errorMessage("审核不通过需填写驳回理由");
        return;
      }
      return (0, _partnerApi.proxyReview)(this.entity);
    }
  }
};
exports.default = _default;