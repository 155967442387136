var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "发布商品",
        visible: _vm.dialogVisible,
        "close-on-click-modal": false,
        center: "",
        width: "80%",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
        close: _vm.closeDialog,
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "elForm",
          staticClass: "dialog-form",
          attrs: {
            model: _vm.entity,
            rules: _vm.rules,
            inline: true,
            "label-width": _vm.getLabelWidth(),
          },
        },
        [
          _c(
            "el-steps",
            {
              staticClass: "head",
              attrs: {
                active: _vm.active,
                "finish-status": "success",
                simple: "",
              },
            },
            [
              _c("el-step", { attrs: { title: "类型" } }),
              _vm._v(" "),
              _c("el-step", { attrs: { title: "基本信息" } }),
              _vm._v(" "),
              _c("el-step", { attrs: { title: "图文信息" } }),
              _vm._v(" "),
              _vm.entity.model === 1
                ? _c("el-step", { attrs: { title: "规格信息" } })
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.active === 0,
                  expression: "active===0",
                },
              ],
            },
            [
              _c("div", { staticClass: "tip" }, [
                _vm._v("tip：单规格与多规格的商品添加后不能相互转换"),
              ]),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "审核状态", prop: "model" } },
                [
                  _c(
                    "el-select",
                    {
                      model: {
                        value: _vm.entity.model,
                        callback: function ($$v) {
                          _vm.$set(_vm.entity, "model", $$v)
                        },
                        expression: "entity.model",
                      },
                    },
                    _vm._l(_vm.modelMap, function (value, key) {
                      return _c("el-option", {
                        key: key,
                        attrs: { label: value, value: Number(key) },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "form-button-container" },
                [
                  _c(
                    "el-button",
                    {
                      staticClass: "btn",
                      on: {
                        click: function ($event) {
                          return _vm.next(1)
                        },
                      },
                    },
                    [_vm._v("下一步")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.active === 1,
                  expression: "active===1",
                },
              ],
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "名称", prop: "title" } },
                [
                  _c("el-input", {
                    attrs: { clearable: "" },
                    model: {
                      value: _vm.entity.title,
                      callback: function ($$v) {
                        _vm.$set(_vm.entity, "title", $$v)
                      },
                      expression: "entity.title",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "商品分类", prop: "categoryIdList" } },
                [
                  _c("el-cascader", {
                    attrs: {
                      options: _vm.cateGoryOptions,
                      props: _vm.categoryProps,
                    },
                    model: {
                      value: _vm.entity.categoryIdList,
                      callback: function ($$v) {
                        _vm.$set(_vm.entity, "categoryIdList", $$v)
                      },
                      expression: "entity.categoryIdList",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _vm.entity.model !== 1
                ? _c(
                    "el-form-item",
                    { attrs: { label: "市场价", prop: "marketPrice" } },
                    [
                      _c("el-input", {
                        directives: [
                          {
                            name: "number-input",
                            rawName: "v-number-input",
                            value: { type: "decimal" },
                            expression: "{ type: 'decimal' }",
                          },
                        ],
                        attrs: { clearable: "" },
                        model: {
                          value: _vm.entity.marketPrice,
                          callback: function ($$v) {
                            _vm.$set(_vm.entity, "marketPrice", $$v)
                          },
                          expression: "entity.marketPrice",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.entity.model !== 1
                ? _c(
                    "el-form-item",
                    { attrs: { label: "结算价", prop: "basePrice" } },
                    [
                      _c("el-input", {
                        directives: [
                          {
                            name: "number-input",
                            rawName: "v-number-input",
                            value: { type: "decimal" },
                            expression: "{ type: 'decimal' }",
                          },
                        ],
                        attrs: { clearable: "" },
                        model: {
                          value: _vm.entity.basePrice,
                          callback: function ($$v) {
                            _vm.$set(_vm.entity, "basePrice", $$v)
                          },
                          expression: "entity.basePrice",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.entity.model !== 1
                ? _c(
                    "el-form-item",
                    { attrs: { label: "售价", prop: "price" } },
                    [
                      _c("el-input", {
                        directives: [
                          {
                            name: "number-input",
                            rawName: "v-number-input",
                            value: { type: "decimal" },
                            expression: "{ type: 'decimal' }",
                          },
                        ],
                        attrs: { clearable: "" },
                        model: {
                          value: _vm.entity.price,
                          callback: function ($$v) {
                            _vm.$set(_vm.entity, "price", $$v)
                          },
                          expression: "entity.price",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.entity.model !== 1
                ? _c(
                    "el-form-item",
                    { attrs: { label: "预估库存", prop: "stock" } },
                    [
                      _c("el-input", {
                        directives: [
                          {
                            name: "number-input",
                            rawName: "v-number-input",
                            value: { type: "integer" },
                            expression: "{ type: 'integer' }",
                          },
                        ],
                        attrs: { clearable: "" },
                        model: {
                          value: _vm.entity.stock,
                          callback: function ($$v) {
                            _vm.$set(_vm.entity, "stock", $$v)
                          },
                          expression: "entity.stock",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "form-button-container" },
                [
                  _c(
                    "el-button",
                    {
                      staticClass: "btn",
                      on: {
                        click: function ($event) {
                          return _vm.pre(0)
                        },
                      },
                    },
                    [_vm._v("上一步")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      staticClass: "btn",
                      on: {
                        click: function ($event) {
                          return _vm.next(2)
                        },
                      },
                    },
                    [_vm._v("下一步")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.active === 2,
                  expression: "active===2",
                },
              ],
            },
            [
              _c(
                "div",
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "主图", prop: "thumb" } },
                    [
                      _c("single-img-upload", {
                        attrs: { clearable: "" },
                        model: {
                          value: _vm.entity.thumb,
                          callback: function ($$v) {
                            _vm.$set(_vm.entity, "thumb", $$v)
                          },
                          expression: "entity.thumb",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "轮播图", prop: "images" } },
                    [
                      _c("multi-img-upload", {
                        model: {
                          value: _vm.entity.images,
                          callback: function ($$v) {
                            _vm.$set(_vm.entity, "images", $$v)
                          },
                          expression: "entity.images",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "图文详情", prop: "content" } },
                [
                  _c("rich-text", {
                    ref: "contentRef",
                    attrs: { id: "content" },
                    model: {
                      value: _vm.entity.content,
                      callback: function ($$v) {
                        _vm.$set(_vm.entity, "content", $$v)
                      },
                      expression: "entity.content",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "form-button-container" },
                [
                  _c(
                    "el-button",
                    {
                      staticClass: "btn",
                      on: {
                        click: function ($event) {
                          return _vm.pre(1)
                        },
                      },
                    },
                    [_vm._v("上一步")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      staticClass: "btn",
                      on: {
                        click: function ($event) {
                          return _vm.next(3)
                        },
                      },
                    },
                    [_vm._v("下一步")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.active === 3,
                  expression: "active===3",
                },
              ],
            },
            [
              _c(
                "el-button",
                {
                  staticClass: "btn",
                  staticStyle: { "margin-bottom": "10px" },
                  attrs: {
                    type: "primary",
                    icon: "el-icon-circle-plus-outline",
                  },
                  on: { click: _vm.addSpecType },
                },
                [_vm._v("添加规格类型\n      ")]
              ),
              _vm._v(" "),
              _c(
                "el-card",
                { staticClass: "box-card" },
                [
                  _c("h3", [_vm._v("规格参数")]),
                  _vm._v(" "),
                  _c(
                    "el-table",
                    { attrs: { data: _vm.specList, border: "", fit: "" } },
                    [
                      _c("el-table-column", {
                        attrs: {
                          property: "specName",
                          label: "规格名",
                          align: "center",
                          width: "150",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          property: "specValue",
                          label: "规格值",
                          align: "center",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return _vm._l(
                                scope.row.specValue.split("_"),
                                function (item, key) {
                                  return _c(
                                    "el-tag",
                                    {
                                      key: key,
                                      staticStyle: { "margin-left": "5px" },
                                      attrs: { type: "primary" },
                                    },
                                    [_vm._v(_vm._s(item) + "\n              ")]
                                  )
                                }
                              )
                            },
                          },
                        ]),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: { align: "center", label: "操作", width: "200" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c(
                                  "el-button",
                                  {
                                    attrs: { type: "primary", size: "mini" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.updateSpecType(scope.row)
                                      },
                                    },
                                  },
                                  [_vm._v("修改\n              ")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-button",
                                  {
                                    attrs: { type: "danger", size: "mini" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.deleteSpecType(scope.row)
                                      },
                                    },
                                  },
                                  [_vm._v("删除\n              ")]
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-dialog",
                {
                  attrs: {
                    visible: _vm.specDialogVisible,
                    "append-to-body": true,
                    title: "设置规格(多个规格以下划线 _ 隔开)",
                    "close-on-click-modal": false,
                    center: "",
                    width: _vm.getSmallDialogWidth(),
                  },
                  on: {
                    "update:visible": function ($event) {
                      _vm.specDialogVisible = $event
                    },
                  },
                },
                [
                  _c(
                    "el-form",
                    {
                      ref: "specForm",
                      staticClass: "dialog-form",
                      attrs: {
                        model: _vm.specForm,
                        inline: true,
                        "label-width": _vm.getLabelWidth(),
                      },
                    },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "规格名", prop: "specName" } },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: "如：颜色",
                              disabled: _vm.specNameUpdate,
                            },
                            model: {
                              value: _vm.specForm.specName,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.specForm,
                                  "specName",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "specForm.specName",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "规格值", prop: "specValue" } },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: "如：红色_白色_蓝色",
                              type: "textarea",
                              autosize: "",
                            },
                            model: {
                              value: _vm.specForm.specValue,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.specForm,
                                  "specValue",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "specForm.specValue",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "dialog-footer",
                      attrs: { slot: "footer" },
                      slot: "footer",
                    },
                    [
                      _c(
                        "el-button",
                        {
                          on: {
                            click: function ($event) {
                              _vm.specDialogVisible = false
                            },
                          },
                        },
                        [_vm._v("取消")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: { click: _vm.handleSpecAddOrUpdate },
                        },
                        [_vm._v("确定\n          ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-card",
                { staticClass: "box-card" },
                [
                  _c("h3", [_vm._v("规格组合")]),
                  _vm._v(" "),
                  _c(
                    "el-table",
                    {
                      ref: "skuTable",
                      attrs: {
                        data: _vm.entity.skuList,
                        "row-key": "name",
                        border: "",
                        fit: "",
                        "highlight-current-row": "",
                      },
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          prop: "specName",
                          label: "规格名称",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return _vm._l(
                                scope.row.specName.split("_"),
                                function (item, key) {
                                  return _c(
                                    "el-tag",
                                    {
                                      key: key,
                                      staticStyle: { "margin-left": "5px" },
                                    },
                                    [_vm._v(_vm._s(item) + "\n              ")]
                                  )
                                }
                              )
                            },
                          },
                        ]),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          label: "图片",
                          prop: "image",
                          width: "150",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (ref) {
                              var row = ref.row
                              return [
                                _c("single-img-upload", {
                                  attrs: { width: "60", height: "60" },
                                  model: {
                                    value: row.image,
                                    callback: function ($$v) {
                                      _vm.$set(row, "image", $$v)
                                    },
                                    expression: "row.image",
                                  },
                                }),
                              ]
                            },
                          },
                        ]),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          prop: "marketPrice",
                          width: "150",
                          label: "市场价",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "header",
                            fn: function (scope) {
                              return [
                                _c("div", [_vm._v("市场价")]),
                                _vm._v(" "),
                                _c("el-input", {
                                  directives: [
                                    {
                                      name: "number-input",
                                      rawName: "v-number-input",
                                      value: { type: "decimal" },
                                      expression: "{ type: 'decimal' }",
                                    },
                                  ],
                                  attrs: {
                                    size: "mini",
                                    placeholder: "批量修改市场价",
                                  },
                                  on: {
                                    input: function ($event) {
                                      return _vm.inputDefaultMarketPrice()
                                    },
                                  },
                                  model: {
                                    value: _vm.defaultSku.marketPrice,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.defaultSku,
                                        "marketPrice",
                                        $$v
                                      )
                                    },
                                    expression: "defaultSku.marketPrice",
                                  },
                                }),
                              ]
                            },
                          },
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c("el-input", {
                                  directives: [
                                    {
                                      name: "number-input",
                                      rawName: "v-number-input",
                                      value: { type: "decimal" },
                                      expression: "{ type: 'decimal' }",
                                    },
                                  ],
                                  attrs: {
                                    placeholder: "市场价",
                                    clearable: "",
                                  },
                                  on: {
                                    input: function ($event) {
                                      return _vm.onInputPrice(
                                        scope.$index,
                                        scope.row
                                      )
                                    },
                                    blur: function ($event) {
                                      return _vm.onCheckPrice(
                                        scope.$index,
                                        scope.row
                                      )
                                    },
                                  },
                                  model: {
                                    value: scope.row.marketPrice,
                                    callback: function ($$v) {
                                      _vm.$set(scope.row, "marketPrice", $$v)
                                    },
                                    expression: "scope.row.marketPrice",
                                  },
                                }),
                              ]
                            },
                          },
                        ]),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          prop: "price",
                          width: "150",
                          label: "销售价",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "header",
                            fn: function (scope) {
                              return [
                                _c("div", [_vm._v("销售价")]),
                                _vm._v(" "),
                                _c("el-input", {
                                  directives: [
                                    {
                                      name: "number-input",
                                      rawName: "v-number-input",
                                      value: { type: "decimal" },
                                      expression: "{ type: 'decimal' }",
                                    },
                                  ],
                                  attrs: {
                                    size: "mini",
                                    placeholder: "批量修改销售价",
                                  },
                                  on: {
                                    input: function ($event) {
                                      return _vm.inputDefaultPrice()
                                    },
                                  },
                                  model: {
                                    value: _vm.defaultSku.price,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.defaultSku, "price", $$v)
                                    },
                                    expression: "defaultSku.price",
                                  },
                                }),
                              ]
                            },
                          },
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c("el-input", {
                                  directives: [
                                    {
                                      name: "number-input",
                                      rawName: "v-number-input",
                                      value: { type: "decimal" },
                                      expression: "{ type: 'decimal' }",
                                    },
                                  ],
                                  attrs: {
                                    placeholder: "销售价",
                                    clearable: "",
                                  },
                                  on: {
                                    input: function ($event) {
                                      return _vm.onInputPrice(
                                        scope.$index,
                                        scope.row
                                      )
                                    },
                                    blur: function ($event) {
                                      return _vm.onCheckPrice(
                                        scope.$index,
                                        scope.row
                                      )
                                    },
                                  },
                                  model: {
                                    value: scope.row.price,
                                    callback: function ($$v) {
                                      _vm.$set(scope.row, "price", $$v)
                                    },
                                    expression: "scope.row.price",
                                  },
                                }),
                              ]
                            },
                          },
                        ]),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          prop: "basePrice",
                          width: "150",
                          label: "结算价",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "header",
                            fn: function (scope) {
                              return [
                                _c("div", [_vm._v("结算价")]),
                                _vm._v(" "),
                                _c("el-input", {
                                  directives: [
                                    {
                                      name: "number-input",
                                      rawName: "v-number-input",
                                      value: { type: "decimal" },
                                      expression: "{ type: 'decimal' }",
                                    },
                                  ],
                                  attrs: {
                                    size: "mini",
                                    placeholder: "批量修改结算价",
                                  },
                                  on: {
                                    input: function ($event) {
                                      return _vm.inputDefaultBasePrice()
                                    },
                                  },
                                  model: {
                                    value: _vm.defaultSku.basePrice,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.defaultSku, "basePrice", $$v)
                                    },
                                    expression: "defaultSku.basePrice",
                                  },
                                }),
                              ]
                            },
                          },
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c("el-input", {
                                  directives: [
                                    {
                                      name: "number-input",
                                      rawName: "v-number-input",
                                      value: { type: "decimal" },
                                      expression: "{ type: 'decimal' }",
                                    },
                                  ],
                                  attrs: {
                                    placeholder: "请输入结算价",
                                    clearable: "",
                                  },
                                  on: {
                                    input: function ($event) {
                                      return _vm.onInputPrice(
                                        scope.$index,
                                        scope.row
                                      )
                                    },
                                    blur: function ($event) {
                                      return _vm.onCheckPrice(
                                        scope.$index,
                                        scope.row
                                      )
                                    },
                                  },
                                  model: {
                                    value: scope.row.basePrice,
                                    callback: function ($$v) {
                                      _vm.$set(scope.row, "basePrice", $$v)
                                    },
                                    expression: "scope.row.basePrice",
                                  },
                                }),
                              ]
                            },
                          },
                        ]),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          prop: "stock",
                          width: "150",
                          label: "库存",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "header",
                            fn: function (scope) {
                              return [
                                _c("div", [_vm._v("库存")]),
                                _vm._v(" "),
                                _c("el-input", {
                                  directives: [
                                    {
                                      name: "number-input",
                                      rawName: "v-number-input",
                                      value: { type: "integer" },
                                      expression: "{ type: 'integer' }",
                                    },
                                  ],
                                  attrs: {
                                    size: "mini",
                                    placeholder: "批量修改库存",
                                  },
                                  on: {
                                    input: function ($event) {
                                      return _vm.inputDefaultStock()
                                    },
                                  },
                                  model: {
                                    value: _vm.defaultSku.stock,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.defaultSku, "stock", $$v)
                                    },
                                    expression: "defaultSku.stock",
                                  },
                                }),
                              ]
                            },
                          },
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c("el-input", {
                                  directives: [
                                    {
                                      name: "number-input",
                                      rawName: "v-number-input",
                                      value: { type: "integer" },
                                      expression: "{ type: 'integer' }",
                                    },
                                  ],
                                  attrs: { placeholder: "库存", clearable: "" },
                                  model: {
                                    value: scope.row.stock,
                                    callback: function ($$v) {
                                      _vm.$set(scope.row, "stock", $$v)
                                    },
                                    expression: "scope.row.stock",
                                  },
                                }),
                              ]
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "form-button-container" },
                [
                  _c(
                    "el-button",
                    {
                      staticClass: "btn",
                      on: {
                        click: function ($event) {
                          return _vm.pre(2)
                        },
                      },
                    },
                    [_vm._v("上一步")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      staticClass: "btn",
                      on: {
                        click: function ($event) {
                          return _vm.next(4)
                        },
                      },
                    },
                    [_vm._v("下一步")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _vm.active === 4
            ? _c("div", [
                _c("div", { staticClass: "tip" }, [
                  _vm._v(
                    "tip：提交数据后，您需要将商品上架，商品管理员才会审核您的商品"
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "form-button-container" },
                  [
                    _c(
                      "el-button",
                      {
                        staticClass: "btn",
                        on: {
                          click: function ($event) {
                            return _vm.pre(3)
                          },
                        },
                      },
                      [_vm._v("上一步")]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        staticClass: "btn",
                        attrs: { type: "primary", icon: "el-icon-check" },
                        on: { click: _vm.submitForm },
                      },
                      [_vm._v("提交")]
                    ),
                  ],
                  1
                ),
              ])
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }