"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _adTime = require("./ad-time");
var _globalDialog = require("@/mixins/global-dialog");
var _advertiseApi = require("@/api/advertise-api");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  mixins: [_adTime.adTime, _globalDialog.updateDialog],
  methods: {
    //执行修改操作
    doUpdate: function doUpdate() {
      console.log(this.entity);
      var value = this.entity.value;
      if (typeof value[0] === 'string') {
        this.entity.startTime = this.formatDate(new Date(value[0]), "HH:mm");
      } else {
        this.entity.startTime = this.formatDate(value[0], "HH:mm");
      }
      if (typeof value[1] === 'string') {
        this.entity.endTime = this.formatDate(new Date(value[1]), "HH:mm");
      } else {
        this.entity.endTime = this.formatDate(value[1], "HH:mm");
      }
      return (0, _advertiseApi.adTimeUpdate)(this.entity);
    }
  }
};
exports.default = _default;