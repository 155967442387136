"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ropeDeviceData = void 0;
var ropeDeviceData = {
  data: function data() {
    return {
      //是否展示详情窗口
      showDetailDialog: false,
      //是否修改过头像枚举
      isHeadImgChangedMap: {
        0: '否',
        1: '是'
      },
      //是否修改过昵称标签颜色枚举
      isHeadImgChangedColorMap: {
        0: 'danger',
        1: 'success',
        2: 'danger',
        3: 'warning',
        4: 'info',
        5: 'primary'
      },
      //是否修改过昵称枚举
      isNickNameChangedMap: {
        0: '否',
        1: '是'
      },
      //性别标签颜色枚举
      isNickNameChangedColorMap: {
        0: 'danger',
        1: 'success',
        2: 'danger',
        3: 'warning',
        4: 'info',
        5: 'primary'
      },
      //性别枚举
      sexMap: {
        0: '男',
        1: '女'
      },
      //性别标签颜色枚举
      sexColorMap: {
        0: 'danger',
        1: 'success',
        2: 'danger',
        3: 'warning',
        4: 'info',
        5: 'primary'
      },
      backUrl: '/sport/ropeDeviceData/page',
      entity: {},
      rules: {
        uuid: [{
          required: true,
          message: '请输入唯一编码'
        }, {
          max: 64,
          message: '唯一编码长度在64个字符内'
        }],
        nickName: [{
          required: true,
          message: '请输入昵称'
        }, {
          max: 255,
          message: '昵称长度在255个字符内'
        }],
        headImg: [{
          required: true,
          message: '请输入头像'
        }, {
          max: 255,
          message: '头像长度在255个字符内'
        }],
        province: [{
          required: true,
          message: '请输入省'
        }, {
          max: 10,
          message: '省长度在10个字符内'
        }],
        city: [{
          required: true,
          message: '请输入市'
        }, {
          max: 10,
          message: '市长度在10个字符内'
        }],
        district: [{
          required: true,
          message: '请输入区'
        }, {
          max: 10,
          message: '区长度在10个字符内'
        }],
        school: [{
          required: true,
          message: '请输入学校'
        }, {
          max: 50,
          message: '学校长度在50个字符内'
        }],
        grade: [{
          required: true,
          message: '请输入年级'
        }, {
          max: 50,
          message: '年级长度在50个字符内'
        }],
        classes: [{
          required: true,
          message: '请输入班级'
        }, {
          max: 50,
          message: '班级长度在50个字符内'
        }],
        name: [{
          required: true,
          message: '请输入姓名'
        }, {
          max: 255,
          message: '姓名长度在255个字符内'
        }],
        sex: [{
          required: true,
          message: '请输入性别'
        }],
        height: [{
          required: true,
          message: '请输入身高'
        }, {
          max: 10,
          message: '身高长度在10个字符内'
        }],
        birthday: [{
          required: true,
          message: '请输入出生日期'
        }, {
          max: 255,
          message: '出生日期长度在255个字符内'
        }],
        hobbit: [{
          required: true,
          message: '请输入运动爱好'
        }, {
          max: 255,
          message: '运动爱好长度在255个字符内'
        }],
        dayNum: [{
          required: true,
          message: '请输入日跳绳总数'
        }],
        daySeconds: [{
          required: true,
          message: '请输入日跳绳秒数'
        }],
        weekNum: [{
          required: true,
          message: '请输入周跳绳总数'
        }],
        weekSeconds: [{
          required: true,
          message: '请输入周跳绳秒数'
        }],
        monthNum: [{
          required: true,
          message: '请输入月跳绳总数'
        }],
        monthSeconds: [{
          required: true,
          message: '请输入月跳绳秒数'
        }],
        yearNum: [{
          required: true,
          message: '请输入年跳绳总数'
        }],
        yearSeconds: [{
          required: true,
          message: '请输入年跳绳秒数'
        }],
        isHeadImgChanged: [{
          required: true,
          message: '请输入是否修改过头像'
        }],
        isNickNameChanged: [{
          required: true,
          message: '请输入是否修改过昵称'
        }],
        userId: [{
          required: true,
          message: '请输入用户ID'
        }],
        ropeId: [{
          required: true,
          message: '请输入跳绳订单ID'
        }]
      }
    };
  }
};
exports.ropeDeviceData = ropeDeviceData;