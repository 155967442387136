"use strict";

var _interopRequireDefault = require("D:/shenbimaliang1/sbml-backend/backend-page/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _orderApi = require("@/api/order-api");
var _globalDialog = require("@/mixins/global-dialog");
var _permission = require("@/mixins/permission");
var _order = require("./order");
var _OrderAdd = _interopRequireDefault(require("./OrderAdd"));
var _OrderUpdate = _interopRequireDefault(require("./OrderUpdate"));
var _OrderDetail = _interopRequireDefault(require("./OrderDetail"));
var _OrderSlave = _interopRequireDefault(require("@/views/order/order/OrderSlave.vue"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'OrderPage',
  components: {
    OrderSlave: _OrderSlave.default,
    Pagination: _Pagination.default,
    OrderAdd: _OrderAdd.default,
    OrderUpdate: _OrderUpdate.default,
    OrderDetail: _OrderDetail.default
  },
  mixins: [_order.order, _globalDialog.pageDialog, _permission.permission],
  created: function created() {
    if (this.isNotEmpty(this.$route.params.orderNo)) {
      this.pageParam.orderNo = this.$route.params.orderNo;
    }
  },
  methods: {
    //导出
    exportExcel: function exportExcel() {
      (0, _orderApi.orderExport)(this.pageParam);
    },
    //调用接口查询
    doSearch: function doSearch() {
      return (0, _orderApi.orderPage)(this.pageParam);
    },
    /**
     * 执行批量删除操作
     * @param {Object} idList id集合
     */
    doBatchDelete: function doBatchDelete(idList) {
      return batchDelete(idList);
    },
    /**
     * 打开订单详情窗口
     */
    openDetailDialog: function openDetailDialog() {
      var _this = this;
      var selectData = this.getSelect();
      if (this.isEmpty(selectData)) {
        return;
      }
      this.showDetailDialog = true;
      this.$nextTick(function () {
        // 把选中行的数据拷贝一份传递给修改对话框
        selectData = _this.transTimeDia(selectData);
        _this.$refs.detailDialog.setEntity(_this.objectCopy(selectData));
      });
    },
    /**
     * 打开子单分页窗口
     */
    openSlaveDialog: function openSlaveDialog(selectData) {
      var _this2 = this;
      this.showSlaveDialog = true;
      this.$nextTick(function () {
        _this2.$refs.slaveDialog.init(selectData);
      });
    },
    /**
     * 打开子单退款分页窗口
     */
    openSlaveRefundDialog: function openSlaveRefundDialog(selectData) {
      var _this3 = this;
      this.showSlaveDialog = true;
      this.$nextTick(function () {
        _this3.$refs.slaveDialog.init(selectData, true);
      });
    }
  }
};
exports.default = _default;