"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
var _default2 = {
  name: 'TableImg',
  props: {
    // 图片数据(图片url组成的数组) 通过v-model传递
    imgUrl: {
      type: String,
      default: function _default() {
        return '';
      }
    }
  }
};
exports.default = _default2;