"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.proxyUpdate = exports.proxyRopeStatisticsPage = exports.proxyRopeStatisticsExport = exports.proxyReview = exports.proxyPage = exports.proxyExport = exports.proxyDelete = exports.proxyAdd = void 0;
var _request = require("@/utils/request");
//=============================================================代理=======================================================
/**
 * 代理新增
 */
var proxyAdd = function proxyAdd(data) {
  return (0, _request.post)('/partner/proxy/add', data);
};
/**
 * 代理注销
 */
exports.proxyAdd = proxyAdd;
var proxyDelete = function proxyDelete(data) {
  return (0, _request.post)('/partner/proxy/delete', data);
};
/**
 * 代理修改
 */
exports.proxyDelete = proxyDelete;
var proxyUpdate = function proxyUpdate(data) {
  return (0, _request.post)('/partner/proxy/update', data);
};
/**
 * 代理审核
 */
exports.proxyUpdate = proxyUpdate;
var proxyReview = function proxyReview(data) {
  return (0, _request.post)('/partner/proxy/review', data);
};
/**
 * 代理列表
 */
exports.proxyReview = proxyReview;
var proxyPage = function proxyPage(data) {
  return (0, _request.post)('/partner/proxy/page', data);
};
/**
 * 代理导出
 */
exports.proxyPage = proxyPage;
var proxyExport = function proxyExport(param, name) {
  return (0, _request.exportExcel)('/partner/proxy/export', param, name);
};
//=============================================================代理跳绳数据统计=======================================================
/**
 * 代理跳绳数据统计列表
 */
exports.proxyExport = proxyExport;
var proxyRopeStatisticsPage = function proxyRopeStatisticsPage(data) {
  return (0, _request.post)('/partner/proxyRopeStatistics/page', data);
};
/**
 * 代理跳绳数据统计导出
 */
exports.proxyRopeStatisticsPage = proxyRopeStatisticsPage;
var proxyRopeStatisticsExport = function proxyRopeStatisticsExport(param, name) {
  return (0, _request.exportExcel)('/partner/proxyRopeStatistics/export', param, name);
};
exports.proxyRopeStatisticsExport = proxyRopeStatisticsExport;