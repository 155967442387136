var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "main" },
    [
      _c(
        "el-popover",
        {
          attrs: {
            "popper-class": "feedback-popover",
            placement: "top",
            width: "350",
            title: "意见反馈",
          },
          model: {
            value: _vm.visible,
            callback: function ($$v) {
              _vm.visible = $$v
            },
            expression: "visible",
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "feedback",
              attrs: {
                model: _vm.entity,
                rules: _vm.ruleForm,
                "label-position": "top",
                align: "left",
              },
            },
            [
              _c("el-form-item", { attrs: { prop: "type" } }, [
                _c("div", { staticClass: "feedback-title" }, [
                  _vm._v("反馈类型"),
                  _c("span", { staticStyle: { color: "#f50d0d" } }, [
                    _vm._v("*"),
                  ]),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "feedback-type" },
                  _vm._l(_vm.types, function (type, index) {
                    return _c(
                      "el-button",
                      {
                        key: index,
                        staticClass: "feedback-type-btn",
                        class:
                          index === _vm.activeIndex
                            ? !_vm.isSellerServer()
                              ? "feedback-type-btn-pl-active"
                              : "feedback-type-btn-se-active"
                            : "",
                        on: {
                          click: function ($event) {
                            return _vm.changeType(index)
                          },
                        },
                      },
                      [_vm._v("\n            " + _vm._s(type) + "\n          ")]
                    )
                  }),
                  1
                ),
              ]),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { prop: "content" } },
                [
                  _c("div", { staticClass: "feedback-title" }, [
                    _vm._v("反馈内容"),
                    _c("span", { staticStyle: { color: "#f50d0d" } }, [
                      _vm._v("*"),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("el-input", {
                    attrs: {
                      placeholder: "请输入反馈内容",
                      type: "textarea",
                      maxlength: "300",
                      rows: "5",
                      "show-word-limit": "",
                      clearable: "",
                      resize: "none",
                    },
                    model: {
                      value: _vm.entity.content,
                      callback: function ($$v) {
                        _vm.$set(_vm.entity, "content", $$v)
                      },
                      expression: "entity.content",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { prop: "img" } },
                [
                  _c("div", { staticClass: "feedback-title" }, [
                    _vm._v("图片"),
                    _c("span", { staticStyle: { color: "#969696" } }, [
                      _vm._v("（最多支持4张）"),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("multi-img-upload", {
                    attrs: { width: 65, height: 65, limit: 4 },
                    model: {
                      value: _vm.entity.img,
                      callback: function ($$v) {
                        _vm.$set(_vm.entity, "img", $$v)
                      },
                      expression: "entity.img",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { prop: "contactWay" } },
                [
                  _c("div", { staticClass: "feedback-title" }, [
                    _vm._v("联系邮箱/电话"),
                    _c("span", { staticStyle: { color: "#f50d0d" } }, [
                      _vm._v("*"),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("el-input", {
                    attrs: {
                      placeholder: "请输入联系邮箱/电话",
                      clearable: "",
                    },
                    model: {
                      value: _vm.entity.contactWay,
                      callback: function ($$v) {
                        _vm.$set(_vm.entity, "contactWay", $$v)
                      },
                      expression: "entity.contactWay",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              staticClass: "submit-btn",
              style: {
                backgroundImage: _vm.isSellerServer()
                  ? _vm.variables.seller_btn
                  : _vm.variables.platform_btn,
              },
              attrs: { size: "mini" },
              on: { click: _vm.submit },
            },
            [_vm._v("提交\n    ")]
          ),
          _vm._v(" "),
          _c("el-button", {
            staticClass: "feedback-btn",
            attrs: {
              slot: "reference",
              type: "info",
              icon: "el-icon-message",
              circle: "",
            },
            slot: "reference",
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }