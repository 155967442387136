"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _sku = require("./sku");
var _globalDialog = require("@/mixins/global-dialog");
var _productApi = require("@/api/product-api");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  mixins: [_sku.sku, _globalDialog.addDialog],
  methods: {
    //执行添加操作
    doAdd: function doAdd() {
      return (0, _productApi.skuAdd)(this.entity);
    }
  }
};
exports.default = _default;