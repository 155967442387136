var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "修改绳子信息",
        visible: _vm.dialogVisible,
        "close-on-click-modal": false,
        center: "",
        width: _vm.dialogWidth,
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
        close: _vm.closeDialog,
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "elForm",
          staticClass: "dialog-form",
          attrs: {
            model: _vm.entity,
            rules: _vm.rules,
            inline: true,
            "label-width": _vm.getLabelWidth(),
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "唯一编码", prop: "uuid" } },
            [
              _c("el-input", {
                attrs: { clearable: "" },
                model: {
                  value: _vm.entity.uuid,
                  callback: function ($$v) {
                    _vm.$set(_vm.entity, "uuid", $$v)
                  },
                  expression: "entity.uuid",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "昵称", prop: "nickName" } },
            [
              _c("el-input", {
                attrs: { clearable: "" },
                model: {
                  value: _vm.entity.nickName,
                  callback: function ($$v) {
                    _vm.$set(_vm.entity, "nickName", $$v)
                  },
                  expression: "entity.nickName",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "头像", prop: "headImg" } },
            [
              _c("el-input", {
                attrs: { clearable: "" },
                model: {
                  value: _vm.entity.headImg,
                  callback: function ($$v) {
                    _vm.$set(_vm.entity, "headImg", $$v)
                  },
                  expression: "entity.headImg",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "省", prop: "province" } },
            [
              _c("el-input", {
                attrs: { clearable: "" },
                model: {
                  value: _vm.entity.province,
                  callback: function ($$v) {
                    _vm.$set(_vm.entity, "province", $$v)
                  },
                  expression: "entity.province",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "市", prop: "city" } },
            [
              _c("el-input", {
                attrs: { clearable: "" },
                model: {
                  value: _vm.entity.city,
                  callback: function ($$v) {
                    _vm.$set(_vm.entity, "city", $$v)
                  },
                  expression: "entity.city",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "区", prop: "district" } },
            [
              _c("el-input", {
                attrs: { clearable: "" },
                model: {
                  value: _vm.entity.district,
                  callback: function ($$v) {
                    _vm.$set(_vm.entity, "district", $$v)
                  },
                  expression: "entity.district",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "学校", prop: "school" } },
            [
              _c("el-input", {
                attrs: { clearable: "" },
                model: {
                  value: _vm.entity.school,
                  callback: function ($$v) {
                    _vm.$set(_vm.entity, "school", $$v)
                  },
                  expression: "entity.school",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "年级", prop: "grade" } },
            [
              _c("el-input", {
                attrs: { clearable: "" },
                model: {
                  value: _vm.entity.grade,
                  callback: function ($$v) {
                    _vm.$set(_vm.entity, "grade", $$v)
                  },
                  expression: "entity.grade",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "班级", prop: "classes" } },
            [
              _c("el-input", {
                attrs: { clearable: "" },
                model: {
                  value: _vm.entity.classes,
                  callback: function ($$v) {
                    _vm.$set(_vm.entity, "classes", $$v)
                  },
                  expression: "entity.classes",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "姓名", prop: "name" } },
            [
              _c("el-input", {
                attrs: { clearable: "" },
                model: {
                  value: _vm.entity.name,
                  callback: function ($$v) {
                    _vm.$set(_vm.entity, "name", $$v)
                  },
                  expression: "entity.name",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "性别", prop: "sex" } },
            [
              _c(
                "el-select",
                {
                  model: {
                    value: _vm.entity.sex,
                    callback: function ($$v) {
                      _vm.$set(_vm.entity, "sex", $$v)
                    },
                    expression: "entity.sex",
                  },
                },
                _vm._l(_vm.sexMap, function (value, key) {
                  return _c("el-option", {
                    key: key,
                    attrs: { label: value, value: Number(key) },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "身高", prop: "height" } },
            [
              _c("el-input", {
                attrs: { clearable: "" },
                model: {
                  value: _vm.entity.height,
                  callback: function ($$v) {
                    _vm.$set(_vm.entity, "height", $$v)
                  },
                  expression: "entity.height",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "出生日期", prop: "birthday" } },
            [
              _c("el-input", {
                attrs: { clearable: "" },
                model: {
                  value: _vm.entity.birthday,
                  callback: function ($$v) {
                    _vm.$set(_vm.entity, "birthday", $$v)
                  },
                  expression: "entity.birthday",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "运动爱好", prop: "hobbit" } },
            [
              _c("el-input", {
                attrs: { clearable: "" },
                model: {
                  value: _vm.entity.hobbit,
                  callback: function ($$v) {
                    _vm.$set(_vm.entity, "hobbit", $$v)
                  },
                  expression: "entity.hobbit",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "日跳绳总数", prop: "dayNum" } },
            [
              _c("el-input", {
                attrs: { clearable: "" },
                model: {
                  value: _vm.entity.dayNum,
                  callback: function ($$v) {
                    _vm.$set(_vm.entity, "dayNum", $$v)
                  },
                  expression: "entity.dayNum",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "日跳绳秒数", prop: "daySeconds" } },
            [
              _c("el-input", {
                attrs: { clearable: "" },
                model: {
                  value: _vm.entity.daySeconds,
                  callback: function ($$v) {
                    _vm.$set(_vm.entity, "daySeconds", $$v)
                  },
                  expression: "entity.daySeconds",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "周跳绳总数", prop: "weekNum" } },
            [
              _c("el-input", {
                attrs: { clearable: "" },
                model: {
                  value: _vm.entity.weekNum,
                  callback: function ($$v) {
                    _vm.$set(_vm.entity, "weekNum", $$v)
                  },
                  expression: "entity.weekNum",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "周跳绳秒数", prop: "weekSeconds" } },
            [
              _c("el-input", {
                attrs: { clearable: "" },
                model: {
                  value: _vm.entity.weekSeconds,
                  callback: function ($$v) {
                    _vm.$set(_vm.entity, "weekSeconds", $$v)
                  },
                  expression: "entity.weekSeconds",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "月跳绳总数", prop: "monthNum" } },
            [
              _c("el-input", {
                attrs: { clearable: "" },
                model: {
                  value: _vm.entity.monthNum,
                  callback: function ($$v) {
                    _vm.$set(_vm.entity, "monthNum", $$v)
                  },
                  expression: "entity.monthNum",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "月跳绳秒数", prop: "monthSeconds" } },
            [
              _c("el-input", {
                attrs: { clearable: "" },
                model: {
                  value: _vm.entity.monthSeconds,
                  callback: function ($$v) {
                    _vm.$set(_vm.entity, "monthSeconds", $$v)
                  },
                  expression: "entity.monthSeconds",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "年跳绳总数", prop: "yearNum" } },
            [
              _c("el-input", {
                attrs: { clearable: "" },
                model: {
                  value: _vm.entity.yearNum,
                  callback: function ($$v) {
                    _vm.$set(_vm.entity, "yearNum", $$v)
                  },
                  expression: "entity.yearNum",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "年跳绳秒数", prop: "yearSeconds" } },
            [
              _c("el-input", {
                attrs: { clearable: "" },
                model: {
                  value: _vm.entity.yearSeconds,
                  callback: function ($$v) {
                    _vm.$set(_vm.entity, "yearSeconds", $$v)
                  },
                  expression: "entity.yearSeconds",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "是否修改过头像", prop: "isHeadImgChanged" } },
            [
              _c("el-input", {
                attrs: { clearable: "" },
                model: {
                  value: _vm.entity.isHeadImgChanged,
                  callback: function ($$v) {
                    _vm.$set(_vm.entity, "isHeadImgChanged", $$v)
                  },
                  expression: "entity.isHeadImgChanged",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "是否修改过昵称", prop: "isNickNameChanged" } },
            [
              _c("el-input", {
                attrs: { clearable: "" },
                model: {
                  value: _vm.entity.isNickNameChanged,
                  callback: function ($$v) {
                    _vm.$set(_vm.entity, "isNickNameChanged", $$v)
                  },
                  expression: "entity.isNickNameChanged",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "用户ID", prop: "userId" } },
            [
              _c("el-input", {
                directives: [
                  {
                    name: "number-input",
                    rawName: "v-number-input",
                    value: { type: "integer" },
                    expression: "{ type: 'integer' }",
                  },
                ],
                attrs: { clearable: "" },
                model: {
                  value: _vm.entity.userId,
                  callback: function ($$v) {
                    _vm.$set(_vm.entity, "userId", $$v)
                  },
                  expression: "entity.userId",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "跳绳订单ID", prop: "ropeId" } },
            [
              _c("el-input", {
                directives: [
                  {
                    name: "number-input",
                    rawName: "v-number-input",
                    value: { type: "integer" },
                    expression: "{ type: 'integer' }",
                  },
                ],
                attrs: { clearable: "" },
                model: {
                  value: _vm.entity.ropeId,
                  callback: function ($$v) {
                    _vm.$set(_vm.entity, "ropeId", $$v)
                  },
                  expression: "entity.ropeId",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "form-button-container" },
        [
          _c(
            "el-button",
            {
              attrs: { type: "danger", icon: "el-icon-back" },
              on: {
                click: function ($event) {
                  _vm.dialogVisible = false
                },
              },
            },
            [_vm._v("返回")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              staticClass: "form-button-submit",
              attrs: { type: "primary", icon: "el-icon-check" },
              on: { click: _vm.submitForm },
            },
            [_vm._v("提交")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }