"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.checkinTypeEnums = exports.checkinStatusEnums = exports.checkinLogUpdate = exports.checkinLogPage = exports.checkinLogExport = exports.checkinLogDelete = exports.checkinLogAdd = exports.checkinAwardUpdate = exports.checkinAwardTypeEnums = exports.checkinAwardPage = exports.checkinAwardLogShip = exports.checkinAwardLogPage = exports.checkinAwardLogExport = exports.checkinAwardLogDelete = exports.checkinAwardLogAdd = exports.checkinAwardExport = exports.checkinAwardDelete = exports.checkinAwardAdd = void 0;
var _request = require("@/utils/request");
//=============================================================枚举=======================================================
var checkinStatusEnums = function checkinStatusEnums() {
  return (0, _request.post)('/checkin/enums/status');
};
exports.checkinStatusEnums = checkinStatusEnums;
var checkinAwardTypeEnums = function checkinAwardTypeEnums() {
  return (0, _request.post)('/checkin/enums/award/type');
};
exports.checkinAwardTypeEnums = checkinAwardTypeEnums;
var checkinTypeEnums = function checkinTypeEnums() {
  return (0, _request.post)('/checkin/enums/type');
};
//=============================================================用户签到记录=======================================================
/**
 * 用户签到记录新增
 */
exports.checkinTypeEnums = checkinTypeEnums;
var checkinLogAdd = function checkinLogAdd(data) {
  return (0, _request.post)('/checkin/checkinLog/add', data);
};
/**
 * 用户签到记录批量删除
 */
exports.checkinLogAdd = checkinLogAdd;
var checkinLogDelete = function checkinLogDelete(idList) {
  return (0, _request.post)('/checkin/checkinLog/delete?idList=' + idList);
};
/**
 * 用户签到记录修改
 */
exports.checkinLogDelete = checkinLogDelete;
var checkinLogUpdate = function checkinLogUpdate(data) {
  return (0, _request.post)('/checkin/checkinLog/update', data);
};
/**
 * 用户签到记录列表
 */
exports.checkinLogUpdate = checkinLogUpdate;
var checkinLogPage = function checkinLogPage(data) {
  return (0, _request.post)('/checkin/checkinLog/page', data);
};
/**
 * 用户签到记录导出
 */
exports.checkinLogPage = checkinLogPage;
var checkinLogExport = function checkinLogExport(param, name) {
  return (0, _request.exportExcel)('/checkin/checkinLog/export', param, name);
};
//=============================================================签到奖励=======================================================
/**
 * 签到奖励新增
 */
exports.checkinLogExport = checkinLogExport;
var checkinAwardAdd = function checkinAwardAdd(data) {
  return (0, _request.post)('/checkin/checkinAward/add', data);
};
/**
 * 签到奖励批量删除
 */
exports.checkinAwardAdd = checkinAwardAdd;
var checkinAwardDelete = function checkinAwardDelete(idList) {
  return (0, _request.post)('/checkin/checkinAward/delete?idList=' + idList);
};
/**
 * 签到奖励修改
 */
exports.checkinAwardDelete = checkinAwardDelete;
var checkinAwardUpdate = function checkinAwardUpdate(data) {
  return (0, _request.post)('/checkin/checkinAward/update', data);
};
/**
 * 签到奖励列表
 */
exports.checkinAwardUpdate = checkinAwardUpdate;
var checkinAwardPage = function checkinAwardPage(data) {
  return (0, _request.post)('/checkin/checkinAward/page', data);
};
/**
 * 签到奖励导出
 */
exports.checkinAwardPage = checkinAwardPage;
var checkinAwardExport = function checkinAwardExport(param, name) {
  return (0, _request.exportExcel)('/checkin/checkinAward/export', param, name);
};
//=============================================================签到奖励记录=======================================================
/**
 * 签到奖励记录新增
 */
exports.checkinAwardExport = checkinAwardExport;
var checkinAwardLogAdd = function checkinAwardLogAdd(data) {
  return (0, _request.post)('/checkin/checkinAwardLog/add', data);
};
/**
 * 签到奖励记录批量删除
 */
exports.checkinAwardLogAdd = checkinAwardLogAdd;
var checkinAwardLogDelete = function checkinAwardLogDelete(idList) {
  return (0, _request.post)('/checkin/checkinAwardLog/delete?idList=' + idList);
};
/**
 * 签到奖励实物发货
 */
exports.checkinAwardLogDelete = checkinAwardLogDelete;
var checkinAwardLogShip = function checkinAwardLogShip(data) {
  return (0, _request.post)('/checkin/checkinAwardLog/ship', data);
};
/**
 * 签到奖励记录列表
 */
exports.checkinAwardLogShip = checkinAwardLogShip;
var checkinAwardLogPage = function checkinAwardLogPage(data) {
  return (0, _request.post)('/checkin/checkinAwardLog/page', data);
};
/**
 * 签到奖励记录导出
 */
exports.checkinAwardLogPage = checkinAwardLogPage;
var checkinAwardLogExport = function checkinAwardLogExport(param, name) {
  return (0, _request.exportExcel)('/checkin/checkinAwardLog/export', param, name);
};
exports.checkinAwardLogExport = checkinAwardLogExport;