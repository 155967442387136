"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
var _default = {
  name: "CommonIllustrate",
  props: {
    title: {
      type: String,
      default: "说明"
    },
    content: {
      type: String,
      default: "<h1>说明</h1>"
    }
  },
  data: function data() {
    return {
      dialogVisible: true
    };
  },
  methods: {
    closeDialog: function closeDialog() {
      this.dialogVisible = false;
      this.$emit('close');
    }
  }
};
exports.default = _default;