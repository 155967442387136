"use strict";

var _interopRequireDefault = require("D:/shenbimaliang1/sbml-backend/backend-page/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _interopRequireWildcard2 = _interopRequireDefault(require("D:/shenbimaliang1/sbml-backend/backend-page/node_modules/@babel/runtime/helpers/interopRequireWildcard.js"));
/**
 * 广告平台管理路由配置
 */
var advertiseRouter = [{
  path: 'ad-operation/page',
  name: 'AdPage',
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/views/advertise/ad-operation/AdPage'));
    });
  },
  meta: {
    title: '广告列表'
  }
}, {
  path: 'adBanner/page',
  name: 'AdBannerPage',
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/views/advertise/adBanner/AdBannerPage'));
    });
  },
  meta: {
    title: '广告-平台列表'
  }
}, {
  path: 'adBuyOrder/page',
  name: 'AdBuyOrderPage',
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/views/advertise/adBuyOrder/AdBuyOrderPage'));
    });
  },
  meta: {
    title: '广告购买订单列表'
  }
}, {
  path: 'adBuySlaveOrder/page',
  name: 'AdBuySlaveOrderPage',
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/views/advertise/adBuySlaveOrder/AdBuySlaveOrderPage'));
    });
  },
  meta: {
    title: '广告购买订单-子单列表'
  }
}, {
  path: 'adTime/page',
  name: 'AdTimePage',
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/views/advertise/adTime/AdTimePage'));
    });
  },
  meta: {
    title: '广告时段列表'
  }
}, {
  path: 'adGaveOrder/page',
  name: 'AdGaveOrderPage',
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/views/advertise/adGaveOrder/AdGaveOrderPage'));
    });
  },
  meta: {
    title: '广告卡转赠订单列表'
  }
}, {
  path: 'adDurationLog/page',
  name: 'AdDurationLogPage',
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/views/advertise/adDurationLog/AdDurationLogPage'));
    });
  },
  meta: {
    title: '广告时长记录列表'
  }
}, {
  path: 'adRating/page',
  name: 'AdRatingPage',
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/views/advertise/adRating/AdRatingPage'));
    });
  },
  meta: {
    title: '时段竞价列表'
  }
}, {
  path: 'adPortrait/page',
  name: 'AdPortraitPage',
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/views/advertise/adPortrait/AdPortraitPage'));
    });
  },
  meta: {
    title: '肖像列表'
  }
}, {
  path: 'adSaleOrder/page',
  name: 'AdSaleOrderPage',
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/views/advertise/adSaleOrder/AdSaleOrderPage'));
    });
  },
  meta: {
    title: '广告出售订单列表'
  }
}];
var _default = advertiseRouter;
exports.default = _default;