var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "广告主审核",
        visible: _vm.dialogVisible,
        "close-on-click-modal": false,
        center: "",
        width: "50%",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
        close: _vm.closeDialog,
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "elForm",
          staticClass: "dialog-form",
          attrs: {
            model: _vm.entity,
            rules: _vm.rules,
            inline: true,
            "label-width": _vm.getLabelWidth(),
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "广告主状态", prop: "reviewStatus" } },
            [
              _c(
                "el-select",
                {
                  model: {
                    value: _vm.entity.reviewStatus,
                    callback: function ($$v) {
                      _vm.$set(_vm.entity, "reviewStatus", $$v)
                    },
                    expression: "entity.reviewStatus",
                  },
                },
                _vm._l(_vm.reviewStatusMap, function (value, key) {
                  return _c("el-option", {
                    key: key,
                    attrs: { label: value, value: Number(key) },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _vm.entity.reviewStatus === 3
            ? _c(
                "el-form-item",
                { attrs: { label: "驳回理由", prop: "backReason" } },
                [
                  _c("el-input", {
                    attrs: { clearable: "" },
                    model: {
                      value: _vm.entity.backReason,
                      callback: function ($$v) {
                        _vm.$set(_vm.entity, "backReason", $$v)
                      },
                      expression: "entity.backReason",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "form-button-container" },
        [
          _c(
            "el-button",
            {
              attrs: { type: "danger", icon: "el-icon-back" },
              on: {
                click: function ($event) {
                  _vm.dialogVisible = false
                },
              },
            },
            [_vm._v("返回")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              directives: [
                { name: "preventReClick", rawName: "v-preventReClick" },
              ],
              staticClass: "form-button-submit",
              attrs: { type: "primary", icon: "el-icon-check" },
              on: { click: _vm.submitForm },
            },
            [_vm._v("\n      提交\n    ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }