"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.userUpdate = exports.userPage = exports.userLogout = exports.userLogin = exports.userDelete = exports.userAdd = exports.uploadFile = exports.updateStatus = exports.updatePassword = exports.updateName = exports.systemOutSendCode = exports.smsUpdatePassword = exports.smsRecordUpdate = exports.smsRecordPage = exports.smsRecordDelete = exports.smsRecordAdd = exports.roleUpdate = exports.roleTree = exports.rolePage = exports.roleDelete = exports.roleAdd = exports.resetPassword = exports.operateLogPage = exports.navigateTree = exports.menuUpdate = exports.menuPage = exports.menuDelete = exports.menuAdd = exports.getKaptchaCode = exports.getGeneratorEntity = exports.getAllTable = exports.getAllColumn = exports.generator = void 0;
var _request = require("@/utils/request");
/**
 * 查询所有的数据库表
 */
var getAllTable = function getAllTable(data) {
  return (0, _request.post)('/codeGenerator/getAllTable', data);
};
/**
 * 获取某张表的全部列的信息
 */
exports.getAllTable = getAllTable;
var getAllColumn = function getAllColumn(data) {
  return (0, _request.post)('/codeGenerator/getAllColumn', data);
};
/**
 * 获取自动生成代码的默认配置
 */
exports.getAllColumn = getAllColumn;
var getGeneratorEntity = function getGeneratorEntity() {
  return (0, _request.post)('/codeGenerator/getGeneratorEntity');
};
/**
 * 代码自动生成
 */
exports.getGeneratorEntity = getGeneratorEntity;
var generator = function generator(data) {
  return (0, _request.post)('/codeGenerator/generator', data);
};
//========================================================菜单========================================================
/**
 * 菜单新增
 */
exports.generator = generator;
var menuAdd = function menuAdd(data) {
  return (0, _request.post)('/system/menu/add', data);
};
/**
 * 菜单批量删除
 */
exports.menuAdd = menuAdd;
var menuDelete = function menuDelete(idList) {
  return (0, _request.post)('/system/menu/delete?idList=' + idList);
};
/**
 * 菜单修改
 */
exports.menuDelete = menuDelete;
var menuUpdate = function menuUpdate(data) {
  return (0, _request.post)('/system/menu/update', data);
};
/**
 * 菜单列表
 */
exports.menuUpdate = menuUpdate;
var menuPage = function menuPage(data) {
  return (0, _request.post)('/system/menu/page', data);
};
/**
 * 导航栏树形结构数据
 */
exports.menuPage = menuPage;
var navigateTree = function navigateTree() {
  return (0, _request.post)('/system/menu/navigateTree');
};
//========================================================角色========================================================
/**
 * 角色新增
 */
exports.navigateTree = navigateTree;
var roleAdd = function roleAdd(data) {
  return (0, _request.post)('/system/role/add', data);
};
/**
 * 角色批量删除
 */
exports.roleAdd = roleAdd;
var roleDelete = function roleDelete(idList) {
  return (0, _request.post)('/system/role/delete?idList=' + idList);
};
/**
 * 角色修改
 */
exports.roleDelete = roleDelete;
var roleUpdate = function roleUpdate(data) {
  return (0, _request.post)('/system/role/update', data);
};
/**
 * 角色列表
 */
exports.roleUpdate = roleUpdate;
var rolePage = function rolePage(data) {
  return (0, _request.post)('/system/role/page', data);
};
/**
 * 角色树形结构数据
 */
exports.rolePage = rolePage;
var roleTree = function roleTree(data) {
  return (0, _request.post)('/system/role/tree', data);
};

//========================================================用户========================================================
exports.roleTree = roleTree;
var getKaptchaCode = function getKaptchaCode() {
  return (0, _request.fetch)('/system/kaptcha/code');
};
/**
 * 系统外部发送验证码（未登录，无关联关系）
 */
exports.getKaptchaCode = getKaptchaCode;
var systemOutSendCode = function systemOutSendCode(data) {
  return (0, _request.post)('/system/user/outSendCode', data);
};
/**
 * 用户新增
 */
exports.systemOutSendCode = systemOutSendCode;
var userAdd = function userAdd(data) {
  return (0, _request.post)('/system/user/add', data);
};
/**
 * 用户批量删除
 */
exports.userAdd = userAdd;
var userDelete = function userDelete(idList) {
  return (0, _request.post)('/system/user/delete?idList=' + idList);
};
/**
 * 用户修改
 */
exports.userDelete = userDelete;
var userUpdate = function userUpdate(data) {
  return (0, _request.post)('/system/user/update', data);
};
/**
 * 用户列表
 */
exports.userUpdate = userUpdate;
var userPage = function userPage(data) {
  return (0, _request.post)('/system/user/page', data);
};
/**
 * 用户登录
 */
exports.userPage = userPage;
var userLogin = function userLogin(data) {
  return (0, _request.post)('/system/user/login', data);
};
/**
 * 用户退出登录
 */
exports.userLogin = userLogin;
var userLogout = function userLogout() {
  return (0, _request.post)('/system/user/logout');
};
/**
 * 修改系统用户状态
 */
exports.userLogout = userLogout;
var updateStatus = function updateStatus(data) {
  return (0, _request.post)('/system/user/status', data);
};
/**
 * 修改密码
 */
exports.updateStatus = updateStatus;
var updatePassword = function updatePassword(data) {
  return (0, _request.post)('/system/user/updatePassword', data);
};
/**
 * 修改昵称
 */
exports.updatePassword = updatePassword;
var updateName = function updateName(data) {
  return (0, _request.post)('/system/user/updateName', data);
};
/**
 * 短信验证修改密码
 */
exports.updateName = updateName;
var smsUpdatePassword = function smsUpdatePassword(data) {
  return (0, _request.post)('/system/user/smsUpdatePassword', data);
};
/**
 * 重置密码
 */
exports.smsUpdatePassword = smsUpdatePassword;
var resetPassword = function resetPassword(id) {
  return (0, _request.post)('/system/user/resetPassword?id=' + id);
};
/**
 * 操作日志列表
 */
exports.resetPassword = resetPassword;
var operateLogPage = function operateLogPage(data) {
  return (0, _request.post)('/system/operateLog/page', data);
};

//=============================================================短信记录=======================================================
/**
 * 短信记录新增
 */
exports.operateLogPage = operateLogPage;
var smsRecordAdd = function smsRecordAdd(data) {
  return (0, _request.post)('/system/smsRecord/add', data);
};
/**
 * 短信记录批量删除
 */
exports.smsRecordAdd = smsRecordAdd;
var smsRecordDelete = function smsRecordDelete(idList) {
  return (0, _request.post)('/system/smsRecord/delete?idList=' + idList);
};
/**
 * 短信记录修改
 */
exports.smsRecordDelete = smsRecordDelete;
var smsRecordUpdate = function smsRecordUpdate(data) {
  return (0, _request.post)('/system/smsRecord/update', data);
};
/**
 * 短信记录列表
 */
exports.smsRecordUpdate = smsRecordUpdate;
var smsRecordPage = function smsRecordPage(data) {
  return (0, _request.post)('/system/smsRecord/page', data);
};

//=================================================================上传文件====================================================
exports.smsRecordPage = smsRecordPage;
var uploadFile = function uploadFile(action, params) {
  var config = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
  return (0, _request.upload)(action, params, config);
};
exports.uploadFile = uploadFile;