"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.grade = void 0;
var grade = {
  data: function data() {
    return {
      //是否展示详情窗口
      showDetailDialog: false,
      //是否展示班级列表窗口
      showClassDialog: false,
      backUrl: '/school/grade/page',
      entity: {},
      //班级选项
      classOptions: {},
      rules: {
        area: [{
          required: true,
          message: '请选择学校所在区域'
        }],
        schoolId: [{
          required: true,
          message: '请输入学校'
        }],
        nameList: [{
          required: true,
          message: '请输入年级'
        }],
        schoolName: [{
          required: true,
          message: '请输入学校名称'
        }, {
          max: 255,
          message: '年级长度在255个字符内'
        }],
        classArr: [{
          required: true,
          message: '请输入班级'
        }]
      }
    };
  }
};
exports.grade = grade;