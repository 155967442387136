"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.teacherUpdate = exports.teacherRaceUpdate = exports.teacherRacePage = exports.teacherRaceLogUpdate = exports.teacherRaceLogPage = exports.teacherRaceLogExport = exports.teacherRaceLogDelete = exports.teacherRaceLogAdd = exports.teacherRaceExport = exports.teacherRaceDelete = exports.teacherRaceAdd = exports.teacherPage = exports.teacherJobUpdate = exports.teacherJobSlaveUpdate = exports.teacherJobSlavePage = exports.teacherJobSlaveExport = exports.teacherJobSlaveDelete = exports.teacherJobSlaveAdd = exports.teacherJobPage = exports.teacherJobExport = exports.teacherJobDelete = exports.teacherJobAdd = exports.teacherExport = exports.teacherDelete = exports.teacherClassUpdate = exports.teacherClassPage = exports.teacherClassExport = exports.teacherClassDelete = exports.teacherClassAdd = exports.teacherApplyUpdate = exports.teacherApplyPage = exports.teacherApplyExport = exports.teacherApplyDelete = exports.teacherApplyAdd = exports.teacherAdd = void 0;
var _request = require("@/utils/request");
//=============================================================老师=======================================================
/**
 * 老师新增
 */
var teacherAdd = function teacherAdd(data) {
  return (0, _request.post)('/teacher/teacher/add', data);
};
/**
 * 老师批量删除
 */
exports.teacherAdd = teacherAdd;
var teacherDelete = function teacherDelete(idList) {
  return (0, _request.post)('/teacher/teacher/delete?idList=' + idList);
};
/**
 * 老师修改
 */
exports.teacherDelete = teacherDelete;
var teacherUpdate = function teacherUpdate(data) {
  return (0, _request.post)('/teacher/teacher/update', data);
};
/**
 * 老师列表
 */
exports.teacherUpdate = teacherUpdate;
var teacherPage = function teacherPage(data) {
  return (0, _request.post)('/teacher/teacher/page', data);
};
/**
 * 老师导出
 */
exports.teacherPage = teacherPage;
var teacherExport = function teacherExport(param, name) {
  return (0, _request.exportExcel)('/teacher/teacher/export', param, name);
};
//=============================================================老师申请=======================================================
/**
 * 老师申请新增
 */
exports.teacherExport = teacherExport;
var teacherApplyAdd = function teacherApplyAdd(data) {
  return (0, _request.post)('/teacher/teacherApply/add', data);
};
/**
 * 老师申请批量删除
 */
exports.teacherApplyAdd = teacherApplyAdd;
var teacherApplyDelete = function teacherApplyDelete(idList) {
  return (0, _request.post)('/teacher/teacherApply/delete?idList=' + idList);
};
/**
 * 老师申请修改
 */
exports.teacherApplyDelete = teacherApplyDelete;
var teacherApplyUpdate = function teacherApplyUpdate(data) {
  return (0, _request.post)('/teacher/teacherApply/update', data);
};
/**
 * 老师申请列表
 */
exports.teacherApplyUpdate = teacherApplyUpdate;
var teacherApplyPage = function teacherApplyPage(data) {
  return (0, _request.post)('/teacher/teacherApply/page', data);
};
/**
 * 老师申请导出
 */
exports.teacherApplyPage = teacherApplyPage;
var teacherApplyExport = function teacherApplyExport(param, name) {
  return (0, _request.exportExcel)('/teacher/teacherApply/export', param, name);
};
//=============================================================老师班级=======================================================
//=============================================================老师班级=======================================================
/**
 * 老师班级新增
 */
exports.teacherApplyExport = teacherApplyExport;
var teacherClassAdd = function teacherClassAdd(data) {
  return (0, _request.post)('/teacher/teacherClass/add', data);
};
/**
 * 老师班级批量删除
 */
exports.teacherClassAdd = teacherClassAdd;
var teacherClassDelete = function teacherClassDelete(idList) {
  return (0, _request.post)('/teacher/teacherClass/delete?idList=' + idList);
};
/**
 * 老师班级修改
 */
exports.teacherClassDelete = teacherClassDelete;
var teacherClassUpdate = function teacherClassUpdate(data) {
  return (0, _request.post)('/teacher/teacherClass/update', data);
};
/**
 * 老师班级列表
 */
exports.teacherClassUpdate = teacherClassUpdate;
var teacherClassPage = function teacherClassPage(data) {
  return (0, _request.post)('/teacher/teacherClass/page', data);
};
/**
 * 老师班级导出
 */
exports.teacherClassPage = teacherClassPage;
var teacherClassExport = function teacherClassExport(param, name) {
  return (0, _request.exportExcel)('/teacher/teacherClass/export', param, name);
};
//=============================================================作业=======================================================
/**
 * 作业新增
 */
exports.teacherClassExport = teacherClassExport;
var teacherJobAdd = function teacherJobAdd(data) {
  return (0, _request.post)('/teacher/teacherJob/add', data);
};
/**
 * 作业批量删除
 */
exports.teacherJobAdd = teacherJobAdd;
var teacherJobDelete = function teacherJobDelete(idList) {
  return (0, _request.post)('/teacher/teacherJob/delete?idList=' + idList);
};
/**
 * 作业修改
 */
exports.teacherJobDelete = teacherJobDelete;
var teacherJobUpdate = function teacherJobUpdate(data) {
  return (0, _request.post)('/teacher/teacherJob/update', data);
};
/**
 * 作业列表
 */
exports.teacherJobUpdate = teacherJobUpdate;
var teacherJobPage = function teacherJobPage(data) {
  return (0, _request.post)('/teacher/teacherJob/page', data);
};
/**
 * 作业导出
 */
exports.teacherJobPage = teacherJobPage;
var teacherJobExport = function teacherJobExport(param, name) {
  return (0, _request.exportExcel)('/teacher/teacherJob/export', param, name);
};
//=============================================================交作业记录=======================================================
/**
 * 交作业记录新增
 */
exports.teacherJobExport = teacherJobExport;
var teacherJobSlaveAdd = function teacherJobSlaveAdd(data) {
  return (0, _request.post)('/teacher/teacherJobSlave/add', data);
};
/**
 * 交作业记录批量删除
 */
exports.teacherJobSlaveAdd = teacherJobSlaveAdd;
var teacherJobSlaveDelete = function teacherJobSlaveDelete(idList) {
  return (0, _request.post)('/teacher/teacherJobSlave/delete?idList=' + idList);
};
/**
 * 交作业记录修改
 */
exports.teacherJobSlaveDelete = teacherJobSlaveDelete;
var teacherJobSlaveUpdate = function teacherJobSlaveUpdate(data) {
  return (0, _request.post)('/teacher/teacherJobSlave/update', data);
};
/**
 * 交作业记录列表
 */
exports.teacherJobSlaveUpdate = teacherJobSlaveUpdate;
var teacherJobSlavePage = function teacherJobSlavePage(data) {
  return (0, _request.post)('/teacher/teacherJobSlave/page', data);
};
/**
 * 交作业记录导出
 */
exports.teacherJobSlavePage = teacherJobSlavePage;
var teacherJobSlaveExport = function teacherJobSlaveExport(param, name) {
  return (0, _request.exportExcel)('/teacher/teacherJobSlave/export', param, name);
};
//=============================================================比赛=======================================================
/**
 * 比赛新增
 */
exports.teacherJobSlaveExport = teacherJobSlaveExport;
var teacherRaceAdd = function teacherRaceAdd(data) {
  return (0, _request.post)('/teacher/teacherRace/add', data);
};
/**
 * 比赛批量删除
 */
exports.teacherRaceAdd = teacherRaceAdd;
var teacherRaceDelete = function teacherRaceDelete(idList) {
  return (0, _request.post)('/teacher/teacherRace/delete?idList=' + idList);
};
/**
 * 比赛修改
 */
exports.teacherRaceDelete = teacherRaceDelete;
var teacherRaceUpdate = function teacherRaceUpdate(data) {
  return (0, _request.post)('/teacher/teacherRace/update', data);
};
/**
 * 比赛列表
 */
exports.teacherRaceUpdate = teacherRaceUpdate;
var teacherRacePage = function teacherRacePage(data) {
  return (0, _request.post)('/teacher/teacherRace/page', data);
};
/**
 * 比赛导出
 */
exports.teacherRacePage = teacherRacePage;
var teacherRaceExport = function teacherRaceExport(param, name) {
  return (0, _request.exportExcel)('/teacher/teacherRace/export', param, name);
};
//=============================================================比赛记录=======================================================
/**
 * 比赛记录新增
 */
exports.teacherRaceExport = teacherRaceExport;
var teacherRaceLogAdd = function teacherRaceLogAdd(data) {
  return (0, _request.post)('/teacher/teacherRaceLog/add', data);
};
/**
 * 比赛记录批量删除
 */
exports.teacherRaceLogAdd = teacherRaceLogAdd;
var teacherRaceLogDelete = function teacherRaceLogDelete(idList) {
  return (0, _request.post)('/teacher/teacherRaceLog/delete?idList=' + idList);
};
/**
 * 比赛记录修改
 */
exports.teacherRaceLogDelete = teacherRaceLogDelete;
var teacherRaceLogUpdate = function teacherRaceLogUpdate(data) {
  return (0, _request.post)('/teacher/teacherRaceLog/update', data);
};
/**
 * 比赛记录列表
 */
exports.teacherRaceLogUpdate = teacherRaceLogUpdate;
var teacherRaceLogPage = function teacherRaceLogPage(data) {
  return (0, _request.post)('/teacher/teacherRaceLog/page', data);
};
/**
 * 比赛记录导出
 */
exports.teacherRaceLogPage = teacherRaceLogPage;
var teacherRaceLogExport = function teacherRaceLogExport(param, name) {
  return (0, _request.exportExcel)('/teacher/teacherRaceLog/export', param, name);
};
exports.teacherRaceLogExport = teacherRaceLogExport;