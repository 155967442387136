"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.function.name");
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'AppMain',
  computed: {
    cachedViews: function cachedViews() {
      return this.$store.state.tagsView.cachedViews;
    },
    key: function key() {
      // return this.$route.path;下面代码解决vue路由参数变化，页面不刷新问题
      return this.$route.name !== undefined ? this.$route.name + new Date() : this.$route + new Date();
    }
  }
};
exports.default = _default;