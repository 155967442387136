"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _sellerReviewLog = require("./seller-review-log");
var _globalDialog = require("@/mixins/global-dialog");
var _sellerApi = require("@/api/seller-api");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  mixins: [_sellerReviewLog.sellerReviewLog, _globalDialog.updateDialog],
  methods: {
    //执行修改操作
    doUpdate: function doUpdate() {
      if (this.entity.reviewStatus === null) {
        this.errorMessage('请选择审核状态');
        return;
      }
      if (this.entity.reviewStatus === 2 && (this.entity.backReason === null || this.entity.backReason.trim() === '' || this.entity.backReason === undefined || this.entity.backReason === 'undefined')) {
        this.errorMessage('审核不通过需填写拒绝理由');
        return;
      }
      return (0, _sellerApi.sellerReviewLogReview)(this.entity);
    }
  }
};
exports.default = _default;