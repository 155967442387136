"use strict";

var _interopRequireDefault = require("D:/shenbimaliang1/sbml-backend/backend-page/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _product = require("./product");
var _RatingPage = _interopRequireDefault(require("@/views/afterSales/rating/RatingPage.vue"));
//
//
//
//
//
//
//
var _default = {
  components: {
    RatingPage: _RatingPage.default
  },
  mixins: [_product.product],
  data: function data() {
    return {
      dialogVisible: false,
      //传给年纪窗口的学校ID
      productId: null
    };
  },
  methods: {
    //执行修改操作
    init: function init(productId) {
      this.productId = productId;
      this.dialogVisible = true;
    }
  }
};
exports.default = _default;