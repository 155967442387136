var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      staticClass: "my-dialog",
      attrs: {
        visible: _vm.dialogVisible,
        "close-on-click-modal": false,
        width: "390px",
        "append-to-body": "",
        center: "",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
        close: _vm.closeDialog,
      },
    },
    [
      _c("div", { staticClass: "my-head" }, [_vm._v("修改密码")]),
      _vm._v(" "),
      _c("sms-code", {
        ref: "smsCode",
        staticClass: "custom-input-round",
        attrs: {
          "btn-color": !_vm.isSellerServer() ? "#119bf6" : "#FFAE26",
          "is-show-sub": false,
          ruleForm: _vm.entity,
          "submit-text": "修改密码",
        },
        on: { send: _vm.send, submit: _vm.submit },
      }),
      _vm._v(" "),
      _c(
        "el-form",
        {
          ref: "elForm",
          staticClass: "my-form",
          attrs: {
            inline: true,
            model: _vm.entity,
            rules: _vm.rules,
            "label-position": "top",
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { prop: "password" } },
            [
              _c("el-input", {
                staticClass: "custom-input-round",
                attrs: {
                  "prefix-icon": "el-icon-lock",
                  placeholder: "新密码",
                  "show-password": "",
                },
                model: {
                  value: _vm.entity.password,
                  callback: function ($$v) {
                    _vm.$set(_vm.entity, "password", $$v)
                  },
                  expression: "entity.password",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { prop: "confirmPassword" } },
            [
              _c("el-input", {
                staticClass: "custom-input-round",
                attrs: {
                  "prefix-icon": "el-icon-lock",
                  "show-password": "",
                  placeholder: "再次输入新密码",
                },
                model: {
                  value: _vm.entity.confirmPassword,
                  callback: function ($$v) {
                    _vm.$set(_vm.entity, "confirmPassword", $$v)
                  },
                  expression: "entity.confirmPassword",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-button",
        {
          directives: [{ name: "preventReClick", rawName: "v-preventReClick" }],
          staticClass: "submit-btn",
          style: {
            backgroundImage: _vm.isSellerServer()
              ? _vm.variables.seller_btn
              : _vm.variables.platform_btn,
          },
          on: { click: _vm.submitForm },
        },
        [_vm._v("\n    提交\n  ")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }