"use strict";

var _interopRequireDefault = require("D:/shenbimaliang1/sbml-backend/backend-page/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _interopRequireWildcard2 = _interopRequireDefault(require("D:/shenbimaliang1/sbml-backend/backend-page/node_modules/@babel/runtime/helpers/interopRequireWildcard.js"));
/**
 * 签到服务管理路由配置
 */
var checkinRouter = [{
  path: 'checkinLog/page',
  name: 'CheckinLogPage',
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/views/checkin/checkinLog/CheckinLogPage'));
    });
  },
  meta: {
    title: '用户签到记录列表'
  }
}, {
  path: 'checkinAward/page',
  name: 'CheckinAwardPage',
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/views/checkin/checkinAward/CheckinAwardPage'));
    });
  },
  meta: {
    title: '签到奖励列表'
  }
}, {
  path: 'checkinAwardLog/page',
  name: 'CheckinAwardLogPage',
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/views/checkin/checkinAwardLog/CheckinAwardLogPage'));
    });
  },
  meta: {
    title: '签到奖励记录列表'
  }
}];
var _default = checkinRouter;
exports.default = _default;