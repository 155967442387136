"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _adSaleOrder = require("./ad-sale-order");
var _globalDialog = require("@/mixins/global-dialog");
var _advertiseApi = require("@/api/advertise-api");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  mixins: [_adSaleOrder.adSaleOrder, _globalDialog.updateDialog],
  methods: {
    //执行修改操作
    doUpdate: function doUpdate() {
      return (0, _advertiseApi.adSaleOrderUpdate)(this.entity);
    }
  }
};
exports.default = _default;