"use strict";

var _interopRequireDefault = require("D:/shenbimaliang1/sbml-backend/backend-page/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _sellerApi = require("@/api/seller-api");
var _globalDialog = require("@/mixins/global-dialog");
var _permission = require("@/mixins/permission");
var _sellerMoneyBalanceLog = require("./seller-money-balance-log");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'sellerMoneyBalanceLog',
  components: {
    Pagination: _Pagination.default
  },
  mixins: [_sellerMoneyBalanceLog.sellerMoneyBalanceLog, _globalDialog.pageDialog, _permission.permission],
  methods: {
    //导出
    exportExcel: function exportExcel() {
      (0, _sellerApi.sellerMoneyBalanceLogExport)(this.pageParam);
    },
    //调用接口查询
    doSearch: function doSearch() {
      return (0, _sellerApi.sellerMoneyBalanceLogPage)(this.pageParam);
    }
  }
};
exports.default = _default;