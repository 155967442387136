"use strict";

var _interopRequireDefault = require("D:/shenbimaliang1/sbml-backend/backend-page/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.createUniqueString = createUniqueString;
exports.toStorage = toStorage;
exports.validImgUpload = void 0;
require("core-js/modules/es6.regexp.replace");
require("core-js/modules/es6.regexp.to-string");
var _vue = _interopRequireDefault(require("vue"));
/**
 * 创建唯一的字符串
 * @return {string} ojgdvbvaua40
 */
function createUniqueString() {
  var timestamp = +new Date() + '';
  var randomNum = parseInt((1 + Math.random()) * 65536) + '';
  return (+(randomNum + timestamp)).toString(32);
}

/**
 * 数字存储大小格式化
 * @param {number} num 存储大小 单位：Byte
 * @param {number} digits 保留几位小数
 * @return {string} 2MB
 */
function toStorage(num, digits) {
  digits = digits || 2;
  if (num < 1024) {
    return num + 'B';
  }
  num = num * 1000 / 1024;
  var si = [{
    value: 1e18,
    symbol: 'E'
  }, {
    value: 1e15,
    symbol: 'P'
  }, {
    value: 1e12,
    symbol: 'T'
  }, {
    value: 1e9,
    symbol: 'G'
  }, {
    value: 1e6,
    symbol: 'M'
  }, {
    value: 1e3,
    symbol: 'K'
  }];
  for (var i = 0; i < si.length; i++) {
    if (num >= si[i].value) {
      return (num / si[i].value).toFixed(digits).replace(/\.0+$|(\.[0-9]*[1-9])0+$/, '$1') + si[i].symbol + 'B';
    }
  }
}

/**
 * 图片上传
 * @param {file} file el-upload文件对象
 * @param {number} size 限制的文件大小(kb) 默认10M
 */
var validImgUpload = function validImgUpload(file, size) {
  size = +size || 10240;
  var isSizeOut = file.size / 1024 > size;
  if (isSizeOut) {
    _vue.default.prototype.errorMessage('上传图片大小不能超过' + toStorage(size * 1024));
  }
  return !isSizeOut;
};
exports.validImgUpload = validImgUpload;