"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.number.constructor");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = {
  name: "SmsCode",
  props: {
    myClass: {
      type: String,
      default: function _default() {
        return null;
      }
    },
    ruleForm: {
      type: Object,
      require: true
    },
    countDown: {
      type: Number,
      default: 60
    },
    submitText: {
      type: String,
      default: "提交"
    },
    isDisabled: {
      type: Boolean,
      default: false
    },
    isShowSub: {
      type: Boolean,
      default: true
    },
    isCheckPhone: {
      type: Boolean,
      default: true
    },
    isHidePhone: {
      type: Boolean,
      default: false
    },
    btnColor: {
      type: String,
      default: "#2cb7e8"
    }
  },
  data: function data() {
    var checkPhone = function checkPhone(rule, value, callback) {
      if (!value) {
        return callback(new Error('请输入手机号'));
      } else {
        var reg = /^1[3|4|5|7|8][0-9]\d{8}$/;
        if (reg.test(value)) {
          callback();
        } else {
          return callback(new Error('请输入正确的手机号'));
        }
      }
    };
    return {
      fontColor: '',
      time: 60,
      //是否禁用按钮
      disabled: false,
      //按钮文本
      btnText: '发送验证码',
      rules: {
        phone: [{
          required: true,
          validator: checkPhone,
          trigger: 'blur'
        }],
        code: [{
          required: true,
          message: '请输入6位短信验证码',
          trigger: 'blur'
        }, {
          max: 6,
          min: 6,
          message: '请输入6位短信验证码'
        }]
      }
    };
  },
  created: function created() {
    this.fontColor = this.btnColor;
  },
  methods: {
    /**
     * 提交验证
     */
    submit: function submit() {
      var _this = this;
      this.$refs.elForm.validate(function (valid) {
        if (valid) {
          _this.$emit('submit', _this.ruleForm.code);
        } else {
          _this.$emit('errHandle');
        }
      });
    },
    /**
     * 发送验证码
     */
    sendCode: function sendCode() {
      var _this2 = this;
      this.$refs.elForm.validateField('phone', function (errorMessage) {
        if (!errorMessage) {
          _this2.$emit('send');
        }
      });
    },
    /**
     * 倒计时
     */
    doCountDown: function doCountDown() {
      var _this3 = this;
      // 1.时间开始倒数
      // 2.按钮进入禁用状态
      // 3.如果倒计时结束 按钮恢复可用状态 按钮文字变为重新发送, 时间重置
      // 4.倒计时的过程中 按钮文字为 多少s后重新发送
      var timer = setInterval(function () {
        _this3.time--;
        _this3.btnText = "".concat(_this3.time, "s\u540E\u91CD\u53D1");
        // this.btnText = `${this.time}s`
        _this3.disabled = true;
        _this3.fontColor = "#738083";
        if (_this3.time === 0) {
          _this3.disabled = false;
          _this3.btnText = '重新发送';
          _this3.fontColor = "#2cb7e8";
          _this3.time = _this3.countDown;
          clearInterval(timer);
        }
      }, 1000);
    }
  }
};
exports.default = _default2;