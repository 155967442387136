var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "子订单详情",
        visible: _vm.dialogVisible,
        "close-on-click-modal": false,
        "append-to-body": "",
        center: "",
        width: _vm.dialogWidth,
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
        close: _vm.closeDialog,
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "elForm",
          staticClass: "dialog-form",
          attrs: {
            model: _vm.entity,
            rules: _vm.rules,
            inline: true,
            "label-width": _vm.getLabelWidth(),
          },
        },
        [
          _c(
            "el-descriptions",
            {
              staticClass: "margin-top",
              attrs: {
                column: _vm.isPc() ? 3 : 1,
                "label-style": { width: "120px" },
                border: "",
                size: "medium",
                title: "基本信息",
              },
            },
            [
              _c(
                "el-descriptions-item",
                [
                  _c("template", { slot: "label" }, [
                    _c("i", { staticClass: "el-icon-postcard" }),
                    _vm._v("\n          母订单编号\n        "),
                  ]),
                  _vm._v(
                    "\n        " + _vm._s(_vm.entity.orderNo) + "\n      "
                  ),
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "el-descriptions-item",
                [
                  _c("template", { slot: "label" }, [
                    _c("i", { staticClass: "el-icon-postcard" }),
                    _vm._v("\n          商品图片\n        "),
                  ]),
                  _vm._v(" "),
                  _c("single-img-upload", {
                    attrs: { height: "80", "is-disabled": "", width: "80" },
                    model: {
                      value: _vm.entity.productImg,
                      callback: function ($$v) {
                        _vm.$set(_vm.entity, "productImg", $$v)
                      },
                      expression: "entity.productImg",
                    },
                  }),
                ],
                2
              ),
              _vm._v(" "),
              !_vm.isSeller()
                ? _c(
                    "el-descriptions-item",
                    [
                      _c("template", { slot: "label" }, [
                        _c("i", { staticClass: "el-icon-postcard" }),
                        _vm._v("\n          商品ID\n        "),
                      ]),
                      _vm._v(
                        "\n        " + _vm._s(_vm.entity.productId) + "\n      "
                      ),
                    ],
                    2
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "el-descriptions-item",
                [
                  _c("template", { slot: "label" }, [
                    _c("i", { staticClass: "el-icon-postcard" }),
                    _vm._v("\n          商品名称\n        "),
                  ]),
                  _vm._v(
                    "\n        " + _vm._s(_vm.entity.productName) + "\n      "
                  ),
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "el-descriptions-item",
                [
                  _c("template", { slot: "label" }, [
                    _c("i", { staticClass: "el-icon-postcard" }),
                    _vm._v("\n          单价\n        "),
                  ]),
                  _vm._v("\n        " + _vm._s(_vm.entity.price) + "\n      "),
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "el-descriptions-item",
                [
                  _c("template", { slot: "label" }, [
                    _c("i", { staticClass: "el-icon-postcard" }),
                    _vm._v("\n          成本价\n        "),
                  ]),
                  _vm._v(
                    "\n        " + _vm._s(_vm.entity.basePrice) + "\n      "
                  ),
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "el-descriptions-item",
                [
                  _c("template", { slot: "label" }, [
                    _c("i", { staticClass: "el-icon-postcard" }),
                    _vm._v("\n          数量\n        "),
                  ]),
                  _vm._v("\n        " + _vm._s(_vm.entity.number) + "\n      "),
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "el-descriptions-item",
                [
                  _c("template", { slot: "label" }, [
                    _c("i", { staticClass: "el-icon-postcard" }),
                    _vm._v("\n          规格\n        "),
                  ]),
                  _vm._v(
                    "\n        " + _vm._s(_vm.entity.productSpec) + "\n      "
                  ),
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "el-descriptions-item",
                [
                  _c("template", { slot: "label" }, [
                    _c("i", { staticClass: "el-icon-postcard" }),
                    _vm._v("\n          专区\n        "),
                  ]),
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.areaMap[_vm.entity.area]) +
                      "\n      "
                  ),
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "el-descriptions-item",
                [
                  _c("template", { slot: "label" }, [
                    _c("i", { staticClass: "el-icon-postcard" }),
                    _vm._v("\n          支付健康宝\n        "),
                  ]),
                  _vm._v(
                    "\n        " + _vm._s(_vm.entity.payPoints) + "\n      "
                  ),
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "el-descriptions-item",
                [
                  _c("template", { slot: "label" }, [
                    _c("i", { staticClass: "el-icon-postcard" }),
                    _vm._v("\n          支付金额\n        "),
                  ]),
                  _vm._v(
                    "\n        " + _vm._s(_vm.entity.payMoney) + "\n      "
                  ),
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "el-descriptions-item",
                [
                  _c("template", { slot: "label" }, [
                    _c("i", { staticClass: "el-icon-postcard" }),
                    _vm._v("\n          总金额\n        "),
                  ]),
                  _vm._v(
                    "\n        " + _vm._s(_vm.entity.totalMoney) + "\n      "
                  ),
                ],
                2
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-descriptions",
            {
              staticClass: "margin-top",
              attrs: {
                column: _vm.isPc() ? 3 : 1,
                "label-style": { width: "120px" },
                border: "",
                size: "medium",
                title: "物流信息",
              },
            },
            [
              _c(
                "el-descriptions-item",
                [
                  _c("template", { slot: "label" }, [
                    _c("i", { staticClass: "el-icon-postcard" }),
                    _vm._v("\n          物流公司\n        "),
                  ]),
                  _vm._v(
                    "\n        " + _vm._s(_vm.entity.shipCompany) + "\n      "
                  ),
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "el-descriptions-item",
                [
                  _c("template", { slot: "label" }, [
                    _c("i", { staticClass: "el-icon-postcard" }),
                    _vm._v("\n          物流单号\n        "),
                  ]),
                  _vm._v("\n        " + _vm._s(_vm.entity.shipNo) + "\n      "),
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "el-descriptions-item",
                [
                  _c("template", { slot: "label" }, [
                    _c("i", { staticClass: "el-icon-postcard" }),
                    _vm._v("\n          发货时间\n        "),
                  ]),
                  _vm._v(
                    "\n        " + _vm._s(_vm.entity.shipTime) + "\n      "
                  ),
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "el-descriptions-item",
                [
                  _c("template", { slot: "label" }, [
                    _c("i", { staticClass: "el-icon-postcard" }),
                    _vm._v("\n          收货人姓名\n        "),
                  ]),
                  _vm._v(
                    "\n        " + _vm._s(_vm.entity.addressName) + "\n      "
                  ),
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "el-descriptions-item",
                [
                  _c("template", { slot: "label" }, [
                    _c("i", { staticClass: "el-icon-postcard" }),
                    _vm._v("\n          收货人手机号\n        "),
                  ]),
                  _vm._v(
                    "\n        " + _vm._s(_vm.entity.addressPhone) + "\n      "
                  ),
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "el-descriptions-item",
                [
                  _c("template", { slot: "label" }, [
                    _c("i", { staticClass: "el-icon-postcard" }),
                    _vm._v("\n          收货地址\n        "),
                  ]),
                  _vm._v(
                    "\n        " + _vm._s(_vm.entity.address) + "\n      "
                  ),
                ],
                2
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-descriptions",
            {
              staticClass: "margin-top",
              attrs: {
                column: _vm.isPc() ? 3 : 1,
                "label-style": { width: "120px" },
                border: "",
                size: "medium",
                title: "退款信息",
              },
            },
            [
              _c(
                "el-descriptions-item",
                [
                  _c("template", { slot: "label" }, [
                    _c("i", { staticClass: "el-icon-postcard" }),
                    _vm._v("\n          退货状态\n        "),
                  ]),
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.refundStatusMap[_vm.entity.refundStatus]) +
                      "\n      "
                  ),
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "el-descriptions-item",
                [
                  _c("template", { slot: "label" }, [
                    _c("i", { staticClass: "el-icon-postcard" }),
                    _vm._v("\n          退款理由\n        "),
                  ]),
                  _vm._v(
                    "\n        " + _vm._s(_vm.entity.refundReason) + "\n      "
                  ),
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "el-descriptions-item",
                [
                  _c("template", { slot: "label" }, [
                    _c("i", { staticClass: "el-icon-postcard" }),
                    _vm._v("\n          退款类型\n        "),
                  ]),
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.refundTypeMap[_vm.entity.refundType]) +
                      "\n      "
                  ),
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "el-descriptions-item",
                [
                  _c("template", { slot: "label" }, [
                    _c("i", { staticClass: "el-icon-postcard" }),
                    _vm._v("\n          退款物流公司\n        "),
                  ]),
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.entity.refundShipCompany) +
                      "\n      "
                  ),
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "el-descriptions-item",
                [
                  _c("template", { slot: "label" }, [
                    _c("i", { staticClass: "el-icon-postcard" }),
                    _vm._v("\n          退款物流单号\n        "),
                  ]),
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.entity.refundShipOrderNo) +
                      "\n      "
                  ),
                ],
                2
              ),
            ],
            1
          ),
          _vm._v(" "),
          !_vm.isSeller()
            ? _c(
                "el-descriptions",
                {
                  staticClass: "margin-top",
                  attrs: {
                    column: _vm.isPc() ? 3 : 1,
                    "label-style": { width: "120px" },
                    border: "",
                    size: "medium",
                    title: "分润信息",
                  },
                },
                [
                  _c(
                    "el-descriptions-item",
                    [
                      _c("template", { slot: "label" }, [
                        _c("i", { staticClass: "el-icon-postcard" }),
                        _vm._v("\n          邀请人\n        "),
                      ]),
                      _vm._v(
                        "\n        " + _vm._s(_vm.entity.inviterId) + "\n      "
                      ),
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _c(
                    "el-descriptions-item",
                    [
                      _c("template", { slot: "label" }, [
                        _c("i", { staticClass: "el-icon-postcard" }),
                        _vm._v("\n          邀请人奖励\n        "),
                      ]),
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.entity.inviterAwardMoney) +
                          "\n      "
                      ),
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _c(
                    "el-descriptions-item",
                    [
                      _c("template", { slot: "label" }, [
                        _c("i", { staticClass: "el-icon-postcard" }),
                        _vm._v("\n          代理ID\n        "),
                      ]),
                      _vm._v(
                        "\n        " + _vm._s(_vm.entity.proxyId) + "\n      "
                      ),
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _c(
                    "el-descriptions-item",
                    [
                      _c("template", { slot: "label" }, [
                        _c("i", { staticClass: "el-icon-postcard" }),
                        _vm._v("\n          代理人奖励\n        "),
                      ]),
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.entity.proxyAwardMoney) +
                          "\n      "
                      ),
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _c(
                    "el-descriptions-item",
                    [
                      _c("template", { slot: "label" }, [
                        _c("i", { staticClass: "el-icon-postcard" }),
                        _vm._v("\n          是否已经分润\n        "),
                      ]),
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.hadAwardMap[_vm.entity.hadAward]) +
                          "\n      "
                      ),
                    ],
                    2
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "form-button-container" },
        [
          _c(
            "el-button",
            {
              attrs: { type: "danger", icon: "el-icon-back" },
              on: {
                click: function ($event) {
                  _vm.dialogVisible = false
                },
              },
            },
            [_vm._v("返回")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }