"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.numberInput = void 0;
require("core-js/modules/es6.regexp.replace");
function onInput(el, ele, binding, vnode) {
  function handle() {
    var numberType = binding.value.type;
    //整数
    if (numberType == 'integer') {
      ele.value = ele.value.replace(/[^\d -]/g, '');
      //去掉除第一个字符以外所有的-
      ele.value = ele.value.substring(0, 1) + ele.value.substring(1).replace(/\-/g, '');
    }
    //正整数
    if (numberType == 'positiveInteger') {
      ele.value = ele.value.replace(/[^\d]/g, '');
    }
    //小数
    if (numberType == 'decimal') {
      ele.value = ele.value.replace(/[^\-?\d.]/g, '').replace(/^\./g, '0.').replace(/\.{2,}/g, '.'); //负数,小数,多个点 第一个点加上0.  不能有多个.
      //去掉除第一个字符以外所有的-
      ele.value = ele.value.substring(0, 1) + ele.value.substring(1).replace(/\-/g, '');
      //返回某个指定的字符串值在字符串中首次出现的位置
      var firstPlace = ele.value.indexOf('.');
      if (firstPlace > 0) {
        //去掉后面所有的.
        ele.value = ele.value.substring(0, firstPlace + 1) + ele.value.substring(firstPlace + 1).replace(/\./g, '');
      }
    }
    //正小数
    if (numberType == 'positiveDecimal') {
      ele.value = ele.value.replace(/[^\d.]/g, '').replace(/^\./g, '0.').replace(/\.{2,}/g, '.'); //负数,小数,多个点 第一个点加上0.  不能有多个.
      //返回某个指定的字符串值在字符串中首次出现的位置
      var _firstPlace = ele.value.indexOf('.');
      if (_firstPlace > 0) {
        //去掉后面所有的.
        ele.value = ele.value.substring(0, _firstPlace + 1) + ele.value.substring(_firstPlace + 1).replace(/\./g, '');
      }
    }
    //这句代码特别重要,让input输入框中的值和model的值保持一致,没这句代码会导致model更新后,这个地方修改输入框的值不会触发model的再次更新
    ele.dispatchEvent(new Event('input')); //如果值被修改了会再次触发model的更新
  }

  return handle;
}
/**
 * 对输入框中的非数字字符进行替换
 */
var numberInput = {
  bind: function bind(el, binding, vnode) {
    var ele = el.tagName === 'INPUT' ? el : el.querySelector('input');
    ele.addEventListener('keyup', onInput(el, ele, binding, vnode), false);
  }
};
exports.numberInput = numberInput;