"use strict";

var _interopRequireDefault = require("D:/shenbimaliang1/sbml-backend/backend-page/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _advertiseApi = require("@/api/advertise-api");
var _globalDialog = require("@/mixins/global-dialog");
var _permission = require("@/mixins/permission");
var _adBuySlaveOrder = require("./ad-buy-slave-order");
var _AdBuySlaveOrderAdd = _interopRequireDefault(require("./AdBuySlaveOrderAdd"));
var _AdBuySlaveOrderUpdate = _interopRequireDefault(require("./AdBuySlaveOrderUpdate"));
var _AdBuySlaveOrderDetail = _interopRequireDefault(require("./AdBuySlaveOrderDetail"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'AdBuySlaveOrderPage',
  components: {
    Pagination: _Pagination.default,
    AdBuySlaveOrderAdd: _AdBuySlaveOrderAdd.default,
    AdBuySlaveOrderUpdate: _AdBuySlaveOrderUpdate.default,
    AdBuySlaveOrderDetail: _AdBuySlaveOrderDetail.default
  },
  mixins: [_adBuySlaveOrder.adBuySlaveOrder, _globalDialog.pageDialog, _permission.permission],
  methods: {
    //导出
    exportExcel: function exportExcel() {
      (0, _advertiseApi.adBuySlaveOrderExport)(this.pageParam);
    },
    //调用接口查询
    doSearch: function doSearch() {
      return (0, _advertiseApi.adBuySlaveOrderPage)(this.pageParam);
    },
    /**
     * 执行批量删除操作
     * @param {Object} idList id集合
     */
    doBatchDelete: function doBatchDelete(idList) {
      return (0, _advertiseApi.adBuySlaveOrderDelete)(idList);
    },
    /**
     * 打开广告购买订单-子单详情窗口
     */
    openDetailDialog: function openDetailDialog() {
      var _this = this;
      var selectData = this.getSelect();
      if (this.isEmpty(selectData)) {
        return;
      }
      this.showDetailDialog = true;
      this.$nextTick(function () {
        // 把选中行的数据拷贝一份传递给修改对话框
        selectData = _this.transTimeDia(selectData);
        _this.$refs.detailDialog.setEntity(_this.objectCopy(selectData));
      });
    }
  }
};
exports.default = _default;