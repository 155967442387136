"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.adWithdrawLogReview = exports.adWithdrawLogPage = exports.adWithdrawLogLock = exports.adWithdrawLogExport = exports.adUserReview = exports.adUserRecharge = exports.adUserPage = exports.adUserMoneyBalanceTypeEnums = exports.adUserMoneyBalanceLogUpdate = exports.adUserMoneyBalanceLogPage = exports.adUserMoneyBalanceLogExport = exports.adUserMoneyBalanceLogDelete = exports.adUserMoneyBalanceLogAdd = exports.adUserMoneyAdTypeEnums = exports.adUserMoneyAdLogUpdate = exports.adUserMoneyAdLogPage = exports.adUserMoneyAdLogExport = exports.adUserMoneyAdLogDelete = exports.adUserMoneyAdLogAdd = exports.adUserExport = exports.adUserDelete = exports.adUserBankUpdate = exports.adUserBankPage = exports.adUserBankExport = exports.adUserBankDelete = exports.adUserBankAdd = exports.adMoneyEnums = void 0;
var _request = require("@/utils/request");
//=============================================================枚举=======================================================
var adUserMoneyBalanceTypeEnums = function adUserMoneyBalanceTypeEnums() {
  return (0, _request.post)('/advertise/enums/ad/user-money-balance/type');
};
exports.adUserMoneyBalanceTypeEnums = adUserMoneyBalanceTypeEnums;
var adUserMoneyAdTypeEnums = function adUserMoneyAdTypeEnums() {
  return (0, _request.post)('/advertise/enums/ad/user-money-ad/type');
};
exports.adUserMoneyAdTypeEnums = adUserMoneyAdTypeEnums;
var adMoneyEnums = function adMoneyEnums() {
  return (0, _request.post)('/advertise/enums/money');
};
//=============================================================广告主=======================================================
/**
 * 广告主充值
 */
exports.adMoneyEnums = adMoneyEnums;
var adUserRecharge = function adUserRecharge(data) {
  return (0, _request.post)('/advertise/adUser/recharge', data);
};
/**
 * 广告主批量删除
 */
exports.adUserRecharge = adUserRecharge;
var adUserDelete = function adUserDelete(idList) {
  return (0, _request.post)('/advertise/adUser/delete?idList=' + idList);
};
/**
 * 广告主审核
 */
exports.adUserDelete = adUserDelete;
var adUserReview = function adUserReview(data) {
  return (0, _request.post)('/advertise/adUser/review', data);
};
/**
 * 广告主列表
 */
exports.adUserReview = adUserReview;
var adUserPage = function adUserPage(data) {
  return (0, _request.post)('/advertise/adUser/page', data);
};
/**
 * 广告主导出
 */
exports.adUserPage = adUserPage;
var adUserExport = function adUserExport(param, name) {
  return (0, _request.exportExcel)('/advertise/adUser/export', param, name);
};
//=============================================================广告主银行卡=======================================================
/**
 * 广告主银行卡新增
 */
exports.adUserExport = adUserExport;
var adUserBankAdd = function adUserBankAdd(data) {
  return (0, _request.post)('/advertise/adUserBank/add', data);
};
/**
 * 广告主银行卡批量删除
 */
exports.adUserBankAdd = adUserBankAdd;
var adUserBankDelete = function adUserBankDelete(idList) {
  return (0, _request.post)('/advertise/adUserBank/delete?idList=' + idList);
};
/**
 * 广告主银行卡修改
 */
exports.adUserBankDelete = adUserBankDelete;
var adUserBankUpdate = function adUserBankUpdate(data) {
  return (0, _request.post)('/advertise/adUserBank/update', data);
};
/**
 * 广告主银行卡列表
 */
exports.adUserBankUpdate = adUserBankUpdate;
var adUserBankPage = function adUserBankPage(data) {
  return (0, _request.post)('/advertise/adUserBank/page', data);
};
/**
 * 广告主银行卡导出
 */
exports.adUserBankPage = adUserBankPage;
var adUserBankExport = function adUserBankExport(param, name) {
  return (0, _request.exportExcel)('/advertise/adUserBank/export', param, name);
};
//=============================================================广告主资金明细-广告卡=======================================================
/**
 * 广告主资金明细-广告卡新增
 */
exports.adUserBankExport = adUserBankExport;
var adUserMoneyAdLogAdd = function adUserMoneyAdLogAdd(data) {
  return (0, _request.post)('/advertise/adUserMoneyAdLog/add', data);
};
/**
 * 广告主资金明细-广告卡批量删除
 */
exports.adUserMoneyAdLogAdd = adUserMoneyAdLogAdd;
var adUserMoneyAdLogDelete = function adUserMoneyAdLogDelete(idList) {
  return (0, _request.post)('/advertise/adUserMoneyAdLog/delete?idList=' + idList);
};
/**
 * 广告主资金明细-广告卡修改
 */
exports.adUserMoneyAdLogDelete = adUserMoneyAdLogDelete;
var adUserMoneyAdLogUpdate = function adUserMoneyAdLogUpdate(data) {
  return (0, _request.post)('/advertise/adUserMoneyAdLog/update', data);
};
/**
 * 广告主资金明细-广告卡列表
 */
exports.adUserMoneyAdLogUpdate = adUserMoneyAdLogUpdate;
var adUserMoneyAdLogPage = function adUserMoneyAdLogPage(data) {
  return (0, _request.post)('/advertise/adUserMoneyAdLog/page', data);
};
/**
 * 广告主资金明细-广告卡导出
 */
exports.adUserMoneyAdLogPage = adUserMoneyAdLogPage;
var adUserMoneyAdLogExport = function adUserMoneyAdLogExport(param, name) {
  return (0, _request.exportExcel)('/advertise/adUserMoneyAdLog/export', param, name);
};
//=============================================================广告主资金明细-余额=======================================================
/**
 * 广告主资金明细-余额新增
 */
exports.adUserMoneyAdLogExport = adUserMoneyAdLogExport;
var adUserMoneyBalanceLogAdd = function adUserMoneyBalanceLogAdd(data) {
  return (0, _request.post)('/advertise/adUserMoneyBalanceLog/add', data);
};
/**
 * 广告主资金明细-余额批量删除
 */
exports.adUserMoneyBalanceLogAdd = adUserMoneyBalanceLogAdd;
var adUserMoneyBalanceLogDelete = function adUserMoneyBalanceLogDelete(idList) {
  return (0, _request.post)('/advertise/adUserMoneyBalanceLog/delete?idList=' + idList);
};
/**
 * 广告主资金明细-余额修改
 */
exports.adUserMoneyBalanceLogDelete = adUserMoneyBalanceLogDelete;
var adUserMoneyBalanceLogUpdate = function adUserMoneyBalanceLogUpdate(data) {
  return (0, _request.post)('/advertise/adUserMoneyBalanceLog/update', data);
};
/**
 * 广告主资金明细-余额列表
 */
exports.adUserMoneyBalanceLogUpdate = adUserMoneyBalanceLogUpdate;
var adUserMoneyBalanceLogPage = function adUserMoneyBalanceLogPage(data) {
  return (0, _request.post)('/advertise/adUserMoneyBalanceLog/page', data);
};
/**
 * 广告主资金明细-余额导出
 */
exports.adUserMoneyBalanceLogPage = adUserMoneyBalanceLogPage;
var adUserMoneyBalanceLogExport = function adUserMoneyBalanceLogExport(param, name) {
  return (0, _request.exportExcel)('/advertise/adUserMoneyBalanceLog/export', param, name);
};
//=============================================================广告主提现=======================================================
/**
 * 提现批量锁定
 */
exports.adUserMoneyBalanceLogExport = adUserMoneyBalanceLogExport;
var adWithdrawLogLock = function adWithdrawLogLock(idList) {
  return (0, _request.post)('/advertise/adWithdrawLog/lock?idList=' + idList);
};
/**
 * 提现审核
 */
exports.adWithdrawLogLock = adWithdrawLogLock;
var adWithdrawLogReview = function adWithdrawLogReview(data) {
  return (0, _request.post)('/advertise/adWithdrawLog/review', data);
};
/**
 * 广告主提现列表
 */
exports.adWithdrawLogReview = adWithdrawLogReview;
var adWithdrawLogPage = function adWithdrawLogPage(data) {
  return (0, _request.post)('/advertise/adWithdrawLog/page', data);
};
/**
 * 广告主提现导出
 */
exports.adWithdrawLogPage = adWithdrawLogPage;
var adWithdrawLogExport = function adWithdrawLogExport(param, name) {
  return (0, _request.exportExcel)('/advertise/adWithdrawLog/export', param, name);
};
exports.adWithdrawLogExport = adWithdrawLogExport;