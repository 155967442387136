"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.advert = void 0;
var advert = {
  data: function data() {
    return {
      //状态枚举
      statusMap: {
        0: '禁用',
        1: '启用'
      },
      //状态标签颜色枚举
      statusColorMap: {
        0: 'danger',
        1: 'success'
      },
      //状态枚举
      typeMap: {
        'navigator': '首页ICON',
        'advert': '活动页'
      },
      //状态标签颜色枚举
      typeColorMap: {
        'navigator': 'danger',
        'advert': 'success'
      },
      //状态枚举
      advertTypeMap: {
        'carousel': 'carousel',
        'middle': 'middle'
      },
      //状态标签颜色枚举
      advertTypeColorMap: {
        'carousel': 'danger',
        'middle': 'success'
      },
      //是否展示详情窗口
      showDetailDialog: false,
      backUrl: '/operation/advert/page',
      entity: {},
      rules: {
        type: [{
          required: true,
          message: '请输入类型'
        }, {
          max: 20,
          message: '类型长度在20个字符内'
        }],
        image: [{
          required: true,
          message: '请输入图片'
        }, {
          max: 256,
          message: '图片长度在256个字符内'
        }],
        link: [{
          required: true,
          message: '请输入跳转地址'
        }, {
          max: 256,
          message: '跳转地址长度在256个字符内'
        }],
        name: [{
          required: true,
          message: '请输入名称'
        }, {
          max: 50,
          message: '名称长度在50个字符内'
        }],
        sort: [{
          required: true,
          message: '请输入排序'
        }],
        advertType: [{
          required: false,
          message: '请输入活动页类型'
        }, {
          max: 20,
          message: '活动页类型长度在20个字符内'
        }],
        status: [{
          required: true,
          message: '请输入状态'
        }]
      }
    };
  }
};
exports.advert = advert;