"use strict";

var _interopRequireDefault = require("D:/shenbimaliang1/sbml-backend/backend-page/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _interopRequireWildcard2 = _interopRequireDefault(require("D:/shenbimaliang1/sbml-backend/backend-page/node_modules/@babel/runtime/helpers/interopRequireWildcard.js"));
/**
 * 商品管理路由配置
 */
var productRouter = [{
  path: 'productCategory/page',
  name: 'ProductCategoryPage',
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/views/product/productCategory/ProductCategoryPage'));
    });
  },
  meta: {
    title: '商品分类列表'
  }
}, {
  path: 'product/page',
  name: 'ProductPage',
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/views/product/product/ProductPage'));
    });
  },
  meta: {
    title: '商品列表'
  }
}, {
  path: 'sku/page',
  name: 'SkuPage',
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/views/product/sku/SkuPage'));
    });
  },
  meta: {
    title: '商品SKU列表'
  }
}, {
  path: 'ropeVipProduct/page',
  name: 'RopeVipProductPage',
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/views/product/ropeVipProduct/RopeVipProductPage'));
    });
  },
  meta: {
    title: '绳子会员产品列表'
  }
}];
var _default = productRouter;
exports.default = _default;