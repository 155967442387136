"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ropeVipLogUpdate = exports.ropeVipLogPage = exports.ropeVipLogExport = exports.ropeVipLogDelete = exports.ropeVipLogAdd = exports.ropeDeviceReview = exports.ropeDevicePage = exports.ropeDeviceLock = exports.ropeDeviceExport = exports.orderUpdate = exports.orderSlaveShip = exports.orderSlaveRefund = exports.orderSlavePage = exports.orderSlaveExport = exports.orderShip = exports.orderRopeRefundTypeEnums = exports.orderRopeModeEnums = exports.orderRefundTypeEnums = exports.orderRefundStatusEnums = exports.orderPayTypeEnums = exports.orderPage = exports.orderExport = exports.expressUpdate = exports.expressPage = exports.expressExport = exports.expressDelete = exports.expressAdd = void 0;
var _request = require("@/utils/request");
//=============================================================枚举=======================================================
var orderPayTypeEnums = function orderPayTypeEnums() {
  return (0, _request.post)('/order/enums/pay/type');
};
exports.orderPayTypeEnums = orderPayTypeEnums;
var orderRefundStatusEnums = function orderRefundStatusEnums() {
  return (0, _request.post)('/order/enums/refund/status');
};
exports.orderRefundStatusEnums = orderRefundStatusEnums;
var orderRefundTypeEnums = function orderRefundTypeEnums() {
  return (0, _request.post)('/order/enums/refund/type');
};
exports.orderRefundTypeEnums = orderRefundTypeEnums;
var orderRopeRefundTypeEnums = function orderRopeRefundTypeEnums() {
  return (0, _request.post)('/order/enums/rope/refund/way');
};
exports.orderRopeRefundTypeEnums = orderRopeRefundTypeEnums;
var orderRopeModeEnums = function orderRopeModeEnums() {
  return (0, _request.post)('/order/enums/rope/mode');
};
//=============================================================订单=======================================================
/**
 * 订单修改
 */
exports.orderRopeModeEnums = orderRopeModeEnums;
var orderUpdate = function orderUpdate(data) {
  return (0, _request.post)('/order/order/update', data);
};
/**
 * 订单列表
 */
exports.orderUpdate = orderUpdate;
var orderPage = function orderPage(data) {
  return (0, _request.post)('/order/order/page', data);
};
/**
 * 订单发货
 */
exports.orderPage = orderPage;
var orderShip = function orderShip(data) {
  return (0, _request.post)('/order/order/ship', data);
};
/**
 * 订单导出
 */
exports.orderShip = orderShip;
var orderExport = function orderExport(param, name) {
  return (0, _request.exportExcel)('/order/order/export', param, name);
};
//=============================================================子订单=======================================================
/**
 * 子订单发货
 */
exports.orderExport = orderExport;
var orderSlaveShip = function orderSlaveShip(data) {
  return (0, _request.post)('/order/orderSlave/ship', data);
};
/**
 * 子订单退款
 */
exports.orderSlaveShip = orderSlaveShip;
var orderSlaveRefund = function orderSlaveRefund(data) {
  return (0, _request.post)('/order/orderSlave/refund', data);
};
/**
 * 子订单列表
 */
exports.orderSlaveRefund = orderSlaveRefund;
var orderSlavePage = function orderSlavePage(data) {
  return (0, _request.post)('/order/orderSlave/page', data);
};
/**
 * 子订单导出
 */
exports.orderSlavePage = orderSlavePage;
var orderSlaveExport = function orderSlaveExport(param, name) {
  return (0, _request.exportExcel)('/order/orderSlave/export', param, name);
};
//=============================================================物流=======================================================
/**
 * 物流新增
 */
exports.orderSlaveExport = orderSlaveExport;
var expressAdd = function expressAdd(data) {
  return (0, _request.post)('/order/express/add', data);
};
/**
 * 物流批量删除
 */
exports.expressAdd = expressAdd;
var expressDelete = function expressDelete(idList) {
  return (0, _request.post)('/order/express/delete?idList=' + idList);
};
/**
 * 物流修改
 */
exports.expressDelete = expressDelete;
var expressUpdate = function expressUpdate(data) {
  return (0, _request.post)('/order/express/update', data);
};
/**
 * 物流列表
 */
exports.expressUpdate = expressUpdate;
var expressPage = function expressPage(data) {
  return (0, _request.post)('/order/express/page', data);
};
/**
 * 物流导出
 */
exports.expressPage = expressPage;
var expressExport = function expressExport(param, name) {
  return (0, _request.exportExcel)('/order/express/export', param, name);
};
//=============================================================绳子订单=======================================================
/**
 * 绳子订单锁定
 */
exports.expressExport = expressExport;
var ropeDeviceLock = function ropeDeviceLock(id) {
  return (0, _request.post)('/order/ropeDevice/lock?id=' + id);
};
/**
 * 绳子订单审核
 */
exports.ropeDeviceLock = ropeDeviceLock;
var ropeDeviceReview = function ropeDeviceReview(data) {
  return (0, _request.post)('/order/ropeDevice/review', data);
};
/**
 * 绳子订单列表
 */
exports.ropeDeviceReview = ropeDeviceReview;
var ropeDevicePage = function ropeDevicePage(data) {
  return (0, _request.post)('/order/ropeDevice/page', data);
};
/**
 * 绳子订单导出
 */
exports.ropeDevicePage = ropeDevicePage;
var ropeDeviceExport = function ropeDeviceExport(param, name) {
  return (0, _request.exportExcel)('/order/ropeDevice/export', param, name);
};
//=============================================================绳子会员订单=======================================================
/**
 * 绳子会员订单新增
 */
exports.ropeDeviceExport = ropeDeviceExport;
var ropeVipLogAdd = function ropeVipLogAdd(data) {
  return (0, _request.post)('/order/ropeVipLog/add', data);
};
/**
 * 绳子会员订单批量删除
 */
exports.ropeVipLogAdd = ropeVipLogAdd;
var ropeVipLogDelete = function ropeVipLogDelete(idList) {
  return (0, _request.post)('/order/ropeVipLog/delete?idList=' + idList);
};
/**
 * 绳子会员订单修改
 */
exports.ropeVipLogDelete = ropeVipLogDelete;
var ropeVipLogUpdate = function ropeVipLogUpdate(data) {
  return (0, _request.post)('/order/ropeVipLog/update', data);
};
/**
 * 绳子会员订单列表
 */
exports.ropeVipLogUpdate = ropeVipLogUpdate;
var ropeVipLogPage = function ropeVipLogPage(data) {
  return (0, _request.post)('/order/ropeVipLog/page', data);
};
/**
 * 绳子会员订单导出
 */
exports.ropeVipLogPage = ropeVipLogPage;
var ropeVipLogExport = function ropeVipLogExport(param, name) {
  return (0, _request.exportExcel)('/order/ropeVipLog/export', param, name);
};
exports.ropeVipLogExport = ropeVipLogExport;