"use strict";

var _interopRequireDefault = require("D:/shenbimaliang1/sbml-backend/backend-page/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _advertiseApi = require("@/api/advertise-api");
var _globalDialog = require("@/mixins/global-dialog");
var _permission = require("@/mixins/permission");
var _adSaleOrder = require("./ad-sale-order");
var _AdSaleOrderAdd = _interopRequireDefault(require("./AdSaleOrderAdd"));
var _AdSaleOrderUpdate = _interopRequireDefault(require("./AdSaleOrderUpdate"));
var _AdSaleOrderDetail = _interopRequireDefault(require("./AdSaleOrderDetail"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'AdSaleOrderPage',
  components: {
    Pagination: _Pagination.default,
    AdSaleOrderAdd: _AdSaleOrderAdd.default,
    AdSaleOrderUpdate: _AdSaleOrderUpdate.default,
    AdSaleOrderDetail: _AdSaleOrderDetail.default
  },
  mixins: [_adSaleOrder.adSaleOrder, _globalDialog.pageDialog, _permission.permission],
  methods: {
    //导出
    exportExcel: function exportExcel() {
      (0, _advertiseApi.adSaleOrderExport)(this.pageParam);
    },
    //调用接口查询
    doSearch: function doSearch() {
      return (0, _advertiseApi.adSaleOrderPage)(this.pageParam);
    },
    /**
     * 执行批量删除操作
     * @param {Object} idList id集合
     */
    doBatchDelete: function doBatchDelete(idList) {
      return (0, _advertiseApi.adSaleOrderDelete)(idList);
    },
    /**
     * 打开广告出售订单详情窗口
     */
    openDetailDialog: function openDetailDialog() {
      var _this = this;
      var selectData = this.getSelect();
      if (this.isEmpty(selectData)) {
        return;
      }
      this.showDetailDialog = true;
      this.$nextTick(function () {
        // 把选中行的数据拷贝一份传递给修改对话框
        selectData = _this.transTimeDia(selectData);
        _this.$refs.detailDialog.setEntity(_this.objectCopy(selectData));
      });
    }
  }
};
exports.default = _default;