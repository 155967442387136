import { render, staticRenderFns } from "./ProductUpdate.vue?vue&type=template&id=4370888e&scoped=true&"
import script from "./ProductUpdate.vue?vue&type=script&lang=js&"
export * from "./ProductUpdate.vue?vue&type=script&lang=js&"
import style0 from "./ProductUpdate.vue?vue&type=style&index=0&id=4370888e&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4370888e",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\shenbimaliang1\\sbml-backend\\backend-page\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('4370888e')) {
      api.createRecord('4370888e', component.options)
    } else {
      api.reload('4370888e', component.options)
    }
    module.hot.accept("./ProductUpdate.vue?vue&type=template&id=4370888e&scoped=true&", function () {
      api.rerender('4370888e', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/product/product/ProductUpdate.vue"
export default component.exports