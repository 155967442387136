"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.teacher = void 0;
var teacher = {
  data: function data() {
    return {
      //是否展示详情窗口
      showDetailDialog: false,
      //状态枚举
      statusMap: {
        0: '禁用',
        1: '启用'
      },
      //状态标签颜色枚举
      statusColorMap: {
        0: 'danger',
        1: 'success',
        2: 'danger',
        3: 'warning',
        4: 'info',
        5: 'primary'
      },
      backUrl: '/teacher/teacher/page',
      entity: {},
      rules: {
        registerIp: [{
          required: true,
          message: '请输入注册IP'
        }, {
          max: 256,
          message: '注册IP长度在256个字符内'
        }],
        status: [{
          required: true,
          message: '请输入状态'
        }],
        nickName: [{
          required: true,
          message: '请输入昵称'
        }, {
          max: 255,
          message: '昵称长度在255个字符内'
        }],
        wxUnionId: [{
          required: true,
          message: '请输入微信unionId'
        }, {
          max: 255,
          message: '微信unionId长度在255个字符内'
        }],
        wxMiniOpenId: [{
          required: true,
          message: '请输入小程序openId'
        }, {
          max: 255,
          message: '小程序openId长度在255个字符内'
        }],
        headImg: [{
          required: true,
          message: '请输入头像'
        }, {
          max: 255,
          message: '头像长度在255个字符内'
        }],
        mobile: [{
          required: true,
          message: '请输入手机号'
        }, {
          max: 255,
          message: '手机号长度在255个字符内'
        }]
      }
    };
  }
};
exports.teacher = teacher;