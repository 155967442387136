"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
var _default2 = {
  name: "LineStack",
  props: {
    title: {
      type: String
    },
    lId: {
      type: String,
      default: function _default() {
        return this.guid();
      }
    },
    // 分类
    cate: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    // 数据
    data: {
      type: Array,
      default: function _default() {
        return [];
      }
    }
  },
  watch: {
    data: function data() {
      this.initLine();
    },
    cate: function cate() {
      this.initLine();
    }
  },
  methods: {
    initLine: function initLine() {
      try {
        var lineStackEChart = this.$echarts5.init(document.getElementById('lineStackChart' + this.lId));
        var option = {
          title: {
            text: this.title,
            left: 'center'
          },
          tooltip: {
            trigger: 'axis'
          },
          legend: {
            left: 10,
            top: '20px'
          },
          // grid: {
          //   left: '3%',
          //   right: '4%',
          //   bottom: '3%',
          //   containLabel: true
          // },
          toolbox: {
            //保存图片按钮
            feature: {
              saveAsImage: {}
            }
          },
          xAxis: {
            type: 'category',
            boundaryGap: false,
            data: this.cate
          },
          yAxis: {
            type: 'value'
          },
          series: this.data
        };
        lineStackEChart.setOption(option);
        //随着屏幕大小调节图表
        window.addEventListener("resize", function () {
          lineStackEChart.resize();
        });
      } catch (e) {}
    }
  }
};
exports.default = _default2;