"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.sellerReviewLog = void 0;
var sellerReviewLog = {
  data: function data() {
    return {
      //审核状态枚举
      reviewStatusMap: {
        1: '审核通过',
        2: '审核不通过'
      },
      //审核状态标签颜色枚举
      reviewStatusColorMap: {
        1: 'success',
        2: 'danger'
      },
      //是否展示详情窗口
      showDetailDialog: false,
      //是否展示审核窗口
      showReviewDialog: false,
      //审核状态枚举
      statusMap: {
        0: '待审核',
        1: '审核通过',
        2: '审核不通过'
      },
      //审核状态标签颜色枚举
      statusColorMap: {
        0: 'danger',
        1: 'success',
        2: 'danger',
        3: 'warning',
        4: 'info',
        5: 'primary'
      },
      backUrl: '/seller/sellerReviewLog/page',
      entity: {},
      rules: {
        sellerId: [{
          required: false,
          message: '请输入商户ID'
        }],
        logo: [{
          required: false,
          message: '请输入商标'
        }, {
          max: 255,
          message: '商标长度在255个字符内'
        }],
        idCardFront: [{
          required: false,
          message: '请输入身份证人头面'
        }, {
          max: 255,
          message: '身份证人头面长度在255个字符内'
        }],
        idCardBackend: [{
          required: false,
          message: '请输入身份证国徽面'
        }, {
          max: 255,
          message: '身份证国徽面长度在255个字符内'
        }],
        business: [{
          required: false,
          message: '请输入营业执照'
        }, {
          max: 255,
          message: '营业执照长度在255个字符内'
        }],
        license: [{
          required: false,
          message: '请输入许可证'
        }, {
          max: 255,
          message: '许可证长度在255个字符内'
        }],
        address: [{
          required: false,
          message: '请输入地址'
        }, {
          max: 255,
          message: '地址长度在255个字符内'
        }],
        contactName: [{
          required: false,
          message: '请输入联系人姓名'
        }, {
          max: 255,
          message: '联系人姓名长度在255个字符内'
        }],
        phone: [{
          required: false,
          message: '请输入联系电话'
        }, {
          max: 255,
          message: '联系电话长度在255个字符内'
        }],
        name: [{
          required: false,
          message: '请输入商户名称'
        }, {
          max: 255,
          message: '商户名称长度在255个字符内'
        }],
        status: [{
          required: false,
          message: '请输入审核状态'
        }],
        backReason: [{
          required: false,
          message: '请输入审核不通过理由'
        }, {
          max: 255,
          message: '审核不通过理由长度在255个字符内'
        }],
        reviewStatus: [{
          required: true,
          message: '请选择审核状态'
        }]
      }
    };
  }
};
exports.sellerReviewLog = sellerReviewLog;