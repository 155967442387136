"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.feedback = void 0;
var feedback = {
  data: function data() {
    return {
      types: ['功能异常', '使用体验', '产品建议', '客服投诉', '其他'],
      //状态枚举
      isSolvedMap: {
        0: '未解决',
        1: '已解决'
      },
      //状态标签颜色枚举
      isSolvedColorMap: {
        0: 'danger',
        1: 'success'
      },
      //平台枚举
      platformMap: {
        0: 'APP',
        1: '后台'
      },
      //平台标签颜色枚举
      platformColorMap: {
        0: 'danger',
        1: 'success'
      },
      //是否展示详情窗口
      showDetailDialog: false,
      backUrl: '/operation/feedback/page',
      entity: {},
      rules: {
        type: [{
          required: true,
          message: '请输入类型'
        }, {
          max: 255,
          message: '类型长度在255个字符内'
        }],
        content: [{
          required: true,
          message: '请输入内容'
        }, {
          max: 2147483647,
          message: '内容长度在2147483647个字符内'
        }],
        img: [{
          required: true,
          message: '请输入图片'
        }, {
          max: 255,
          message: '图片长度在255个字符内'
        }],
        contactWay: [{
          required: true,
          message: '请输入联系方式'
        }, {
          max: 255,
          message: '联系方式长度在255个字符内'
        }],
        isSolved: [{
          required: true,
          message: '请输入是否已处理'
        }]
      }
    };
  }
};
exports.feedback = feedback;