"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.searchKeyword = void 0;
var searchKeyword = {
  data: function data() {
    return {
      //是否展示详情窗口
      showDetailDialog: false,
      backUrl: '/operation/searchKeyword/page',
      entity: {},
      rules: {
        name: [{
          required: true,
          message: '请输入搜索词'
        }, {
          max: 50,
          message: '搜索词长度在50个字符内'
        }],
        sort: [{
          required: true,
          message: '请输入搜索次数'
        }]
      }
    };
  }
};
exports.searchKeyword = searchKeyword;