var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      staticClass: "seller",
      attrs: {
        title: !_vm.isUpdate ? "商家入驻" : "重新申请",
        visible: _vm.dialogVisible,
        "close-on-click-modal": false,
        "append-to-body": "",
        center: "",
        width: "50%",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
        close: _vm.closeDialog,
      },
    },
    [
      _c(
        "el-steps",
        {
          staticClass: "head",
          attrs: {
            active: _vm.active,
            "finish-status": "success",
            "align-center": "",
          },
        },
        [
          _c("el-step", [
            _c(
              "span",
              {
                staticStyle: { "font-size": "13px" },
                attrs: { slot: "title" },
                slot: "title",
              },
              [_vm._v("店铺信息")]
            ),
          ]),
          _vm._v(" "),
          _c("el-step", [
            _c(
              "span",
              {
                staticStyle: { "font-size": "13px" },
                attrs: { slot: "title" },
                slot: "title",
              },
              [_vm._v("资质信息")]
            ),
          ]),
          _vm._v(" "),
          _c("el-step", [
            _c(
              "span",
              {
                staticStyle: { "font-size": "13px" },
                attrs: { slot: "title" },
                slot: "title",
              },
              [_vm._v("收款信息")]
            ),
          ]),
          _vm._v(" "),
          _c("el-step", [
            _c(
              "span",
              {
                staticStyle: { "font-size": "13px" },
                attrs: { slot: "title" },
                slot: "title",
              },
              [_vm._v("短信验证")]
            ),
          ]),
          _vm._v(" "),
          _c("el-step", [
            _c(
              "span",
              {
                staticStyle: { "font-size": "13px" },
                attrs: { slot: "title" },
                slot: "title",
              },
              [_vm._v("确认")]
            ),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-form",
        {
          ref: "elForm",
          staticClass: "dialog-form",
          attrs: {
            model: _vm.entity,
            rules: _vm.rules,
            inline: true,
            "label-width": "180px",
          },
        },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.active === 0,
                  expression: "active===0",
                },
              ],
            },
            [
              _c(
                "el-card",
                { staticClass: "my-card" },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "商户名称", prop: "name" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入商户名称", clearable: "" },
                        model: {
                          value: _vm.entity.name,
                          callback: function ($$v) {
                            _vm.$set(_vm.entity, "name", $$v)
                          },
                          expression: "entity.name",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "联系人姓名", prop: "contactName" } },
                    [
                      _c("el-input", {
                        attrs: {
                          placeholder: "请输入联系人姓名",
                          clearable: "",
                        },
                        model: {
                          value: _vm.entity.contactName,
                          callback: function ($$v) {
                            _vm.$set(_vm.entity, "contactName", $$v)
                          },
                          expression: "entity.contactName",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    {
                      attrs: { label: "联系手机号（登录账号）", prop: "phone" },
                    },
                    [
                      _c("el-input", {
                        directives: [
                          {
                            name: "number-input",
                            rawName: "v-number-input",
                            value: { type: "integer" },
                            expression: "{ type: 'integer' }",
                          },
                        ],
                        attrs: {
                          placeholder: "请输入联系手机号",
                          clearable: "",
                        },
                        model: {
                          value: _vm.entity.phone,
                          callback: function ($$v) {
                            _vm.$set(_vm.entity, "phone", $$v)
                          },
                          expression: "entity.phone",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-card",
                { staticClass: "my-card" },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "商标图", prop: "logo" } },
                    [
                      _c("single-img-upload", {
                        attrs: { clearable: "" },
                        model: {
                          value: _vm.entity.logo,
                          callback: function ($$v) {
                            _vm.$set(_vm.entity, "logo", $$v)
                          },
                          expression: "entity.logo",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "tip" }, [
                    _vm._v("\n          需要体现您店铺特色\n        "),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-card",
                { staticClass: "my-card" },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "省市区", prop: "area" } },
                    [
                      _c("mine-cascader", {
                        on: {
                          input: function ($event) {
                            _vm.entity.area = $event
                          },
                        },
                        model: {
                          value: _vm.entity.area,
                          callback: function ($$v) {
                            _vm.$set(_vm.entity, "area", $$v)
                          },
                          expression: "entity.area",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "详细地址", prop: "detail" } },
                    [
                      _c("el-input", {
                        attrs: {
                          type: "textarea",
                          placeholder: "请输入详细地址",
                          clearable: "",
                        },
                        model: {
                          value: _vm.entity.detail,
                          callback: function ($$v) {
                            _vm.$set(_vm.entity, "detail", $$v)
                          },
                          expression: "entity.detail",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "form-button-container" },
                [
                  _c(
                    "el-button",
                    {
                      staticClass: "btn",
                      on: {
                        click: function ($event) {
                          return _vm.next(1)
                        },
                      },
                    },
                    [_vm._v("下一步")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.active === 1,
                  expression: "active===1",
                },
              ],
            },
            [
              _c(
                "el-card",
                { staticClass: "my-card" },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "身份证正面（国徽面）",
                        prop: "idCardBackend",
                      },
                    },
                    [
                      _c("single-img-upload", {
                        attrs: { clearable: "" },
                        model: {
                          value: _vm.entity.idCardBackend,
                          callback: function ($$v) {
                            _vm.$set(_vm.entity, "idCardBackend", $$v)
                          },
                          expression: "entity.idCardBackend",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "身份证反面（人像面）",
                        prop: "idCardFront",
                      },
                    },
                    [
                      _c("single-img-upload", {
                        attrs: { clearable: "" },
                        model: {
                          value: _vm.entity.idCardFront,
                          callback: function ($$v) {
                            _vm.$set(_vm.entity, "idCardFront", $$v)
                          },
                          expression: "entity.idCardFront",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "tip" }, [
                    _vm._v(
                      "\n          需清晰展示文字信息，身份证在有效期内\n        "
                    ),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c("el-card", { staticClass: "my-card" }, [
                _c(
                  "div",
                  { staticClass: "my-card-item" },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "营业执照", prop: "business" } },
                      [
                        _c("single-img-upload", {
                          attrs: { clearable: "" },
                          model: {
                            value: _vm.entity.business,
                            callback: function ($$v) {
                              _vm.$set(_vm.entity, "business", $$v)
                            },
                            expression: "entity.business",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "tip" }, [
                      _vm._v(
                        "\n            文字清晰，边框完整，可用有效特许证件代替\n          "
                      ),
                    ]),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "my-card-item" },
                  [
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: "许可证（或特许证件）",
                          prop: "license",
                        },
                      },
                      [
                        _c("single-img-upload", {
                          attrs: { clearable: "" },
                          model: {
                            value: _vm.entity.license,
                            callback: function ($$v) {
                              _vm.$set(_vm.entity, "license", $$v)
                            },
                            expression: "entity.license",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "tip" }, [
                      _vm._v(
                        "\n            文字清晰，边框完整，露出国徽，拍复印件需要加盖印章，可用有效证件代替\n          "
                      ),
                    ]),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "form-button-container" },
                [
                  _c(
                    "el-button",
                    {
                      staticClass: "btn",
                      on: {
                        click: function ($event) {
                          return _vm.pre(0)
                        },
                      },
                    },
                    [_vm._v("上一步")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      staticClass: "btn",
                      on: {
                        click: function ($event) {
                          return _vm.next(2)
                        },
                      },
                    },
                    [_vm._v("下一步")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.active === 2,
                  expression: "active===2",
                },
              ],
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "账户类型" } },
                [
                  _c("el-input", {
                    attrs: { value: "对公账户", disabled: "" },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "开户人姓名", prop: "bankName" } },
                [
                  _c("el-input", {
                    attrs: { clearable: "" },
                    model: {
                      value: _vm.entity.bankName,
                      callback: function ($$v) {
                        _vm.$set(_vm.entity, "bankName", $$v)
                      },
                      expression: "entity.bankName",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "银行账号", prop: "bankAccount" } },
                [
                  _c("el-input", {
                    directives: [
                      {
                        name: "number-input",
                        rawName: "v-number-input",
                        value: { type: "integer" },
                        expression: "{ type: 'integer' }",
                      },
                    ],
                    attrs: { clearable: "" },
                    model: {
                      value: _vm.entity.bankAccount,
                      callback: function ($$v) {
                        _vm.$set(_vm.entity, "bankAccount", _vm._n($$v))
                      },
                      expression: "entity.bankAccount",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "开户行", prop: "bank" } },
                [
                  _c("el-input", {
                    attrs: { clearable: "" },
                    model: {
                      value: _vm.entity.bank,
                      callback: function ($$v) {
                        _vm.$set(_vm.entity, "bank", $$v)
                      },
                      expression: "entity.bank",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "支行", prop: "bankBranch" } },
                [
                  _c("el-input", {
                    attrs: { clearable: "" },
                    model: {
                      value: _vm.entity.bankBranch,
                      callback: function ($$v) {
                        _vm.$set(_vm.entity, "bankBranch", $$v)
                      },
                      expression: "entity.bankBranch",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "预留手机号", prop: "bankPhone" } },
                [
                  _c("el-input", {
                    directives: [
                      {
                        name: "number-input",
                        rawName: "v-number-input",
                        value: { type: "integer" },
                        expression: "{ type: 'integer' }",
                      },
                    ],
                    attrs: { clearable: "" },
                    model: {
                      value: _vm.entity.bankPhone,
                      callback: function ($$v) {
                        _vm.$set(_vm.entity, "bankPhone", $$v)
                      },
                      expression: "entity.bankPhone",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "tip" }, [
                _vm._v(
                  "\n        温馨提示：请注意填写，如填写错误商户自行负责\n      "
                ),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "form-button-container" },
                [
                  _c(
                    "el-button",
                    {
                      staticClass: "btn",
                      on: {
                        click: function ($event) {
                          return _vm.pre(1)
                        },
                      },
                    },
                    [_vm._v("上一步")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      staticClass: "btn",
                      on: {
                        click: function ($event) {
                          return _vm.next(3)
                        },
                      },
                    },
                    [_vm._v("下一步")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.active === 3,
                  expression: "active===3",
                },
              ],
              staticStyle: { "max-width": "400px", margin: "auto" },
            },
            [
              _c("sms-code", {
                ref: "smsCode",
                attrs: {
                  ruleForm: _vm.entity,
                  "btn-color": "#FFAE26",
                  "is-show-sub": false,
                  "is-disabled": "",
                },
                on: { send: _vm.send, submit: _vm.submit },
              }),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "form-button-container" },
                [
                  _c(
                    "el-button",
                    {
                      staticClass: "btn",
                      on: {
                        click: function ($event) {
                          return _vm.pre(2)
                        },
                      },
                    },
                    [_vm._v("上一步")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      staticClass: "btn",
                      on: {
                        click: function ($event) {
                          return _vm.next(4)
                        },
                      },
                    },
                    [_vm._v("下一步")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.active === 4,
                  expression: "active===4",
                },
              ],
            },
            [
              _c(
                "div",
                {
                  staticStyle: {
                    "font-weight": "bold",
                    "font-size": "21px",
                    "margin-bottom": "20px",
                  },
                },
                [
                  _vm._v(
                    "\n        即将提交入驻申请，默认密码将会以短信通知发送给您，请注意查收\n      "
                  ),
                ]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "tip" }, [
                _vm._v("\n        审核过程中，业务员会联系您的手机号："),
                _c(
                  "span",
                  {
                    staticStyle: {
                      color: "#38bbee",
                      "font-weight": "bold",
                      margin: "0 3px",
                    },
                  },
                  [_vm._v(_vm._s(_vm.entity.phone))]
                ),
                _vm._v("，请注意来自东莞100开头的电话；"),
                _c("br"),
                _vm._v(
                  "\n        在此期间，您也可以登录本商户系统查看您的审核进度\n      "
                ),
              ]),
              _vm._v(" "),
              _c("el-form-item", { attrs: { prop: "checkAgree" } }, [
                _c(
                  "div",
                  { staticClass: "check-row" },
                  [
                    _c(
                      "el-checkbox",
                      {
                        model: {
                          value: _vm.entity.checkAgree,
                          callback: function ($$v) {
                            _vm.$set(_vm.entity, "checkAgree", $$v)
                          },
                          expression: "entity.checkAgree",
                        },
                      },
                      [_vm._v("\n            我已阅读并同意\n          ")]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-link",
                      {
                        staticStyle: {
                          color: "#19aeff",
                          "padding-bottom": "3px",
                          "text-decoration": "underline",
                        },
                        attrs: { underline: false },
                        on: {
                          click: function ($event) {
                            return _vm.openIllustrate()
                          },
                        },
                      },
                      [_vm._v("《开店说明》\n          ")]
                    ),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "form-button-container" },
                [
                  _c(
                    "el-button",
                    {
                      staticClass: "btn",
                      on: {
                        click: function ($event) {
                          return _vm.pre(3)
                        },
                      },
                    },
                    [_vm._v("上一步")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      directives: [
                        { name: "preventReClick", rawName: "v-preventReClick" },
                      ],
                      on: {
                        click: function ($event) {
                          return _vm.submitMineForm()
                        },
                      },
                    },
                    [_vm._v("提交申请")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _vm.showIllustrateDialog
        ? _c("common-illustrate", {
            ref: "illustrateDialog",
            attrs: { title: "开店说明", content: _vm.content },
            on: {
              close: function ($event) {
                _vm.showIllustrateDialog = false
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }