"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getItemCache = getItemCache;
exports.getObjCache = getObjCache;
exports.removeItemCache = removeItemCache;
exports.setItemCache = setItemCache;
exports.setObjCache = setObjCache;
require("core-js/modules/es6.number.constructor");
/**
 * 存储对象
 *
 * @param key
 * @param val
 * @param expireTime 毫秒
 */
function setObjCache(key, val, expireTime) {
  localStorage.setItem(key, JSON.stringify(val));
  var currentTime = Number(new Date().getTime());
  localStorage.setItem(key + "_expireTime", currentTime + expireTime);
}

/**
 * 存储普通元素
 *
 * @param key
 * @param val
 * @param expireTime 毫秒
 */
function setItemCache(key, val, expireTime) {
  localStorage.setItem(key, val);
  var currentTime = Number(new Date().getTime());
  localStorage.setItem(key + "_expireTime", currentTime + expireTime);
}

/**
 * 获取普通元素并判断是否过期
 * @param key
 */
function getItemCache(key) {
  var val = localStorage.getItem(key);
  var expireTime = localStorage.getItem(key + "_expireTime");
  if (expireTime < new Date().getTime()) {
    removeItemCache(key);
    return null;
  }
  return val;
}

/**
 * 获取对象并判断是否过期
 * @param key
 */
function getObjCache(key) {
  var val = localStorage.getItem(key);
  var expireTime = localStorage.getItem(key + "_expireTime");
  //过期移除旧的元素
  if (expireTime < new Date().getTime()) {
    removeItemCache(key);
    return null;
  }
  return JSON.parse(val);
}

/**
 * 移除元素
 * @param key
 */
function removeItemCache(key) {
  localStorage.removeItem(key);
  localStorage.removeItem(key + "_expireTime");
}