"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _proxyRopeStatistics = require("./proxy-rope-statistics");
var _globalDialog = require("@/mixins/global-dialog");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  mixins: [_proxyRopeStatistics.proxyRopeStatistics, _globalDialog.updateDialog]
};
exports.default = _default;