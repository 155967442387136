var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("quill-editor", {
        ref: "myQuillEditor",
        attrs: { options: _vm.quillEditorOption },
        on: { change: _vm.onEditorChange },
        model: {
          value: _vm.content,
          callback: function ($$v) {
            _vm.content = $$v
          },
          expression: "content",
        },
      }),
      _vm._v(" "),
      _c("single-img-upload", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: false,
            expression: "false",
          },
        ],
        attrs: { id: _vm.id },
        model: {
          value: _vm.quillEditorImgUrl,
          callback: function ($$v) {
            _vm.quillEditorImgUrl = $$v
          },
          expression: "quillEditorImgUrl",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }