"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _adUserBank = require("./ad-user-bank");
var _globalDialog = require("@/mixins/global-dialog");
var _adMasterApi = require("@/api/ad-master-api");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  mixins: [_adUserBank.adUserBank, _globalDialog.addDialog],
  methods: {
    //执行添加操作
    doAdd: function doAdd() {
      return (0, _adMasterApi.adUserBankAdd)(this.entity);
    }
  }
};
exports.default = _default;