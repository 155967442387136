"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.adSaleOrder = void 0;
var adSaleOrder = {
  data: function data() {
    return {
      //是否展示详情窗口
      showDetailDialog: false,
      //状态枚举
      statusMap: {
        0: '待成交',
        1: '部分成交',
        2: '已成交',
        3: '已取消'
      },
      //状态标签颜色枚举
      statusColorMap: {
        0: 'danger',
        1: 'success',
        2: 'danger',
        3: 'warning',
        4: 'info',
        5: 'primary'
      },
      //角色枚举
      roleMap: {
        0: '用户',
        1: '商户'
      },
      //角色标签颜色枚举
      roleColorMap: {
        0: 'danger',
        1: 'success',
        2: 'danger',
        3: 'warning',
        4: 'info',
        5: 'primary'
      },
      backUrl: '/advertise/adSaleOrder/page',
      entity: {},
      rules: {
        status: [{
          required: true,
          message: '请输入状态'
        }],
        userId: [{
          required: true,
          message: '请输入用户ID'
        }],
        number: [{
          required: true,
          message: '请输入数量'
        }],
        hadNumber: [{
          required: true,
          message: '请输入已成交数量'
        }],
        price: [{
          required: true,
          message: '请输入价格'
        }],
        role: [{
          required: true,
          message: '请输入角色'
        }],
        cancelTime: [{
          required: true,
          message: '请输入取消时间'
        }],
        frozenNumber: [{
          required: true,
          message: '请输入冻结数量'
        }]
      }
    };
  }
};
exports.adSaleOrder = adSaleOrder;