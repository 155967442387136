"use strict";

var _interopRequireDefault = require("D:/shenbimaliang1/sbml-backend/backend-page/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _advertiseApi = require("@/api/advertise-api");
var _globalDialog = require("@/mixins/global-dialog");
var _permission = require("@/mixins/permission");
var _adPortrait = require("./ad-portrait");
var _AdPortraitAdd = _interopRequireDefault(require("./AdPortraitAdd"));
var _AdPortraitUpdate = _interopRequireDefault(require("./AdPortraitUpdate"));
var _AdPortraitDetail = _interopRequireDefault(require("./AdPortraitDetail"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'AdPortraitPage',
  components: {
    Pagination: _Pagination.default,
    AdPortraitAdd: _AdPortraitAdd.default,
    AdPortraitUpdate: _AdPortraitUpdate.default,
    AdPortraitDetail: _AdPortraitDetail.default
  },
  mixins: [_adPortrait.adPortrait, _globalDialog.pageDialog, _permission.permission],
  methods: {
    //导出
    exportExcel: function exportExcel() {
      (0, _advertiseApi.adPortraitExport)(this.pageParam);
    },
    //调用接口查询
    doSearch: function doSearch() {
      return (0, _advertiseApi.adPortraitPage)(this.pageParam);
    },
    /**
     * 执行批量删除操作
     * @param {Object} idList id集合
     */
    doBatchDelete: function doBatchDelete(idList) {
      return (0, _advertiseApi.adPortraitDelete)(idList);
    },
    /**
     * 打开肖像详情窗口
     */
    openDetailDialog: function openDetailDialog() {
      var _this = this;
      var selectData = this.getSelect();
      if (this.isEmpty(selectData)) {
        return;
      }
      this.showDetailDialog = true;
      this.$nextTick(function () {
        // 把选中行的数据拷贝一份传递给修改对话框
        selectData = _this.transTimeDia(selectData);
        _this.$refs.detailDialog.setEntity(_this.objectCopy(selectData));
      });
    }
  }
};
exports.default = _default;