var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "审核商户修改记录",
        visible: _vm.dialogVisible,
        "close-on-click-modal": false,
        center: "",
        width: _vm.dialogWidth,
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
        close: _vm.closeDialog,
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "elForm",
          staticClass: "dialog-form",
          attrs: {
            model: _vm.entity,
            rules: _vm.rules,
            inline: true,
            "label-width": _vm.getLabelWidth(),
          },
        },
        [
          _c(
            "el-descriptions",
            {
              staticClass: "margin-top",
              attrs: {
                title: "基本信息",
                "label-style": { width: "120px" },
                column: _vm.isPc() ? 3 : 1,
                size: "medium",
                border: "",
              },
            },
            [
              _c(
                "el-descriptions-item",
                [
                  _c("template", { slot: "label" }, [
                    _c("i", { staticClass: "el-icon-s-shop" }),
                    _vm._v("\n          商户名称\n        "),
                  ]),
                  _vm._v("\n        " + _vm._s(_vm.entity.name) + "\n      "),
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "el-descriptions-item",
                [
                  _c("template", { slot: "label" }, [
                    _c("i", { staticClass: "el-icon-mobile-phone" }),
                    _vm._v("\n          联系人姓名\n        "),
                  ]),
                  _vm._v(
                    "\n        " + _vm._s(_vm.entity.contactName) + "\n      "
                  ),
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "el-descriptions-item",
                [
                  _c("template", { slot: "label" }, [
                    _c("i", { staticClass: "el-icon-mobile-phone" }),
                    _vm._v("\n          联系电话\n        "),
                  ]),
                  _vm._v("\n        " + _vm._s(_vm.entity.phone) + "\n      "),
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "el-descriptions-item",
                [
                  _c("template", { slot: "label" }, [
                    _c("i", { staticClass: "el-icon-map-location" }),
                    _vm._v("\n          商户地址\n        "),
                  ]),
                  _vm._v(
                    "\n        " + _vm._s(_vm.entity.address) + "\n      "
                  ),
                ],
                2
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-descriptions",
            {
              staticClass: "margin-top",
              attrs: {
                title: "资料信息",
                "label-style": { width: "120px" },
                column: _vm.isPc() ? 3 : 2,
                size: "medium",
                border: "",
              },
            },
            [
              _c(
                "el-descriptions-item",
                [
                  _c("template", { slot: "label" }, [
                    _c("i", { staticClass: "el-icon-picture" }),
                    _vm._v("\n          商标\n        "),
                  ]),
                  _vm._v(" "),
                  _c("single-img-upload", {
                    attrs: { height: "100", width: "100", isDisabled: "" },
                    model: {
                      value: _vm.entity.logo,
                      callback: function ($$v) {
                        _vm.$set(_vm.entity, "logo", $$v)
                      },
                      expression: "entity.logo",
                    },
                  }),
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "el-descriptions-item",
                [
                  _c("template", { slot: "label" }, [
                    _c("i", { staticClass: "el-icon-picture" }),
                    _vm._v("\n          身份证人头面\n        "),
                  ]),
                  _vm._v(" "),
                  _c("single-img-upload", {
                    attrs: { height: "100", width: "100", isDisabled: "" },
                    model: {
                      value: _vm.entity.idCardFront,
                      callback: function ($$v) {
                        _vm.$set(_vm.entity, "idCardFront", $$v)
                      },
                      expression: "entity.idCardFront",
                    },
                  }),
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "el-descriptions-item",
                [
                  _c("template", { slot: "label" }, [
                    _c("i", { staticClass: "el-icon-picture" }),
                    _vm._v("\n          身份证国徽面\n        "),
                  ]),
                  _vm._v(" "),
                  _c("single-img-upload", {
                    attrs: { height: "100", width: "100", isDisabled: "" },
                    model: {
                      value: _vm.entity.idCardBackend,
                      callback: function ($$v) {
                        _vm.$set(_vm.entity, "idCardBackend", $$v)
                      },
                      expression: "entity.idCardBackend",
                    },
                  }),
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "el-descriptions-item",
                [
                  _c("template", { slot: "label" }, [
                    _c("i", { staticClass: "el-icon-picture" }),
                    _vm._v("\n          营业执照\n        "),
                  ]),
                  _vm._v(" "),
                  _c("single-img-upload", {
                    attrs: { height: "100", width: "100", isDisabled: "" },
                    model: {
                      value: _vm.entity.business,
                      callback: function ($$v) {
                        _vm.$set(_vm.entity, "business", $$v)
                      },
                      expression: "entity.business",
                    },
                  }),
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "el-descriptions-item",
                [
                  _c("template", { slot: "label" }, [
                    _c("i", { staticClass: "el-icon-picture" }),
                    _vm._v("\n          许可证\n        "),
                  ]),
                  _vm._v(" "),
                  _c("single-img-upload", {
                    attrs: { height: "100", width: "100", isDisabled: "" },
                    model: {
                      value: _vm.entity.license,
                      callback: function ($$v) {
                        _vm.$set(_vm.entity, "license", $$v)
                      },
                      expression: "entity.license",
                    },
                  }),
                ],
                2
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "margin-top" },
            [
              _c(
                "el-form-item",
                { attrs: { label: "审核状态", prop: "reviewStatus" } },
                [
                  _c(
                    "el-select",
                    {
                      model: {
                        value: _vm.entity.reviewStatus,
                        callback: function ($$v) {
                          _vm.$set(_vm.entity, "reviewStatus", $$v)
                        },
                        expression: "entity.reviewStatus",
                      },
                    },
                    _vm._l(_vm.reviewStatusMap, function (value, key) {
                      return _c("el-option", {
                        key: key,
                        attrs: { label: value, value: Number(key) },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "审核不通过理由", prop: "backReason" } },
                [
                  _c("el-input", {
                    attrs: { clearable: "" },
                    model: {
                      value: _vm.entity.backReason,
                      callback: function ($$v) {
                        _vm.$set(_vm.entity, "backReason", $$v)
                      },
                      expression: "entity.backReason",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "form-button-container" },
        [
          _c(
            "el-button",
            {
              attrs: { type: "danger", icon: "el-icon-back" },
              on: {
                click: function ($event) {
                  _vm.dialogVisible = false
                },
              },
            },
            [_vm._v("返回")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              staticClass: "form-button-submit",
              attrs: { type: "primary", icon: "el-icon-check" },
              on: { click: _vm.submitForm },
            },
            [_vm._v("提交")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }