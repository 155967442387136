"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _sellerWithdrawLog = require("./seller-withdraw-log");
var _globalDialog = require("@/mixins/global-dialog");
var _sellerApi = require("@/api/seller-api");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  mixins: [_sellerWithdrawLog.sellerWithdrawLog, _globalDialog.updateDialog],
  methods: {
    //执行修改操作
    doUpdate: function doUpdate() {
      return (0, _sellerApi.sellerWithdrawLogReview)({
        id: this.entity.id,
        reviewStatus: this.entity.reviewStatus,
        backReason: this.entity.cancelReason
      });
    }
  }
};
exports.default = _default;