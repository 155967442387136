"use strict";

var _interopRequireDefault = require("D:/shenbimaliang1/sbml-backend/backend-page/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _operationApi = require("@/api/operation-api");
var _globalDialog = require("@/mixins/global-dialog");
var _permission = require("@/mixins/permission");
var _searchKeyword = require("./search-keyword");
var _SearchKeywordAdd = _interopRequireDefault(require("./SearchKeywordAdd"));
var _SearchKeywordUpdate = _interopRequireDefault(require("./SearchKeywordUpdate"));
var _SearchKeywordDetail = _interopRequireDefault(require("./SearchKeywordDetail"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'SearchKeywordPage',
  components: {
    Pagination: _Pagination.default,
    SearchKeywordAdd: _SearchKeywordAdd.default,
    SearchKeywordUpdate: _SearchKeywordUpdate.default,
    SearchKeywordDetail: _SearchKeywordDetail.default
  },
  mixins: [_searchKeyword.searchKeyword, _globalDialog.pageDialog, _permission.permission],
  methods: {
    //导出
    exportExcel: function exportExcel() {
      (0, _operationApi.searchKeywordExport)(this.pageParam);
    },
    //调用接口查询
    doSearch: function doSearch() {
      return (0, _operationApi.searchKeywordPage)(this.pageParam);
    },
    /**
     * 执行批量删除操作
     * @param {Object} idList id集合
     */
    doBatchDelete: function doBatchDelete(idList) {
      return (0, _operationApi.searchKeywordDelete)(idList);
    },
    /**
     * 打开热门搜索详情窗口
     */
    openDetailDialog: function openDetailDialog() {
      var _this = this;
      var selectData = this.getSelect();
      if (this.isEmpty(selectData)) {
        return;
      }
      this.showDetailDialog = true;
      this.$nextTick(function () {
        // 把选中行的数据拷贝一份传递给修改对话框
        selectData = _this.transTimeDia(selectData);
        _this.$refs.detailDialog.setEntity(_this.objectCopy(selectData));
      });
    }
  }
};
exports.default = _default;