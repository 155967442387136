var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "新增比赛记录",
        visible: _vm.dialogVisible,
        "close-on-click-modal": false,
        center: "",
        width: _vm.dialogWidth,
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
        close: _vm.closeDialog,
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "elForm",
          staticClass: "dialog-form",
          attrs: {
            model: _vm.entity,
            rules: _vm.rules,
            inline: true,
            "label-width": _vm.getLabelWidth(),
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "比赛ID", prop: "raceId" } },
            [
              _c("el-input", {
                directives: [
                  {
                    name: "number-input",
                    rawName: "v-number-input",
                    value: { type: "integer" },
                    expression: "{ type: 'integer' }",
                  },
                ],
                attrs: { clearable: "" },
                model: {
                  value: _vm.entity.raceId,
                  callback: function ($$v) {
                    _vm.$set(_vm.entity, "raceId", $$v)
                  },
                  expression: "entity.raceId",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "跳绳UUID", prop: "ropeId" } },
            [
              _c("el-input", {
                directives: [
                  {
                    name: "number-input",
                    rawName: "v-number-input",
                    value: { type: "integer" },
                    expression: "{ type: 'integer' }",
                  },
                ],
                attrs: { clearable: "" },
                model: {
                  value: _vm.entity.ropeId,
                  callback: function ($$v) {
                    _vm.$set(_vm.entity, "ropeId", $$v)
                  },
                  expression: "entity.ropeId",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "跳绳名称", prop: "ropeDataName" } },
            [
              _c("el-input", {
                attrs: { clearable: "" },
                model: {
                  value: _vm.entity.ropeDataName,
                  callback: function ($$v) {
                    _vm.$set(_vm.entity, "ropeDataName", $$v)
                  },
                  expression: "entity.ropeDataName",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "跳绳头像", prop: "ropeDataImg" } },
            [
              _c("el-input", {
                attrs: { clearable: "" },
                model: {
                  value: _vm.entity.ropeDataImg,
                  callback: function ($$v) {
                    _vm.$set(_vm.entity, "ropeDataImg", $$v)
                  },
                  expression: "entity.ropeDataImg",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "学校", prop: "schoolName" } },
            [
              _c("el-input", {
                attrs: { clearable: "" },
                model: {
                  value: _vm.entity.schoolName,
                  callback: function ($$v) {
                    _vm.$set(_vm.entity, "schoolName", $$v)
                  },
                  expression: "entity.schoolName",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "年级", prop: "gradeName" } },
            [
              _c("el-input", {
                attrs: { clearable: "" },
                model: {
                  value: _vm.entity.gradeName,
                  callback: function ($$v) {
                    _vm.$set(_vm.entity, "gradeName", $$v)
                  },
                  expression: "entity.gradeName",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "班级", prop: "className" } },
            [
              _c("el-input", {
                attrs: { clearable: "" },
                model: {
                  value: _vm.entity.className,
                  callback: function ($$v) {
                    _vm.$set(_vm.entity, "className", $$v)
                  },
                  expression: "entity.className",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "备注", prop: "note" } },
            [
              _c("el-input", {
                attrs: { clearable: "" },
                model: {
                  value: _vm.entity.note,
                  callback: function ($$v) {
                    _vm.$set(_vm.entity, "note", $$v)
                  },
                  expression: "entity.note",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "数量（个）或者时长（秒）", prop: "data" } },
            [
              _c("el-input", {
                directives: [
                  {
                    name: "number-input",
                    rawName: "v-number-input",
                    value: { type: "integer" },
                    expression: "{ type: 'integer' }",
                  },
                ],
                attrs: { clearable: "" },
                model: {
                  value: _vm.entity.data,
                  callback: function ($$v) {
                    _vm.$set(_vm.entity, "data", $$v)
                  },
                  expression: "entity.data",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "教师ID", prop: "teacherUserId" } },
            [
              _c("el-input", {
                directives: [
                  {
                    name: "number-input",
                    rawName: "v-number-input",
                    value: { type: "integer" },
                    expression: "{ type: 'integer' }",
                  },
                ],
                attrs: { clearable: "" },
                model: {
                  value: _vm.entity.teacherUserId,
                  callback: function ($$v) {
                    _vm.$set(_vm.entity, "teacherUserId", $$v)
                  },
                  expression: "entity.teacherUserId",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "form-button-container" },
        [
          _c(
            "el-button",
            {
              attrs: { type: "danger", icon: "el-icon-back" },
              on: {
                click: function ($event) {
                  _vm.dialogVisible = false
                },
              },
            },
            [_vm._v("返回")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              directives: [
                { name: "preventReClick", rawName: "v-preventReClick" },
              ],
              staticClass: "form-button-submit",
              attrs: { type: "primary", icon: "el-icon-check" },
              on: { click: _vm.submitForm },
            },
            [_vm._v("提交")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }