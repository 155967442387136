"use strict";

var _interopRequireDefault = require("D:/shenbimaliang1/sbml-backend/backend-page/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _sellerApi = require("@/api/seller-api");
var _globalDialog = require("@/mixins/global-dialog");
var _permission = require("@/mixins/permission");
var _sellerReviewLog = require("./seller-review-log");
var _SellerReviewLogReview = _interopRequireDefault(require("@/views/seller/sellerReviewLog/SellerReviewLogReview"));
var _SellerReviewLogDetail = _interopRequireDefault(require("@/views/seller/sellerReviewLog/SellerReviewLogDetail"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'SellerReviewLogPage',
  components: {
    SellerReviewLogDetail: _SellerReviewLogDetail.default,
    SellerReviewLogReview: _SellerReviewLogReview.default,
    Pagination: _Pagination.default
  },
  mixins: [_sellerReviewLog.sellerReviewLog, _globalDialog.pageDialog, _permission.permission],
  methods: {
    //导出
    exportExcel: function exportExcel() {
      (0, _sellerApi.sellerReviewLogExport)(this.pageParam);
    },
    //调用接口查询
    doSearch: function doSearch() {
      return (0, _sellerApi.sellerReviewLogPage)(this.pageParam);
    },
    /**
     * 打开商户审核记录表详情窗口
     */
    openDetailDialog: function openDetailDialog(selectData) {
      var _this = this;
      this.showDetailDialog = true;
      this.$nextTick(function () {
        // 把选中行的数据拷贝一份传递给修改对话框
        selectData = _this.transTimeDia(selectData);
        _this.$refs.detailDialog.setEntity(_this.objectCopy(selectData));
      });
    },
    /**
     * 打开商户审核记录表详情窗口
     */
    openReviewDialog: function openReviewDialog(selectData) {
      var _this2 = this;
      this.showReviewDialog = true;
      this.$nextTick(function () {
        // 把选中行的数据拷贝一份传递给修改对话框
        selectData = _this2.transTimeDia(selectData);
        _this2.$refs.reviewDialog.setEntity(_this2.objectCopy(selectData));
      });
    }
  }
};
exports.default = _default;