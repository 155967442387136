"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.sellerWithdrawLog = void 0;
var sellerWithdrawLog = {
  data: function data() {
    var checkAmount = function checkAmount(rule, value, callback) {
      if (!value) {
        return callback(new Error('请输入提现金额'));
      } else {
        if (value >= 100) {
          callback();
        } else {
          return callback(new Error('最少提现数量为100元'));
        }
      }
    };
    return {
      //是否展示提现窗口
      showWithdrawDialog: false,
      //审核状态枚举
      reviewStatusMap: {
        1: '审核通过',
        2: '审核不通过'
      },
      //审核状态标签颜色枚举
      reviewStatusColorMap: {
        1: 'success',
        2: 'danger'
      },
      //状态枚举
      statusMap: {
        0: '等待提现',
        1: '正在处理',
        2: '提现成功',
        3: '用户撤销',
        4: '系统撤销'
      },
      //状态标签颜色枚举
      statusColorMap: {
        0: 'danger',
        1: 'success',
        2: 'danger',
        3: 'warning',
        4: 'info',
        5: 'primary'
      },
      backUrl: '/seller/sellerWithdrawLog/page',
      entity: {},
      rules: {
        sellerId: [{
          required: true,
          message: '请输入商户ID'
        }],
        amount: [{
          required: true,
          validator: checkAmount,
          trigger: 'blur'
        }],
        fees: [{
          required: true,
          message: '请输入提现手续费'
        }],
        status: [{
          required: true,
          message: '请输入状态'
        }],
        adminId: [{
          required: true,
          message: '请输入审核管理员ID'
        }, {
          max: 50,
          message: '审核管理员ID长度在50个字符内'
        }],
        cancelReason: [{
          required: false,
          message: '请输入撤销原因'
        }, {
          max: 255,
          message: '撤销原因长度在255个字符内'
        }],
        bank: [{
          required: true,
          message: '请输入提现银行信息'
        }, {
          max: 255,
          message: '提现银行信息长度在255个字符内'
        }],
        reviewStatus: [{
          required: true,
          message: '请选择审核状态'
        }]
      }
    };
  }
};
exports.sellerWithdrawLog = sellerWithdrawLog;