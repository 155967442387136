"use strict";

var _interopRequireDefault = require("D:/shenbimaliang1/sbml-backend/backend-page/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _school = require("./school");
var _globalDialog = require("@/mixins/global-dialog");
var _schoolApi = require("@/api/school-api");
var _index = _interopRequireDefault(require("@/components/MineCascader/index.vue"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    MineCascader: _index.default
  },
  mixins: [_school.school, _globalDialog.addDialog],
  methods: {
    //执行添加操作
    doAdd: function doAdd() {
      return (0, _schoolApi.schoolAdd)(this.entity);
    }
  }
};
exports.default = _default;