"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.versionUpdate = exports.versionPage = exports.versionDelete = exports.versionAdd = exports.searchKeywordUpdate = exports.searchKeywordPage = exports.searchKeywordExport = exports.searchKeywordDelete = exports.searchKeywordAdd = exports.noticeUpdate = exports.noticeQuery = exports.noticePage = exports.noticeExport = exports.noticeDelete = exports.noticeAdd = exports.feedbackSolved = exports.feedbackPage = exports.feedbackExport = exports.feedbackAdd = exports.advertUpdate = exports.advertPage = exports.advertExport = exports.advertDelete = exports.advertAdd = void 0;
var _request = require("@/utils/request");
//=============================================================广告栏=======================================================
/**
 * 广告栏新增
 */
var advertAdd = function advertAdd(data) {
  return (0, _request.post)('/operation/advert/add', data);
};
/**
 * 广告栏批量删除
 */
exports.advertAdd = advertAdd;
var advertDelete = function advertDelete(idList) {
  return (0, _request.post)('/operation/advert/delete?idList=' + idList);
};
/**
 * 广告栏修改
 */
exports.advertDelete = advertDelete;
var advertUpdate = function advertUpdate(data) {
  return (0, _request.post)('/operation/advert/update', data);
};
/**
 * 广告栏列表
 */
exports.advertUpdate = advertUpdate;
var advertPage = function advertPage(data) {
  return (0, _request.post)('/operation/advert/page', data);
};
/**
 * 广告栏导出
 */
exports.advertPage = advertPage;
var advertExport = function advertExport(param, name) {
  return (0, _request.exportExcel)('/operation/advert/export', param, name);
};
//=============================================================意见反馈=======================================================
/**
 * 意见反馈添加
 */
exports.advertExport = advertExport;
var feedbackAdd = function feedbackAdd(data) {
  return (0, _request.post)('/operation/feedback/add', data);
};
/**
 * 意见反馈批量解决
 */
exports.feedbackAdd = feedbackAdd;
var feedbackSolved = function feedbackSolved(idList) {
  return (0, _request.post)('/operation/feedback/solved?idList=' + idList);
};
/**
 * 意见反馈列表
 */
exports.feedbackSolved = feedbackSolved;
var feedbackPage = function feedbackPage(data) {
  return (0, _request.post)('/operation/feedback/page', data);
};
/**
 * 意见反馈导出
 */
exports.feedbackPage = feedbackPage;
var feedbackExport = function feedbackExport(param, name) {
  return (0, _request.exportExcel)('/operation/feedback/export', param, name);
};
//=============================================================通知=======================================================
/**
 * 通知新增
 */
exports.feedbackExport = feedbackExport;
var noticeAdd = function noticeAdd(data) {
  return (0, _request.post)('/operation/notice/add', data);
};
/**
 * 通知批量删除
 */
exports.noticeAdd = noticeAdd;
var noticeDelete = function noticeDelete(idList) {
  return (0, _request.post)('/operation/notice/delete?idList=' + idList);
};
/**
 * 通知修改
 */
exports.noticeDelete = noticeDelete;
var noticeUpdate = function noticeUpdate(data) {
  return (0, _request.post)('/operation/notice/update', data);
};
/**
 * 通知列表
 */
exports.noticeUpdate = noticeUpdate;
var noticePage = function noticePage(data) {
  return (0, _request.post)('/operation/notice/page', data);
};
/**
 * 通知查询
 */
exports.noticePage = noticePage;
var noticeQuery = function noticeQuery(id) {
  return (0, _request.post)('/operation/notice/query?id=' + id);
};
/**
 * 通知导出
 */
exports.noticeQuery = noticeQuery;
var noticeExport = function noticeExport(param, name) {
  return (0, _request.exportExcel)('/operation/notice/export', param, name);
};
//=============================================================版本控制=======================================================
/**
 * 版本控制新增
 */
exports.noticeExport = noticeExport;
var versionAdd = function versionAdd(data) {
  return (0, _request.post)('/operation/version/add', data);
};
/**
 * 版本控制批量删除
 */
exports.versionAdd = versionAdd;
var versionDelete = function versionDelete(idList) {
  return (0, _request.post)('/operation/version/delete?idList=' + idList);
};
/**
 * 版本控制修改
 */
exports.versionDelete = versionDelete;
var versionUpdate = function versionUpdate(data) {
  return (0, _request.post)('/operation/version/update', data);
};
/**
 * 版本控制列表
 */
exports.versionUpdate = versionUpdate;
var versionPage = function versionPage(data) {
  return (0, _request.post)('/operation/version/page', data);
};
//=============================================================热门搜索=======================================================
/**
 * 热门搜索新增
 */
exports.versionPage = versionPage;
var searchKeywordAdd = function searchKeywordAdd(data) {
  return (0, _request.post)('/operation/searchKeyword/add', data);
};
/**
 * 热门搜索批量删除
 */
exports.searchKeywordAdd = searchKeywordAdd;
var searchKeywordDelete = function searchKeywordDelete(idList) {
  return (0, _request.post)('/operation/searchKeyword/delete?idList=' + idList);
};
/**
 * 热门搜索修改
 */
exports.searchKeywordDelete = searchKeywordDelete;
var searchKeywordUpdate = function searchKeywordUpdate(data) {
  return (0, _request.post)('/operation/searchKeyword/update', data);
};
/**
 * 热门搜索列表
 */
exports.searchKeywordUpdate = searchKeywordUpdate;
var searchKeywordPage = function searchKeywordPage(data) {
  return (0, _request.post)('/operation/searchKeyword/page', data);
};
/**
 * 热门搜索导出
 */
exports.searchKeywordPage = searchKeywordPage;
var searchKeywordExport = function searchKeywordExport(param, name) {
  return (0, _request.exportExcel)('/operation/searchKeyword/export', param, name);
};
exports.searchKeywordExport = searchKeywordExport;