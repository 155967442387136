var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "新增代理跳绳数据统计",
        visible: _vm.dialogVisible,
        "close-on-click-modal": false,
        center: "",
        width: _vm.dialogWidth,
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
        close: _vm.closeDialog,
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "elForm",
          staticClass: "dialog-form",
          attrs: {
            model: _vm.entity,
            rules: _vm.rules,
            inline: true,
            "label-width": _vm.getLabelWidth(),
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "代理ID", prop: "proxyId" } },
            [
              _c("el-input", {
                directives: [
                  {
                    name: "number-input",
                    rawName: "v-number-input",
                    value: { type: "integer" },
                    expression: "{ type: 'integer' }",
                  },
                ],
                attrs: { clearable: "" },
                model: {
                  value: _vm.entity.proxyId,
                  callback: function ($$v) {
                    _vm.$set(_vm.entity, "proxyId", $$v)
                  },
                  expression: "entity.proxyId",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "代理用户ID", prop: "userId" } },
            [
              _c("el-input", {
                directives: [
                  {
                    name: "number-input",
                    rawName: "v-number-input",
                    value: { type: "integer" },
                    expression: "{ type: 'integer' }",
                  },
                ],
                attrs: { clearable: "" },
                model: {
                  value: _vm.entity.userId,
                  callback: function ($$v) {
                    _vm.$set(_vm.entity, "userId", $$v)
                  },
                  expression: "entity.userId",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "代理区域", prop: "area" } },
            [
              _c("el-input", {
                attrs: { clearable: "" },
                model: {
                  value: _vm.entity.area,
                  callback: function ($$v) {
                    _vm.$set(_vm.entity, "area", $$v)
                  },
                  expression: "entity.area",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "激活数量", prop: "activeCount" } },
            [
              _c("el-input", {
                directives: [
                  {
                    name: "number-input",
                    rawName: "v-number-input",
                    value: { type: "integer" },
                    expression: "{ type: 'integer' }",
                  },
                ],
                attrs: { clearable: "" },
                model: {
                  value: _vm.entity.activeCount,
                  callback: function ($$v) {
                    _vm.$set(_vm.entity, "activeCount", $$v)
                  },
                  expression: "entity.activeCount",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "交付保证金数量", prop: "payCount" } },
            [
              _c("el-input", {
                directives: [
                  {
                    name: "number-input",
                    rawName: "v-number-input",
                    value: { type: "integer" },
                    expression: "{ type: 'integer' }",
                  },
                ],
                attrs: { clearable: "" },
                model: {
                  value: _vm.entity.payCount,
                  callback: function ($$v) {
                    _vm.$set(_vm.entity, "payCount", $$v)
                  },
                  expression: "entity.payCount",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "form-button-container" },
        [
          _c(
            "el-button",
            {
              attrs: { type: "danger", icon: "el-icon-back" },
              on: {
                click: function ($event) {
                  _vm.dialogVisible = false
                },
              },
            },
            [_vm._v("返回")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              directives: [
                { name: "preventReClick", rawName: "v-preventReClick" },
              ],
              staticClass: "form-button-submit",
              attrs: { type: "primary", icon: "el-icon-check" },
              on: { click: _vm.submitForm },
            },
            [_vm._v("提交")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }