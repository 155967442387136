"use strict";

var _interopRequireDefault = require("D:/shenbimaliang1/sbml-backend/backend-page/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.constantRoutes = void 0;
var _interopRequireWildcard2 = _interopRequireDefault(require("D:/shenbimaliang1/sbml-backend/backend-page/node_modules/@babel/runtime/helpers/interopRequireWildcard.js"));
var _vue = _interopRequireDefault(require("vue"));
var _vueRouter = _interopRequireDefault(require("vue-router"));
var _layout = _interopRequireDefault(require("@/layout"));
var _systemRouter = _interopRequireDefault(require("./modules/system-router"));
var _memberRouter = _interopRequireDefault(require("./modules/member-router"));
var _operationRouter = _interopRequireDefault(require("./modules/operation-router"));
var _sellerRouter = _interopRequireDefault(require("./modules/seller-router"));
var _schoolRouter = _interopRequireDefault(require("@/router/modules/school-router"));
var _productRouter = _interopRequireDefault(require("./modules/product-router"));
var _afterSalesRouter = _interopRequireDefault(require("./modules/afterSales-router"));
var _orderRouter = _interopRequireDefault(require("@/router/modules/order-router"));
var _partnerRouter = _interopRequireDefault(require("@/router/modules/partner-router"));
var _sportRouter = _interopRequireDefault(require("@/router/modules/sport-router"));
var _teacherRouter = _interopRequireDefault(require("@/router/modules/teacher-router"));
var _advertiseRouter = _interopRequireDefault(require("@/router/modules/advertise-router"));
var _adMasterRouter = _interopRequireDefault(require("@/router/modules/ad-master-router"));
var _checkinRouter = _interopRequireDefault(require("@/router/modules/checkin-router"));
_vue.default.use(_vueRouter.default);

/* Layout */

// 系统管理模块路由配置
// 会员管理模块路由配置
// 运营管理模块路由配置
// 商户管理模块路由配置
// 校园管理模块路由配置
// 商品模块路由配置
// 售后管理模块路由配置
// 订单管理模块路由配置
//合伙人管理模块路由配置
//运动管理模块路由配置
//教师平台管理;
//广告平台管理;
//广告主管理;

//签到服务管理;

var constantRoutes = [{
  path: '/login',
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/views/system/login/Login.vue'));
    });
  },
  hidden: true
}, {
  path: '/404',
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/views/system/404'));
    });
  },
  hidden: true
}, {
  path: '/',
  component: _layout.default,
  redirect: '/dashboard',
  children: [{
    path: 'dashboard',
    name: 'Dashboard',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/system/home/Dashboard.vue'));
      });
    },
    meta: {
      title: '主页',
      keepAlive: true
    }
  }, {
    path: '/codeGenerator',
    name: 'CodeGenerator',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/system/CodeGenerator'));
      });
    },
    meta: {
      title: '代码生成',
      keepAlive: true
    }
  }, {
    path: '/swagger',
    name: '接口文档',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/system/swagger/SwaggerUI.vue'));
      });
    },
    meta: {
      title: '接口文档',
      keepAlive: true
    }
  }]
}, {
  path: '/system',
  component: _layout.default,
  name: '系统管理',
  children: _systemRouter.default
}, {
  path: '/member',
  component: _layout.default,
  name: '会员管理',
  children: _memberRouter.default
}, {
  path: '/operation',
  component: _layout.default,
  name: '运营管理',
  children: _operationRouter.default
}, {
  path: '/seller',
  component: _layout.default,
  name: '商户平台管理',
  children: _sellerRouter.default
}, {
  path: '/teacher',
  component: _layout.default,
  name: '教师平台管理',
  children: _teacherRouter.default
}, {
  path: '/advertise',
  component: _layout.default,
  name: '广告平台管理',
  children: _advertiseRouter.default
}, {
  path: '/ad-master',
  component: _layout.default,
  name: '广告主管理',
  children: _adMasterRouter.default
}, {
  path: '/school',
  component: _layout.default,
  name: '校园平台管理',
  children: _schoolRouter.default
}, {
  path: '/sport',
  component: _layout.default,
  name: '运动管理',
  children: _sportRouter.default
}, {
  path: '/product',
  component: _layout.default,
  name: '商品管理',
  children: _productRouter.default
}, {
  path: '/partner',
  component: _layout.default,
  name: '合伙人管理',
  children: _partnerRouter.default
}, {
  path: '/order',
  component: _layout.default,
  name: '订单管理',
  children: _orderRouter.default
}, {
  path: '/afterSales',
  component: _layout.default,
  name: '售后管理',
  children: _afterSalesRouter.default
}, {
  path: '/checkin',
  component: _layout.default,
  name: '签到服务管理',
  children: _checkinRouter.default
},
// 404 page must be placed at the end !!!
{
  path: '*',
  redirect: '/404',
  hidden: true
}];
exports.constantRoutes = constantRoutes;
var createRouter = function createRouter() {
  return new _vueRouter.default({
    mode: 'history',
    // require service support
    // base: '/dist',
    scrollBehavior: function scrollBehavior() {
      return {
        y: 0
      };
    },
    routes: constantRoutes
  });
};
var router = createRouter();
var _default = router;
exports.default = _default;