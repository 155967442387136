exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".mine-cascader{padding:0;border-radius:15px}.mine-cascader .drop-down{min-width:190px}.mine-cascader .mine-cascader-tab-body{width:300px;display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-orient:horizontal;-webkit-box-direction:normal;-ms-flex-direction:row;flex-direction:row;-ms-flex-wrap:wrap;flex-wrap:wrap;text-align:left;height:140px;overflow-y:scroll}.mine-cascader .mine-cascader-tab-body .tab-item{width:80px;padding:1px;cursor:pointer}.mine-cascader .mine-cascader-tab-body .tab-active{color:#409EFF;text-decoration:underline}\n", ""]);

// exports
exports.locals = {
	"menuText": "#bfcbd9",
	"menuActiveText": "#409EFF",
	"subMenuActiveText": "#f4f4f5",
	"menuBg": "#304156",
	"menuHover": "#263445",
	"subMenuBg": "#1f2d3d",
	"subMenuHover": "#001528",
	"platform_btn": "linear-gradient(233deg, #119bf6 40%, #a5c7f3 100%)",
	"menuSellerText": "#fff3f9",
	"menuActiveSellerText": "#FF652F",
	"subMenuActiveSellerText": "#FF652F",
	"menuSellerBg": "#FFAE26",
	"menuSellerHover": "#c78920",
	"subMenuSellerBg": "#fda615",
	"subMenuSellerHover": "#cb8511",
	"seller_btn": "linear-gradient(233deg, #FFAE26 40%, #FF652F 100%)",
	"sideBarWidth": "210px"
};