"use strict";

var _interopRequireDefault = require("D:/shenbimaliang1/sbml-backend/backend-page/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _interopRequireWildcard2 = _interopRequireDefault(require("D:/shenbimaliang1/sbml-backend/backend-page/node_modules/@babel/runtime/helpers/interopRequireWildcard.js"));
/**
 * 教师平台管理路由配置
 */
var teacherRouter = [{
  path: 'teacher/page',
  name: 'TeacherPage',
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/views/teacher/teacher/TeacherPage'));
    });
  },
  meta: {
    title: '老师列表'
  }
}, {
  path: 'teacherApply/page',
  name: 'TeacherApplyPage',
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/views/teacher/teacherApply/TeacherApplyPage'));
    });
  },
  meta: {
    title: '老师申请列表'
  }
}, {
  path: 'teacherClass/page',
  name: 'TeacherClassPage',
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/views/teacher/teacherClass/TeacherClassPage'));
    });
  },
  meta: {
    title: '老师班级列表'
  }
}, {
  path: 'teacherJob/page',
  name: 'TeacherJobPage',
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/views/teacher/teacherJob/TeacherJobPage'));
    });
  },
  meta: {
    title: '作业列表'
  }
}, {
  path: 'teacherJobSlave/page',
  name: 'TeacherJobSlavePage',
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/views/teacher/teacherJobSlave/TeacherJobSlavePage'));
    });
  },
  meta: {
    title: '交作业记录列表'
  }
}, {
  path: 'teacherRace/page',
  name: 'TeacherRacePage',
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/views/teacher/teacherRace/TeacherRacePage'));
    });
  },
  meta: {
    title: '比赛列表'
  }
}, {
  path: 'teacherRaceLog/page',
  name: 'TeacherRaceLogPage',
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/views/teacher/teacherRaceLog/TeacherRaceLogPage'));
    });
  },
  meta: {
    title: '比赛记录列表'
  }
}];
var _default = teacherRouter;
exports.default = _default;