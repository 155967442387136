"use strict";

var _interopRequireDefault = require("D:/shenbimaliang1/sbml-backend/backend-page/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.number.constructor");
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _afterSalesApi = require("@/api/afterSales-api");
var _globalDialog = require("@/mixins/global-dialog");
var _permission = require("@/mixins/permission");
var _rating = require("./rating");
var _RatingDisabled = _interopRequireDefault(require("./RatingDisabled.vue"));
var _RatingUpdate = _interopRequireDefault(require("./RatingUpdate"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'RatingPage',
  components: {
    Pagination: _Pagination.default,
    RatingDisabled: _RatingDisabled.default,
    RatingUpdate: _RatingUpdate.default
  },
  mixins: [_rating.rating, _globalDialog.pageDialog, _permission.permission],
  props: {
    productId: {
      type: Number
    },
    isShow: {
      type: Boolean,
      default: true
    }
  },
  created: function created() {
    if (this.isNotEmpty(this.productId)) {
      this.pageParam.productId = this.productId;
    }
  },
  methods: {
    //导出
    exportExcel: function exportExcel() {
      (0, _afterSalesApi.ratingExport)(this.pageParam);
    },
    //调用接口查询
    doSearch: function doSearch() {
      return (0, _afterSalesApi.ratingPage)(this.pageParam);
    },
    /**
     * 打开评价详情窗口
     */
    openDisabledDialog: function openDisabledDialog() {
      var _this = this;
      var selectData = this.getSelect();
      if (this.isEmpty(selectData)) {
        return;
      }
      this.showDisabledDialog = true;
      this.$nextTick(function () {
        // 把选中行的数据拷贝一份传递给修改对话框
        selectData = _this.transTimeDia(selectData);
        _this.$refs.disabledDialog.setEntity(_this.objectCopy(selectData));
      });
    }
  }
};
exports.default = _default;