"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ropeVipProduct = void 0;
var ropeVipProduct = {
  data: function data() {
    return {
      //是否展示详情窗口
      showDetailDialog: false,
      //是否在售枚举
      isSalesMap: {
        0: '否',
        1: '是'
      },
      //是否在售标签颜色枚举
      isSalesColorMap: {
        0: 'danger',
        1: 'success',
        2: 'danger',
        3: 'warning',
        4: 'info',
        5: 'primary'
      },
      backUrl: '/product/ropeVipProduct/page',
      entity: {},
      rules: {
        name: [{
          required: true,
          message: '请输入商品名称'
        }, {
          max: 255,
          message: '商品名称长度在255个字符内'
        }],
        isSales: [{
          required: true,
          message: '请输入是否在售'
        }],
        thumb: [{
          required: true,
          message: '请输入主图'
        }, {
          max: 1024,
          message: '主图长度在1024个字符内'
        }],
        stock: [{
          required: true,
          message: '请输入库存'
        }],
        isDel: [{
          required: true,
          message: '请输入是否删除'
        }],
        sku: [{
          required: true,
          message: '请输入规格名称'
        }, {
          max: 255,
          message: '规格名称长度在255个字符内'
        }],
        sort: [{
          required: true,
          message: '请输入排序'
        }]
      }
    };
  }
};
exports.ropeVipProduct = ropeVipProduct;