"use strict";

var _interopRequireDefault = require("D:/shenbimaliang1/sbml-backend/backend-page/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.permission = void 0;
require("core-js/modules/es6.regexp.split");
var _jsCookie = _interopRequireDefault(require("js-cookie"));
/**
 * 用户权限校验
 */
var permission = {
  methods: {
    /** 根据当前登录用户是否有该权限
     * @param {Object} name 权限名称
     */
    hasPermission: function hasPermission(name) {
      //不传权限值的按钮就显示
      if (this.isEmpty(name)) {
        return true;
      }
      //缓存中没有用户数据就直接返回false
      if (this.isEmpty(this.systemUser)) {
        return false;
      }
      return $.inArray(name, this.permissionList) >= 0;
    },
    /**
     * true 待审核的商户权限
     */
    isWaitSeller: function isWaitSeller() {
      return $.inArray('待审核商户', this.systemUser.roleSetName.split(",")) >= 0;
    },
    /**
     * true 已认证的商户权限
     */
    isSuccessSeller: function isSuccessSeller() {
      return $.inArray('商户', this.systemUser.roleSetName.split(",")) >= 0;
    },
    /**
     * true 审核通过的商户权限
     */
    isSeller: function isSeller() {
      return $.inArray('商户', this.systemUser.roleSetName.split(",")) >= 0 || $.inArray('待审核商户', this.systemUser.roleSetName.split(",")) >= 0;
    },
    /**
     * true 管理员权限
     */
    isAdmin: function isAdmin() {
      return $.inArray('管理员', this.systemUser.roleSetName.split(",")) >= 0;
    },
    /**
     * true 超级管理员权限
     */
    isSuperAdmin: function isSuperAdmin() {
      return $.inArray('超级管理员', this.systemUser.roleSetName.split(",")) >= 0;
    },
    /**
     * true 是管理员以上角色
     */
    isAllAdmin: function isAllAdmin() {
      return $.inArray('超级管理员', this.systemUser.roleSetName.split(",")) >= 0 || $.inArray('管理员', this.systemUser.roleSetName.split(",")) >= 0;
    }
  },
  created: function created() {
    //从缓存中拿到登录过的用户信息
    this.systemUser = JSON.parse(_jsCookie.default.get('systemUser'));
    this.permissionList = JSON.parse(window.localStorage.getItem('systemUser_permissionList'));
  }
};
exports.permission = permission;