"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
var _default = {
  name: "FullScreen",
  data: function data() {
    return {
      isFullScreen: false
    };
  },
  mounted: function mounted() {
    var _this = this;
    // 监听不到尺寸变化
    window.addEventListener('resize', function () {
      // 非全屏状态
      if (!_this.isFull()) {
        _this.isFullScreen = false;
      }
    });
  },
  methods: {
    //全屏
    fullScreenEle: function fullScreenEle() {
      return document.fullscreenElement || document.webkitFullscreenElement || document.msFullscreenElement || document.mozFullScreenElement || null;
    },
    //判断是否全屏
    isFull: function isFull() {
      return !!(document.webkitIsFullScreen || this.fullScreenEle());
    },
    /**
     * 改变状态
     */
    changeStatus: function changeStatus() {
      this.isFullScreen = !this.isFullScreen;
      this.isFullScreen ? this.requestFullScreen() : this.exitFullscreen();
    },
    /**
     * 进入全屏
     */
    requestFullScreen: function requestFullScreen() {
      var de = document.documentElement;
      if (de.requestFullscreen) {
        de.requestFullscreen();
      } else if (de.mozRequestFullScreen) {
        de.mozRequestFullScreen();
      } else if (de.webkitRequestFullScreen) {
        de.webkitRequestFullScreen();
      }
    },
    /**
     * 退出全屏
     */
    exitFullscreen: function exitFullscreen() {
      var de = document;
      if (de.exitFullscreen) {
        de.exitFullscreen();
      } else if (de.mozCancelFullScreen) {
        de.mozCancelFullScreen();
      } else if (de.webkitCancelFullScreen) {
        de.webkitCancelFullScreen();
      }
    }
  }
};
exports.default = _default;