"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.skuUpdate = exports.skuPage = exports.skuExport = exports.skuDelete = exports.skuAdd = exports.ropeVipProductUpdate = exports.ropeVipProductPage = exports.ropeVipProductExport = exports.ropeVipProductDelete = exports.ropeVipProductAdd = exports.productUpdate = exports.productShelf = exports.productReview = exports.productPage = exports.productHot = exports.productExport = exports.productDelete = exports.productCategoryUpdate = exports.productCategoryPage = exports.productCategoryExport = exports.productCategoryDelete = exports.productCategoryAdd = exports.productAdd = void 0;
var _request = require("@/utils/request");
//=============================================================枚举=======================================================

//=============================================================商品分类=======================================================
/**
 * 商品分类新增
 */
var productCategoryAdd = function productCategoryAdd(data) {
  return (0, _request.post)('/product/productCategory/add', data);
};
/**
 * 商品分类批量删除
 */
exports.productCategoryAdd = productCategoryAdd;
var productCategoryDelete = function productCategoryDelete(idList) {
  return (0, _request.post)('/product/productCategory/delete?idList=' + idList);
};
/**
 * 商品分类修改
 */
exports.productCategoryDelete = productCategoryDelete;
var productCategoryUpdate = function productCategoryUpdate(data) {
  return (0, _request.post)('/product/productCategory/update', data);
};
/**
 * 商品分类列表
 */
exports.productCategoryUpdate = productCategoryUpdate;
var productCategoryPage = function productCategoryPage(data) {
  return (0, _request.post)('/product/productCategory/page', data);
};
/**
 * 商品分类导出
 */
exports.productCategoryPage = productCategoryPage;
var productCategoryExport = function productCategoryExport(param, name) {
  return (0, _request.exportExcel)('/product/productCategory/export', param, name);
};

//=============================================================商品=======================================================
/**
 * 商品新增
 */
exports.productCategoryExport = productCategoryExport;
var productAdd = function productAdd(data) {
  return (0, _request.post)('/product/product/add', data);
};
/**
 * 商品批量删除
 */
exports.productAdd = productAdd;
var productDelete = function productDelete(idList) {
  return (0, _request.post)('/product/product/delete?idList=' + idList);
};
/**
 * 商品上下架
 */
exports.productDelete = productDelete;
var productShelf = function productShelf(idList, status) {
  return (0, _request.post)('/product/product/shelf?idList=' + idList + '&status=' + status);
};
/**
 * 商品修改
 */
exports.productShelf = productShelf;
var productUpdate = function productUpdate(data) {
  return (0, _request.post)('/product/product/update', data);
};
/**
 * 商品是否热门修改
 */
exports.productUpdate = productUpdate;
var productHot = function productHot(data) {
  return (0, _request.post)('/product/product/hot', data);
};
/**
 * 商品审核
 */
exports.productHot = productHot;
var productReview = function productReview(data) {
  return (0, _request.post)('/product/product/review', data);
};
/**
 * 商品列表
 */
exports.productReview = productReview;
var productPage = function productPage(data) {
  return (0, _request.post)('/product/product/page', data);
};
/**
 * 商品导出
 */
exports.productPage = productPage;
var productExport = function productExport(param, name) {
  return (0, _request.exportExcel)('/product/product/export', param, name);
};
//=============================================================商品SKU=======================================================
/**
 * 商品SKU新增
 */
exports.productExport = productExport;
var skuAdd = function skuAdd(data) {
  return (0, _request.post)('/product/sku/add', data);
};
/**
 * 商品SKU批量删除
 */
exports.skuAdd = skuAdd;
var skuDelete = function skuDelete(idList) {
  return (0, _request.post)('/product/sku/delete?idList=' + idList);
};
/**
 * 商品SKU修改
 */
exports.skuDelete = skuDelete;
var skuUpdate = function skuUpdate(data) {
  return (0, _request.post)('/product/sku/update', data);
};
/**
 * 商品SKU列表
 */
exports.skuUpdate = skuUpdate;
var skuPage = function skuPage(data) {
  return (0, _request.post)('/product/sku/page', data);
};
/**
 * 商品SKU导出
 */
exports.skuPage = skuPage;
var skuExport = function skuExport(param, name) {
  return (0, _request.exportExcel)('/product/sku/export', param, name);
};
//=============================================================绳子会员产品=======================================================
/**
 * 绳子会员产品新增
 */
exports.skuExport = skuExport;
var ropeVipProductAdd = function ropeVipProductAdd(data) {
  return (0, _request.post)('/product/ropeVipProduct/add', data);
};
/**
 * 绳子会员产品批量删除
 */
exports.ropeVipProductAdd = ropeVipProductAdd;
var ropeVipProductDelete = function ropeVipProductDelete(idList) {
  return (0, _request.post)('/product/ropeVipProduct/delete?idList=' + idList);
};
/**
 * 绳子会员产品修改
 */
exports.ropeVipProductDelete = ropeVipProductDelete;
var ropeVipProductUpdate = function ropeVipProductUpdate(data) {
  return (0, _request.post)('/product/ropeVipProduct/update', data);
};
/**
 * 绳子会员产品列表
 */
exports.ropeVipProductUpdate = ropeVipProductUpdate;
var ropeVipProductPage = function ropeVipProductPage(data) {
  return (0, _request.post)('/product/ropeVipProduct/page', data);
};
/**
 * 绳子会员产品导出
 */
exports.ropeVipProductPage = ropeVipProductPage;
var ropeVipProductExport = function ropeVipProductExport(param, name) {
  return (0, _request.exportExcel)('/product/ropeVipProduct/export', param, name);
};
exports.ropeVipProductExport = ropeVipProductExport;