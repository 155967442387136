"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.proxy = void 0;
var proxy = {
  data: function data() {
    return {
      //是否展示详情窗口
      showDetailDialog: false,
      //是否注销枚举
      isDelMap: {
        0: '未注销',
        1: '已注销'
      },
      //是否注销标签颜色枚举
      isDelColorMap: {
        0: 'success',
        1: 'danger'
      },
      //审核状态枚举
      reviewStatusMap: {
        0: '待审核',
        1: '审核通过',
        2: '审核拒绝'
      },
      //审核状态标签颜色枚举
      reviewStatusColorMap: {
        0: 'info',
        1: 'success',
        2: 'danger'
      },
      backUrl: '/partner/proxy/page',
      entity: {},
      rules: {
        area: [{
          required: false,
          message: '请选择代理区域'
        }],
        userId: [{
          required: false,
          message: '请输入用户ID'
        }],
        phone: [{
          required: false,
          message: '请输入手机号'
        }],
        backReason: [{
          required: true,
          message: '请输入驳回原因'
        }],
        reviewStatus: [{
          required: true,
          message: '请选择审核状态'
        }]
      }
    };
  }
};
exports.proxy = proxy;