"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _adUser = require("./ad-user");
var _globalDialog = require("@/mixins/global-dialog");
var _adMasterApi = require("@/api/ad-master-api");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  mixins: [_adUser.adUser, _globalDialog.updateDialog],
  methods: {
    //执行修改操作
    doUpdate: function doUpdate() {
      if (this.entity.rechargeMoney === 0) {
        this.errorMessage('充值金额不能为0');
        return;
      }
      return (0, _adMasterApi.adUserRecharge)(this.entity);
    }
  }
};
exports.default = _default;