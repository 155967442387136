"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ropeLogUpdate = exports.ropeLogPage = exports.ropeLogExport = exports.ropeLogDelete = exports.ropeLogAdd = exports.ropeDeviceDataUpdate = exports.ropeDeviceDataPage = exports.ropeDeviceDataExport = exports.ropeDeviceDataDelete = exports.ropeDeviceDataAdd = void 0;
var _request = require("@/utils/request");
//=============================================================绳子信息=======================================================
/**
 * 绳子信息新增
 */
var ropeDeviceDataAdd = function ropeDeviceDataAdd(data) {
  return (0, _request.post)('/sport/ropeDeviceData/add', data);
};
/**
 * 绳子信息批量删除
 */
exports.ropeDeviceDataAdd = ropeDeviceDataAdd;
var ropeDeviceDataDelete = function ropeDeviceDataDelete(idList) {
  return (0, _request.post)('/sport/ropeDeviceData/delete?idList=' + idList);
};
/**
 * 绳子信息修改
 */
exports.ropeDeviceDataDelete = ropeDeviceDataDelete;
var ropeDeviceDataUpdate = function ropeDeviceDataUpdate(data) {
  return (0, _request.post)('/sport/ropeDeviceData/update', data);
};
/**
 * 绳子信息列表
 */
exports.ropeDeviceDataUpdate = ropeDeviceDataUpdate;
var ropeDeviceDataPage = function ropeDeviceDataPage(data) {
  return (0, _request.post)('/sport/ropeDeviceData/page', data);
};
/**
 * 绳子信息导出
 */
exports.ropeDeviceDataPage = ropeDeviceDataPage;
var ropeDeviceDataExport = function ropeDeviceDataExport(param, name) {
  return (0, _request.exportExcel)('/sport/ropeDeviceData/export', param, name);
};
//=============================================================跳绳记录=======================================================
/**
 * 跳绳记录新增
 */
exports.ropeDeviceDataExport = ropeDeviceDataExport;
var ropeLogAdd = function ropeLogAdd(data) {
  return (0, _request.post)('/sport/ropeLog/add', data);
};
/**
 * 跳绳记录批量删除
 */
exports.ropeLogAdd = ropeLogAdd;
var ropeLogDelete = function ropeLogDelete(idList) {
  return (0, _request.post)('/sport/ropeLog/delete?idList=' + idList);
};
/**
 * 跳绳记录修改
 */
exports.ropeLogDelete = ropeLogDelete;
var ropeLogUpdate = function ropeLogUpdate(data) {
  return (0, _request.post)('/sport/ropeLog/update', data);
};
/**
 * 跳绳记录列表
 */
exports.ropeLogUpdate = ropeLogUpdate;
var ropeLogPage = function ropeLogPage(data) {
  return (0, _request.post)('/sport/ropeLog/page', data);
};
/**
 * 跳绳记录导出
 */
exports.ropeLogPage = ropeLogPage;
var ropeLogExport = function ropeLogExport(param, name) {
  return (0, _request.exportExcel)('/sport/ropeLog/export', param, name);
};
exports.ropeLogExport = ropeLogExport;