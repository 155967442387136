"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.address = void 0;
var address = {
  data: function data() {
    return {
      //是否展示详情窗口
      showDetailDialog: false,
      //是否默认地址枚举
      isDefaultMap: {
        0: '否',
        1: '是'
      },
      //是否默认地址标签颜色枚举
      isDefaultColorMap: {
        0: 'danger',
        1: 'success',
        2: 'danger',
        3: 'warning',
        4: 'info',
        5: 'primary'
      },
      backUrl: '/member/address/page',
      entity: {},
      rules: {
        name: [{
          required: true,
          message: '请输入收件人'
        }, {
          max: 50,
          message: '收件人长度在50个字符内'
        }],
        phone: [{
          required: true,
          message: '请输入收件人联系方式'
        }, {
          max: 20,
          message: '收件人联系方式长度在20个字符内'
        }],
        province: [{
          required: true,
          message: '请输入省份'
        }, {
          max: 20,
          message: '省份长度在20个字符内'
        }],
        city: [{
          required: true,
          message: '请输入市'
        }, {
          max: 20,
          message: '市长度在20个字符内'
        }],
        district: [{
          required: true,
          message: '请输入区'
        }, {
          max: 20,
          message: '区长度在20个字符内'
        }],
        detailAddress: [{
          required: true,
          message: '请输入详细地址'
        }, {
          max: 255,
          message: '详细地址长度在255个字符内'
        }],
        isDefault: [{
          required: true,
          message: '请输入是否默认地址'
        }],
        userId: [{
          required: true,
          message: '请输入用户ID'
        }]
      }
    };
  }
};
exports.address = address;