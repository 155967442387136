"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.sku = void 0;
var sku = {
  data: function data() {
    return {
      //是否展示详情窗口
      showDetailDialog: false,
      backUrl: '/product/sku/page',
      entity: {},
      rules: {
        productId: [{
          required: true,
          message: '请输入商品ID'
        }],
        stock: [{
          required: true,
          message: '请输入库存'
        }],
        image: [{
          required: true,
          message: '请输入图片'
        }, {
          max: 256,
          message: '图片长度在256个字符内'
        }],
        marketPrice: [{
          required: true,
          message: '请输入市场价'
        }],
        price: [{
          required: true,
          message: '请输入售价'
        }],
        basePrice: [{
          required: true,
          message: '请输入成本价'
        }],
        name: [{
          required: true,
          message: '请输入名称'
        }, {
          max: 50,
          message: '名称长度在50个字符内'
        }],
        title: [{
          required: true,
          message: '请输入类型'
        }, {
          max: 50,
          message: '类型长度在50个字符内'
        }]
      }
    };
  }
};
exports.sku = sku;