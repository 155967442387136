"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.adGaveOrder = void 0;
var adGaveOrder = {
  data: function data() {
    return {
      //是否展示详情窗口
      showDetailDialog: false,
      backUrl: '/advertise/adGaveOrder/page',
      entity: {},
      rules: {
        userId: [{
          required: true,
          message: '请输入合伙人ID'
        }],
        adUserId: [{
          required: true,
          message: '请输入广告主ID'
        }],
        amount: [{
          required: true,
          message: '请输入广告卡数量'
        }],
        adUserName: [{
          required: true,
          message: '请输入广告主名称'
        }, {
          max: 255,
          message: '广告主名称长度在255个字符内'
        }]
      }
    };
  }
};
exports.adGaveOrder = adGaveOrder;