var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "platform" },
    [
      _c("el-card", { staticClass: "platform-card" }, [
        _c(
          "div",
          { staticClass: "platform-head" },
          [
            _c(
              "el-row",
              { attrs: { gutter: 24 } },
              [
                _c("el-col", { attrs: { lg: 18, md: 16, sm: 14, xs: 24 } }, [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.systemUser.name) +
                      "，欢迎登录！\n        "
                  ),
                ]),
                _vm._v(" "),
                _c("el-col", { attrs: { lg: 6, md: 8, sm: 10, xs: 24 } }, [
                  _c("span", { staticClass: "platform-head-time" }, [
                    _vm._v("登录时间：" + _vm._s(_vm.systemUser.lastLoginTime)),
                  ]),
                ]),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("el-row", { attrs: { gutter: 24 } }, [
        _c(
          "div",
          { staticStyle: { "margin-top": "20px" } },
          [
            _c(
              "el-col",
              { attrs: { lg: 11, sm: 24, xs: 24 } },
              [_c("china-map", { attrs: { "map-data": _vm.mapData } })],
              1
            ),
            _vm._v(" "),
            _c(
              "el-col",
              { attrs: { lg: 7, sm: 13, xs: 24 } },
              [
                _c(
                  "el-carousel",
                  {
                    attrs: {
                      interval: 4000,
                      "indicator-position": "outside",
                      height: "410px",
                    },
                  },
                  _vm._l(_vm.studentData, function (item, index) {
                    return _c(
                      "el-carousel-item",
                      { key: index },
                      [
                        _c("pie", {
                          attrs: {
                            title: item.title,
                            cate: item.cate,
                            data: item.data,
                          },
                        }),
                      ],
                      1
                    )
                  }),
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-col",
              { attrs: { lg: 6, sm: 11, xs: 24 } },
              [
                _c(
                  "el-row",
                  [
                    _c(
                      "el-col",
                      { attrs: { lg: 24, sm: 9, xs: 24 } },
                      [_c("clock")],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      { attrs: { lg: 24, sm: 15, xs: 24 } },
                      [
                        _c("el-card", { staticClass: "platform-data-card" }, [
                          _c(
                            "span",
                            { staticClass: "platform-data-card-title" },
                            [_vm._v("平台数据：")]
                          ),
                          _vm._v(" "),
                          _c("div", { staticClass: "platform-data-content" }, [
                            _c(
                              "div",
                              { staticClass: "platform-data-content-row" },
                              [
                                _c(
                                  "div",
                                  { staticClass: "platform-data-sub-title" },
                                  [_vm._v("注册用户数：")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass: "platform-data-row-value",
                                    staticStyle: { color: "#3bcb82" },
                                  },
                                  [
                                    _vm._v(
                                      "\n                    " +
                                        _vm._s(
                                          _vm.bigNumberTransform(
                                            _vm.platformData.userNum
                                          )
                                        ) +
                                        "位\n                  "
                                    ),
                                  ]
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "platform-data-content-row" },
                              [
                                _c(
                                  "div",
                                  { staticClass: "platform-data-sub-title" },
                                  [_vm._v("绳子数量：")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass: "platform-data-row-value",
                                    staticStyle: { color: "#d75df1" },
                                  },
                                  [
                                    _vm._v(
                                      "\n                    " +
                                        _vm._s(
                                          _vm.bigNumberTransform(
                                            _vm.platformData.ropeNum
                                          )
                                        ) +
                                        "根\n                  "
                                    ),
                                  ]
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "platform-data-content-row" },
                              [
                                _c(
                                  "div",
                                  { staticClass: "platform-data-sub-title" },
                                  [_vm._v("商户数量：")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass: "platform-data-row-value",
                                    staticStyle: { color: "#3cb4ec" },
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.bigNumberTransform(
                                          _vm.platformData.sellerNum
                                        )
                                      ) + "家\n                  "
                                    ),
                                  ]
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "platform-data-content-row" },
                              [
                                _c(
                                  "div",
                                  { staticClass: "platform-data-sub-title" },
                                  [_vm._v("教师数量：")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass: "platform-data-row-value",
                                    staticStyle: { color: "#fde15a" },
                                  },
                                  [
                                    _vm._v(
                                      "\n                    " +
                                        _vm._s(
                                          _vm.bigNumberTransform(
                                            _vm.platformData.teacherNum
                                          )
                                        ) +
                                        "位\n                  "
                                    ),
                                  ]
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "platform-data-content-row" },
                              [
                                _c(
                                  "div",
                                  { staticClass: "platform-data-sub-title" },
                                  [_vm._v("代理数量：")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass: "platform-data-row-value",
                                    staticStyle: { color: "#ff765a" },
                                  },
                                  [
                                    _vm._v(
                                      "\n                    " +
                                        _vm._s(
                                          _vm.bigNumberTransform(
                                            _vm.platformData.teacherNum
                                          )
                                        ) +
                                        "位\n                  "
                                    ),
                                  ]
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "platform-data-content-row" },
                              [
                                _c(
                                  "div",
                                  { staticClass: "platform-data-sub-title" },
                                  [_vm._v("累计交易金额：")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass: "platform-data-row-value",
                                    staticStyle: { color: "#ef2828" },
                                  },
                                  [
                                    _vm._v(
                                      "\n                    " +
                                        _vm._s(
                                          _vm.bigNumberTransform(
                                            _vm.platformData.transactionTotal
                                          )
                                        ) +
                                        "元\n                  "
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ]),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("el-row", { attrs: { gutter: 24 } }, [
        _c(
          "div",
          { staticStyle: { "margin-top": "20px" } },
          [
            _c(
              "el-col",
              { attrs: { lg: 12, sm: 24, xs: 24 } },
              [
                _c(
                  "el-carousel",
                  {
                    attrs: {
                      interval: 4000,
                      "indicator-position": "outside",
                      height: "410px",
                    },
                  },
                  [
                    _c(
                      "el-carousel-item",
                      [
                        _c("pie", {
                          attrs: {
                            "o-label": _vm.isPc(),
                            title: _vm.orderPieData.title,
                            cate: _vm.orderPieData.cate,
                            data: _vm.orderPieData.data,
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-carousel-item",
                      [
                        _c("line-stack", {
                          attrs: {
                            cate: _vm.orderSuccessData.cate,
                            data: _vm.orderSuccessData.data,
                            title: _vm.orderSuccessData.title,
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-col",
              { attrs: { lg: 12, sm: 24, xs: 24 } },
              [
                _c(
                  "el-carousel",
                  {
                    attrs: {
                      interval: 4000,
                      "indicator-position": "outside",
                      height: "410px",
                    },
                  },
                  [
                    _c(
                      "el-carousel-item",
                      [
                        _c("line-stack", {
                          attrs: {
                            title: _vm.userRegisterData.title,
                            cate: _vm.userRegisterData.cate,
                            data: _vm.userRegisterData.data,
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-carousel-item",
                      [
                        _c("bar", {
                          attrs: {
                            title: "跳绳人数数据",
                            data: _vm.ropeBarData,
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c(
        "el-row",
        { attrs: { gutter: 24 } },
        [
          _c(
            "el-col",
            { attrs: { md: 12, xs: 24 } },
            [
              _c(
                "el-card",
                { staticClass: "platform-card platform-card-bottom" },
                [
                  _c("div", { staticClass: "platform-card-title" }, [
                    _vm._v("\n          在线系统用户："),
                    _c("span", { staticClass: "platform-card-title-num" }, [
                      _vm._v(_vm._s(_vm.onlineData.total)),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "platform-card-content" },
                    [
                      _vm._l(_vm.onlineData.onlineList, function (onlineUser) {
                        return _c(
                          "div",
                          {
                            key: onlineUser.id,
                            staticClass: "platform-card-content-row",
                            style: _vm.isNotPc() && { fontSize: "14px" },
                          },
                          [
                            _c(
                              "el-row",
                              { attrs: { gutter: 24 } },
                              [
                                _c("el-col", { attrs: { span: 8 } }, [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(onlineUser.name) +
                                      "\n              "
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("el-col", { attrs: { span: 8 } }, [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(onlineUser.role) +
                                      "\n              "
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("el-col", { attrs: { span: 8 } }, [
                                  _c(
                                    "div",
                                    {
                                      class: onlineUser.isLogin
                                        ? "platform-card-content-row-login"
                                        : "platform-card-content-row-logout",
                                    },
                                    [
                                      _vm._v(
                                        "\n                  " +
                                          _vm._s(
                                            onlineUser.isLogin ? "在线" : "离线"
                                          ) +
                                          "\n                "
                                      ),
                                    ]
                                  ),
                                ]),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      }),
                      _vm._v(" "),
                      _vm.onlineData.onlineList.length < _vm.onlineData.total
                        ? _c(
                            "div",
                            {
                              staticStyle: {
                                "font-size": "20px",
                                color: "#6c6c6c",
                              },
                            },
                            [_vm._v("\n            ······\n          ")]
                          )
                        : _vm._e(),
                    ],
                    2
                  ),
                ]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-col",
            { attrs: { md: 12, xs: 24 } },
            [
              _c(
                "el-card",
                { staticClass: "platform-card platform-card-bottom" },
                [
                  _c("div", { staticClass: "platform-card-title" }, [
                    _vm._v("最近操作记录"),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "platform-card-content" },
                    _vm._l(_vm.operateLogs, function (operateLog) {
                      return _c(
                        "div",
                        {
                          key: operateLog.id,
                          staticClass: "platform-card-content-row",
                          style: _vm.isNotPc() && { fontSize: "14px" },
                        },
                        [
                          _c(
                            "el-row",
                            { attrs: { gutter: 24 } },
                            [
                              _c("el-col", { attrs: { span: 8 } }, [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(operateLog.createTime) +
                                    "\n              "
                                ),
                              ]),
                              _vm._v(" "),
                              _c("el-col", { attrs: { span: 16 } }, [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(operateLog.content) +
                                    "\n              "
                                ),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    }),
                    0
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }