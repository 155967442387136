"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.adDurationLog = void 0;
var adDurationLog = {
  data: function data() {
    return {
      //是否展示详情窗口
      showDetailDialog: false,
      backUrl: '/advertise/adDurationLog/page',
      entity: {},
      rules: {
        userId: [{
          required: true,
          message: '请输入用户ID'
        }],
        duration: [{
          required: true,
          message: '请输入看广告时长（秒）'
        }],
        deviceNo: [{
          required: true,
          message: '请输入设备号'
        }, {
          max: 255,
          message: '设备号长度在255个字符内'
        }],
        adRatingId: [{
          required: true,
          message: '请输入广告子单ID'
        }],
        costAdCardNum: [{
          required: true,
          message: '请输入消耗多少广告卡'
        }],
        costUserId: [{
          required: true,
          message: '请输入广告主'
        }],
        proxyId: [{
          required: true,
          message: '请输入代理ID'
        }],
        userAwardPoints: [{
          required: true,
          message: '请输入用户奖励健康宝'
        }],
        proxyAwardPoints: [{
          required: true,
          message: '请输入代理奖励健康宝'
        }],
        platformAwardPoints: [{
          required: true,
          message: '请输入平台滞留的健康宝'
        }]
      }
    };
  }
};
exports.adDurationLog = adDurationLog;