var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "新增商户",
        visible: _vm.dialogVisible,
        "close-on-click-modal": false,
        center: "",
        width: _vm.dialogWidth,
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
        close: _vm.closeDialog,
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "elForm",
          staticClass: "dialog-form",
          attrs: {
            model: _vm.entity,
            rules: _vm.rules,
            inline: true,
            "label-width": _vm.getLabelWidth(),
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "商家店铺名", prop: "name" } },
            [
              _c("el-input", {
                attrs: { clearable: "" },
                model: {
                  value: _vm.entity.name,
                  callback: function ($$v) {
                    _vm.$set(_vm.entity, "name", $$v)
                  },
                  expression: "entity.name",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "商家logo", prop: "logo" } },
            [
              _c("el-input", {
                attrs: { clearable: "" },
                model: {
                  value: _vm.entity.logo,
                  callback: function ($$v) {
                    _vm.$set(_vm.entity, "logo", $$v)
                  },
                  expression: "entity.logo",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "退货手机号", prop: "refundPhone" } },
            [
              _c("el-input", {
                attrs: { clearable: "" },
                model: {
                  value: _vm.entity.refundPhone,
                  callback: function ($$v) {
                    _vm.$set(_vm.entity, "refundPhone", $$v)
                  },
                  expression: "entity.refundPhone",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "退货收件人姓名", prop: "refundName" } },
            [
              _c("el-input", {
                attrs: { clearable: "" },
                model: {
                  value: _vm.entity.refundName,
                  callback: function ($$v) {
                    _vm.$set(_vm.entity, "refundName", $$v)
                  },
                  expression: "entity.refundName",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "退货地址", prop: "refundAddress" } },
            [
              _c("el-input", {
                attrs: { clearable: "" },
                model: {
                  value: _vm.entity.refundAddress,
                  callback: function ($$v) {
                    _vm.$set(_vm.entity, "refundAddress", $$v)
                  },
                  expression: "entity.refundAddress",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "余额", prop: "balance" } },
            [
              _c("el-input", {
                directives: [
                  {
                    name: "number-input",
                    rawName: "v-number-input",
                    value: { type: "decimal" },
                    expression: "{ type: 'decimal' }",
                  },
                ],
                attrs: { clearable: "" },
                model: {
                  value: _vm.entity.balance,
                  callback: function ($$v) {
                    _vm.$set(_vm.entity, "balance", $$v)
                  },
                  expression: "entity.balance",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "联系电话", prop: "phone" } },
            [
              _c("el-input", {
                attrs: { clearable: "" },
                model: {
                  value: _vm.entity.phone,
                  callback: function ($$v) {
                    _vm.$set(_vm.entity, "phone", $$v)
                  },
                  expression: "entity.phone",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "状态", prop: "status" } },
            [
              _c(
                "el-select",
                {
                  model: {
                    value: _vm.entity.status,
                    callback: function ($$v) {
                      _vm.$set(_vm.entity, "status", $$v)
                    },
                    expression: "entity.status",
                  },
                },
                _vm._l(_vm.statusMap, function (value, key) {
                  return _c("el-option", {
                    key: key,
                    attrs: { label: value, value: key },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "审核不通过理由", prop: "backReason" } },
            [
              _c("el-input", {
                attrs: { clearable: "" },
                model: {
                  value: _vm.entity.backReason,
                  callback: function ($$v) {
                    _vm.$set(_vm.entity, "backReason", $$v)
                  },
                  expression: "entity.backReason",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "经度", prop: "longitude" } },
            [
              _c("el-input", {
                directives: [
                  {
                    name: "number-input",
                    rawName: "v-number-input",
                    value: { type: "decimal" },
                    expression: "{ type: 'decimal' }",
                  },
                ],
                attrs: { clearable: "" },
                model: {
                  value: _vm.entity.longitude,
                  callback: function ($$v) {
                    _vm.$set(_vm.entity, "longitude", $$v)
                  },
                  expression: "entity.longitude",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "纬度", prop: "latitude" } },
            [
              _c("el-input", {
                directives: [
                  {
                    name: "number-input",
                    rawName: "v-number-input",
                    value: { type: "decimal" },
                    expression: "{ type: 'decimal' }",
                  },
                ],
                attrs: { clearable: "" },
                model: {
                  value: _vm.entity.latitude,
                  callback: function ($$v) {
                    _vm.$set(_vm.entity, "latitude", $$v)
                  },
                  expression: "entity.latitude",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "地址", prop: "address" } },
            [
              _c("el-input", {
                attrs: { clearable: "" },
                model: {
                  value: _vm.entity.address,
                  callback: function ($$v) {
                    _vm.$set(_vm.entity, "address", $$v)
                  },
                  expression: "entity.address",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "联系人", prop: "contactName" } },
            [
              _c("el-input", {
                attrs: { clearable: "" },
                model: {
                  value: _vm.entity.contactName,
                  callback: function ($$v) {
                    _vm.$set(_vm.entity, "contactName", $$v)
                  },
                  expression: "entity.contactName",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "身份证人头面", prop: "idCardFront" } },
            [
              _c("el-input", {
                attrs: { clearable: "" },
                model: {
                  value: _vm.entity.idCardFront,
                  callback: function ($$v) {
                    _vm.$set(_vm.entity, "idCardFront", $$v)
                  },
                  expression: "entity.idCardFront",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "身份证国徽面", prop: "idCardBackend" } },
            [
              _c("el-input", {
                attrs: { clearable: "" },
                model: {
                  value: _vm.entity.idCardBackend,
                  callback: function ($$v) {
                    _vm.$set(_vm.entity, "idCardBackend", $$v)
                  },
                  expression: "entity.idCardBackend",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "营业执照", prop: "business" } },
            [
              _c("el-input", {
                attrs: { clearable: "" },
                model: {
                  value: _vm.entity.business,
                  callback: function ($$v) {
                    _vm.$set(_vm.entity, "business", $$v)
                  },
                  expression: "entity.business",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "许可证", prop: "license" } },
            [
              _c("el-input", {
                attrs: { clearable: "" },
                model: {
                  value: _vm.entity.license,
                  callback: function ($$v) {
                    _vm.$set(_vm.entity, "license", $$v)
                  },
                  expression: "entity.license",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "开户名", prop: "bankName" } },
            [
              _c("el-input", {
                attrs: { clearable: "" },
                model: {
                  value: _vm.entity.bankName,
                  callback: function ($$v) {
                    _vm.$set(_vm.entity, "bankName", $$v)
                  },
                  expression: "entity.bankName",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "银行卡", prop: "bankAccount" } },
            [
              _c("el-input", {
                attrs: { clearable: "" },
                model: {
                  value: _vm.entity.bankAccount,
                  callback: function ($$v) {
                    _vm.$set(_vm.entity, "bankAccount", $$v)
                  },
                  expression: "entity.bankAccount",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "开户行", prop: "bank" } },
            [
              _c("el-input", {
                attrs: { clearable: "" },
                model: {
                  value: _vm.entity.bank,
                  callback: function ($$v) {
                    _vm.$set(_vm.entity, "bank", $$v)
                  },
                  expression: "entity.bank",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "开户支行", prop: "bankBranch" } },
            [
              _c("el-input", {
                attrs: { clearable: "" },
                model: {
                  value: _vm.entity.bankBranch,
                  callback: function ($$v) {
                    _vm.$set(_vm.entity, "bankBranch", $$v)
                  },
                  expression: "entity.bankBranch",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "开户手机号", prop: "bankPhone" } },
            [
              _c("el-input", {
                attrs: { clearable: "" },
                model: {
                  value: _vm.entity.bankPhone,
                  callback: function ($$v) {
                    _vm.$set(_vm.entity, "bankPhone", $$v)
                  },
                  expression: "entity.bankPhone",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "经营时段", prop: "rangeTime" } },
            [
              _c("el-input", {
                attrs: { clearable: "" },
                model: {
                  value: _vm.entity.rangeTime,
                  callback: function ($$v) {
                    _vm.$set(_vm.entity, "rangeTime", $$v)
                  },
                  expression: "entity.rangeTime",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "form-button-container" },
        [
          _c(
            "el-button",
            {
              attrs: { type: "danger", icon: "el-icon-back" },
              on: {
                click: function ($event) {
                  _vm.dialogVisible = false
                },
              },
            },
            [_vm._v("返回")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              staticClass: "form-button-submit",
              attrs: { type: "primary", icon: "el-icon-check" },
              on: { click: _vm.submitForm },
            },
            [_vm._v("提交")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }