"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.productCategory = void 0;
var productCategory = {
  data: function data() {
    return {
      //是否展示详情窗口
      showDetailDialog: false,
      backUrl: '/product/productCategory/page',
      entity: {},
      //状态枚举
      statusMap: {
        0: '禁用',
        1: '启用'
      },
      //状态标签颜色枚举
      statusColorMap: {
        0: 'danger',
        1: 'success',
        2: 'danger',
        3: 'warning',
        4: 'info',
        5: 'primary'
      },
      rules: {
        sort: [{
          required: true,
          message: '请输入排序'
        }],
        status: [{
          required: true,
          message: '请选择状态'
        }],
        image: [{
          required: true,
          message: '请上传图片'
        }],
        name: [{
          required: true,
          message: '请输入名称'
        }, {
          max: 256,
          message: '名称长度在256个字符内'
        }],
        parentId: [{
          required: true,
          message: '请输入父级ID'
        }],
        level: [{
          required: true,
          message: '请输入层级'
        }]
      }
    };
  }
};
exports.productCategory = productCategory;