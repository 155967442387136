"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _teacherRaceLog = require("./teacher-race-log");
var _globalDialog = require("@/mixins/global-dialog");
var _teacherApi = require("@/api/teacher-api");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  mixins: [_teacherRaceLog.teacherRaceLog, _globalDialog.updateDialog],
  methods: {
    //执行修改操作
    doUpdate: function doUpdate() {
      return (0, _teacherApi.teacherRaceLogUpdate)(this.entity);
    }
  }
};
exports.default = _default;