"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.proxyRopeStatistics = void 0;
var proxyRopeStatistics = {
  data: function data() {
    return {
      //是否展示详情窗口
      showDetailDialog: false,
      backUrl: '/partner/proxyRopeStatistics/page',
      entity: {},
      rules: {
        proxyId: [{
          required: true,
          message: '请输入代理ID'
        }],
        userId: [{
          required: true,
          message: '请输入代理用户ID'
        }],
        area: [{
          required: true,
          message: '请输入代理区域'
        }, {
          max: 100,
          message: '代理区域长度在100个字符内'
        }],
        activeCount: [{
          required: true,
          message: '请输入激活数量'
        }],
        payCount: [{
          required: true,
          message: '请输入交付保证金数量'
        }]
      }
    };
  }
};
exports.proxyRopeStatistics = proxyRopeStatistics;